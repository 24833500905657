import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Singelblog.css";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { IoPersonCircle } from "react-icons/io5";
import Redirect from "../../../AllPageComponents/Rederict";
const Singelblog = () => {
  const { id } = useParams();
  const { slug } = useParams();

  const [singelblogs, setSingelBlogs] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}blog/get_blog/${id}`
        );
        setSingelBlogs(response.data.data);
      } catch (error) {
        console.error("Error fetching blog data:", error);
      }
    };

    fetchData();
    window.scrollTo(0, 0);
  }, [id]);

  //console.log("singeldata", singelblogs);

  const { metatitle, metadesc } = singelblogs;

  return (
    <>
    <Redirect/>
      <Helmet>
        <title>{metatitle}</title>
        <meta name={metadesc} content="test" />
      </Helmet>

      <div className="fullpage">
        <div className="imagedives">
          {/* <span className='catagoryspan'>{singelblogs.category}</span> */}
          <h1 className="titleblogpost">{singelblogs.title}</h1>

        </div>
        <div className="titlediv">
          <div className="catagorytitlee">
            <span className="catagoryspan">{singelblogs.category}</span>

            <span>
              <IoPersonCircle fontSize={"35px"} color="#0166B3" />
              {"Admin"}
              {"17-04-2022"}
            </span>
          </div>

        </div>
        <div className="blog-img-div">
          <img
            className="blog-img"
            src={`${process.env.REACT_APP_MAIN_URL}/uploads/blog_images/${singelblogs.image}`}
            alt=""
          />
        </div>
        <div
          className="textblogs"
          dangerouslySetInnerHTML={{ __html: singelblogs.blog }}
        ></div>
      </div>
    </>
  );
};

export default Singelblog;
