import React, { useState, useEffect } from "react";
import "./AgeModal.css";
import { RxCross1 } from "react-icons/rx";
import { useSelector } from "react-redux";
import calculateAge from "./AgeModalHelper";
import { UpdatePlanAgeApi } from "../../redux/Slice";
import axios from "axios";
import { encryption } from "../../helper/common";
import { useNavigate } from "react-router-dom";
import ProposalPop from "../../commonComponents/ProposalPop";
import {
  UpdatePlanApi,
  updateCoverAmount,
  updateInsuredData,
  updateTotalAmount,
  updateProposalClick,
  updateInsuredMembers,
  resetAddonData,
  updatePremium,
  updateFullHdfcPremium,
  selectProposal,
  updateInsuredArray,
  updatePremiumData,
  settingProposal,
} from "../../redux/Slice";
import { useDispatch } from "react-redux";
import Loader from "../../../AllPageComponents/Loader";
import Swal from "sweetalert2";

import { updateQuickQuoteCompanywise } from "../../Api/Api";

const AgeModal = ({
  isOpen,
  showModal,
  setShowModal,
  ageDifference,
  ageSame,
  setProposalCorrect,
  setProposerCorrect,
  modalResponse,
  setApiResponse,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [newPremium, setNewPremium] = useState("");
  const [newUserData, setNewUserData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [responseData, setResponseData] = useState("");
  const [stopLoading, setStopLoading] = useState(false);
  const [showPop , setShowPop] = useState(false);

  const [appear, setAppear] = useState(false);

  const [premiumData, setPremiumData] = useState("");

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const unique_id = params.get("req_id");
  const plan_id = params.get("plan_id");
  const c_id = params.get("c_id");

  const dispatch = useDispatch();

  const nav = useNavigate();

  // the redux work is here ---------------------------

  const { fetchPlandata } = useSelector((state) => {
    return state.FetchPlan;
  });

  console.log("modalResponse in agemodal", modalResponse);



  const { oldSumInsured, oldTenure } = useSelector((state) => {
    return state.FetchPlan;
  });

  // const planData = fetchPlandata[c_id].find(
  //   (element) => element.planCode == plan_id
  // );

  // the previous premium data fetching here -------------------------

  const { addAddOnsdata } = useSelector((state) => {
    return state.FetchPlan;
  });

  // const totalAmount = useSelector(
  //   (state) => state.FetchPlan.financialData.totalAmount
  // );

useEffect(()=>{
  if(modalResponse == ""){
    handleError("Oops there is an error !");
    return

  }
},[modalResponse])

  const totalPremium = modalResponse?.response?.premiumBreakup
    ? modalResponse?.response?.premiumBreakup?.totalPremium || 0
    : "";

  const { userdata } = useSelector((state) => {
    return state.FetchPlan;
  });
  const proposalData = useSelector(selectProposal);

  console.log("proposaldata in agemodal", proposalData);

  //console.log("the userdata in agemodal page", userdata);

  // --------------------- redux work end --------------------------

  // function to change the premium with updated data ---------------------

  const calculateAgeGap = (agedata, dateOfBirth) => {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    //console.log("the agegap ", age);

    return age;
  };

  // the function to update insured age locally --------------

  const updateInsuredDetails = (insuredDetails, index, newAge) => {
    let updatedInsuredDetails;

    let insuredArr = JSON.parse(insuredDetails);
    let insured = insuredArr[index];

    // Check if the age in the insuredDetails array is different from the corresponding newAge
    if (insured.InsuredAge !== newAge) {
      // If ages are different, create a new object with the updated age and push it to the updatedInsuredDetails array
      updatedInsuredDetails = {
        ...insured,
        InsuredAge: newAge,
      };
    } else {
      // If ages are the same, push the original insured object to the updatedInsuredDetails array
      updatedInsuredDetails = insured;
    }

    return updatedInsuredDetails;
  };

  const updateAllInsuredAges = (insuredDetails, newAges) => {
    let updatedInsuredDetails = [];

    let insuredArr = insuredDetails;
    insuredArr.forEach((insured, index) => {
      let ageChangeFound = false;
      for (var i = 0; i < newAges.length; i++) {
        let newAgeItem = newAges[i];

        //if index of current relation was found in the new ages list
        if (newAgeItem.index == index) {
          ageChangeFound = true;

          // Check if the age in the insuredDetails array is different from the corresponding newAge
          if (insured.InsuredAge !== newAgeItem.newAge) {
            // If ages are different, create a new object with the updated age and push it to the updatedInsuredDetails array
            updatedInsuredDetails.push({
              ...insured,
              InsuredAge: newAgeItem.newAge,
            });
          } else {
            // If ages are the same, push the original insured object to the updatedInsuredDetails array
            updatedInsuredDetails.push(insured);
          }
        }

        if (ageChangeFound) break;
      }
      if (!ageChangeFound) {
        updatedInsuredDetails.push(insured);
      }
    });

    return updatedInsuredDetails;
  };

  const premiumChange = (companyName, planCode) => {
    setIsLoading(true);

    // getting the new insuredage array from localstorage ---------------
    const storedInsuredDetail = proposalData.InsuredDetail;
    let parsedInsuredDetail = null;
    // if (typeof storedInsuredDetail === 'string') {
    //   try {
    //     parsedInsuredDetail = JSON.parse(storedInsuredDetail);
    //   } catch (error) {
    //     console.error("Failed to parse storedInsuredDetail as JSON:", error);
    //   }
    // }

    let updatedInsuredDetails = updateAllInsuredAges(
      storedInsuredDetail,
      ageDifference
    );

    ////console.log("the arr in useEffect", arr);
    //console.log("the updated insured", updatedInsuredDetails);
    console.log("modalresponse", modalResponse)

    let payloadnew = 
      {
        ProposalName: proposalData.InsuredDetail[0].InsuredName,
        Proposalphonenumber:modalResponse.request.Proposalphonenumber,
        PinCode: modalResponse.request.PinCode,
        District: modalResponse.request.District,
        State: modalResponse.request.State,
        PED:modalResponse.request.PED,
        Gender: proposalData.Gender,
        tenure: modalResponse.request.tenure,
        sum_insured: modalResponse.request.sum_insured,
        InsuredDetail:updatedInsuredDetails,
        PlanCode: plan_id,
        CompanyName: c_id,
        HDCRiderSI : modalResponse?.request?.HDCRiderSI ? modalResponse.request.HDCRiderSI :0,
        addons : modalResponse?.request?.addons ? modalResponse.request.addons :"",
        deductible : modalResponse?.request?.deductible ? modalResponse.request.deductible :0
    }
    

    const updatedUserdata = {
      ...proposalData,
      CompanyName: c_id,
      PlanCode: plan_id,
      InsuredDetail: updatedInsuredDetails,
    };

    console.log("the updated userData", payloadnew);
    // alert(JSON.stringify(updatedUserdata));

    setNewUserData(payloadnew);

    updateQuickQuoteCompanywise(payloadnew, unique_id).then((data) => {
      if (data?.data) {
        setResponseData(data?.data);
      }
      if (data.data.response == null) {
        setStopLoading(true);
        setIsLoading(false);
        return;
      }
      // dispatch(settingProposal(data.data.request));

      console.log("the data", data.data.response.premiumBreakup.totalPremium);
      if (showModal == true) {
        dispatch(updateInsuredArray(data.data.request.InsuredDetail));
        // data.data.request.InsuredDetail.map((item, index) => {
        //   // Dispatch action to update insured data in Redux
        //   dispatch(updateInsuredData(item));
        // });

        //finally, all old data is updated, so push to local storage

        data.data.request.InsuredDetail &&
          localStorage.setItem(
            "InsuredDetail",
            JSON.stringify(data.data.request.InsuredDetail)
          );
      }
      if (totalPremium == data.data.response.premiumBreakup.totalPremium) {
        // submitAccept();
        if (c_id == "HDFC") {
          dispatch(updateFullHdfcPremium(premiumData));
        }
        nav(
          "/" +
            "medicaldetails" +
            "?req_id=" +
            unique_id +
            "&c_id=" +
            c_id +
            "&plan_id=" +
            plan_id
        );

        return;
      }

      setIsLoading(false);
      console.log(
        "the new premium response",
        data.data.response.premiumBreakup.totalPremium
      );

      if (c_id == "HDFC") {
        setPremiumData(data.data.response.hdfc_premium);
      }
      if (data.data.response) {
        setNewPremium(data.data.response.premiumBreakup.totalPremium);
      }
      setAppear(true);
      //  dispatch(updateCoverAmount({cname:companyName,amount:selectedValue }));
      //      //console.log("the data from new api . ",data)
    });
  };

  useEffect(() => {
    // setIsLoading(true)
    premiumChange(c_id, plan_id);
  }, []);
  // premium change function end ---------------------

  //the view plan button function --------------------

  const handleOnClickView = async () => {
    // Call the API or perform any other necessary action
    setIsLoading(true);
    try {
      // let payloadRequest = {
      //   ...modalResponse.request,
      //   planCode: plan_id,
      //   CompanyName: c_id,
      //   InsuredDetail: newUserData.InsuredDetail,
      // };
      let payloadRequest = {
      
          ProposalName: proposalData.InsuredDetail[0].InsuredName,
          Proposalphonenumber:modalResponse.request.Proposalphonenumber,
          PinCode: modalResponse.request.PinCode,
          District: modalResponse.request.District,
          State: modalResponse.request.State,
          PED:modalResponse.request.PED,
          Gender: proposalData.Gender,
          tenure: modalResponse.request.tenure,
          sum_insured: modalResponse.request.sum_insured,
          InsuredDetail: responseData.request.InsuredDetail,
          PlanCode: plan_id,
          CompanyName: c_id,
          HDCRiderSI : modalResponse?.request?.HDCRiderSI ? modalResponse.request.HDCRiderSI :0,
          addons : modalResponse?.request?.addons ? modalResponse.request.addons :"",
          deductible : modalResponse?.request?.deductible ? modalResponse.request.deductible :0
      
      
      }

      let response = await updateQuickQuoteCompanywise(
        payloadRequest,
        unique_id
      ).then((res) => {
        setResponseData(res.data);
        if (res.data.response == null) {
          setStopLoading(true);
          setIsLoading(false);
          return;
        }
        // dispatch(settingProposal(res.data.request));

        setIsLoading(false);

        nav(`/plans?req_id=${unique_id}`);
      });
    } catch (error) {
      console.log("the error", error);
    }
    //console.log("the localstorage", localStorage);

    // ---------------------------------------------------------------------------------
  };

  const submitViewPlan = () => {
    dispatch(updateInsuredArray(newUserData.InsuredDetail));

    //finally, all old data is updated, so push to local storage

    newUserData &&
      localStorage.setItem(
        "InsuredDetail",
        JSON.stringify(newUserData.InsuredDetail)
      );
    // dispatch(updateInsuredData(newUserData.InsuredDetail));

    // navigation to plans page and update the age ------------
    handleOnClickView();
  };



  const handlePopupClose = () => {
    setShowPop(false);
  };

  const submitAccept = () => {
    const newData = responseData.request.InsuredDetail.map((item, index) => ({
      data: [
        {
          key: "InsuredAge",
          value: parseInt(item.InsuredAge),
        },
      ],
      index: index,
    }));

    //console.log("the newdata in newinsured accept", newData);

    newData.map((item, index) => {
      // Dispatch action to update insured data in Redux
      dispatch(updateInsuredData(item));
    });

    //finally, all old data is updated, so push to local storage

    responseData.request &&
      localStorage.setItem(
        "InsuredDetail",
        JSON.stringify(responseData.request.InsuredDetail)
      );

    // handleonClickAccept();
    // dispatch(updateInsuredMembers(newUserData.InsuredDetail));
    dispatch(updateInsuredArray(responseData.request.InsuredDetail));

    dispatch(updateTotalAmount(newPremium));
    // dispatch(updatePremium(newPremium))
    dispatch(updatePremiumData(newPremium));
    updatePremium();
  };

  const updatePremium = async () => {
    setIsLoading(true);
    try {
      // let payloadRequest = {
      //   ...proposalData,
      //   planCode: plan_id,
      //   CompanyName: c_id,
      //   InsuredDetail: newUserData.InsuredDetail,
      // };
      let payloadRequest = 
      {
        ProposalName: proposalData.InsuredDetail[0].InsuredName,
        Proposalphonenumber:modalResponse.request.Proposalphonenumber,
        PinCode: modalResponse.request.PinCode,
        District: modalResponse.request.District,
        State: modalResponse.request.State,
        PED:modalResponse.request.PED,
        Gender: proposalData.Gender,
        tenure: modalResponse.request.tenure,
        sum_insured: modalResponse.request.sum_insured,
        InsuredDetail:responseData.request.InsuredDetail,
        PlanCode: plan_id,
          CompanyName: c_id,
          HDCRiderSI : modalResponse?.request?.HDCRiderSI ? modalResponse.request.HDCRiderSI :0,
          addons : modalResponse?.request?.addons ? modalResponse.request.addons :"",
          deductible : modalResponse?.request?.deductible ? modalResponse.request.deductible :0
    }

      let response = await updateQuickQuoteCompanywise(
        payloadRequest,
        unique_id
      ).then((res) => {
        setResponseData(res.data);
        if (res.data.response == null) {
          setStopLoading(true);
          setIsLoading(false);
          return;
        }
        // dispatch(settingProposal(res.data.request));

        setIsLoading(false);

        nav(
          "/" +
            "medicaldetails" +
            "?req_id=" +
            unique_id +
            "&c_id=" +
            c_id +
            "&plan_id=" +
            plan_id
        );
      });
    } catch (error) {
      console.log("the error", error);
    }
  };

  useEffect(() => {
    setIsActive(showModal);
    setIsClosing(false);
    if (isOpen) {
      document.body.classList.add("popup-open");
    } else {
      document.body.classList.remove("popup-open");
    }
  }, [showModal]);

  const handleClose = () => {
    setIsClosing(true);
    setAppear(false);

    setShowModal(false);
    document.body.classList.remove("popup-open");
    dispatch(updateProposalClick(false));
  };

  const handleError = (message) => {
    Swal.fire({
      title: "Error!",
      text: message,
      icon: "error",
      confirmButtonText: "OK",
    }).then((result) => {
      if (result.isConfirmed) {
        // Reload the page when user clicks "OK"
        window.location.reload();
      }
    });
    setStopLoading(true);
    setIsLoading(false);
    return;
  };

  useEffect(() => {
    if (responseData ? responseData.response == null : "") {
      setStopLoading(true);
      setIsLoading(false);

      handleError("Oops there is an Error");
      return;
    }
  }, [responseData]);

  return (
    <>
      {isLoading && <Loader />}
      {stopLoading == false && (
        <div className={appear ? "modal-wrapper" : "appear-none"}>
          <div
            className={`overlay ${isActive ? "active" : ""}`}
            onClick={handleClose}
          ></div>
          <div
            className={`slideInComponent ${isActive ? "active" : ""} ${
              isClosing ? "closing" : ""
            }`}
          >
            {/*_____________ the header part start here ___________  */}

            <div className="ageupper">
              <h3>Premium Revised</h3>
              {/* <button className="ageCloseButton" onClick={handleClose}>
              <RxCross1 className="ageCloseButton-icon" />
            </button> */}
            </div>

            {/*________ header end _____ */}

            {/* the body of modal start _________ */}

            <div className="agecontext">
              <p className="contextwarning">
                Please Note: - Based on the birth date entered your premium has
                been revised.
              </p>

              {/* <div className="agecontext-lower"> */}
              <div className="d-flex align-items-center">
                <img
                  style={{ width: "80px" }}
                  src={modalResponse.response.insurerLogo}
                />
                <p className="p-2">
                  {modalResponse.response.planName} -{" "}
                  {modalResponse.response.planDescription}
                </p>
              </div>

              {/* _____________the member details start here ________________ */}

              {ageDifference.map((ageItem, index) => {
                //console.log("the ageitem", ageItem);

                return (
                  <div className="age-member-details">
                    <p className="age-membername">
                      Member - {ageItem.item.InsuredName}
                    </p>
                    <div className="age-difference-details">
                      <div className="agedetails-flex">
                        <p>Previous age</p>
                        <h3>
                          <s>{ageItem.item.InsuredAge == 0 ? "< 91 Days" : `${ageItem.item.InsuredAge} Years`}  </s>
                        </h3>
                      </div>
                      <div
                        className="agedetails-flex"
                        style={{ marginRight: "28px" }}
                      >
                        <p>As per date of birth</p>
                        <h3>{calculateAge(ageItem.item.dateOfbirth)==0 ? "< 91 Days" : `${calculateAge(ageItem.item.dateOfbirth)} Years`} </h3>
                      </div>
                    </div>
                  </div>
                );
              })}

              {ageSame.map((ageItem, index) => {
                //console.log("the ageitem", ageItem);

                return (
                  <div className="age-member-details">
                    <p className="age-membername">
                      Member - {ageItem.InsuredName}
                    </p>
                    <div className="age-difference-details">
                      <div className="agedetails-flex">
                        <p>Current age</p>
                        <h3>{calculateAge(ageItem.dateOfbirth)==0? " < 91 Days" : `${calculateAge(ageItem.dateOfbirth)} Years`} </h3>
                      </div>
                    </div>
                  </div>
                );
              })}

              {/*________________ member details end ___________________ */}
            </div>

            <div className="age-premium-details">
              <div className="age-premium-inner">
                <p>Old Premium</p>
                <span>
                  <s>{totalPremium}/-</s>
                </span>
              </div>

              <div className="age-premium-inner agerightside">
                <p>Revised Premium</p>
                {/* <span>
            
            <b>â‚¹{newPremium}/-</b>
          </span> */}
                {newPremium ? ( // Render if newPremium has a value
                  <span>
                    <b>{newPremium}/-</b>
                  </span>
                ) : (
                  // Render Loader component if newPremium is still loading
                  <Loader />
                )}
              </div>
            </div>
            <div className="age-premium-footer">
              <div className="age-premium-change">
                <div className="age-footer-upper">
                  <p className="totalprimuim"> Total Premium </p>
                  <div className="age-footer-premium-detail">
                    <p className="age-old-premium">
                      <s>{totalPremium}/-</s>
                    </p>
                    {newPremium ? (
                      <p className="age-new-premium">{newPremium}/-</p>
                    ) : (
                      <Loader />
                    )}
                  </div>
                </div>

                <div className="age-footer-btn">
                  <button
                    onClick={() => {
                      setShowPop(true)
                      setTimeout(() =>{
                        setShowPop(false)

                      setIsLoading(true);
                      submitAccept();
                      if (c_id == "HDFC") {
                        dispatch(updateFullHdfcPremium(premiumData));
                      }
                    }, 3000);
                    }}
                    
                    className="acceptplane"
                  >
                    Accept & continue
                  </button>
                  <button
                    onClick={() => {
                      setShowPop(true)


                      setTimeout(() =>{
                      setShowPop(false)

                      setIsLoading(true);
                      submitViewPlan();
                      setProposalCorrect(false);
                      setProposerCorrect(false);
                    }, 3000);
                    }}
                    
                    className="acceptplane"
                  >
                    View other plans
                  </button>
                </div>
              </div>
            </div>

            {
              showPop && <ProposalPop
              show={showPop}
              onClose={() => setShowPop(false)}
              onExited={handlePopupClose} // Trigger after popup has exited
              />
            }

            {/* __________the modal body end here _________ */}
          </div>
        </div>
      )}
    </>
  );
};

export default AgeModal;