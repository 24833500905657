import React from 'react';
import FAQ from "../AllPageComponents/FAQ/FAQ";
import PartnerCompany from "../AllPageComponents/PartnerCompany/PartnerCompany";
import "../CarInsurnce/Carinsurence.css";
import { VehicalApi, vehicleInsur } from "../Health/redux/Slice";
import { useState } from "react";
import FastImage from "../FastImage";
import { auto } from '@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core'
import { useDispatch } from "react-redux";
import CarInsuranceForm from './CarInsuranceForm';
import Redirect from '../AllPageComponents/Rederict';

const CashlessGarage = () => {
    const dispath = useDispatch();

    const [formData, setFormData] = useState({
      car_number: "",
      phone_no: "",
      pin: "",
      car_type: "fourwheeler",
    });
  
    const handleChange = (event) => {
      const { name, value } = event.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    };
  
    const handleSubmit = (event) => {
      event.preventDefault();
      // Log the entire form data
      dispath(vehicleInsur(formData));
      dispath(VehicalApi(formData))
        .then((result) => {
          //console.log("Data Send");
        })
        .catch((err) => {
          throw err;
        });
    }

  return (
    <>
    <Redirect/>
    <section className="car-insurance insurance-all insurance-background car-main">
  <div className="container">
    <div className="row insurance-row-one car-cashless">
      <div className="col-sm-12 col-md-4 col-lg-4 mian-one">
        <div className="car-insurance-first-inner">
          <h4 style={{color:"#0166B3",fontFamily:"Poppins",fontWeight:500,fontSize:"18pt"}}>Car Insurance for Areas with Limited Garage Access</h4>
       
          <p>
          Living in an area with limited access to garages can pose unique challenges for car owners. At Beema1.com, we understand these challenges and offer tailored car insurance solutions to meet your specific needs.
          </p>
     
        </div>
      </div>
      <div className="col-sm-12 col-md-4 col-lg-4 side-image">
      <FastImage
            url={"Website%20Img/carImagMascord.png?updatedAt=1718773961090"}
            width={auto}
            height={auto}
            alt={"ca-img"}
            className='fmailimagegifs'
            />
      </div>
     
      <CarInsuranceForm/>
 
  
    </div>
  </div>
</section>


<div className="container">
    <h5 style={{color:"#2959B8"}}>What Is Cashless Car Insurance Policy?</h5>
    <div className="yellowline"></div>
    
    <p>
              In motor insurance to make the entire claim process more
              hassle-free insurance providers offer cashless compensation for
              repairs. This is done by insurance companies that have tie-ups
              with certain garages (car repair shops). At these partner garages,
              an insurance company settles a car insurance claim in a cashless
              manner, i.e, it settles the repair bill directly with the garage.
              Thus, the policyholder is not required to pay anything to the
              garage for the repairs of their car except their own liabilities.
            </p>
            <p>
              In case of a mishap, you can inform your car insurance company and
              get your car transported directly to one of these cashless
              garages. Here the car will be inspected and repaired and the cost
              communicated to the insurance provider all without bothering you
              about it. Additionally, these places ensure a high quality of
              service as well as spare parts which ensures you have one less
              thing to think about.
            </p>
            <p>Benefits of Cashless Car Insurance Policy</p>
            <p>
              When you get your four-wheeler repaired at a cashless garage, you
              enjoy the following benefits:
            </p>
  </div>








<section className="full-space-block">
  <div className="container">
    <div className="row full-space-block-row">
      <div className="col-sm-12 col-md-12 col-lg-12">
        <div className="insurance-less-farage">
          <div className="simple-text-block">
            
            <h6>1. Hassle-free Experience</h6>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer too Lorem Ipsum is
              simply dummy text of the printing and typesetting industry. Lorem
              Ipsum has been the industry's standard dummy text ever since the
              1500s, when an unknown printer too
            </p>
            <h6>2. Priority in Service</h6>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer too Lorem Ipsum is
              simply dummy text of the printing and typesetting industry. Lorem
              Ipsum has been the industry's standard dummy text ever since the
              1500s, when an unknown printer too
            </p>
            <h6>3. Quick Claim Settlement</h6>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer too Lorem Ipsum is
              simply dummy text of the printing and typesetting industry. Lorem
              Ipsum has been the industry's standard dummy text ever since the
              1500s, when an unknown printer too
            </p>
            <h6>4. Guaranteed Quality Service</h6>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer too Lorem Ipsum is
              simply dummy text of the printing and typesetting industry. Lorem
              Ipsum has been the industry's standard dummy text ever since the
              1500s, when an unknown printer too
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<PartnerCompany/>

    <FAQ/>


    </>
  )
}

export default CashlessGarage