import React from 'react';
import { Link } from 'react-router-dom';

const StepSlider = ({ activeStep, disabledSteps = [] }) => {
    const handleClick = (step) => {
        const insuredDetails = JSON.parse(localStorage.getItem('InsuredDetail')) || [];
        const isStep1Allowed = insuredDetails.length > 0;
        const isStep3Allowed = insuredDetails.some(item => parseInt(item.InsuredAge) > 0);
        const isStep4Allowed = localStorage.getItem('ProposalName');
        if (isStep1Allowed) {
            if (step === 1 || step === 2) {
                return `/healthStep${step}`;
            }
        } if (isStep3Allowed) {
            if (step === 1 || step === 2 || step === 3) {
                return `/healthStep${step}`;
            }
        } if (isStep4Allowed) {
            if (step === 1 || step === 2 || step === 3 || step === 4) {
                return `/healthStep${step}`;
            }
        } else {
            return ''; 
        }
    };
    const isStepDisabled = (step) => disabledSteps.includes(step);

    return (
        <>
            <div className="md-stepper-horizontal">
                {[1, 2, 3, 4].map((step) => (
                    <div
                        key={step}
                        className={`md-step ${activeStep === step ? 'active' : 'disable'} ${isStepDisabled(
                            step
                        ) ? 'disabled' : ''}`}
                    >
                        <Link to={handleClick(step)} className={isStepDisabled(step) ? 'disabled-link' : ''}>
                            <div className="md-step-circle">
                                <span>{step}</span>
                            </div>
                            <div className="md-step-title">Step {step}</div>
                            <div className="md-step-bar-left"></div>
                        </Link>
                        <div className="md-step-bar-right"></div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default StepSlider;
