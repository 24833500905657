import React from 'react';
import FAQ from "../AllPageComponents/FAQ/FAQ";
import PartnerCompany from "../AllPageComponents/PartnerCompany/PartnerCompany";
import "../CarInsurnce/Carinsurence.css";
import FastImage from "../FastImage";
import { auto } from '@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core'
import BikeInsuranceForm from './BikeInsuranceForm';
import Redirect from '../AllPageComponents/Rederict';

const BikeCompareQuotes = () => {

  return (
    <>
    <Redirect/>
    <section className="car-insurance insurance-all insurance-background">
  <div className="container">
    <div className="row insurance-row-one">
      <div className="col-sm-12 col-md-4 col-lg-4 mian-one">
        <div className="car-insurance-first-inner">
          <h4 style={{fontFamily:'Poppins',fontWeight:500,fontSize:"18pt",color:'#0166B3'}}>Bike Compare quotes</h4>
          <br />
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer too
          </p>
        </div>
      </div>
      <div className="col-sm-12 col-md-4 col-lg-4 side-image">
      <FastImage
            url={"Website%20Img/BikeInsurence.png?updatedAt=1718779269495"}
            width={auto}
            height={auto}
            alt={"ca-img"}
            />
      </div>
      <style
        dangerouslySetInnerHTML={{
          __html: "\n    .hide{\n        display: none;\n    }\n"
        }}
      />

      <BikeInsuranceForm/>
     

    
    </div>
  </div>
</section>


<br />
<br />
<div className="container">
  <h4 style={{color:"#0166B3"}}>What is Bike Compare Quots ?</h4>
  <div className="yellowline"></div>
    <div className="row">
 
      <div className="col-md-12 d-flex justify-content-center align-items-center">
     <p style={{textAlign:'justify'}}>
     Just like stylish clothing, fancy footwear, and other alluring accessories and documents, protection while travelling is also crucial. Find some of the reasons to buy an insurance plan in the below section.

     </p>
     </div>
    </div>
  </div>
<br />






<div className="container">
  <div className="row">
    <div className="col-md-6">
      <h6 style={{color:"#0166B3",fontWeight:500,fontSize:'15pt'}}>1.Safeguard From Damages:-</h6> 
      <div className="yellowline"></div>
      <p style={{textAlign:'justify'}}>Life is unpredictable, and so are the events that can occur during our journey. Hence, to help you enjoy a stress-free journey, travel insurance covers some damages like misplacing of checked-in luggage, loss of passport, emergency medical expenses, and much more. The insurance plan curated to safeguard travellers will allow them to compensate for any further costs incurred during the journey.</p>
    </div>
    <div className="col-md-6">
      <h6 style={{color:"#0166B3",fontWeight:500,fontSize:'15pt'}}>2.Reimburse Medical Expenses:-</h6>
      <div className="yellowline"></div>
      <p style={{textAlign:'justify'}}>In the case of mishappening, a travel insurance plan can be of great advantage. Therefore, it is crucial to buy an insurance plan to safeguard your finances and get protection against the cost of medical treatment for injury or illness incurred while travelling. A policyholder may get cashless treatment at the global network hospitals of the insurance companies upto the limit endorsed in the plan.</p>
    </div>
    <div className="col-md-6">
      <h6 style={{color:"#0166B3",fontWeight:500,fontSize:'15pt'}}>3.Coverage For Itinerary Changes:-</h6> 
      <div className="yellowline"></div>
      <p style={{textAlign:'justify'}}>In between all that excitement, if any unexpected circumstances such as health issues, booking cancellations, or any other situation occur, then a travel insurance company will act as your protection buddy. Thus, an insurance company is liable to compensate for all the expenses that may occur before the commencement of an adventurous journey.</p>
    </div>
    <div className="col-md-6">
      <h6 style={{color:"#0166B3",fontWeight:500,fontSize:'15pt'}}>4.Protection Against Personal Liability:-</h6>
      <div className="yellowline"></div>
      <p style={{textAlign:'justify'}}>The protection offered to the insured in case a third-party gets injured is known as personal liability. Hence, if mistakenly you cause any damage to a third party while travelling, especially if you are on an international trip, then your insurance plan would be liable to pay compensation. Third-party liability can apply to both property damage and personal injury.</p>
    </div>
  </div>
</div>







<PartnerCompany/>
<FAQ/>

    </>
  )
}

export default BikeCompareQuotes