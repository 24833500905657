import "../../CommonCssFiles/planstyle.css";
import { FormHelperText, TextField } from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { useSelector, useDispatch } from "react-redux";
import React, { useState, useEffect } from "react";
import {
  getDate,
  feetAndInchesToCm,
  cmToFeetAndInch,
} from "../../helper/common";
import calculateAge from "../AfterPlanFetchPage/AgeModalHelper";
import dayjs from "dayjs";
import {
  selectProposal,
  updateData,
  updateInsuredData,
  updateName,
  updateProposalName,
  updateErrorResult,
  updateErrorResultProposal,
  updateProposalClick,
  getDateofbirth,
  updateValidCheckProposer,
  updateheight,
  settingDob
} from "../../redux/Slice";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useRef } from "react";

const Proposal = ({ selfData, data, index }) => {
  const [heightfeet, setHeightfeet] = useState(0);
  const [heightinches, setHeightinches] = useState(0);
  const [dobError, setDobError] = useState();
  const [weightError, setWeightError] = useState("");
  const [heightErrorProposal, setHeightErrorProposal] = useState();
  const [heightInchesProposal, setHeightInchesProposal] = useState("");
  const [fullNameError, setFullNameError] = useState("");
  const [maritalError, setMaritalError] = useState("");
  const [conflictData, setConFlictData] = useState([
    {
      id: "",
      relation: "",
    },
  ]);
  const [salutationError, setSalutationError] = useState("");
  const dispatch = useDispatch();
  // const proposalData = useSelector(selectProposal);

  const proposalData = selfData;
  const proposalDataNew = useSelector(selectProposal);

  console.log("the proposaldatanew", proposalDataNew)

  const insuredDetail = proposalDataNew.InsuredDetail;

  const pData = insuredDetail[index];


  console.log("The Income data",pData)

  // const { userdata } = useSelector((state) => {
  //   return state.FetchPlan;
  // });

  let userdata = selfData;
  const proposalClick = useSelector((state) => state.FetchPlan.proposalClick);

  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});

  const [salute, setSalute] = useState();
  const [nameData, setNameData] = useState();
  const [maritalData, setMaritaldata] = useState();
  const [dobdata, setDobData] = useState();
  const [weightData, setWeightData] = useState();
  const [heightData, setHeightData] = useState();
  const [inchData, setInchData] = useState();

  useEffect(() => {
    if (data.InsuredRelation === "Wife") {
      dispatch(
        updateInsuredData({
          index: index,
          data: [{ key: "Salutation", value: "3" }],
        })
      );
    }
    if (data.InsuredRelation === "Husband") {
      dispatch(
        updateInsuredData({
          index: index,
          data: [{ key: "Salutation", value: "1" }],
        })
      );
    }
    if (data.InsuredRelation === "Mother") {
      dispatch(
        updateInsuredData({
          index: index,
          data: [{ key: "Salutation", value: "3" }],
        })
      );
    }
    if (data.InsuredRelation === "Father") {
      dispatch(
        updateInsuredData({
          index: index,
          data: [{ key: "Salutation", value: "1" }],
        })
      );
    }

    // Marridial Status Start Hear From Thair /////////////////////////////////////////////////

    if (data.InsuredRelation === "Wife") {
      dispatch(
        updateInsuredData({
          index: index,
          data: [{ key: "MaritalStatus", value: "Married" }],
        })
      );
    }
    if (data.InsuredRelation === "Husband") {
      dispatch(
        updateInsuredData({
          index: index,
          data: [{ key: "MaritalStatus", value: "Married" }],
        })
      );
    }
    if (data.InsuredRelation === "Father") {
      dispatch(
        updateInsuredData({
          index: index,
          data: [{ key: "MaritalStatus", value: "Married" }],
        })
      );
    }
    if (data.InsuredRelation === "Mother") {
      dispatch(
        updateInsuredData({
          index: index,
          data: [{ key: "MaritalStatus", value: "Married" }],
        })
      );
    }
  }, []);

  // MaritalStatus

  // validation start here -----------------------------------

  const validateSaluitation = (value) => {
    // console.log("Saludation value", value);

    if (
      value == null ||
      value == " " ||
      value == "" ||
      value == undefined ||
      value == "0"
    ) {
      setSalutationError("Salutation can not be blank !");
      return false;
    } else if (!value) {
      setSalutationError("Please select a valid option !");
      return false;
    } else {
      setSalutationError("");
      return true;
    }
  };

  const validateFullName = (value) => {
    if (
      value == null ||
      value == 0 ||
      value == " " ||
      value == "" ||
      value == undefined
    ) {
      setFullNameError("Name can not be blank !");
      return false;
    } else if (!/^[a-zA-Z\s]+$/.test(value)) {
      setFullNameError("Full name must contain only letters");
      return false;
    } else {
      setFullNameError("");
      return true;
    }
    // console.log("validation is working");
  };

  const validateMarital = (value) => {
    if (
      value == null ||
      value == 0 ||
      value == " " ||
      value == "" ||
      value == undefined
    ) {
      setMaritalError("Marital status can not be blank !");
      return false;
    } else {
      setMaritalError("");
      return true;
    }
  };

  const validateDob = (value) => {
    let ca = calculateAge(value);

    if (
      value == null ||
      value == 0 ||
      value == " " ||
      value == "" ||
      value == undefined
    ) {
      setDobError("Date of birth cannot be blank");
      return false;
    }

    if (ca > 99) {
      setDobError(
        "Please select a valid date of birth (age must be at most 99)"
      );
      return false;
    } else {
      setDobError("");
      return true;
    }
  };

  const validateWeight = (value) => {
    if (
      value == null ||
      value == 0 ||
      value == " " ||
      value == "" ||
      value == undefined
    ) {
      setWeightError("Weight can not be blank.");
      return false;
    } else if (!/^\d{1,3}$/.test(value)) {
      setWeightError("Invalid weight (up to 3 digits)");
      return false;
    } else {
      setWeightError("");
      return true;
    }
  };

  // const validateFeet = (value) => {
  //   if (
  //     value == null ||
  //     value == 0 ||
  //     value == " " ||
  //     value == "" ||
  //     value == undefined
  //   ) {
  //     setHeightErrorProposal("Height can not be blank.");
  //     return false;
  //   } else if (!/^\d{0,2}$/.test(value)) {
  //     setHeightErrorProposal(
  //       "Invalid value for height in feet (up to 2 digits)"
  //     );
  //     return false;
  //   } else {
  //     setHeightErrorProposal("");
  //     return true;
  //   }
  // };

  // const validateInch = (value) => {
  //   if (value == null || value == undefined) {
  //     setHeightInchesProposal("Inches can not be blank");
  //     return false;
  //   } else if (!/^\d{0,2}$/.test(value)) {
  //     setHeightInchesProposal(
  //       "Invalid value for height in inches (up to 2 digits)"
  //     );
  //     return false;
  //   } else {
  //     setHeightInchesProposal("");
  //     return true;
  //   }
  // };

  const validateHeight = (value) => {
    console.log("The Coming value is",typeof(value))
    if (
      value == null ||
      value == undefined ||
      value == "0" ||
      value == "" ||
      value == "Na"||
      value == "un"
    ) {
      setHeightErrorProposal("Height cannot be blank");
      return false;
    } else {
      setHeightErrorProposal("");
      return true;
    }
  };

  // validation end here ---------------------

  // useEffect for validation on clicking proceed in summary ---------------------------------

  useEffect(() => {
    if (proposalClick) {
      // alert(JSON.stringify(pData));
      let salutationCheck = validateSaluitation(pData.Salutation);
      let fullnameCheck = validateFullName(pData.InsuredName);
      let maritalCheck = validateMarital(pData.MaritalStatus);
      let dobCheck = validateDob(pData.dateOfbirth);
      let weightCheck = validateWeight(pData.weight);
      let feetCheck = validateHeight(pData.height_feet || pData.height);
      // let inchCheck = validateHeight(pData.height_inch || pData.height);
      // let heightCheck = validateHeight(pData.height);


      let boolvalue =
        salutationCheck &&
        fullnameCheck &&
        maritalCheck &&
        dobCheck &&
        weightCheck &&
        feetCheck 
        // &&
        // inchCheck 
        // heightCheck


    }

    dispatch(updateProposalClick(false));

  }, [proposalClick]);

  // If girl age is under 18 then the girl is ms otherwish Mrs
  const girlData = localStorage.getItem("InsuredDetail");
  // console.log("Dataiss", girlData);

  if (girlData) {
    try {
      const dataArray = JSON.parse(girlData);
      const daughterData = dataArray.filter(
        (girl) => girl.InsuredRelation === "Daughter"
      );
      if (daughterData.length > 0) {
        const girluserData = daughterData[0].InsuredAge;
        // console.log("Daughter age:", girluserData);

        const girlPinCode = daughterData[0].PinCode;
        // console.log("Daughter Pin Code:", girlPinCode);
      } else {
        console.log("No daughter data found");
      }
    } catch (error) {
      console.error("Error parsing JSON:", error);
    }
  } else {
    console.log("No data found in localStorage");
  }


//   function cmToFeetInch(cm) {
//     // 1 cm = 0.0328084 feet
//     let totalFeet = cm * 0.0328084;
//     let feet = Math.floor(totalFeet);
//     let inches = (totalFeet - feet) * 12;
    
//     // Convert inches to a string and take up to two decimal places without rounding
//     let decimalInches = inches.toString().split('.');
//     if (decimalInches.length > 1) {
//         decimalInches = decimalInches[1].substring(0, 2);
//     } else {
//         decimalInches = '00';
//     }

//     return `${feet}.${decimalInches}`;
// }
function cmToFeetInch(cm) {
  const totalInches = cm / 2.54; // Convert cm to inches
  const feet = Math.floor(totalInches / 12); // Get the whole feet part
  const inches = Math.floor((totalInches % 12) * 100) / 100; // Get the remaining inches, up to 2 decimal places
  
  return `${feet}.${inches}`;
}



  const [handelmaxcm, setHandelmaxcm] = useState(pData.height ? (pData.height) : 0);
  const [handelFtInchi, sethandelFtInchi] = useState(`${pData.height_feet}.${pData.height_inch}`);

  const handleValueSet = (e) => {
    let userInput = e.target.value;

    if (userInput === "") {
      setHandelmaxcm("");
      sethandelFtInchi("");
      return;
    }

    if (userInput.length > 7) {
      userInput = userInput.slice(0); // Truncate to 6 digits
    }
    if (parseFloat(userInput) > 332.74) {
      userInput = 332.74;
    }

    setHandelmaxcm(userInput);

    const cm = parseFloat(userInput);
    const totalInches = cm / 2.54;
    const feet = Math.floor(totalInches / 12);
    const inches = Math.round(totalInches % 12);

    const feetInch = `${feet}.${inches < 10 ? "0" : ""}${inches}`;
    sethandelFtInchi(feetInch);
  };

  const heighttoinchi = (e) => {
    let userInput = e.target.value;

    if (userInput.length > 5) {
      userInput = userInput.slice(0, 5); // Truncate to 5 characters
    }

    if (parseFloat(userInput) > 10.11) {
      userInput = "10.11"; // Ensure userInput is a string
    }
    if (parseFloat(userInput) < 0.0) {
      userInput = "1.0"; // Ensure userInput is a string
    }
    if (parseFloat(userInput) === 0.0) {
      userInput = "1.0"; // Ensure userInput is a string
    }

    sethandelFtInchi(userInput);

    // Split the input into feet and inches
    const parts = userInput.split(".");
    let feet = parseInt(parts[0], 10) || 0; // Default to 0 if NaN
    let inches = parts.length > 1 ? parseInt(parts[1], 10) || 0 : 0;

    // Convert feet and inches to centimeters
    const totalInches = feet * 12 + inches;
    const cmValue = totalInches * 2.54;
    const roundedCm = Math.round(cmValue * 100) / 100;

    setHandelmaxcm(roundedCm);
  };

  const handlfeet = handelFtInchi[0];

  useEffect(() => {
    console.log("useEffect triggered with handelFtInchi:", handelFtInchi);

    if (typeof handelFtInchi === "string") {
      const parts = handelFtInchi.split(".");
      const feet = parseInt(parts[0], 10) || 0;
      let inches = parts.length > 1 ? parseFloat(`0.${parts[1]}`) : 0; // Get inches directly

      inches = Math.round(inches * 12);

      // console.log('Calculated feet:', feet, 'Inches:', inches);
      const handelFtInchiStr = String(handelFtInchi);

      console.log("new",handelFtInchiStr)

      let height_feet = handelFtInchiStr.slice(0, 2).replace(".", "");

      let height_inch;

      if(handelFtInchiStr.toString().includes('.')){
       height_inch = handelFtInchiStr.slice(-2).replace(".", "");
      }
      else{
        height_inch = 0;
      }
      const heightInCm = feetAndInchesToCm(height_feet, height_inch);

      console.log(heightInCm);
      dispatch(
        updateInsuredData({
          index: index,
          data: [
            { key: "height_feet", value: height_feet },
            { key: "height_inch", value: height_inch },
          ],
        })
      );

      if (heightInCm) {
        dispatch(
          updateInsuredData({
            index: index,
            data: [{ key: "height", value: heightInCm }],
          })
        );
      }
    }
  }, [handelFtInchi, dispatch]);

  const handler = (e, key, date = false, height = false) => {
    let val;
    if (date) {
      val = getDate(e);
    } else {
      val = e.target.value;
    }

    if (key == "height") {
      if (height == "feet") {
        // console.log("feet", e.target.value);
        // console.log("the value in feet", val, heightinches, e.target.value);
      } else if (height == "inches") {
        // console.log("the value in inchis", val, heightfeet, e.target.value);
      }
    }
    // console.log("the proposal click in proposal", proposalClick);
    // handler(e, "Salutation");

    dispatch(
      updateInsuredData({
        index: index,
        data: [{ key: key, value: val }],
      })
    );
  };


  return (
    <>
      <p
        className="user-info"
        data-bs-toggle="collapse"
        href={`#${data.InsuredRelation.replace(/[^a-zA-Z0-9]/g, "")}`}
        role="button"
        aria-expanded="true"
        aria-controls={`${data.InsuredRelation.replace(/[^a-zA-Z0-9]/g, "")}`}
      >
        {data.InsuredRelation === "Wife" ? "Spouse" : data.InsuredRelation}(
          {data.InsuredAge == 0 ? "< 91 Days" : `${data.InsuredAge} Years` })
      </p>
      <div
        className="userhide collapse show m-10"
        // class={dataFull ? "collapse show m-10" : "collapse m-10"}
        id={`${data.InsuredRelation.replace(/[^a-zA-Z0-9]/g, "")}`}
      >
        <div>
          <>
            <div className="row ppv10">
              <div className="col-md-2 px-0 py-0">
                <div className="prpfbd" style={{ width: "100%" }}>
                  {/* <TextField
                style={{width:"100%"}}
                  id="standard-select-currency-native"
                  select
                  label="Salutation"
                  SelectProps={{
                    native: true,
                  }}
                  name="salutation-proposer"
                  variant="standard"
                  error={salutationError}
                  helperText={salutationError}
                  onChange={(e) => {
                    handler(e, "Salutation");
                    setSalute(e.target.value);
                    validateSaluitation(e.target.value)
                  }}
                >
                  <option value="0">select</option>
                  <option
                    value={1}
                    selected={
                      pData.Salutation ? pData.Salutation == "1" ||
                      data.InsuredRelation === "Husband" ||
                      data.InsuredRelation === "Wife" : null
                    }
                  >
                    Mr.
                  </option>

                  <option value={2} selected={pData.Salutation == "2"}>
                    Ms.
                  </option>
                  <option
                    value={3}
                    selected={
                      pData.Salutation == "3" ||
                      data.InsuredRelation === "Wife" ||
                      data.InsuredRelation === "Mother"
                    }
                  >
                    Mrs.
                  </option>
                </TextField> */}

                  <span style={{ color: "#2959B8", fontSize: "8pt" }}>
                    Salutation*
                  </span>
                  <Select
                    style={{ width: "100%" }}
                    id="standard-select-currency-native"
                    select
                    label="Salutation"
                    defaultValue={
                      data.InsuredRelation === "Husband" ||
                      data.InsuredRelation === "Father"
                        ? 1
                        : data.InsuredRelation === "Mother" ||
                          data.InsuredRelation === "Wife"
                        ? 2
                        : (pData.Salutation? pData.Salutation : 0)
                    }
                    SelectProps={{
                      native: true,
                    }}
                    variant="standard"
                    error={salutationError}
                    helperText={salutationError}
                    onChange={(e) => {
                      handler(e, "Salutation");
                      setSalute(e.target.value);
                      validateSaluitation(e.target.value);
                    }}
                  >
                    <MenuItem value={0}>Select</MenuItem>

                    <MenuItem
                      value={1}
                      selected={
                        pData.Salutation
                          ? pData.Salutation == "1" ||
                            data.InsuredRelation === "Husband" ||
                            data.InsuredRelation === "Father" ||
                            data.InsuredRelation === "Son"
                          : null
                      }
                    >
                      Mr.
                    </MenuItem>

                    <MenuItem value={2} selected={pData.Salutation == "2"}>
                      Mrs.
                    </MenuItem>

                    <MenuItem
                      value={3}
                      selected={
                        pData.Salutation == "3" ||
                        data.InsuredRelation === "Wife" ||
                        data.InsuredRelation === "Mother"
                      }
                    >
                      Ms.
                    </MenuItem>
                  </Select>
                  <p className="error">{salutationError}</p>
                </div>
              </div>

              <div className="col-md-5  px-0 py-2">
                <div className="prpfbd" style={{ width: "100%" }}>
                  <TextField
                    style={{ width: "100%" }}
                    id="standard-helperText"
                    label="Full Name"
                    defaultValue={
                      data.InsuredRelation == "Self"
                        ? selfData.ProposalName
                        : ""
                    }
                    variant="standard"
                    value={pData.InsuredName}
                    error={fullNameError}
                    helperText={fullNameError}
                    required
                    onChange={(e) => {
                      handler(e, "InsuredName");
                      setNameData(e.target.value);
                      validateFullName(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-5 px-0">
                <div className="prpfbd" style={{ width: "100%" }}>
                  {/* <TextField
                style={{width:"100%"}}
                  id="standard-select-currency-native"
                  select
                  label="MaritalStatus"
                  SelectProps={{
                    native: true,
                  }}
                  error={maritalError}
                  helperText={maritalError}
                  variant="standard"
                  required
                  onChange={(e) => {
                    handler(e, "MaritalStatus");
                    setMaritaldata(e.target.value);
                    validateMarital(e.target.value);
                  }}
                >
                  <option value="0">select</option>
                  <option
                    value="Married"
                    selected={
                      pData.MaritalStatus == "Married" ||
                      data.InsuredRelation === "Wife" ||
                      data.InsuredRelation === "HUsband" ||
                      data.InsuredRelation === "Father" ||
                      data.InsuredRelation === "Mother"
                    }
                  >
                    Married
                  </option>
                  <option
                    value="Unmarried"
                    selected={pData.MaritalStatus == "Unmarried"}
                  >
                    Unmarried
                  </option>
                </TextField> */}

                  {/* <span style={{color:'#2959B8',fontSize:'8pt'}}>MaritalStatus*</span> */}
                  <span style={{ color: "#2959B8", fontSize: "8pt" }}>
                    MaritalStatus*
                  </span>
                  <Select
                    style={{ width: "100%" }}
                    id="standard-select-currency-native"
                    label="MaritalStatus"
                    defaultValue={
                      pData.MaritalStatus === "Married" ||
                      data.InsuredRelation === "Wife" ||
                      data.InsuredRelation === "Husband" ||
                      data.InsuredRelation === "Father" ||
                      data.InsuredRelation === "Mother"
                        ? "Married"
                        : pData.MaritalStatus || 0
                    }
                    SelectProps={{
                      native: true,
                    }}
                    variant="standard"
                    error={maritalError}
                    helperText={maritalError}
                    onChange={(e) => {
                      handler(e, "MaritalStatus");
                      setMaritaldata(e.target.value);
                      validateMarital(e.target.value);
                    }}
                  >
                    <MenuItem value={0}>Select</MenuItem>
                 
                    <MenuItem
                      value="Married"
                      selected={
                        pData.MaritalStatus === "Married" ||
                        data.InsuredRelation === "Wife" ||
                        data.InsuredRelation === "Husband" ||
                        data.InsuredRelation === "Father" ||
                        data.InsuredRelation === "Mother"
                      }
                      // defaultValue={pData.MaritalStatus}
                    >
                      Married
                    </MenuItem>
                    <MenuItem
                      value="Unmarried"
                      selected={pData.MaritalStatus === "Unmarried"}
                    >
                      Unmarried
                    </MenuItem>


                    <MenuItem value="Divorcee">Divorcee</MenuItem>
                    <MenuItem value="Widow">Widow</MenuItem>
                    <MenuItem value="Widower">Widower</MenuItem>




                  </Select>

                  <span className="error">{maritalError}</span>
                </div>
              </div>
            </div>
            <div className="row  ppv10 ">
              <div className="col-md-4 px-0 py-2">
                <div className="prpfbd">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DesktopDatePicker"]}>
                      <DesktopDatePicker
                        variant="standard"
                        id="standard-helperText"
                        label="DOB"
                        defaultValue=""
                        required
                        value={
                          pData.dateOfbirth ? dayjs(pData.dateOfbirth) : ""
                        }
                        onChange={(e) => {
                          handler(e, "dateOfbirth", true);

                          setDobData(getDate(e));
                          validateDob(getDate(e));
                        }}
                        format="DD-MM-YYYY"
                        error={dobError}
                        helperText={dobError}
                        sx={{
                          '& .MuiInputBase-root': {
                            boxShadow: 'none',
                            '& .MuiOutlinedInput-notchedOutline': {
                              border: 0,
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                              border: 0,
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                              border: 0,
                            },
                          },
                          '& .MuiInputBase-input': {
                            padding: '10px 5px', // Adjust padding as needed
                          },
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>

                  {Boolean(dobError) && (
                    <FormHelperText error>{dobError}</FormHelperText>
                  )}
                </div>
              </div>
              <div className="col-md-3 px-0 py-2">
                <div className="prpfbd">
                  <TextField
                    id="standard-helperText"
                    label="Weight (in kgs)"
                    required
                    defaultValue=""
                    variant="standard"
                    error={Boolean(weightError)}
                    helperText={weightError}
                    value={pData.weight}
                    onChange={(e) => {
                      handler(e, "weight");
                      validateWeight(e.target.value);
                      setWeightData(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-2 px-0 py-2">
                <div className="prpfbd">
                  {/* <Box
                    sx={{
                      minWidth: 80,
                      borderBottom: "1px solid gray",
                      height: 50,
                    }}
                  >
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Height (feet)
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="height"
                        value={pData.height_feet}
                        onChange={(e) => {
                          setHeightfeet(e.target.value);
                          validateFeet(e.target.value);
                          handler(e, "height_feet", false, "feet");
                          handler(e, "height", false, "feet");

                          setHeightData(e.target.value);
                        }}
                        error={Boolean(heightErrorProposal)}
                      >
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={6}>6</MenuItem>
                        <MenuItem value={7}>7</MenuItem>
                        <MenuItem value={8}>8</MenuItem>
                        <MenuItem value={9}>9</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                      </Select>
                      {Boolean(heightErrorProposal) && (
                        <FormHelperText error>
                          {heightErrorProposal}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Box> */}

                  <TextField
                    style={{ width: "100%" }}
                    id="standard-helperText"
                    label="Height (cm)"
                    variant="standard"
                    type="number"
                    // placeholder="e.g, 172.72"
                    disabled={true}
                    inputProps={{
                      min: "0",
                      max: "332.74",
                      step: "0.01",
                      pattern: "[0-9]+([.,][0-9]+)?",
                    }}
                    onChange={(e)=>handleValueSet(e)}
                    // value={pData.height ? pData.height : handelmaxcm}
                    value={handelmaxcm}
                  />
                </div>
              </div>

              <div className="col-md-3 px-0 py-2">
                <div className="prpfbd">
                  {/* <Box
                    sx={{
                      minWidth: 80,
                      borderBottom: "1px solid gray",
                      height: 50,
                    }}
                  >
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        inchi
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        required
                        label="inchi"
                        error={Boolean(setHeightInchesProposal)}
                        value={pData.height_inch}
                        onChange={(e) => {
                          setHeightinches(e.target.value);
                          setHeightInchesProposal(e.target.value);

                          handler(e, "height_inch", false, "inches");
                          handler(e, "height", false, "inches");

                          // console.log("e.target.value", e.target.value);
                          validateInch(e.target.value);
                          setInchData(e.target.value);
                        }}
                      >
                        <MenuItem value={0}>0 </MenuItem>
                        <MenuItem value={1}>1 </MenuItem>
                        <MenuItem value={2}>2 </MenuItem>
                        <MenuItem value={3}>3 </MenuItem>
                        <MenuItem value={4}>4 </MenuItem>
                        <MenuItem value={5}>5 </MenuItem>
                        <MenuItem value={6}>6 </MenuItem>
                        <MenuItem value={7}>7 </MenuItem>
                        <MenuItem value={8}>8 </MenuItem>
                        <MenuItem value={9}>9 </MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={11}>11</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  {Boolean(heightInchesProposal) && (
                    <FormHelperText error>
                      {heightInchesProposal}
                    </FormHelperText>
                  )} */}
                  {/* {setHeightInchesProposal && <p className="error-message red-text">{setHeightInchesProposal}</p>} */}

                  {/* <TextField
                    style={{ width: "100%" }}
                    id="standard-helperText"
                    label="height (ft,inchi) "
                    defaultValue=""
                    type="number"
                    variant="standard"
                    placeholder="e.g, 5.8"
                    onChange={(e) => {
                      heighttoinchi(e);
                      setHeightinches(e.target.value);
                      handler(e, "height", false, "inches");
                      setHeightfeet(handlfeet);
                      validateHeight(e.target.value)
                      handler(e, "height", false, "feet");
                      handler(e, "height", false, "inches");
                    }}
                    value={pData.height? cmToFeetInches(pData.height) :handelFtInchi}
                  /> */}

                  <TextField
                    style={{ width: "100%" }}
                    id="standard-helperText"
                    label="height (ft,inchi) "
                    defaultValue=""
                    type="number"
                    variant="standard"
                    placeholder="e.g, 5.8"
                    onChange={(e) => {
                      heighttoinchi(e);
                      setHeightinches(e.target.value);
                      handler(e, "height", false, "inches");
                      setHeightfeet(handlfeet);
                      validateHeight(e.target.value)
                      handler(e, "height", false, "feet");
                      handler(e, "height", false, "inches");
                    }}
                    value={handelFtInchi}
                    // value={pData.height? cmToFeetInches(pData.height) :handelFtInchi}

                  />

                  {Boolean(heightErrorProposal) && (
                    <FormHelperText error>{heightErrorProposal}</FormHelperText>
                  )}
                </div>
              </div>
            </div>
          </>
        </div>
      </div>
    </>
  );
};

const ProposerForm = ({ data, selfData, SelfKey, setProposerCorrect }) => {

  const proposalDataNew = useSelector(selectProposal);




  const [heightfeet, setHeightfeet] = useState(0);
  const [heightinches, setHeightinches] = useState(0);
  const [saluteValue, setSaluteValue] = useState(1);
  const [nameVal, setNameVal] = useState(proposalDataNew.ProposalName);
  const [dobValue, setDobValue] = useState();
  const [maritalValue, setMaritalValue] = useState();
  const [heightValue, setHeightValue] = useState();
  const [weightValue, setWeightValue] = useState();
  const [inchValue, setInchValue] = useState();
  const [occupValue, setOccupValue] = useState();
  const [eduValue, setEduValue] = useState();
  const [incomeValue, setIncomeValue] = useState();

  // errors
  const [dobError, setDobError] = useState("");
  const [weightError, setWeightError] = useState("");
  const [heightFeetError, setHeightFeetError] = useState("");
  const [heightInchesError, setHeightInchesError] = useState("");
  const [annualIncomeError, setAnnualIncomeError] = useState("");
  const [panNumError, setPanNumError] = useState("");
  const [saluteError, setSaluteError] = useState("");
  const [maritalError, setMaritalError] = useState("");
  const [eduError, setEduError] = useState("");
  const [fullNameError, setFullNameError] = useState("");
  const [occupationError, setOccupationError] = useState("");

  const dispatch = useDispatch();
  // const { userdata } = useSelector((state) => {
  //   return state.FetchPlan;
  // });
  let userdata = selfData;
  // console.log("userData in proposer form", userdata);

  // const proposalDataNew = useSelector(selectProposal);

  console.log("The proposal data", proposalDataNew);
  // console.log("the proposalData new", proposalDataNew)

  const proposalData = selfData;
  const proposalClick = useSelector((state) => state.FetchPlan.proposalClick);

  // console.log("the proposalclick in proposal", proposalClick);

  // console.log("the proposalData in pfdata", proposalData);
  const insuredDetail = proposalData.InsuredDetail;
  console.log("The Insure details", proposalData);
  // console.log("the userdata in pf data", userdata);

  // console.log("the proposalData in proposal component", proposalData)

  const pfData = proposalDataNew
    ? proposalDataNew.InsuredDetail.length != 0
      ? proposalDataNew.InsuredDetail[0].InsuredRelation == "Self"
        ? proposalDataNew.InsuredDetail[0]
        : proposalDataNew
      : ""
    : "";

  console.log("the pf data ", pfData);
  // alert(JSON.stringify(pfData))

  function convertHeightToCm(heightStr) {
    // Parse the height string to extract feet and inches
    const [feet, inches = 0] = heightStr.split('.').map(Number);
  
    // Convert feet to centimeters (1 foot = 30.48 cm)
    const feetToCm = feet * 30.48;
  
    // Convert inches to centimeters (1 inch = 2.54 cm)
    const inchesToCm = inches * 2.54;
  
    // Calculate the total height in centimeters
    const totalHeightCm = feetToCm + inchesToCm;
  
    return totalHeightCm;
  }

  const handler = (e, key, date = false, height = false) => {
    let val;

    if (date) {
      val = getDate(e);

      console.log("The values", val);
    } else {
      val = e.target.value;
    }

    if (key == "height") {
    val = convertHeightToCm(e.target.value);
    console.log("the val ", val);
    }

    dispatch(
      updateInsuredData({
        index: SelfKey,
        data: [{ key: key, value: val }],
      })
    );
  };

  useEffect(() => {
    if (data != false) {
      dispatch(
        updateData([{ key: "ProposalName", value: selfData.ProposalName }])
      );
    }
  }, []);

  function convertCmToFeet(cm) {
    const inches = cm / 2.54;
    const feet = Math.floor(inches / 12);
    const remainingInches = Math.round(inches % 12);
    let response = { feet: feet };
    return response;
  }

  function convertCmToInches(cm) {
    const inches = cm / 2.54;
    const feet = Math.floor(inches / 12);
    const remainingInches = Math.round(inches % 12);
    let response = { inches: remainingInches };
    return response;
  }

  const validateFullName = (value) => {
    if (
      value == null ||
      value == 0 ||
      value == " " ||
      value == "" ||
      value == undefined
    ) {
      setFullNameError("Name can not be blank !");
      return false;
    } else if (!/^[a-zA-Z\s]+$/.test(value)) {
      setFullNameError("Full name must contain only letters");
      return false;
    } else {
      setFullNameError("");
      return true;
    }
  };

  const validateSaluitation = (value) => {
    // console.log("salutevalue and value", saluteValue, value);
    if (saluteValue == 0) {
      if (
        value == null ||
        value == " " ||
        value == "" ||
        value == undefined ||
        value == "0"
      ) {
        setSaluteError("Salutation can not be blank !");
        return false;
      } else {
        setSaluteError("");
        return true;
      }
    } else {
      return true;
    }
  };

  const validateMarital = (value) => {
    if (value == null || value == 0 || value == " " || value == undefined) {
      setMaritalError("Marital status can not be blank !");
      return false;
    } else {
      setMaritalError("");
      return true;
    }
  };

  const validateDob = (value) => {
    if (
      value == null ||
      value == 0 ||
      value == " " ||
      value == "" ||
      value == undefined
    ) {
      setDobError("Date of birth cannot be blank");
      return false;
    }
    if (!value) {
      setDobError(
        "Please select a valid date of birth (age must be at least 18 and at most 99)"
      );
      return false;
    }
    let ca = calculateAge(value);
    if (ca < 18 || ca > 99) {
      setDobError(
        "Please select a valid date of birth (age must be at least 18 and at most 99)"
      );
      return false;
    } else {
      setDobError("");
      return true;
    }
  };

  const calculateAge = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };

  const validateWeight = (value) => {
    if (
      value == null ||
      value == 0 ||
      value == " " ||
      value == "" ||
      value == undefined
    ) {
      setWeightError("Weight cannot be blank");
      return false;
    } else if (!/^\d{1,3}$/.test(value)) {
      setWeightError("Invalid weight (up to 3 digits)");
      return false;
    } else {
      setWeightError("");
      return true;
    }
  };

  const validateEducation = (value) => {
    // console.log("the value in education", value);
    if (
      value == null ||
      value == 0 ||
      value == " " ||
      value == "" ||
      value == undefined
    ) {
      setEduError("Education cannot be blank !");
      return false;
    } else if (!value) {
      setEduError("Please select a valid option !");
      return false;
    } else {
      setEduError("");
      return true;
    }
  };

  // const validateFeet = (e) => {
  //   // Ensure event and event.target are defined
  //   if (!e || !e.target) {
  //     console.error("Event or event target is undefined or null.");

  //   }
  //   const value = e.target.value;
  //   console.log("The value length", value.length);
  //   const stringValue = String(value).trim();

  //   if(stringValue === "0" || undefined || null || ""){
  //     setHeightFeetError("Height cannot be blank");

  //   }

  //   // Check if the length of the input is at least 3 characters (e.g., "5.8")
  //   if (stringValue.length < 3) {
  //     setHeightFeetError("Enter a Valid height");

  //   }

  //   setHeightFeetError("");
  //   return true;
  // };

  const validateHeight = (value) => {
    console.log("Upcoming value:", value);

    if (
      value == null ||
      value === undefined ||
      value === "0" ||
      value === "" ||
      value == "Na" ||
      value == "un"
      
    ) {
      setHeightInchesError("Height cannot be blank");
      return false;
    } else {
      setHeightInchesError("");
      return true;
    }
  };
  useEffect(() => {
    console.log("Height error state updated:", heightInchesError);
  }, [heightInchesError]);

  // const validateInch = (value) => {
  //   if (value == null || value == undefined) {
  //     setHeightInchesError("Inches  cannot be blank");
  //     return false;
  //   }

  // else if (!/^\d{0,2}$/.test(value)) {
  //   setHeightInchesError(
  //     "Invalid value for height in inches (up to 2 digits)"
  //   );
  //   return false;
  // }

  //   else {
  //     setHeightInchesError("");
  //     return true;
  //   }
  // };
  const validateOccupation = (value) => {
    if (
      value == null ||
      value == 0 ||
      value == " " ||
      value == "" ||
      value == undefined
    ) {
      setOccupationError("Occupation  cannot be blank");
      return false;
    } else if (!value) {
      setOccupationError("Please select a valid occupation !");
      return false;
    } else {
      setOccupationError("");
      return true;
    }
  };

  const validateAnnualIncome = (value) => {
    // console.log("the value in annual income", value);
    if (
      value == null ||
      value == 0 ||
      value == " " ||
      value == "" ||
      value == undefined
    ) {
      setAnnualIncomeError("Annual Income  cannot be blank");
      return false;
    } else if (!/^\d{1,10}$/.test(value)) {
      setAnnualIncomeError("Invalid annual income (positive integers only)");
      return false;
    } else {
      setAnnualIncomeError("");
      return true;
    }
  };

  const validatePanNum = (value) => {
    const isValid = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(value.toUpperCase());
    setPanNumError(
      isValid ? "" : "Invalid PAN number format (example: ABCDE1234F)"
    );
  };

  useEffect(() => {
    if (proposalClick) {
      let nameChecked = validateFullName(
        pfData.InsuredName || proposalDataNew.ProposalName
      );
      let DobChecked = validateDob(pfData.dateOfbirth );
      let WeightChecked = validateWeight(pfData.weight );
      let FeetChecked = validateHeight(pfData.height_feet || (pfData.height ? cmToFeetInches(pfData.height ): ""));
      // let InchChecked = validateHeight(proposalDataNew.height_inch);
      let SaluitationChecked = validateSaluitation(proposalDataNew.Salutation);
      let MaritalChecked = validateMarital(pfData.MaritalStatus);
      let anuualcheck = validateAnnualIncome(proposalDataNew.AnnualIncome || proposalData.AnnualIncome);
      let eduCheck = validateEducation(pfData.Education);
      let occupationcheck = validateOccupation(pfData.Occupation );
      // let heightCheck = validateHeight(proposalDataNew.height)

      let boolvalue =
        occupationcheck &&
        nameChecked &&
        DobChecked &&
        WeightChecked &&
        // heightCheck &&
        FeetChecked &&
        // InchChecked &&
        SaluitationChecked &&
        MaritalChecked &&
        anuualcheck &&
        eduCheck;

      // console.log("the boolvalue in new propoer form proposal", boolvalue);
      setProposerCorrect(boolvalue);
      dispatch(updateValidCheckProposer(boolvalue));
    }
    dispatch(updateProposalClick(false));
  }, [proposalClick]);

  const OccupationData = [
    { Name: "Accountants", value: "100" },
    { Name: "Doctors", value: "101" },
    { Name: "Lawyers", value: "102" },
    { Name: "Architects", value: "103" },
    { Name: "IT Professional", value: "104" },
    { Name: "Consulting Engineers", value: "105" },
    { Name: "Teachers", value: "106" },
    { Name: " Bankers, Clerical Staff", value: "107" },
    { Name: " Persons engaged in administrative functions", value: "108" },
    { Name: "Agriculture", value: "109" },
    { Name: "Housewife", value: "H" },
    { Name: "Student", value: "R" },
    { Name: "Farmer", value: "112" },
    { Name: "Builders", value: "113" },
    {
      Name: "Contractors and Engineers engaged in superintending functions only",
      value: "114",
    },
    { Name: "Drivers", value: "115" },
    {
      Name: " All persons engaged in manual labour (Except those falling under Group III)",
      value: "116",
    },
    { Name: " Cash Carrying Employees", value: "117" },
    { Name: "Garage and Motor Mechanics", value: "118" },
    { Name: "Machine Operators", value: "119" },
    { Name: "Professional Athletics and Sportsmen", value: "120" },
    { Name: "Carpenter", value: "121" },
    { Name: "Insurance Advisor", value: "122" },
    { Name: "Retired Persons", value: "123" },
    { Name: "Electricians", value: "124" },
    { Name: "Others", value: "125" },
    {
      Name: "Persons Working in underground mines, explosives, magazines",
      value: "126",
    },
    {
      Name: "Workers involved in electrical installation with high tension supply",
      value: "127",
    },
    { Name: "Army/Navy/Airforce Professional", value: "128" },
    { Name: "Jockeys", value: "129" },
    { Name: " Adventure Sports Professional", value: "130" },
    { Name: "Merchant Navy", value: "131" },
    { Name: "Commercial Pilot", value: "132" },
    { Name: " Other Class 1", value: "133" },
    { Name: " Other Class 2", value: "134" },
  ];









//   function cmToFeetInch(cm) {
//     // 1 cm = 0.0328084 feet
//     let totalFeet = cm * 0.0328084;
//     let feet = Math.floor(totalFeet);
//     let inches = (totalFeet - feet) * 12;
    
//     // Convert inches to a string and take up to two decimal places without rounding
//     let decimalInches = inches.toString().split('.');
//     if (decimalInches.length > 1) {
//         decimalInches = decimalInches[1].substring(0, 2);
//     } else {
//         decimalInches = '00';
//     }

//     // alert(`${feet}.${decimalInches}`)
//     return `${feet}.${decimalInches}`;
// }
function cmToFeetInch(cm) {
  const totalInches = cm / 2.54; // Convert cm to inches
  const feet = Math.floor(totalInches / 12); // Get the whole feet part
  const inches = Math.floor((totalInches % 12) * 100) / 100; // Get the remaining inches, up to 2 decimal places
  
  return `${feet}.${inches}`;
}

  ///////////////////////////////////////////////////////////////////////////

  const [handelmaxcm, setHandelmaxcm] = useState(0);
  const [handelFtInchi, sethandelFtInchi] = useState(`${proposalDataNew.height_feet}.${proposalDataNew.height_inch}`);

  const handleValueSet = (e) => {
    let userInput = e.target.value;

    if (userInput === "") {
      setHandelmaxcm("");
      sethandelFtInchi("");
      return;
    }

    if (userInput.length > 7) {
      userInput = userInput.slice(0); // Truncate to 6 digits
    }
    if (parseFloat(userInput) > 332.74) {
      userInput = 332.74;
    }

    setHandelmaxcm(userInput);

    const cm = parseFloat(userInput);
    const totalInches = cm / 2.54;
    const feet = Math.floor(totalInches / 12);
    const inches = Math.round(totalInches % 12);

    const feetInch = `${feet}.${inches < 10 ? "0" : ""}${inches}`;
    sethandelFtInchi(feetInch);
  };

  const heighttoinchi = (e) => {
    let userInput = e.target.value;

    if (userInput.length > 5) {
      userInput = userInput.slice(0, 5); // Truncate to 5 characters
    }

    if (parseFloat(userInput) > 10.11) {
      userInput = "10.11"; // Ensure userInput is a string
    }
    if (parseFloat(userInput) < 0.0) {
      userInput = "1.0"; // Ensure userInput is a string
    }
    if (parseFloat(userInput) === 0.0) {
      userInput = "1.0"; // Ensure userInput is a string
    }

    sethandelFtInchi(userInput);

    // Split the input into feet and inches
    const parts = userInput.split(".");
    let feet = parseInt(parts[0], 10) || 0; // Default to 0 if NaN
    let inches = parts.length > 1 ? parseInt(parts[1], 10) || 0 : 0;

    // Convert feet and inches to centimeters
    const totalInches = feet * 12 + inches;
    const cmValue = totalInches * 2.54;
    const roundedCm = Math.round(cmValue * 100) / 100;

    setHandelmaxcm(roundedCm);
  };

  const handlfeet = handelFtInchi[0];

  useEffect(() => {
    console.log("useEffect triggered with handelFtInchi:", handelFtInchi);

    if (typeof handelFtInchi === "string") {
      const parts = handelFtInchi.split(".");
      const feet = parseInt(parts[0], 10) || 0;
      let inches = parts.length > 1 ? parseFloat(`0.${parts[1]}`) : 0; // Get inches directly

      inches = Math.round(inches * 12);

      console.log("Calculated feet:", feet, "Inches:", inches);
      const handelFtInchiStr = String(handelFtInchi);
      let height_feet = handelFtInchiStr.slice(0, 2).replace(".", "");
      // let height_inch = handelFtInchiStr.slice(-2).replace(".", "");

      let height_inch;

      if(handelFtInchiStr.toString().includes('.')){
       height_inch = handelFtInchiStr.slice(-2).replace(".", "");
      }
      else{
        height_inch = 0;
      }

      const heightInCm = feetAndInchesToCm(height_feet, height_inch);
      console.log("The height cm", heightInCm);

      dispatch(
        updateData([
          { key: "height_feet", value: height_feet },
          { key: "height_inch", value: height_inch },
        ])
      );
      dispatch(updateData({ key: "height", value: heightInCm }));
    }
  }, [handelFtInchi, dispatch]);


  function cmToFeetInches(cm) {
    const totalInches = cm / 2.54; // Convert cm to inches
    const feet = Math.floor(totalInches / 12); // Get the whole feet part
    const inches = Math.floor((totalInches % 12) * 100) / 100; // Get the remaining inches, up to 2 decimal places
    
    return `${feet}.${inches}`;
}

  return (
    <>
      <ProposalArea selfData={selfData} data={data} />

      <div className={`userhide m-10`}>
        <div className="row  ppv10">
          <div className="col-md-2 px-0 py-0">
            <div className="prpfbd" style={{ width: "100%" }}>
              <span style={{ color: "#2959B8", fontSize: "8pt" }}>
                Salutation*
              </span>
              <Select
                style={{ width: "100%" }}
                id="standard-select-currency-native"
                select
                label="Salutation"
                defaultValue={1}
                SelectProps={{
                  native: true,
                }}
                variant="standard"
                onChange={(e) => {
                  dispatch(
                    updateData([{ key: "Salutation", value: e.target.value }])
                  );
                  handler(e, "Salutation");
                  setSaluteValue(e.target.value);
                  validateSaluitation(e.target.value);
                }}
                error={saluteError}
                helperText={saluteError}
              >
                <MenuItem value={0}>Select</MenuItem>

                <MenuItem
                  value={1}
                  selected={
                    selfData.Gender == "M" || proposalData.Salutation == "1"
                  }
                >
                  Mr.
                </MenuItem>
                <MenuItem
                  value={2}
                  selected={
                    selfData.Gender == "M" || proposalData.Salutation == "2"
                  }
                >
                  Mrs.
                </MenuItem>
                <MenuItem
                  value={3}
                  selected={
                    selfData.Gender === "F" || proposalData.Salutation == "3"
                  }
                >
                  Ms.
                </MenuItem>
              </Select>

              {/* <TextField
              style={{width:"100%"}}
                id="standard-select-currency-native"
                select
                label="Salutation"
                defaultValue={1}
                SelectProps={{
                  native: true,
                }}
                variant="standard"
                onChange={(e) => {
                  dispatch(
                    updateData([{ key: "Salutation", value: e.target.value }])
                  );
                  handler(e, "Salutation");
                  setSaluteValue(e.target.value);
                  validateSaluitation(e.target.value);
                }}
                error={saluteError}
                helperText={saluteError}
              >
                <option value={0}>select</option>
                <option
                  value={1}
                  selected={
                    selfData.Gender == "M" || proposalData.Salutation == "1"
                  }
                >
                  Mr.
                </option>
                <option
                  value={2}
                  selected={
                    selfData.Gender == "M" || proposalData.Salutation == "2"
                  }
                >
                  Mrs.
                </option>
                <option
                  value={3}
                  selected={
                    selfData.Gender === "F" || proposalData.Salutation == "3"
                  }
                >
                  Ms.
                </option>
              </TextField> */}

              {Boolean(saluteError) && (
                <FormHelperText error>{saluteError}</FormHelperText>
              )}
            </div>
          </div>
          <div className="col-md-5 px-0 py-2">
            <div className="prpfbd" style={{ width: "100%" }}>
              {/* {console.log("proposalData.ProposalName", proposalData.ProposalName)} */}
              <TextField
                style={{ width: "100%" }}
                id="standard-helperText"
                label="Full Name"
                defaultValue=""
                value={nameVal}
                variant="standard"
                required
                onChange={(e) => {
                  validateFullName(e.target.value);
                  dispatch(updateProposalName(e.target.value));
                  handler(e, "InsuredName");
                  setNameVal(e.target.value);
                }}
                error={fullNameError}
                helperText={fullNameError}
              />
              {/* {Boolean(fullNameError) && (
                <FormHelperText error>{fullNameError}</FormHelperText>
              )} */}
            </div>
          </div>
          <div className="col-md-5 px-0 py-0">
            <div className="prpfbd" style={{ width: "100%" }}>
              <span style={{ color: "#2959B8", fontSize: "8pt" }}>
                Occupation*
              </span>
              <Select
                style={{ width: "100%" }}
                id="standard-select-currency-native"
                select
                // label="Salutation"
                defaultValue={pfData.Occupation ? pfData.Occupation : 0}
                SelectProps={{
                  native: true,
                }}
                variant="standard"
                required
                onChange={(e) => {
                  dispatch(
                    updateData([{ key: "Occupation", value: e.target.value }])
                  );
                  handler(e, "Occupation");
                  setOccupValue(e.target.value);
                  validateOccupation(e.target.value);
                }}
                error={occupationError}
                helperText={occupationError}
              >
                <MenuItem value={0}>Select</MenuItem>

                {OccupationData.map((data) => {
                  return (
                    <MenuItem
                      value={data.value}
                      selected={proposalData.Occupation == data.value}
                      key={data.value}
                    >
                      {data.Name}
                    </MenuItem>
                  );
                })}
              </Select>
              <span className="error">{occupationError}</span>
            </div>
          </div>
        </div>

        <div className="row ppv10">
          <div className="col-md-4 px-0 py-2">
            <div className="prpfbd" style={{ width: "100%" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DesktopDatePicker"]}>
                  <DesktopDatePicker
                    variant="standard"
                    id="standard-helperText"
                    label="DOB"
                    defaultValue=""
                    value={
                      pfData.dateOfbirth
                        ? dayjs(pfData.dateOfbirth)
                        : ""
                    }
                    required
                    onChange={(e) => {
                      dispatch(
                        updateData([{ key: "dateOfbirth", value: getDate(e) }])
                      );
                      dispatch(
                        updateData([{ key: "KycDateOfbirth", value: getDate(e) }])
                      );
                      handler(e, "dateOfbirth", true);
                      validateDob(getDate(e));
                      setDobValue(getDate(e));
                      dispatch(getDateofbirth(e));
                    }}
                    error={dobError}
                    helperText={dobError}
                    format="DD-MM-YYYY"
                    sx={{
                      '& .MuiInputBase-root': {
                        boxShadow: 'none',
                        '& .MuiOutlinedInput-notchedOutline': {
                          border: 0,
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          border: 0,
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          border: 0,
                        },
                      },
                      '& .MuiInputBase-input': {
                        padding: '10px 5px', // Adjust padding as needed
                      },
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
              {Boolean(dobError) && (
                <FormHelperText error>{dobError}</FormHelperText>
              )}
            </div>
          </div>
          <div className=" col-12 col-md-3 px-0 py-2">
            <div className="prpfbd" style={{ width: "100%" }}>
              <TextField
                style={{ width: "100%" }}
                id="standard-helperText"
                label="Weight (in kgs)"
                required
                defaultValue={pfData.weight}
                variant="standard"
                error={Boolean(weightError)}
                helperText={weightError}
                
                onChange={(e) => {
                  dispatch(
                    updateData([{ key: "weight", value: e.target.value }])
                  );
                  handler(e, "weight");
                  validateWeight(e.target.value);
                  setWeightValue(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="col-md-2 px-0 py-2">
            <div className="prpfbd" style={{ width: "100%" }}>
              {/* <Box
                sx={{
                  minWidth: 80,
                  borderBottom: "1px solid gray",
                  height: 50,
                }}
              >
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Height (feet)
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="height"
                    value={proposalData.height_feet}
                    onChange={(e) => {
                      setHeightfeet(e.target.value);
                      dispatch(
                        updateData([
                          { key: "height_feet", value: e.target.value },
                        ])
                      );
                      setHeightValue(e.target.value);
                      validateFeet(e.target.value);
                    }}
                    error={Boolean(heightFeetError)}
                    helperText={dobError}
                  >
                    <MenuItem value={1}>1 </MenuItem>
                    <MenuItem value={2}>2 </MenuItem>
                    <MenuItem value={3}>3 </MenuItem>
                    <MenuItem value={4}>4 </MenuItem>
                    <MenuItem value={5}>5 </MenuItem>
                    <MenuItem value={6}>6 </MenuItem>
                    <MenuItem value={7}>7 </MenuItem>
                    <MenuItem value={8}>8 </MenuItem>
                    <MenuItem value={9}>9 </MenuItem>
                    <MenuItem value={10}>10 </MenuItem>
                  </Select>
                </FormControl>
              </Box> */}

             

              <TextField
                style={{ width: "100%" }}
                id="standard-helperText"
                label="Height (cm)"
                variant="standard"
                type="number"
                placeholder="e.g, 172.72"
                disabled={true}
                inputProps={{
                  min: "0",
                  max: "332.74",
                  step: "0.01",
                  pattern: "[0-9]+([.,][0-9]+)?",
                }}
                onChange={handleValueSet}
                value={pfData.height ? pfData.height: handelmaxcm}
              />
            </div>
          </div>

          {/* {alert(JSON.stringify(proposalData))} */}

          <div className="col-md-3 px-0 py-2">
            <div className="prpfbd" style={{ width: "100%" }}>
              {/* <TextField
                style={{ width: "100%" }}
                id="standard-helperText"
                label="height (ft,inchi) "
                defaultValue=""
                type="number"
                variant="standard"
                placeholder="e.g, 5.8"
                onChange={(e) => {
                  heighttoinchi(e);
                  setHeightinches(e.target.value);
                  handler(e, "height", false, "inches");
                  setHeightfeet(handlfeet);
                  setHeightValue(e.target.value);
                  validateHeight(e)
                  // validateFeet(e);
                }}
                value={pfData.height ? cmToFeetInches(pfData.height) :handelFtInchi}
              /> */}
              <TextField
                style={{ width: "100%" }}
                id="standard-helperText"
                label="height (ft,inchi) "
                type="number"
                variant="standard"
                placeholder="e.g, 5.8"
                onChange={(e) => {
                  heighttoinchi(e);
                  setHeightinches(e.target.value);
                  handler(e, "height", false, "inches");
                  setHeightfeet(handlfeet);
                  setHeightValue(e.target.value);
                  validateHeight(e)
                  // validateFeet(e);
                }}
                value={handelFtInchi}
              />




              {/* 
              <Box
                sx={{
                  minWidth: 80,
                  borderBottom: "1px solid gray",
                  height: 50,
                }}
              >
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">inchi</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={proposalData.height_inch}
                    required
                    label="inchi"
                    onChange={(e) => {
                      setHeightinches(e.target.value);

                      handler(e, "height", false, "inches");
                      validateInch(e.target.value);
                      dispatch(
                        updateData([
                          { key: "height_inch", value: e.target.value },
                        ])
                      );
                      setInchValue(e.target.value);
                    }}
                    error={Boolean(heightInchesError)}
                    helperText={dobError}
                  >
                    <MenuItem value={0}>0</MenuItem>
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={6}>6</MenuItem>
                    <MenuItem value={7}>7</MenuItem>
                    <MenuItem value={8}>8</MenuItem>
                    <MenuItem value={9}>9</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={11}>11</MenuItem>
                  </Select>
                </FormControl>

              </Box> */}

              {Boolean(heightInchesError) && (
                <FormHelperText error>{heightInchesError}</FormHelperText>
              )}
            </div>
          </div>
        </div>

        <div className="row  ppv10">
          <div className="col-md-4 px-0 py-0">
            <div className="prpfbd" style={{ width: "100%" }}>
              <span style={{ color: "#2959B8", fontSize: "8pt" }}>
                MaritalStatus*
              </span>
              <Select
                style={{ width: "100%" }}
                id="standard-select-currency-native"
                select
                defaultValue={0}
                value={
                  pfData.MaritalStatus
                    ? pfData.MaritalStatus
                    : maritalValue
                }
                SelectProps={{
                  native: true,
                }}
                variant="standard"
                required
                onChange={(e) => {
                  dispatch(
                    updateData([
                      { key: "MaritalStatus", value: e.target.value },
                    ])
                  );
                  handler(e, "MaritalStatus");
                  setMaritalValue(e.target.value);
                  validateMarital(e.target.value);
                }}
                error={maritalError}
                helperText={maritalError}
              >
                <MenuItem value={0}>Select</MenuItem>

                <MenuItem
                  value="Married"
                  selected={proposalData.MaritalStatus == "Married"}
                >
                  Married
                </MenuItem>
                <MenuItem
                  value="Unmarried"
                  selected={proposalData.MaritalStatus == "Unmarried"}
                >
                  Unmarried
                </MenuItem>
                <MenuItem value="Divorcee">Divorcee</MenuItem>
                    <MenuItem value="Widow">Widow</MenuItem>
                    <MenuItem value="Widower">Widower</MenuItem>
              </Select>
              <span className="error">{maritalError}</span>
            </div>
          </div>
          <div className="col-md-4 px-0 py-0">
            <div className="prpfbd" style={{ width: "100%" }}>
              <span style={{ color: "#2959B8", fontSize: "8pt" }}>
                Education*
              </span>
              <Select
                style={{ width: "100%" }}
                id="standard-select-currency-native"
                select
                defaultValue={0}
                value={
                  pfData.Education
                    ? pfData.Education
                    : eduValue
                }
                SelectProps={{
                  native: true,
                }}
                variant="standard"
                required
                onChange={(e) => {
                  dispatch(
                    updateData([{ key: "Education", value: e.target.value }])
                  );
                  handler(e, "Education");
                  setEduValue(e.target.value);
                  validateEducation(e.target.value);
                }}
                error={eduError}
                helperText={eduError}
              >
                <MenuItem value={0}>Select</MenuItem>

                <MenuItem
                  value="highSchool"
                  selected={pfData.Education == "highSchool"}
                >
                  High School
                </MenuItem>
                <MenuItem
                  value="associate"
                  selected={pfData.Education == "associate"}
                >
                  Associate's Degree
                </MenuItem>
                <MenuItem
                  value="bachelor"
                  selected={pfData.Education == "bachelor"}
                >
                  Bachelor's Degree
                </MenuItem>
                <MenuItem
                  value="master"
                  selected={pfData.Education == "master"}
                >
                  Master's Degree
                </MenuItem>
                <MenuItem
                  value="doctorate"
                  selected={pfData.Education == "doctorate"}
                >
                  Doctorate
                </MenuItem>
              </Select>

              <span className="error">{eduError}</span>
            </div>
          </div>
          <div className="col-md-4 px-0 py-2">
            <div className="prpfbd" style={{ width: "100%" }}>
              <TextField
                style={{ width: "100%" }}
                id="standard-helperText"
                label="Annual Income"
                defaultValue={proposalData.AnnualIncome}
                variant="standard"
                error={Boolean(annualIncomeError)}
                helperText={annualIncomeError}
                // value={}
                required
                onChange={(e) => {
                  dispatch(
                    updateData([{ key: "AnnualIncome", value: e.target.value }])
                  );
                  validateAnnualIncome(e.target.value);
                  setIncomeValue(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ProposalArea = ({ data, selfData }) => {
  // console.log("the userdata is", data, selfData);
  return data !== false ? (
    <p
      className="user-info"
      data-bs-toggle="collapse"
      href={`#${data.InsuredRelation}`}
      role="button"
      aria-expanded="true"
      aria-controls={data.InsuredRelation}
    >
      Proposer({data.InsuredRelation} {data.InsuredAge == 0 ? "< 91 Days" : `${data.InsuredAge} Years` } )
    </p>
  ) : (
    <p className="user-info">Proposer</p>
  );
};

// export default Proposal
export { Proposal, ProposerForm };