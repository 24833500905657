import React from 'react';
import PartnerCompany from '../AllPageComponents/PartnerCompany/PartnerCompany';
import FAQ from '../AllPageComponents/FAQ/FAQ';
import './TermInsurence.css';
import TermInsuranceForm from './TermInsuranceForm';

import FastImage from "../FastImage";
import { auto } from '@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core'
import { LuAlarmClock } from "react-icons/lu";
import Redirect from '../AllPageComponents/Rederict';
import TermFormtwo from './TermFormtwo';

const TermCompareInsurance = () => {


  //console.log(formValues)
  return (
    <>
    <Redirect/>
      <section className="car-insurance insurance-all">
        <div className="container">

          <div className="row insurance-row-one">
            <div className="col-sm-12 col-md-4 col-lg-4 ">





                  <div>
              <div className="healthdiscription">
                <h1>Compare Term Insurance</h1>
                <span>
                  "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer too.{" "}
                </span>
              </div>
              <div className="insurance-btn-set">
                <button
                  type="button"
                  className="reminderbutton"
                  data-toggle="modal"
                  data-target="#reminderpopup"
                >
                  {" "}
                  <LuAlarmClock
                    fontSize={"15pt"}
                    style={{ margin: "0 5px", color: "#FBA71B" }}
                  />{" "}
                  Set Reminder
                </button>
              </div>
              </div>
            
            </div>




            <div className="col-sm-12 col-md-4 col-lg-4 side-image">

            <FastImage
            url={"Website%20Img/BikeInsurence.png?updatedAt=1718779269495"}
            width={auto}
            height={auto}
            alt={"ca-img"}
            className='fmailimagegifs'
            />




    
            </div>
           <TermFormtwo/>
         
          </div>
        </div>
      </section>
<section className="half-space-block">
  <div className="container">
    <h5 style={{color:"#2959B8"}}>What is Compare Insurance Policy?</h5>
    <div className="yellowline"></div>
    <p>
      Currently there are 24 insurance companies that provide term insurance in
      India. Term insurance is a type of life insurance policy which provides a
      sum assured known as death benefit in case of an unfortunate demise of the
      life assured during the policy tenure. Term insurance can help an
      individual ensure financial security of their family. Hence it is very
      important to choose an insurance company whom you fully rely on. While
      choosing a term insurance company you should always consider the claim
      settlement ratio of the company.
    </p>
    <p>
      The Indian insurance market is full of term insurance companies that offer
      term insurance plans with high coverage at affordable premium amounts, to
      the insured. Currently, there are 24 insurance companies that offer term
      insurance plans in India.
    </p>
  </div>
</section>
<PartnerCompany/>
<FAQ/>
    </>
  )
}

export default TermCompareInsurance