import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Loader from "./Loader";
import axios from "axios";
import "./Login.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FastImage from "../FastImage";
import { auto } from "@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core";

import {
  selectUserData,
  updatedUserdata,
  updatedLoginPopup,
  selectLoginForm,
  selectOtpForm,
  updatedOtpPopup,
  updatedPhone,
  updatedLoginUser
} from "../Health/redux/Slice";
import { useRef } from "react";


const Login = () => {
  
  const dispatch = useDispatch();

  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  const loginPopup = useSelector(selectLoginForm);


  // Is Loading State When The Otp is Submited then the Loader is Working........

  const [isloadingpage, setisLoadingpage] = useState(false);

  // This is For The Showing gmail for Forgian Country

  const [showmail, setShowmail] = useState(91);

  const countrycheck = (data) => {
    setShowmail(data.target.value)
    // const Datavalue = data.target.value;
    // Datavalue === "91" ? setShowmail(true) : setShowmail(false);
  };

  // ////////////////////////////////////////////////////////////////////

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      sendOtp();
    }
  };

  const closeModal = () => {
    dispatch(updatedLoginPopup(false));
  };
  const sendOtp = () => {
    if (phone === "") {return;}

    setisLoadingpage(true);
    let data = JSON.stringify({
      mobileNumber: phone,
      email: "",
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_BASE_URL + "user/login",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        //console.log(JSON.stringify(response.data));
        closeModal();
        dispatch(updatedOtpPopup(true));
      })
      .catch((error) => {
        //console.log(error);
        closeModal();
        setisLoadingpage(false);
      });
  };

  
  
  
  useEffect(()=>{
    
    if(localStorage.getItem('Proposalphonenumber') != '' && localStorage.getItem('Proposalphonenumber') != null)  
    {
      
      const phone_no = localStorage.getItem('Proposalphonenumber').slice(-10);

      dispatch(updatedPhone(phone_no))
      setPhone(phone_no)
       
    }
    else
    {
      dispatch(updatedPhone(''))
    }
    
   },[])

  const { loginUser } = useSelector((state) => {
    return state.FetchPlan;
  });


// new phone number got by user if not login ---------------

const { newPhoneNum } = useSelector((state) => {
  return state.FetchPlan;
});



const buttonRef = useRef(null);
const handleKeyDown = (event) => {
  // Trigger button click on Enter key
  if (event.key === 'Enter') {
    event.preventDefault(); // Prevent default form submission behavior
    buttonRef.current.click(); // Simulate button click
  }
};




  return (
    <>
      <div className="loader-container">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content lopinotppopup">
            <div className="modal-header">
              <button
                type="button"
                className="close loginMobileClose"
                onClick={(e) => closeModal()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="login-modal">
              <div className="modal-body">
                <div className="new-login-area">
                  <div id="sign-in" className="login-setup-section">
                    <h3 className="request-otp-header">
                      Login with mobile number
                    </h3>
                    
          
                    <div className="form-group login-label">
                      <div className="custom-select-login">
                        <div className="select-cus">
                          <select
                            name="country"
                            id="country"
                            className="form-controls"
                            onChange={countrycheck}
                          >
                            <option value="966">+966</option>
                            <option value="91" selected={true}>
                              +91
                            </option>
                            <option value="47">+47</option>
                            <option value="46">+46</option>
                            <option value="79">+79</option>
                          </select>
                        </div>





                        <input
                          type="text"
                          className="input-edit"
                          placeholder="Mobile Number"
                          id="userNumber"
                          name="userNumber"
                          value={loginPopup.phone}

                          // my work -----
                          defaultValue={newPhoneNum}
                          // ----------

                          style={{paddingLeft:"0px",textAlign:"center",backgroundColor:'transparent'}}

                          maxLength="10"
                          onInput={(e) => {
                            e.target.value = e.target.value
                              .replace(/[^0-9.]/g, "")
                              .replace(/(\..*?)\..*/g, "$1");
                          }}
                          onChange={(e) => {
                            setPhone(e.target.value);
                            dispatch(updatedPhone(e.target.value));
                          }}
                          onKeyPress={handleKeyPress}
                        />





                        
                      </div>
                    
                      <label
                        id="mobileNumberVal"
                        className="error"
                        style={{ display: "none" }}
                      ></label>
                      {showmail ? null : (
                        <input
                          type="email"
                          className="emailtop"
                          placeholder="Email"
                          id="emailId"
                          name="email"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      )}

                      <label
                        id="emailVal"
                        className="error"
                        style={{ display: "none" }}
                      ></label>
                    </div>

                    <button
                      type="button"
                      onClick={() => sendOtp()}
                      className="btn btn-default btn-lg btn-block request-otp blue-btn finalfrmsubt"
                      id="userNumberButton"
                      ref={buttonRef} // Reference for the button
                      onKeyDown={handleKeyDown} // Attach key down handler
                    >
                      <span>
                        {isloadingpage
                          ? "Processing OTP "
                          : "Send OTP"}
                      </span>
                      {/* {isloadingpage ? null : (
                       

                        <FastImage
                  url={"Website%20Img/button-arrow.png?updatedAt=1718712281133"}
                  width={auto}
                  height={auto}
                  alt={"arrow"}
                  src={"Website%20Img/button-arrow.png?updatedAt=1718712281133"}
                />
                        
                      )} */}
                    </button>

                    <div className="validatediv">
                    <span className="coloralert">{phone === '' || phone.length < 10 ? "Plese Enter a Valid Number" : null}</span>
                    </div>

                    <div className="terms-conditions">
                      <p>
                        I agree to{" "}
                        <a href="{{ route('terms-conditions') }}">
                          terms & conditions
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <span className="bg-img-beema">
              

                <FastImage
                  url={"Website%20Img/pop-up_a.webp?updatedAt=1718775653542"}
                  width={auto}
                  height={auto}
                  alt={"arrow"}
                  src={"Website%20Img/pop-up_a.webp?updatedAt=1718775653542"}
                />
              </span>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
};
export default Login;
