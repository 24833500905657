import React from 'react';
import FAQ from '../AllPageComponents/FAQ/FAQ';
import { useDispatch } from 'react-redux'
import { VehicalApi } from '../Health/redux/Slice'
import { useState } from 'react';
import PartnerCompany from '../AllPageComponents/PartnerCompany/PartnerCompany';
import BikeInsuranceForm from './BikeInsuranceForm';
import FastImage from "../FastImage";
import { auto } from '@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core'

const BikeAddonCovers = () => {
  const disptch = useDispatch()


  const [formData, setFormData] = useState({
    car_number: '',
    phone_no: '',
    pin: '',
    car_type: 'Twowheeler',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Log the entire form data
    disptch(VehicalApi(formData))
    //console.log('Form Data:', formData);
    // Add your form submission logic here
  };
  return (
   <>
     <section className="car-insurance insurance-all insurance-background">
        <div className="container">
          <div
            className="row insurance-row-one"
            style={{ justifyContent: "space-around" }}
          >
            <div className="col-sm-12 col-md-4 col-lg-4 mian-one">
              <div className="car-insurance-first-inner">
                <h4>Bike Insurance Add-On Covers</h4>
                <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer too
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4 side-image">
  <FastImage
            url={"Website%20Img/BikeInsurence.png?updatedAt=1718779269495"}
            width={auto}
            height={auto}
            alt={"ca-img"}
            />
            </div>
          
            <style
              dangerouslySetInnerHTML={{
                __html: "\n    .hide{\n        display: none;\n    }\n",
              }}
            />
            <BikeInsuranceForm/>
           

          </div>
        </div>
      </section>
<section class="half-space-block">
		<div class="container">
			<div class="row insurance-row-two-less-farage">
				<div class="col-sm-12 col-md-12 col-lg-12">
					<div class="insurance-less-farage">
						<div class="simple-text-block">
							<h5>What Is Bike Insurance Add-ons Coverage?</h5>
							<p><span style={{fontFamily: "&quot;Segoe UI&quot;;"}}>Add-On Covers in Bike Insurance - A bike insurance add-on is an additional cover that can
								be opted with a comprehensive bike insurance policy to strengthen the coverage and
								protection provided by a bike insurance policy. There are multiple add-ons that can be
								opted for with a comprehensive bike insurance policy.</span></p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
    <section className="full-space-block">
  <div className="container">
    <div className="row full-space-block-row">
      <div className="col-sm-12 col-md-12 col-lg-12">
        <div className="insurance-less-farage">
          <div className="simple-text-block">
            <h5>List of Top Bike Insurance Add-On Covers</h5>
            <p>
              The availability of bike insurance add-ons varies from insurer to
              insure. The following are some bike insurance add-on covers that
              are offered by most of the general insurance companies:
            </p>
            <h6>1. Zero Depreciation Add-on Cover</h6>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer too Lorem Ipsum is
              simply dummy text of the printing and typesetting industry. Lorem
              Ipsum has been the industry's standard dummy text ever since the
              1500s, when an unknown printer too
            </p>
            <h6>2. Consumables Add-on Cover</h6>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer too Lorem Ipsum is
              simply dummy text of the printing and typesetting industry. Lorem
              Ipsum has been the industry's standard dummy text ever since the
              1500s, when an unknown printer too
            </p>
            <h6>3. Roadside Assistance Add-on Cover</h6>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer too Lorem Ipsum is
              simply dummy text of the printing and typesetting industry. Lorem
              Ipsum has been the industry's standard dummy text ever since the
              1500s, when an unknown printer too
            </p>
            <h6>4. Engine Protection Add-on Cover</h6>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer too Lorem Ipsum is
              simply dummy text of the printing and typesetting industry. Lorem
              Ipsum has been the industry's standard dummy text ever since the
              1500s, when an unknown printer too
            </p>
            <h6>5. Key Loss Add-on Cover</h6>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer too Lorem Ipsum is
              simply dummy text of the printing and typesetting industry. Lorem
              Ipsum has been the industry's standard dummy text ever since the
              1500s, when an unknown printer too
            </p>
            <h6>6. Passenger Assistance Add-on Cover</h6>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer too Lorem Ipsum is
              simply dummy text of the printing and typesetting industry. Lorem
              Ipsum has been the industry's standard dummy text ever since the
              1500s, when an unknown printer too
            </p>
            <h6>7. Tyre Damage Add-on Cover</h6>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer too Lorem Ipsum is
              simply dummy text of the printing and typesetting industry. Lorem
              Ipsum has been the industry's standard dummy text ever since the
              1500s, when an unknown printer too
            </p>
          </div>
          <div className="simple-text-block mr-top">
            <h5>Benefits of Add-on Covers in Bike Insurance</h5>
            <p>The add-on covers for bike insurance brings several benefits:</p>
            <h6>1. Cover Car’s Depreciation</h6>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer too Lorem Ipsum is
              simply dummy text of the printing and typesetting industry. Lorem
              Ipsum has been the industry's standard dummy text ever since the
              1500s, when an unknown printer too
            </p>
            <h6>2. Get Emergency Assistance</h6>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer too Lorem Ipsum is
              simply dummy text of the printing and typesetting industry. Lorem
              Ipsum has been the industry's standard dummy text ever since the
              1500s, when an unknown printer too
            </p>
            <h6>3. Provides Engine Protection</h6>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer too Lorem Ipsum is
              simply dummy text of the printing and typesetting industry. Lorem
              Ipsum has been the industry's standard dummy text ever since the
              1500s, when an unknown printer too
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<PartnerCompany/>
<FAQ/>
   </>
  )
}

export default BikeAddonCovers