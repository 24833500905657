import React,{useState} from 'react';
import '../Howtobuyformbeema/Howtobuybeema.css';
import FastImage from '../../../../../FastImage';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { MdOutlineExpandMore } from 'react-icons/md';
import { IoMdAddCircle } from "react-icons/io";

const Howtobuybeema = () => {


    const HowToBuyHealthInsurence = [
        {
            step: 1,
            "title": "Provide Your Details",
            "description": "Enter your information to get instant access to competitive insurance quotes. Easily compare insurance policy premiums from the top-rated insurers."
        },
        {
            step: 2,
            "title": "Compare Quotes & Select The Right Health Insurance Plan",
            "description": "Compare plans based on features and costs aligned with your needs, and your budget, and then choose the best-fit plan for yourself."
        },
        {
            step: 3,
            "title": "Pay and Get Policy Within Minutes",
            "description": "Buy your policy online and receive it instantly in your email inbox."
        },
        {
            step: 4,
            "title": "Get Your Policy",
            "description": "Get your policy – Once you make the payment towards your insurance policy, your policy will be issued immediately in no time, only if there are no underwriting requirements."
        },
        {
          step: 5,
          "title": "Constant Customer Support",
          "description": "Our customer service team is dedicated to helping you with any queries."
      },
      
      ]


      const [expanded, setExpanded] = useState(null);

      const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : null);
      };


  return (
   <div className="fixwidthDivtext">
    <div className="container">

    <h4 className='healthHeading'>
How to Buy Health Insurance Plan from Beema1  </h4>
<div className="yellowline"></div>
<p className="normaltext">
    In just a few clicks, you can secure your health policy with ease and convenience. Our streamlined online process is designed to save you time and effort, allowing you to compare various plans tailored to your needs. Whether you're looking for comprehensive coverage or specific benefits, our user-friendly platform makes it simple to find the right health insurance for you and your family. Plus, our dedicated support team is here to guide you every step of the way, ensuring that you make informed decisions about your healthcare.
</p>
        <div className="row">
            <div className="col-md-6">
                <div className="tabulerbuy1">
                <div className="numberbuy1">
                 1
                </div>
                <div className="textbuy1">
                   Provide Your Details
                </div>
                </div>
                <div className="tabulerbuy1">
                <div className="numberbuy1">
                 2
                </div>
                <div className="textbuy1">
                   Compare and Select Right Plane
                </div>
                </div>
                <div className="tabulerbuy1">
                <div className="numberbuy1">
                 3
                </div>
                <div className="textbuy1">
                 Pay for Your Policy Digitally
                </div>
                </div>
                <div className="tabulerbuy1">
                <div className="numberbuy2">
                 4
                </div>
                <div className="textbuy1">
                 Your Policy Issued
                </div>
                </div>





            </div>
<div className="col-md-6 d-flex justify-content-center align-items-center">
<div>

<FastImage url='/Website%20Img/shield_3009488.png?updatedAt=1727503379599' className="fmailimagegifsstepper"/>


</div>
</div>

<div className="col-md-12 d-flex justify-content-center align-items-center mt-3">

<div>

{
    HowToBuyHealthInsurence.map((data)=>{
        return(
            <Accordion expanded={expanded === `panel${data.step}`} onChange={handleChange(`panel${data.step}`)} key={data.step}>
        <AccordionSummary
          expandIcon={<MdOutlineExpandMore />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="accordingback"
          >
          <li className='listcol'>
          {data.step}.&nbsp;
           {data.title}
          </li>
        </AccordionSummary>
        <AccordionDetails className="AnwsarSpan">
         {data.description}
        </AccordionDetails>
      </Accordion>
    )
})
}

</div>
</div>

        </div>
    </div>
   </div>
  )
}

export default Howtobuybeema