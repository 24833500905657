import React, { useState } from 'react';
import '../CompairPolicy/Compairpolicy.css';
import { useSelector, useDispatch } from 'react-redux';
import { RecvedPolicyCode, SendthePolicyData } from '../../redux/Slice';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { RxCross2 } from "react-icons/rx";


const ComapirPolicyBord = () => {
const nav = useNavigate();


  const {RecevedpolicyCode ,Receved_cid} = useSelector((state) => state.FetchPlan);




  const PlaneCodes = Array.isArray(RecevedpolicyCode) ? RecevedpolicyCode.map(item => item.planCode) : [];




  console.log("The C_id",PlaneCodes)
  const dispatch = useDispatch();

  const ComapirApply = async () => {
    try {
      const planCodesString = PlaneCodes.join(',');
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}health/compare`, {
        planCodes: planCodesString
      });
      dispatch(SendthePolicyData(response.data.data));
      nav(`/compairpolicy?plane_id=${planCodesString}${Receved_cid}`); 
    // nav(`/compairpolicy?planeid=${planCodesString}${Receved_cid}`); 
      //console.log("policy Data is", Recevedpoliydata);
    
    } catch (error) {
      console.error('Error:', error);
    }
  };



  // const RecevedData = RecevedpolicyCode

  const HandelPolicyDelete = (planCode) => {
    console.log('Deleting planCode:', planCode); // Debugging
    
    const updatedPolicyCodes = RecevedpolicyCode.filter(code => code.planCode !== planCode);
    
    console.log('Updated policy codes:', updatedPolicyCodes); // Debugging
    
    dispatch(RecvedPolicyCode(updatedPolicyCodes));
  };
  







  return (
    <div className='policybord'>
    {[...Array(3)].map((_, index) => (
        <React.Fragment key={index}>
            <div className="policybox">
                {RecevedpolicyCode[index] ? (
                    <>
                        <div>
                            <button
                                style={{ backgroundColor: "transparent", border: "none" }}
                                onClick={() => HandelPolicyDelete(RecevedpolicyCode[index].planCode)}
                            >
                                <RxCross2 />
                            </button>
                        </div>
                        <div>
                            <img
                                src={RecevedpolicyCode[index].insurerLogo || 'default-logo.png'} // Fallback for image
                                alt="Insurer Logo"
                            />
                            <span>{RecevedpolicyCode[index].planName ? RecevedpolicyCode[index].planName.slice(0, 15) : 'No Plan Name'}</span>
                        </div>
                    </>
                ) : (
                    <div className='nodatafound'>No Data Available</div>
                )}
            </div>
            {index < 2 && <div className='vsdiv'>vs</div>}
        </React.Fragment>
    ))}

    <button
        className={RecevedpolicyCode.length < 2 ? "Deactiveapplybutton" : 'applybutton'}
        onClick={ComapirApply}
        disabled={RecevedpolicyCode.length < 2}
    >
        Compare now
    </button>
    <button
        className='cancelldbutton'
        onClick={() => dispatch(RecvedPolicyCode([]))}
    >
        Cancel
    </button>
</div>

  );
}

export default ComapirPolicyBord;
