import React from "react";
import PartnerCompany from "../AllPageComponents/PartnerCompany/PartnerCompany";
import FAQ from "../AllPageComponents/FAQ/FAQ";
import BikeInsuranceForm from "./BikeInsuranceForm";
import FastImage from "../FastImage";
import { auto } from '@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core'
import Redirect from "../AllPageComponents/Rederict";


const BikeCompanies = () => {

  return (
    <>
    <Redirect/>
      <section className="car-insurance insurance-all insurance-background">
        <div className="container">
          <div
            className="row insurance-row-one"
            style={{ justifyContent: "space-around" }}
          >
            <div className="col-sm-12 col-md-4 col-lg-4 mian-one">
              <div className="car-insurance-first-inner">
                <h4 style={{fontFamily:'Poppins',fontWeight:500,fontSize:"18pt",color:'#0166B3'}}>Bike Insurance Companies</h4>
          <br />
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer too
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4 side-image">
  <FastImage
            url={"Website%20Img/BikeInsurence.png?updatedAt=1718779269495"}
            width={auto}
            height={auto}
            alt={"ca-img"}
            />
            </div>
          
            <style
              dangerouslySetInnerHTML={{
                __html: "\n    .hide{\n        display: none;\n    }\n",
              }}
            />
            <BikeInsuranceForm/>
           

          </div>
        </div>
      </section>
      <section className="half-space-block">
        <div className="container">
          <div className="row insurance-row-two-less-farage">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div className="insurance-less-farage">
                <div className="simple-text-block Term-insurance">
                  <h5>ICICI Lombard Bike Insurance</h5>
                  <p>
                    Providing a host of benefits, ICICI Lombard Bike Insurance
                    is one of the most trusted companies when it comes to buying
                    a bike insurance plan. Whether you need a multi-trip or
                    single-trip bike insurance plan, ICICI Lombard Bike
                    Insurance can meet most of your bike needs. Some of the
                    advantages of buying a bike insurance plan with ICICI
                    Lombard Bike Insurance are listed below:
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="full-space-block">
        <div class="container">
          <div class="row full-space-block-row">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="insurance-less-farage">
                <div class="simple-text-block">
                  <p>
                    Offers coverage against complete loss of baggage, including
                    hand baggage. Provide wide coverage at an affordable premium
                    so that you can remain stress-free and enjoy travelling to
                    the fullest. There is no medical test required when you buy
                    bike insurance with ICICI Lombard Bike Insurance. Provides
                    cashless hospitalisation in case you need medical assistance
                    in a foreign land.
                  </p>

                  <h6>Bajaj Allianz Bike Insurance</h6>

                  <p>
                    Yet another popular bike insurance provider is Bajaj Bike
                    Insurance which provides plans to meet your varied bike
                    needs. Offering protection against delayed flights, lost
                    baggage, and more, there are several other reasons why you
                    should rely on Bajaj Allianz Bike Insurance. Read on to
                    learn more:
                  </p>

                  <p>
                    Yet another popular bike insurance provider is Bajaj Bike
                    Insurance which provides plans to meet your varied bike
                    needs. Offering protection against delayed flights, lost
                    baggage, and more, there are several other reasons why you
                    should rely on Bajaj Allianz Bike Insurance. Read on to
                    learn more:
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <PartnerCompany />
      <FAQ />
    </>
  );
};

export default BikeCompanies;
