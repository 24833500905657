import React, { useState, useEffect } from "react";
import "../../CommonCssFiles/planstyle.css";
import { TextField } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { Proposal, ProposerForm } from "./Proposal";
import { findSelf } from "../../helper/common";
import Summary from "../../commonComponents/Summary";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import calculateAge from "../AfterPlanFetchPage/AgeModalHelper";
import Loader from "../../../AllPageComponents/Loader";
import Swal from "sweetalert2";

import {
  selectProposal,
  updateData,
  addInsuredData,
  updateInsuredData,
  updateName,
  updateFirstData,
  updateValidCheckProposer,
  updateProposalClick
} from "../../redux/Slice";
import Redirect from "../../../AllPageComponents/Rederict";
import { afterPlanFetchApi, AddonApi } from "../../Api/Api";
import { Helmet } from "react-helmet-async";

const Proposalfrom = () => {
  const history = useNavigate();

  const goBack = () => {
    history(-1);
  };
  const { filters } = useSelector((state) => {
    return state.FetchPlan;
  });

  const proposalData = useSelector(selectProposal);

  const [emailError, setEmailError] = useState("");
  const [mobileNumberError, setMobileNumberError] = useState("");
  const [pincodeError, setPincodeError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [errorArrData, setErrorArrData] = useState([]);
  const [ageDifference, setAgeDifference] = useState([]);
  const [ageSame, setAgeSame] = useState([]);
  const [allProposerData, setAllProposerData] = useState(false);
  const [allProposalData, setAllProposalData] = useState(false);
  const [proposerCorrect, setProposerCorrect] = useState(null);
  const [proposalCorrect, setProposalCorrect] = useState(true);

  const [heightfeet, setHeightfeet] = useState(0);
  const [heightinches, setHeightinches] = useState(0);
  const [dobError, setDobError] = useState();
  const [weightError, setWeightError] = useState("");
  const [heightErrorProposal, setHeightErrorProposal] = useState();
  const [heightInchesProposal, setHeightInchesProposal] = useState("");
  const [fullNameError, setFullNameError] = useState("");
  const [maritalError, setMaritalError] = useState("");
  const [conflictData, setConFlictData] = useState([
    {
      id: "",
      relation: "",
    },
  ]);
  const [salutationError, setSalutationError] = useState("");
  const [addonlist, setAddonList] = useState();
  const [stopLoading, setStopLoading] = useState(false);

  const insuredDetail = proposalData.InsuredDetail;

  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});

  const [salute, setSalute] = useState();
  const [nameData, setNameData] = useState();
  const [maritalData, setMaritaldata] = useState();
  const [dobdata, setDobData] = useState();
  const [weightData, setWeightData] = useState();
  const [heightData, setHeightData] = useState();
  const [inchData, setInchData] = useState();

  const [addonArr, setAddonArr] = useState([]);

  const [insuredData, setInsuredData] = useState("");

  const [loading, setLoading] = useState(true);
  const [apiResponse, setApiResponse] = useState(null);
  const [userdata, setUserData] = useState("");
  const [addonDataList, setAddonDataList] = useState([]);


  const search = window.location.search;
  const params = new URLSearchParams(search);
  const unique_id = params.get("req_id");
  const plan_id = params.get("plan_id");
  const c_id = params.get("c_id");

  // const { userdata } = useSelector((state) => {
  //   return state.FetchPlan;
  // });

  const handleError = (message) => {
    Swal.fire({
      title: "Error!",
      text: message,
      icon: "error",
      confirmButtonText: "OK",
    }).then((result) => {
      if (result.isConfirmed) {
        // Reload the page when user clicks "OK"
        window.location.reload();
      }
    });
    setStopLoading(true);
  };

  const fetchdata = useSelector((state) => state.FetchPlan);

  const proposalClick = useSelector((state) => state.FetchPlan.proposalClick);

  const proposalError = useSelector(
    (state) => state.FetchPlan.proposalErrorResult
  );
  const proposerError = useSelector((state) => state.FetchPlan.errorResult);

  //console.log("the proposalClick in proposalform", proposalClick);
  // let insuredData ;

  //console.log("proposalData", proposalData);
  const dispatch = useDispatch();

  let SelfKey;

  const getSelfKey = (insuredData) => {
    for (let i = 0; i < insuredData.length; i++) {
      if (insuredData[i].InsuredRelation == "Self") {
        return i;
        break;
      }
    }
    return null;
  };

  console.log("the proposalData in proposalform new", proposalData);

  const getAddon = async () => {
    try {
      const response = await AddonApi(c_id, plan_id).then((response) => {
        console.log("the response in addon", response);
        setAddonList(response.addon_data.data);
      });
    } catch (error) {
      console.log("the error in addonlist");
    }
  };

  const fetchPlan = async () => {
    try {
      // setLoading(true);
      // Start loading
      const response = await afterPlanFetchApi(unique_id, c_id, plan_id).then(
        (response) => {
          setLoading(false);
          console.log("the response in proposalForm", response);
          if (response.data.response == null) {
            setStopLoading(true);

            handleError("Oops there is an Error");
          }
          setApiResponse(response.data);

          setAddonDataList(response.data.request.addonPrice)
          if (
            proposalData.InsuredDetail.length == 0 ||
            proposalData.InsuredDetail?.[0].weight == ""
          ) {
            dispatch(updateFirstData(response.data.request));
            // insuredData = response.data.request.InsuredDetail

            setInsuredData(response.data.request.InsuredDetail);

            setUserData(response.data.request);
          } else {
            dispatch(updateFirstData(proposalData));
            // insuredData = response.data.request.InsuredDetail

            setInsuredData(proposalData.InsuredDetail);

            setUserData(proposalData);
          }
          // alert(JSON.stringify(userdata));
          // setDeductibleVal(response.data.request.deductible ? response.data.request.deductible : 25000);
          // setHdcVal(response.data.request.HDCRiderSI?response.data.request.HDCRiderSI:1000)

          SelfKey = getSelfKey(response.data.request.InsuredDetail);

          if (response.data.request.addons) {
            let currentAddons = response.data.request.addons
              ? response.data.request.addons.split(",")
              : [];

            setAddonArr(currentAddons);
          }
          afterResponse(response);
        }
      );
    } catch (error) {
      console.log("the error in afterfetchplan", error);
    } finally {
      // setLoading(false); // Stop loading
    }
  };

  console.log("the proposaldata in proposalform", proposalData);

  useEffect(() => {
    fetchPlan();
    getAddon();
  }, []);

  const afterResponse = (response) => {
    try {
      dispatch(
        updateData([
          { key: "ProposalName", value: response.data.request.ProposalName },
          {
            key: "Proposalphonenumber",
            value: response.data.request.Proposalphonenumber,
          },
          { key: "District", value: response.data.request.District },
          { key: "Sate", value: localStorage.getItem("State") },
        ])
      );

      console.log("the userData in proposalForm", userdata);

      if (proposalData.InsuredDetail.length == 0) {
        dispatch(addInsuredData(response.data.request.InsuredDetail));
      }

      if (SelfKey !== null) {
        dispatch(
          updateInsuredData({
            index: SelfKey,
            data: [
              { key: "InsuredName", value: response.data.request.ProposalName },
            ],
          })
        );
      }
    } catch (error) {}
  };

  const validateEmail = (value) => {
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
      setEmailError("Invalid email address");
    } else {
      setEmailError("");
    }
  };

  const validateMobileNumber = (value) => {
    if (value.length !== 10) {
      setMobileNumberError("Invalid mobile number");
    } else {
      setMobileNumberError("");
    }
  };

  const validatePincode = (value) => {
    // Placeholder validation logic for a 6-digit pincode
    if (!/^\d{6}$/.test(value)) {
      setPincodeError("Invalid pincode");
    } else {
      setPincodeError("");
    }
  };

  const validateAddress = (value) => {
    if (value.trim() === "") {
      setAddressError("Address cannot be empty");
    } else {
      setAddressError("");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const proposalFunction = (data) => {
    const value = data;
    return value;
  };

  const proposerFunction = (data) => {
    const value = data;
    return value;
  };

  // the proposal validations here ---------------------------------

  const validateSaluitation = (value) => {
    if (
      value == null ||
      value == " " ||
      value == "" ||
      value == undefined ||
      value == "0"
    ) {
      setSalutationError("Salutation can not be blank !");
      return false;
    } else if (!value) {
      setSalutationError("Please select a valid option !");
      return false;
    } else {
      setSalutationError("");
      return true;
    }
  };

  const validateFullName = (value) => {
    if (
      value == null ||
      value == 0 ||
      value == " " ||
      value == "" ||
      value == undefined
    ) {
      setFullNameError("Name can not be blank !");
      return false;
    } else if (!/^[a-zA-Z\s]+$/.test(value)) {
      setFullNameError("Full name must contain only letters");
      return false;
    } else {
      setFullNameError("");
      return true;
    }
    //console.log("validation is working");
  };

  const validateMarital = (value) => {
    if (
      value == null ||
      value == 0 ||
      value == " " ||
      value == "" ||
      value == undefined
    ) {
      setMaritalError("Marital status can not be blank !");
      return false;
    } else {
      setMaritalError("");
      return true;
    }
  };

  const validateDob = (value) => {
    let ca = calculateAge(value);

    if (
      value == null ||
      value == 0 ||
      value == " " ||
      value == "" ||
      value == undefined
    ) {
      setDobError("Date of birth cannot be blank");
      return false;
    }

    if (ca > 99) {
      setDobError(
        "Please select a valid date of birth (age must be at most 99)"
      );
      return false;
    } else {
      setDobError("");
      return true;
    }
  };

  const validateWeight = (value) => {
    if (
      value == null ||
      value == 0 ||
      value == " " ||
      value == "" ||
      value == undefined
    ) {
      setWeightError("Weight can not be blank.");
      return false;
    } else if (!/^\d{1,3}$/.test(value)) {
      setWeightError("Invalid weight (up to 3 digits)");
      return false;
    } else {
      setWeightError("");
      return true;
    }
  };

  // const validateFeet = (value) => {
  //   if (
  //     value == null ||
  //     value == 0 ||
  //     value == " " ||
  //     value == "" ||
  //     value == undefined
  //   ) {
  //     setHeightErrorProposal("Height can not be blank.");
  //     return false;
  //   } else if (!/^\d{0,2}$/.test(value)) {
  //     setHeightErrorProposal(
  //       "Invalid value for height in feet (up to 2 digits)"
  //     );
  //     return false;
  //   } else {
  //     setHeightErrorProposal("");
  //     return true;
  //   }
  // };

  const validateHeightNew = (value) => {
    console.log("Upcoming value:", value);

    if (
      value == null ||
      value === undefined ||
      value === "0" ||
      value === "" ||
      value == "Na" ||
      value == "un.ed"

    ) {
      setHeightErrorProposal("Height cannot be blank");
      return false;
    } else {
      setHeightErrorProposal("");
      return true;
    }
  };

  const validateInch = (value) => {
    if (value == null || value === " " || value === "" || value == undefined) {
      setHeightInchesProposal("Inches can not be blank");
      return false;
    } else if (!/^\d{0,2}$/.test(value)) {
      setHeightInchesProposal(
        "Invalid value for height in inches (up to 2 digits)"
      );
      return false;
    } else {
      setHeightInchesProposal("");
      return true;
    }
  };

  const validateHeight = (e) => {
    // Log the entire event object
    console.log("The Upcoming data:", e);

    // Check if the event and its target are defined
    if (!e || !e.target) {
      console.error("Event or event target is undefined");
      setHeightErrorProposal("Height cannot be blank");
      return false;
    }

    // Log the value of the input field
    const comingUser = e.target.value;
    console.log("Input value:", comingUser);

    // Check if the input is empty or invalid
    if (!comingUser || comingUser.trim() === "" || comingUser === "0") {
      setHeightErrorProposal("Height cannot be blank");
      return false;
    } else {
      setHeightErrorProposal("");
      return true;
    }
  };
  // ------------ proposal validation ends here ---------------------------
  const proposalDataNew = useSelector(selectProposal);

//  -----------------------------------------------------------------------------------------------

const validateFullNamePro = (value) => {
  if (
    value == null ||
    value == 0 ||
    value == " " ||
    value == "" ||
    value == undefined
  ) {
    setFullNameErrorPro("Name can not be blank !");
    return false;
  } else if (!/^[a-zA-Z\s]+$/.test(value)) {
    setFullNameErrorPro("Full name must contain only letters");
    return false;
  } else {
    setFullNameErrorPro("");
    return true;
  }
};

const validateSaluitationPro = (value) => {
  // console.log("salutevalue and value", saluteValue, value);
  if (saluteValue == 0) {
    if (
      value == null ||
      value == " " ||
      value == "" ||
      value == undefined ||
      value == "0"
    ) {
      setSaluteErrorPro("Salutation can not be blank !");
      return false;
    } else {
      setSaluteErrorPro("");
      return true;
    }
  } else {
    return true;
  }
};

const validateMaritalPro = (value) => {
  if (value == null || value == 0 || value == " " || value == undefined) {
    setMaritalErrorPro("Marital status can not be blank !");
    return false;
  } else {
    setMaritalErrorPro("");
    return true;
  }
};

const validateDobPro = (value) => {
  if (
    value == null ||
    value == 0 ||
    value == " " ||
    value == "" ||
    value == undefined
  ) {
    setDobErrorPro("Date of birth cannot be blank");
    return false;
  }
  if (!value) {
    setDobErrorPro(
      "Please select a valid date of birth (age must be at least 18 and at most 99)"
    );
    return false;
  }
  let ca = calculateAge(value);
  if (ca < 18 || ca > 99) {
    setDobErrorPro(
      "Please select a valid date of birth (age must be at least 18 and at most 99)"
    );
    return false;
  } else {
    setDobErrorPro("");
    return true;
  }
};

const calculateAge = (dob) => {
  const today = new Date();
  const birthDate = new Date(dob);
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();
  if (
    monthDiff < 0 ||
    (monthDiff === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }
  return age;
};

const validateWeightPro = (value) => {
  if (
    value == null ||
    value == 0 ||
    value == " " ||
    value == "" ||
    value == undefined
  ) {
    setWeightErrorPro("Weight cannot be blank");
    return false;
  } else if (!/^\d{1,3}$/.test(value)) {
    setWeightErrorPro("Invalid weight (up to 3 digits)");
    return false;
  } else {
    setWeightErrorPro("");
    return true;
  }
};

const validateEducationPro = (value) => {
  // console.log("the value in education", value);
  if (
    value == null ||
    value == 0 ||
    value == " " ||
    value == "" ||
    value == undefined
  ) {
    setEduErrorPro("Education cannot be blank !");
    return false;
  } else if (!value) {
    setEduErrorPro("Please select a valid option !");
    return false;
  } else {
    setEduErrorPro("");
    return true;
  }
};

// const validateFeet = (e) => {
//   // Ensure event and event.target are defined
//   if (!e || !e.target) {
//     console.error("Event or event target is undefined or null.");

//   }
//   const value = e.target.value;
//   console.log("The value length", value.length);
//   const stringValue = String(value).trim();

//   if(stringValue === "0" || undefined || null || ""){
//     setHeightFeetError("Height cannot be blank");

//   }

//   // Check if the length of the input is at least 3 characters (e.g., "5.8")
//   if (stringValue.length < 3) {
//     setHeightFeetError("Enter a Valid height");

//   }

//   setHeightFeetError("");
//   return true;
// };

const validateHeightPro = (value) => {
  console.log("Upcoming value:", value);

  if (
    value == null ||
    value === undefined ||
    value === "0" ||
    value === "" ||
    value == "Na" ||
    value == "un"
    
  ) {
    setHeightInchesErrorPro("Height cannot be blank");
    return false;
  } else {
    setHeightInchesErrorPro("");
    return true;
  }
};


// const validateInch = (value) => {
//   if (value == null || value == undefined) {
//     setHeightInchesError("Inches  cannot be blank");
//     return false;
//   }

// else if (!/^\d{0,2}$/.test(value)) {
//   setHeightInchesError(
//     "Invalid value for height in inches (up to 2 digits)"
//   );
//   return false;
// }

//   else {
//     setHeightInchesError("");
//     return true;
//   }
// };
const validateOccupationPro = (value) => {
  if (
    value == null ||
    value == 0 ||
    value == " " ||
    value == "" ||
    value == undefined
  ) {
    setOccupationErrorPro("Occupation  cannot be blank");
    return false;
  } else if (!value) {
    setOccupationErrorPro("Please select a valid occupation !");
    return false;
  } else {
    setOccupationErrorPro("");
    return true;
  }
};

const validateAnnualIncomePro = (value) => {
  // console.log("the value in annual income", value);
  if (
    value == null ||
    value == 0 ||
    value == " " ||
    value == "" ||
    value == undefined
  ) {
    setAnnualIncomeErrorPro("Annual Income  cannot be blank");
    return false;
  } else if (!/^\d{1,10}$/.test(value)) {
    setAnnualIncomeErrorPro("ProInvalid annual income (positive integers only)");
    return false;
  } else {
    setAnnualIncomeErrorPro("");
    return true;
  }
};

const validatePanNum = (value) => {
  const isValid = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(value.toUpperCase());
  setPanNumError(
    isValid ? "" : "Invalid PAN number format (example: ABCDE1234F)"
  );
};


// ----------------------------------------------------------------------------------------------------


const [heightfeetPro, setHeightfeetPro] = useState(0);
const [heightinchesPro, setHeightinchesPro] = useState(0);



// errors
const [dobErrorPro, setDobErrorPro] = useState("");
const [weightErrorPro, setWeightErrorPro] = useState("");
const [heightFeetError, setHeightFeetErrorPro] = useState("");
const [heightInchesError, setHeightInchesErrorPro] = useState("");
const [annualIncomeError, setAnnualIncomeErrorPro] = useState("");
const [panNumError, setPanNumError] = useState("");
const [saluteError, setSaluteErrorPro] = useState("");
const [maritalErrorPro, setMaritalErrorPro] = useState("");
const [eduErrorPro, setEduErrorPro] = useState("");
const [fullNameErrorPro, setFullNameErrorPro] = useState("");
const [occupationErrorPro, setOccupationErrorPro] = useState("");

const [saluteValue, setSaluteValue] = useState(1);

  const pfData = proposalDataNew
    ? proposalDataNew.InsuredDetail.length != 0
      ? proposalDataNew.InsuredDetail[0].InsuredRelation == "Self"
        ? proposalDataNew.InsuredDetail[0]
        : proposalDataNew
      : ""
    : "";

    function cmToFeetInches(cm) {
      const totalInches = cm / 2.54; // Convert cm to inches
      const feet = Math.floor(totalInches / 12); // Get the whole feet part
      const inches = Math.floor((totalInches % 12) * 100) / 100; // Get the remaining inches, up to 2 decimal places
      
      return `${feet}.${inches}`;
  }
    useEffect(() => {
      if (proposalClick) {
        let nameChecked = validateFullNamePro(
          pfData.InsuredName || proposalDataNew.ProposalName
        );
        let DobChecked = validateDobPro(pfData.dateOfbirth );
        let WeightChecked = validateWeightPro(pfData.weight );
        let FeetChecked = validateHeightPro(pfData.height_feet || (pfData.height ? cmToFeetInches(pfData.height ): ""));
        // let InchChecked = validateHeight(proposalDataNew.height_inch);
        let SaluitationChecked = validateSaluitationPro(proposalDataNew.Salutation);
        let MaritalChecked = validateMaritalPro(pfData.MaritalStatus);
        let anuualcheck = validateAnnualIncomePro(proposalDataNew.AnnualIncome || proposalData.AnnualIncome);
        let eduCheck = validateEducationPro(pfData.Education);
        let occupationcheck = validateOccupationPro(pfData.Occupation );
        // let heightCheck = validateHeight(proposalDataNew.height)
  
        let boolvalue =
          occupationcheck &&
          nameChecked &&
          DobChecked &&
          WeightChecked &&
          // heightCheck &&
          FeetChecked &&
          // InchChecked &&
          SaluitationChecked &&
          MaritalChecked &&
          anuualcheck &&
          eduCheck;
  
        // console.log("the boolvalue in new propoer form proposal", boolvalue);
        setProposerCorrect(boolvalue);
    
      }
     
    }, [proposalClick,proposalData.InsuredDetail]);



  // function to check if any proposal have any error or not ----------------------

  useEffect(() => {
    if (proposalClick) {
      let tempProposalArr = []; // Temporary array to store boolvalues for each iteration

      console.log("the proposalData in useEffect proposalForm", proposalData);
      proposalData.InsuredDetail.forEach((data) => {
        if (data.InsuredRelation !== "Self") {
          let salutationCheck = validateSaluitation(data.Salutation);
          let fullnameCheck = validateFullName(data.InsuredName);
          let maritalCheck = validateMarital(data.MaritalStatus);
          let dobCheck = validateDob(data.dateOfbirth);
          let weightCheck = validateWeight(data.weight);
          let feetCheck = validateHeightNew(data.height);
          // let inchCheck = validateInch(data.height_inch);
          // let heightCheck = validateHeight(data.height)
          let boolvalue =
            salutationCheck &&
            fullnameCheck &&
            maritalCheck &&
            dobCheck &&
            weightCheck &&
            feetCheck;
          tempProposalArr.push(boolvalue);
        }
      });

      const proposalCorrect = tempProposalArr.every((boolValue) => boolValue);

      //console.log("the proposalCorrect in proposalform udseEffect",proposalCorrect );
      setProposalCorrect(proposalCorrect);
    }
  }, [proposalClick, proposalData.InsuredDetail]);

  // if (!apiResponse) {
  //   return (
  //     <div>
  //       <Loader />
  //     </div>
  //   );
  // }

  useEffect(() => {
    if (apiResponse ? apiResponse.response == null : "") {
      setStopLoading(true);

      handleError("Oops there is an Error");
    }
  }, [apiResponse]);

  return (
    <>
     <Helmet>
    <title>Proposal Form</title>
        {/* <meta name="description" content="A brief description of your page for SEO." /> */}
        <meta name="keywords" content="Insurance Plan, Insurance Quotes, buy insurance" />
      </Helmet>
      {loading ? (
        <Loader />
      ) : stopLoading == false ? (
        <>
          <Redirect />
          <div className="container">
            <h1 className="mainheading">
              We Sell Happyness* <span className="middelheading">We Buy</span>
              <span className="endheading">Your Fear</span>{" "}
            </h1>

            <div className="row">
              <div className="col-lg-8 proposaldataform">
                <div className="nextsection ">
                  <h1 className="Proposalheader">
                    Proposal form (step 1 of 3)
                  </h1>

                  <ProposerForm
                    selfData={userdata ? userdata : ""}
                    data={findSelf(insuredData)}
                    SelfKey={getSelfKey(insuredData)}
                    setProposerCorrect={setProposerCorrect}
                  />
                  {insuredData.map((data, index) => (
                    <>
                      {data.InsuredRelation != "Self" ? (
                        <Proposal
                          selfData={userdata}
                          data={data}
                          index={index}
                        />
                      ) : null}
                    </>
                  ))}
                </div>
                <div></div>
              </div>

              <div className="col-lg-4">
                {/* {//console.log("showmodal in proposal", showModal)} */}
                <Summary
                  apiResponse={apiResponse}
                  setApiResponse={setApiResponse}
                  ageDifference={ageDifference}
                  setAgeDifference={setAgeDifference}
                  showModal={showModal}
                  setShowModal={setShowModal}
                  ageSame={ageSame}
                  setAgeSame={setAgeSame}
                  nextPage="medicaldetails"
                  proposerCorrect={proposerCorrect}
                  proposalCorrect={proposalCorrect}
                  setProposerCorrect={setProposerCorrect}
                  setProposalCorrect={setProposalCorrect}
                  planInfo={apiResponse}
                  addonsArray={addonArr.length != 0 ? addonArr : ""}
                  setAddonArr={setAddonArr}
                  addondataList={addonDataList}
                  setAddonDataList={setAddonDataList}
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default Proposalfrom;