import React from "react";
import PartnerCompany from "../AllPageComponents/PartnerCompany/PartnerCompany";
import FAQ from "../AllPageComponents/FAQ/FAQ";
import "./TermInsurence.css";
import TermInsuranceForm from "./TermInsuranceForm";
import FastImage from "../FastImage";
import { auto } from '@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core'
import { LuAlarmClock } from "react-icons/lu";
import Redirect from "../AllPageComponents/Rederict";
import TermFormtwo from "./TermFormtwo";




const TermInsuranceInner = () => {

 

  //console.log(formValues);
  return (
    <>
    <Redirect/>
      <section className="car-insurance insurance-all">
        <div className="container">
          <div className="row insurance-row-one">
            <div className="col-sm-12 col-md-4 col-lg-4 mian-one">
              <div className="healthdiscription">
                <h1>Term Insurance Inner</h1>
                <br />
                <span style={{textAlign:"justify"}}>
                  "Term insurance" refers to a type of life insurance that
                  provides coverage for a specific period, or "term," typically
                  ranging from 5 to 30 years. It offers a death benefit to the
                  beneficiaries if the insured individual passes away during the
                  term of the policy. Term insurance is known for its simplicity
                  and affordability compared to other types of life insurance,
                  such as whole life or universal life insurance.{" "}
                </span>
              </div>
              <div className="insurance-btn-set">
                <button
                  type="button"
                  className="reminderbutton"
                  data-toggle="modal"
                  data-target="#reminderpopup"
                >
                  {" "}
                  <LuAlarmClock
                    fontSize={"15pt"}
                    style={{ margin: "0 5px", color: "#FBA71B" }}
                  />{" "}
                  Set Reminder
                </button>
              </div>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4 side-image">

         
            <FastImage
            url={"Website%20Img/BikeInsurence.png?updatedAt=1718779269495"}
            width={auto}
            height={auto}
            alt={"ca-img"}
            className='fmailimagegifs'
            />




    
            </div>
            {/* <TermInsuranceForm/> */}
            <TermFormtwo/>
         
          </div>
        </div>
      </section>


      <div className="container">
    <h5 style={{color:"#2959B8"}}>What is Term Insurece inner ?</h5>
    <div className="yellowline"></div>
    <p>
      Currently there are 24 insurance companies that provide term insurance in
      India. Term insurance is a type of life insurance policy which provides a
      sum assured known as death benefit in case of an unfortunate demise of the
      life assured during the policy tenure. Term insurance can help an
      individual ensure financial security of their family. Hence it is very
      important to choose an insurance company whom you fully rely on. While
      choosing a term insurance company you should always consider the claim
      settlement ratio of the company.
    </p>
    <p>
      The Indian insurance market is full of term insurance companies that offer
      term insurance plans with high coverage at affordable premium amounts, to
      the insured. Currently, there are 24 insurance companies that offer term
      insurance plans in India.
    </p>
  </div>
<br />
<br />


      <section className="full-space-block">
        <div className="container">
          <div className="row full-space-block-row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div className="insurance-less-farage">
                <div className="simple-text-block">
              
      
                  <h5 style={{color:"#2959B8",fontFamily:"Poppins"}}>Why should you buy term insurance?</h5>
    <div className="yellowline"></div>
                  <p>
                    Disease, disability and death are unfortunate and cannot be
                    ignored. Term insurance as a financial tool can help you
                    save your family’s financial future by providing you with
                    required coverage. Given below are individuals who should
                    purchase term insurance.
                  </p>
                  <p>
                    <b>Parents:</b>Parents are always burdened with
                    responsibilities of raising their children. As a parent, you
                    would want to secure your child’s future, provide him/her
                    the best education, accumulate money for their wedding, etc.
                    With a term plan, you can be rest assured that your child
                    will be taken care of financially even if something were to
                    happen to you.
                  </p>
                  <p>
                    <b>Young individuals:</b>While young professionals have less
                    responsibilities in terms of family, but few professionals
                    are sole breadwinners of the family and have liabilities
                    such as vehicle loan, personal loan, etc. to pay off. With
                    term insurance, the family can pay off the loans and
                    liabilities of the insured in case the insured dies within
                    the tenure of the plan. Additionally, the premium for term
                    insurance is low compared to any other type of life
                    insurance policy.
                  </p>
                  <p>
                    <b>Newly married couples:</b>With term insurance, married
                    couples can ensure complete financial support for their
                    spouse in case of an unfortunate event. It is one of the
                    best types of life policies for young couples who want to
                    secure their future.
                  </p>
                  <p>
                    <b>Working women:</b>Many young working women today are
                    supporting their families financially. With a term plan,
                    these women can secure the future of their parents, spouse
                    and children.
                  </p>
                  <p>
                    <b>Taxpayers:</b>Term insurance offers great tax benefits as
                    well. Premium paid is eligible for tax deduction under
                    section 80C of the Income Tax Act, 1961, whereas, the death
                    benefit paid to the nominee is also eligible for tax
                    deduction under section 10(10D)
                  </p>
                  <p>
                    <b>Retirees:</b>Having a term insurance plan in retirement
                    years provides financial security to your spouse as he/she
                    can continue with the same lifestyle without having to
                    compromise on their requirements.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <PartnerCompany />
      <FAQ />
    </>
  );
};

export default TermInsuranceInner;
