import React, { useState, useEffect } from "react";
import "../HealthBenifits/HealthBenifits.css";
import { Link, Element, Events, scrollSpy } from "react-scroll";
import FastImage from "../../../../FastImage";
import { auto } from "@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core";


const HealthBenifits = () => {
  const [activeSection, setActiveSection] = useState("");

  //console.log(activeSection);
  const Arraynav = [
    {
      id: 0,
      to: "introduction",
      name: "Introduction",
    },
    {
      id: 1,
      to: "copayment",
      name: "co-Payment",
    },
    {
      id: 2,
      to: "room-rent",
      name: "Room-Rent",
    },
    {
      id: 3,
      to: "Disease-wise-Limit",
      name: "Disease Wise Limit",
    },
    {
      id: 4,
      to: "Wating-Period",
      name: "Wating Period",
    },
    {
      id: 5,
      to: "Pre-&-Post-Hospitalization",
      name: "Pre & Post Hospitalization",
    },
    {
      id: 6,
      to: "restoretion",
      name: "Restoretion",
    },
    {
      id: 7,
      to: "day-Care-treatment",
      name: "Day Care treatment",
    },
    {
      id: 8,
      to: "domiciliary-expence",
      name: "Domiciliary Expence",
    },
    {
      id: 9,
      to: "no-calme-bonus",
      name: "No Claim Bonus",
    },
    {
      id: 10,
      to: "free-health-checkup",
      name: "Free Health Checkup",
    },
  
    {
      id: 11,
      to: "alternative-treatment",
      name: "Alternative Treatment",
    },
    {
      id: 12,
      to: "maternety",
      name: "Maternety",
    },
    {
      id: 13,
      to: "doctor-consultations",
      name: "Doctor Consultations",
    },
  ];

  useEffect(() => {
    Events.scrollEvent.register("begin", function () {
      //console.log("begin", arguments);
    });

    Events.scrollEvent.register("end", function () {
      //console.log("end", arguments);
    });

    scrollSpy.update();

    return () => {
      Events.scrollEvent.remove("begin");
      Events.scrollEvent.remove("end");
    };
  }, []);




  







  return (
    <>
      <div className="heathmaincompo">
      
        <div className="slidebarse">
          <div className="underslidebar">


            {Arraynav.map((data) => (
              <Link
                to={data.to}
                spy={true}
                smooth={true}
                duration={500}
                onSetActive={(section) => setActiveSection(section)}
              >
                <li
                  key={data.id}
                  className={
                    activeSection === data.to ? "Activeclass" : "deactiveclss"
                    
                  }
                >
                  {data.name}
                </li>
              </Link>
            ))}
          </div>
        </div>

        <div className="maincomtents">
          <div className="heading">
            <h3>Health Insurance Guide</h3>
          </div>

<Element name="introduction">
  <div className="sectionone">
    <div className="hero-blockcenter">
      <div>
        <div className="sectiononeimg">
          <FastImage
            url={"Website%20Img/Insurance-pana.png?updatedAt=1718777641945"}
            width={auto}
            height={auto}
            alt={"healthinsure"}
            src={"Website%20Img/Insurance-pana.png?updatedAt=1718777641945"}
          />
        </div>
        <div className="sectiononetext">
          <p className="textformat">
            Buying insurance is hard. Reading a policy document is even harder.
            And even if you ever try to to painstakingly go through this
            thing, you’ll most likely draw a blank because you won’t know what
             those words mean.Thefore , to uncomplicate matters and Aiming to teach
            you how to read a policy document, we’ve created a set of simple
            case studies to explain what some of the most common terms and
            features actually mean. We will also tell you how they impact you
            and why you may or may not need them.
          </p>
        </div>
      </div>
    </div>
  </div>
  </Element>;

<Element name="copayment">
            <div className="sectionone">

            <div className="hero-blockcenter">

           
              <div>
              <div className="sectiononeimg">
                {/* <iframe src="https://www.youtube.com/embed/tgbNymZ7vqY"></iframe> */}
              </div>
              <div className="sectiononetext">
                <h3>1.Say No To Co-Payment?</h3>
                <div className="yellowline"></div>
                <p className="textformat">
                  You’re about to buy health insurance. The agent pitches a
                  policy with a cover totaling 5 Lakhs for a bargain price of
                  just ₹7000 a year. But then he sweetens the pot even further.
                  He promises to cut down the premium by 25% (roughly
                  ₹1800/year) if only you agree to a 20% co-payment clause. You
                  think that’s amazing and you sign off on the agreement. Wow,
                  you just saved ₹1800. 9 months later, you suffer an accident.
                  You’re hospitalized and you need extensive care. Thankfully
                  you make a full recovery and the bill adds up to ₹2 Lakh. No
                  problem. You’ve got insurance.
                </p>
              </div>
              </div>


              </div>



            </div>
  </Element>

<Element name="room-rent">
            <div className="sectionone">

            <div className="hero-blockcenter">

         
               <div>
              <div className="sectiononeimg">
                <FastImage
                  url={"Website%20Img/Hospital%20patient-bro.png?updatedAt=1718777642502"}
                  width={auto}
                  height={auto}
                  alt={"roomrent"}
                  src={"Website%20Img/Hospital%20patient-bro.png?updatedAt=1718777642502"}
                />
              </div>
              <div className="sectiononetext">
                <h3>2.Check For Restrictions On Room & Room Rent</h3>
                <div className="yellowline"></div>
                <p className='textformat'>
                  Imagine falling sick and finding out your insurer has a limit
                  on room rent. You pick up the policy document begrudgingly and
                  notice that that cap is set at 1% of your sum insured. 1% of
                  ₹5 lakh insured is ₹5,000 each day. That’s not a lot. And now
                  you are upset you can’t pick the room you wanted — one that
                  costs ₹10,000 a day. But you’re fairly certain you won’t be
                  staying here for long. It’s only going to be for a couple of
                  days. And even if you pick the nicer room, you’ll only have to
                  pay the extra ₹10,000 (2 days*5000). Hence you choose one of  the costly
                  room. However, 2 days later, you’re discharged and the
                  insurance company drops a bombshell.
                </p>
              </div>
              </div>

              </div>


            </div>
  </Element>

          <Element name="Disease-wise-Limit">
            <div className="sectionone">
            <div className="hero-blockcenter">
              <div>
              <div className="sectiononeimg">
                <FastImage
                  url={"Website%20Img/Medicine-bro.png?updatedAt=1718777642854"}
                  width={auto}
                  height={auto}
                  alt={"medicinebro"}
                  src={"Website%20Img/Medicine-bro.png?updatedAt=1718777642854"}
                />
              </div>
              <div className="sectiononetext">
                <h3>3.Check for Disease Wise Sub-limits</h3>
                <div className="yellowline"></div>
                <p className='textformat'>
                  10 lakh cover at a premium of just ₹6,000 a year. That’s the
                  offer in front of you. But instead of jumping for joy, you’re
                  suspecting something already. This guy is offering you a price
                  that nobody else is matching. You are skeptical and you ponder
                  for a while. But you go ahead with his recommendation anyway,
                  since he is a family friend. ,And then, one day, your
                  worst fears come true. A slipped disk forces you into the
                  operation theatre. You require extensive treatment. The final
                  bill is hefty. ₹4,36,000 — inclusive of all costs. 
                </p>
              </div>
              </div>
              </div>
            </div>
          </Element>

          <Element name="Wating-Period">
            <div className="sectionone">


            <div className="hero-blockcenter">


              <div>
              <div className="sectiononeimg">
                <FastImage
                  url={"Website%20Img/Waiting-pana.png?updatedAt=1718777645742"}
                  width={auto}
                  height={auto}
                  alt={"roomrent"}
                  src={"Website%20Img/Waiting-pana.png?updatedAt=1718777645742"}
                />
              </div>


              <div className="sectiononetext">
                <h3>4.Seek Low Waiting Periods</h3>
                <div className="yellowline"></div>
                <p className='textformat'>
                  The scariest thing about insurance is the uncertainty involved
                  in buying a policy,  because the moment over promising endup, 
                  the insurer will display his/her urge to talk to you about your
                  medical history. Do you have diabetes ? Do you have any
                  cardiovascular problems? Do you have thyroid-related
                  illnesses? Or do you have any other pre-existing conditions?
                  The list is endless. Once they are through with a volley of 
                  questioning, they might ask you to take a few medical tests.
                  And based on of these reports, they will tell you that they
                  will cover your hospitalization expense so long as it's not
                  related to your pre-existing condition. But what happens, if
                  you are hospitalized for a complication arising out of a past
                  condition ? Well, in that case, they will ask you to wait for a
                  while - maybe 2 to 4 years. 
                </p>
              </div>
              </div>
              </div>
            </div>
          </Element>

          <Element name="Pre-&-Post-Hospitalization">
            <div className="sectionone">

            <div className="hero-blockcenter">
              <div>
              <div className="sectiononeimg">
     
                <FastImage
                  url={"Website%20Img/Hospital%20family%20visit-pana.png?updatedAt=1718777642660"}
                  width={auto}
                  height={auto}
                  alt={"hospital"}
                  src={"Website%20Img/Hospital%20family%20visit-pana.png?updatedAt=1718777642660"}
                />
              </div>
              <div className="sectiononetext">
                <h3> 5.Opt For Pre And Post-Hospitalization Care</h3>
                <div className="yellowline"></div>
                <p className='textformat'>
                  You wake up one day and you start feeling dizzy. You consult
                  your doctor. She prescribes a blood test. Nothing out of the
                  ordinary, she says. So you go home with an ORS pack. But your
                  condition deteriorates. You feel dizzy once again next
                  evening. And this time the doctor prescribes an MRI. She
                  checks the results and asks you to admit yourself to the
                  hospital. The doctor effectively treats your condition over
                  the course of the next 3 days and you are discharged soon
                  after. The Hospital Bill adds up to ₹10,000 The MRI and the
                  diagnostic tests before hospitalization adds up to ₹15,000 You
                  are hoping your insurance covers both bills. But the company
                  states they don’t cover pre-hospitalization expenses. Meaning
                  they won’t cover all the costs you had to bear leading up to
                  your hospitalization.
                </p>
              </div>
              </div>
              </div>


            </div>
          </Element>

          <Element name="restoretion">
            <div className="sectionone">



            <div className="hero-blockcenter">

          
             <div>
              <div className="sectiononeimg">
                <FastImage
                  url={"Website%20Img/Hospital%20wheelchair-pana.png?updatedAt=1718777642950"}
                  width={auto}
                  height={auto}
                  alt={"hospital"}
                  src={"Website%20Img/Hospital%20wheelchair-pana.png?updatedAt=1718777642950"}
                />
              </div>


              <div className="sectiononetext">
                <h3>6. Ask For Restoration Benefit</h3>
                <div className="yellowline"></div>
                <p className='textformat'>
                  It’s a Sunday morning and you’re sipping coffee on the
                  hospital bed. You are thinking about how a small heart
                  complication forced you into a 3-week stay at the hospital.
                  More importantly, you’re thinking about how you ended up a
                  bill totaling ₹4,88,000. Thankfully, your insurance policy is
                  going to take care of the burden. After all, you have a ₹5
                  Lakh cover. So you don’t have to pay anything right now. But
                  you start thinking about other possibilities. See, you bought
                  a combined policy — For both your wife and yourself. And if
                  she were to fall sick anytime soon, then the insurance won’t
                  cover her bills. You’ve used up the benefits already. But what
                  if the insurance restored itself to the initial state
                  immediately after you make a claim?
                </p>
              </div>
              </div>
              </div>

            </div>
          </Element>

          <Element name="day-Care-treatment">
            <div className="sectionone">


            <div className="hero-blockcenter">

           
              <div>
              <div className="sectiononeimg">
             
                <FastImage
                  url={"Website%20Img/CT%20scan-amico.png?updatedAt=1718777642787"}
                  width={auto}
                  height={auto}
                  alt={"ct"}
                  src={"Website%20Img/CT%20scan-amico.png?updatedAt=1718777642787"}
                />
              </div>

              <div className="sectiononetext">
                <h3>7.Seek Coverage For Treatments That Last 24 hrs</h3>
                <div className="yellowline"></div>
                <p className='textformat'>
                  You are out playing a game of cards with your friends.
                  Suddenly you feel a sharp twitch in your abdomen. It's odd,
                  but these things keep happening to you all the time. So you
                  don't pay much attention to it. A few moments passed, and then
                  suddenly the twitch is there again. This time it doesn't go
                  away. Instead, within a moment the pain intensifies. Soon, it
                  becomes unbearable. Your friends take you to the hospital. And
                  after a quick inspection, the doctor breaks the news. It's
                  appendicitis and they have to operate on you immediately. But
                  it's not that big of a deal. In fact, the doctor assures you
                  that he'll discharge you the same day. And although you
                  require some treatment, you walk out of the hospital within 24
                  hours. However, it's an expensive procedure. The bill adds up
                  to ₹80,000 and you're startled and shocked at the same time.
                </p>
              </div>
              </div>
              </div>
            </div>
          </Element>

          <Element name="domiciliary-expence">
          <div className="sectionone">



          <div className="hero-blockcenter">

       
            <div>
            <div className="sectiononeimg">
              <FastImage
                  url={"Website%20Img/Money%20stress-bro.png?updatedAt=1718777645460"}
                  width={auto}
                  height={auto}
                  alt={"moneystress"}
                  src={"Website%20Img/Money%20stress-bro.png?updatedAt=1718777645460"}
                />
            </div>



            <div className="sectiononetext">
              <h3>8.Domiciliary Expense
              </h3>
              <div className="yellowline"></div>
              <p className='textformat'>
              A deadly pandemic starts wreaking havoc. However, your job forces you to step out every day. And then suddenly, one day you wake up with a bad cold. You are coughing incessantly. You hope it’ll go away on its own. But then you have trouble breathing. Your condition deteriorates and you are forced to call the emergency services. But they tell you that the can’t find a hospital bed. Your only choice is to obtain treatment at home. Some hospitals provide this facility. But it's going to cost you money. A lot of money!!!

              And then a ray of hope. Your insurer tells you they will cover your expenses since you are forced to be treated at home.
              
              It could be because of a specific condition or because the hospital couldn’t find you a bed — Like in this case, It’s rare to be in such a spot. But you are glad you have this facility nonetheless
              
              
              </p>
            </div>
            </div>
            </div>

          </div>
        </Element>


 <Element name="no-calme-bonus">
          <div className="sectionone">

          <div className="hero-blockcenter">
            <div>
            <div className="sectiononeimg">
         
              <FastImage
                  url={"Website%20Img/Money%20income-pana.png?updatedAt=1718777642856"}
                  width={auto}
                  height={auto}
                  alt={"noclaimnbonus"}
                  src={"Website%20Img/Money%20income-pana.png?updatedAt=1718777642856"}
                />
            </div>
            <div className="sectiononetext">
              <h3>9. Bonus In Case You Don’t Make A Claim
              <div className="yellowline"></div>
              </h3>
              <p className='textformat'>
              Insurers will tell you they want you to stay fit and healthy. In fact, they will even incentivize you to achieve this objective. For instance, how would you feel if somebody told you they’ll scale up your cover (above and beyond the sum insured) by 50% each year in the event you don’t claim insurance. That would be amazing, right?

              Think about it. You could start with a cover of ₹5 lakhs. And then see it move up to ₹7.5 lakhs and then the next year, another 50% over and the above the sum insured would take your total cover to 10 lakhs. Obviously, they’ll stop at some point. Most insurance companies will tell you they’ll stop once the cover doubles in value. In fact, they’ll also tell you they’ll cut the sum insured by the same amount (50%) in the event you claim insurance after receiving the bonus. 
            
              </p>
            </div>
            </div>
            </div>


          </div>
        </Element>

        <Element name="free-health-checkup">
        <div className="sectionone">


        <div className="hero-blockcenter">
         <div>     
          <div className="sectiononeimg">
         
            <FastImage
                  url={"Website%20Img/Hospital%20bed-bro.png?updatedAt=1718777642450"}
                  width={auto}
                  height={auto}
                  alt={"freehealthcheckup"}
                  src={"Website%20Img/Hospital%20bed-bro.png?updatedAt=1718777642450"}
                />
          </div>
          <div className="sectiononetext">
            <h3>10.Free Health Checkups Every Year

            </h3>
            <div className="yellowline"></div>
            <p className='textformat'>
            It's a lazy Saturday afternoon. You've been waiting for almost an hour now and you can't help but look at the receptionist intently hoping he will call your name. And then suddenly when you least expect it, you hear your name called out loud. You walk into the doctor's room. She directs you to a certain spot and measures your height. She then checks your weight. She makes you sit straight and then takes your blood pressure. She listens to your heart and lungs and Advices a several blood test's for cholesterol and blood sugar. And just before you leave, she offers some advice on how you can lower risks for diabetes, heart disease, and cancer. You get your reports the next day and everything is perfect. You don't need follow-up care, at least, not until next year.

            What you just had was a full body health checkup. 
            
            
            </p>
          </div>
          </div>
          </div>

        </div>
      </Element>


      <Element name="alternative-treatment">
      <div className="sectionone">


      <div className="hero-blockcenter">
        <div>
        <div className="sectiononeimg">
 
          <FastImage
                  url={"Website%20Img/alternative.png?updatedAt=1718777642861"}
                  width={auto}
                  height={auto}
                  alt={"alternative"}
                  src={"Website%20Img/alternative.png?updatedAt=1718777642861"}
                />
        </div>
        <div className="sectiononetext">
          <h3 >11. Cover For Alternative Treatments


          </h3>
          <div className="yellowline"></div>
          <p className='textformat'>
          You’re pushing 50 and you feel drained each day. You go to your physician and he tells you there’s nothing wrong. “It could be stress”, he exclaims. You are unsatisfied but you go home nonetheless. The next day , however you bump into your neighbour You start chatting and he suggests visiting a government certified Ayush Centre.

          So you visit the facility and meet with the practitioners. They suggest getting an Ayurvedic Rejuvenation Therapy — Panchakarma. It’s going to last 7–11 days and it’s going to cost you ₹25,000. That’s quite expensive you think. And then suddenly it hits, your health insurance policy covers Ayush treatments. So you sign off on the payment and get started immediately. Good thing, you remembered that little detail ?
          
          Ayush Coverage is a good-to-have feature. 
          </p>
        </div>
        </div>
        </div>


      </div>
    </Element>

    <Element name="maternety">
    <div className="sectionone">


    <div className="hero-blockcenter">
      <div>
      <div className="sectiononeimg">

        <FastImage
                  url={"Website%20Img/maturnity.png?updatedAt=1718777642535"}
                  width={auto}
                  height={auto}
                  alt={"maternity"}
                  src={"Website%20Img/maturnity.png?updatedAt=1718777642535"}
                />
      </div>
      <div className="sectiononetext">
        <h3>12. Maternity Benefits



        </h3>
        <div className="yellowline"></div>
        <p className='textformat'>
        You are thinking of having kids. But you know you'll have to plan for it in advance. The first order of business is to make arrangements for childbirth - when you will have to visit the hospital and get the baby delivered. You know getting there won't be a problem, but the hospitalization could turn out to be an expensive affair. But what if you could offset this by opting for an insurance policy that covers this cost? It looks like a solid idea. But alas, this plan has some issues.

For starters, the insurer knows for certain that they'll have to bear this expense in the event you decide to have kids. And since most people have at least one kid, the insurer will most likely have to pay up, many companies simply don't offer the benefit. The ones that do will make sure they are adequately compensated.

So they'll push the premiums way higher.


        </p>
      </div>
      </div>
      </div>


    </div>
  </Element>



  <Element name="doctor-consultations">
  <div className="sectionone">


  <div className="hero-blockcenter">


   <div>
    <div className="sectiononeimg">
     
      <FastImage
                  url={"Website%20Img/Online%20Doctor-bro.png?updatedAt=1718777645646"}
                  width={auto}
                  height={auto}
                  alt={"doctorbro"}
                  src={"Website%20Img/Online%20Doctor-bro.png?updatedAt=1718777645646"}
                />
    </div>
    <div className="sectiononetext">
      <h3>13.  When Should You Opt For OPD?




      </h3>
      <div className="yellowline"></div>
      <p className='textformat'>
      Everybody visits the doctor at least once a year. But the doctor you visit,  
      Her consultation fee is off the roof. A routine inspection could set you back thousands. So you come up with a genius plan. You decide to buy insurance and opt for a policy that reimburses all expenses incurred during these visits. You look for options that include outpatient consultation benefits and you find one after a moment of search.
      You exclaim — “Genius.” And you give yourself a pat on the back.
      But alas, nothing in this godforsaken, world is as it seems. 
      For starters, the insurer knows for certain that they’ll have to pay you something each year. Just ask yourself, Have you been to the doctor this year ? Have you paid over ₹1,000 in consultation fee ? Have you paid more ? , And do you think this trend is likely to change ?
      

      </p>
    </div>
    </div>
    </div>

  </div>
</Element>

        </div>
      </div>
    </>
  );
};

export default HealthBenifits;
