import React, { useEffect, useState } from 'react'
import { MdOutlineAutoGraph } from "react-icons/md";
import { AiOutlineFileProtect } from "react-icons/ai";
import { LuAlarmClock } from "react-icons/lu";
import { MdHealthAndSafety } from "react-icons/md";
import { IoIosLogOut } from "react-icons/io";
import { FaPaperPlane } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import { FaChevronDown } from "react-icons/fa6";
import './Clamemanagement.css'
import axios from 'axios';
import FileBase64 from 'react-file-base64';
import Redirect from '../../AllPageComponents/Rederict';
import { RxHamburgerMenu } from "react-icons/rx";
import { RxCross2 } from "react-icons/rx";
import FastImage from "../../FastImage";
import { auto } from '@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core'
import { MdMenuOpen } from "react-icons/md";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import { Helmet } from 'react-helmet-async';












const Clamemanagement = () => {
  const Navigate = useNavigate()
  const [isactiveDashbord, setisactiveDashbord] = useState('Dashbord')
  const HandelDashbordActive = (data) =>{
  setisactiveDashbord(data)}



// Post the api start hear


let base64WithoutPrefix = ''; // Define base64WithoutPrefix outside of functions

const onFileSubmit = (file) => {
  // Remove the prefix 'data:application/pdf;base64,'
  base64WithoutPrefix = file.base64.replace(/^data:application\/pdf;base64,/, '');
};

const handleSubmit = async (event) => {
  event.preventDefault();
  
  const formData = new FormData(event.target);
  const formValues = {};
  for (let [key, value] of formData.entries()) {
    formValues[key] = value;
  }
  
  formValues['photo_video'] = base64WithoutPrefix;
  
  try {
    const userToken = localStorage.getItem('userToken');
    if (!userToken) {
      throw new Error('User token not found');
    }
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}user/dashboard/add_claim`, formValues, {
      params: { token: userToken },
    });
    if(response.data.status === false){

      localStorage.clear();
      window.location.href = '/';
    }
    
    //console.log(response.data); 
  } catch (err) {
    console.warn(err);
  }
};















//  get Api 
const ClameData = async () => {
  try {
    const userToken = localStorage.getItem('userToken');
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}user/dashboard/claim_list`,{
      params: { token: userToken }
    });
    setGetAllmanagemanet(response.data.data);
  } catch (err) {
    console.error(err); // Changed //console.log to console.error for better error handling
  }
};

const [GetAllmanagemanet, setGetAllmanagemanet] = useState([]);

useEffect(() => {
  ClameData();
}, []); // Added missing dependencies array to useEffect

//console.log("Claim Data", GetAllmanagemanet);


const LogoutData = () =>{
  localStorage.clear();
  window.location.href = '/';
}

const [ShowSideBar, setShowSideBar] = useState(true)

const ShowMenuBar = () =>{
  setShowSideBar(!ShowSideBar)
}





const [open, setOpen] = React.useState(false);

const toggleDrawer = (open) => (event) => {
  if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
    return;
  }

  setOpen(open);
};

const list = () => (
  <Box
  sx={{ width: 250 }}
  role="presentation"
  onClick={toggleDrawer(false)}
  onKeyDown={toggleDrawer(false)}
>
<div className='crossdiv'>
<button><RxCross2/></button>
</div>
  <ul className="underullist">

    <li className="activeDashbord">
      <Link to="/dashbord">
        <MdOutlineAutoGraph /> &nbsp; Dashbord
      </Link>
    </li>
    <li className="deactiveDashbord">
      <Link to="/dashbord-policy">
        <AiOutlineFileProtect /> &nbsp; Policy
      </Link>
    </li>
    <li className="deactiveDashbord">
      <Link to="/setup-reminder">
        <LuAlarmClock /> &nbsp; Setup Reminders
      </Link>
    </li>
    <li className="deactiveDashbord">
      <Link to="/dashbord-Claim">
        <MdHealthAndSafety /> &nbsp; Clame Mangement
      </Link>
    </li>
    <li className="deactiveDashbord" onClick={LogoutData}>
      <IoIosLogOut /> &nbsp; Logout
    </li>
  </ul>
</Box>
);

  return (
    <div>
        <Helmet>
        <title>Claim management</title>
        {/* <meta name="description" content="A brief description of your page for SEO." /> */}
        {/* <meta name="keywords" content="best term insurance in india , buy term life insurance, compare and purchase term insurance" /> */}
      </Helmet>
      <div className="dashbordmargin"></div>
    <div className="maincontainer">
    {/* Sidebar Container Start Hear */}
    <div className={ShowSideBar ? "sidebarse" : ''}>
    <div className="subsidebar">
    <ul>
    <Link to={'/dashbord'}> <li className='deactiveDashbord' > <MdOutlineAutoGraph/> Dashbord</li></Link>
    <Link to={'/dashbord-policy'}> <li className="deactiveDashbord">  <AiOutlineFileProtect/> Policy</li></Link>
    <Link to={'/setup-reminder'}> <li className="deactiveDashbord"><LuAlarmClock/> Setup Reminders</li></Link>
    <Link to={'/dashbord-Claim'}> <li className="activeDashbord"> <MdHealthAndSafety/> Clame Mangement</li></Link>
    <li className="deactiveDashbord" onClick={LogoutData}><a><IoIosLogOut/>  Logout</a></li>
    </ul>
    </div>
    </div>
   {/* Sidebar Container End Hear */}



    {/* Dashbord Container Start Hear */}
    <div className="dashbordcontainer">
       <div className="clamemanagementform">
       <div className="addclamemanagement">
  
    

    
    <Button onClick={toggleDrawer(true)} className='navigationbar'><MdMenuOpen style={{fontSize:"15pt"}}/></Button>
      <Drawer
        anchor="left"
        open={open}
        onClose={toggleDrawer(false)}
      >
        {list()}
      </Drawer>
    

       
      
       <button  type="button"  data-toggle="modal" data-target="#exampleModal"><FaPaperPlane style={{marginRight: 5}}/>Submit New Clame</button>
       </div>
       
       <div className="table-container">
       <table className="policy-table">
       <thead>
       <tr>
         <th>Clame Type</th>
         <th> Insurence Type</th>
         <th>Media Photo</th>
         <th>Current Status</th>
         <th>Insurence Company</th>
         <th>Insurence Date</th>
      
       </tr>
     </thead>
     {GetAllmanagemanet.length !== 0 ? (
  GetAllmanagemanet.map((valu, index) => (
    <tr key={index}>
      <td>{valu.claim_type}</td>
      <td>{valu.insurance_type}</td>
      <td>{valu.photos}</td>
      <td>{valu.status}</td>
      <td>{valu.ins_company_name}</td>
      <td>{valu.ins_date}</td>
    </tr>
  ))
) : (
  <tr>
    <td colSpan="6">Sorry, no data found</td>
  </tr>
)}

   
   
       
       
       </table>
       </div>


    </div>
    </div>

    {/* Dashbord Container End Hear */}

    </div>
<div>

{/* Bootstrap modal */}
<div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
<div className="modal-dialog centerdilog">
  <div className="modal-content extrawidth">
  <FastImage
            url={"Website%20Img/pop-up_a.webp?updatedAt=1718775653542"}
            width={auto}
            height={auto}
            alt={"ca-img"}
            className='motalbody'
            />
    <div className="modal-header">
     
      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
   
    <h3 className="modal-title text-center" id="exampleModalLabel" style={{color:"#0166B3"}}>Submit <span style={{color:"#FBA71C"}}>new</span> <span style={{color:"#EE2124"}}>claim</span> </h3>
    <div className="modal-body">




    <div className="clameformunder">
 
      {/* Form inside modal */}
      <form className='clameform' onSubmit={handleSubmit}>
        <div className="row">

          <div className="col-md-6">
            {/* First Name */}
            <div className="form-group">
              <label htmlFor="first_name">First Name</label>
              <input type="text" className="form-control" id="first_name" name="first_name" placeholder="Enter your first name" />
            </div>
          </div>
          <div className="col-md-6">
            {/* Last Name */}
            <div className="form-group">
              <label htmlFor="last_name">Last Name</label>
              <input type="text" className="form-control" id="last_name" name="last_name" placeholder="Enter your last name" />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            {/* Mobile Number */}
            <div className="form-group">
              <label htmlFor="mobile_number">Mobile Number</label>
              <input type="tel" className="form-control" id="mobile_number" name="mobile_number" placeholder="Enter your mobile number" />
            </div>
          </div>
          <div className="col-md-6">
            {/* Email */}
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input type="email" className="form-control" id="email" name="email" placeholder="Enter your email" />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            {/* Address */}
            <div className="form-group">
              <label htmlFor="address">Address</label>
              <input className="form-control" id="address" name="address" placeholder="Enter your address here..." rows="2"/>
            </div>
          </div>
          <div className="col-md-6">
            {/* Insurance Company Name */}
            <div className="form-group">
              <label htmlFor="ins_company_name">Insurance Company Name</label>
              <input type="text" className="form-control" id="ins_company_name" name="ins_company_name" placeholder="Enter your company name" />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            {/* Claim Type */}
            <div className="form-group">
              <label htmlFor="claim_type">Claim Type</label>
              <select className="form-control" id="claim_type" name="claim_type">
                <option value="" disabled>Select claim type</option>
                <option value="Claim Type1">Claim Type1</option>
                <option value="Claim Type2">Claim Type2</option>
                <option value="Claim Type3">Claim Type3</option>
                <option value="Claim Type4">Claim Type4</option>
              </select>
            </div>
          </div>
          <div className="col-md-6">
            {/* Insurance Type */}
            <div className="form-group">
              <label htmlFor="insurance_type">Insurance Type</label>
              <select className="form-control" id="insurance_type" name="insurance_type"><span>is<FaChevronDown/></span>
                <option value="" disabled>Select insurance</option>
                <option>General Insurance</option>
                <option>Car Insurance</option>
                <option>Bike Insurance</option>
                <option>Health Insurance</option>
                <option>Term Insurance</option>
                <option>Travel Insurance</option>
                <option>Life Insurance</option>
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            {/* Insurance Date */}
            <div className="form-group">
              <label htmlFor="ins_date">Insurance Date</label>
              <input type="date" className="form-control" id="ins_date" name="ins_date" />
            </div>
          </div>
          <div className="col-md-12">
            {/* Upload Video or image */}



            <div className="upload-cv-file">
                      <label className="">
                        <div className="upload-icon">
                        <FileBase64 multiple={false} onDone={onFileSubmit}type="file"
                            name="file"
                            className="FileChange"
                            id="file"/>

                       
  <FastImage
            url={"Website%20Img/upppp.png?updatedAt=1718799402584"}
            width={auto}
            height={auto}
            alt={"ca-img"}
            />

                          
                        </div>
                        <h4>
                          Upload CV<span className="text-danger">*</span>
                        </h4>
                        <span id="selectedFileName" />
                        <p>Drag and drop or browse to choose a file</p>
                        <span>File size: 10MB | File format: pdf, doc</span>
                      </label>
                    </div>


          </div>
        </div>

        {/* Submit button */}

        <div className="submitdiv">
        <button type="submit" className="btn btn-primary">Submit</button>
        </div>
      </form>
      </div>





    </div>
  </div>
</div>
</div>
</div>





    </div>
  )
}

export default Clamemanagement