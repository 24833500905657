import React, { useState } from "react";
import { TextField, Box, Typography, Grid } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "./AnswerSetFour.css"; // Importing the CSS file;
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";

import { updateRelianceQAlist , selectReliancecheckedQuestion } from "../../redux/Slice";

const AnswerSetFour = ({ pindex, qdata, parentqData }) => {
  const [sufferingSince, setSufferingSince] = useState();

  const [typeTest , setTypeTest] = useState();
  const [findTest , setFindTest] = useState();
  const [dateTest , setDateTest] = useState();

  const dispatch = useDispatch();

  const handleClick = (event) => {
    event.stopPropagation();
  };

  const convertMMDate = (dateString) =>{
    const date = new Date(dateString);
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getUTCFullYear();
  
    return `${month}/${year}`;
  }
  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
  
    // Extract day, month, and year
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
  
    return `${day}/${month}/${year}`;
  };




  const [dataObj, setDataObj] = useState();

  const ReliancecheckedQuestion = useSelector(selectReliancecheckedQuestion);

  console.log("the answer set one data",ReliancecheckedQuestion)

  console.log("the pindex", pindex);

  console.log("the q data in set one", qdata)




  useEffect(() => {
    if (ReliancecheckedQuestion) {

      console.log("hdfc checked question",ReliancecheckedQuestion )
      const keys = Object.keys(ReliancecheckedQuestion);
      const numbersToCheck = [4];
      const anyNumberPresent = numbersToCheck.some((number) =>
        keys.includes(number.toString())
      );

      const resultObjects = numbersToCheck
        .filter((number) => keys.includes(number.toString()))
        .map((number) => ReliancecheckedQuestion[number.toString()]);


      console.log("the resultObjects", resultObjects) 
      let val;
      
 
      for (let key in resultObjects) {
        if (resultObjects[key].hasOwnProperty(pindex))
        {
          let data_one = resultObjects[key][pindex];
          // console.log("data_one",qdata.question_id)
          let val = data_one.find(item => item.Options[0].OptionId == qdata.QuestionSetCode );
          if (val != undefined)
          {
             console.log("val in for",val)
             let newobject = val;
             console.log("the newObject", newobject);
     
             if (newobject) {
     
               newobject.Options.map((item , index)=>{
                 if(item.OptionId == qdata.QuestionSetCode){
                  
                   
                     setDataObj(item);
                     return
                   }
               })
             }
          }
        }
     }

   
      
    }
  }, [ReliancecheckedQuestion]);

  console.log("the final data in set four", dataObj);

  const normalizeDate = (dateString) => {

    if (dateString) {
      const [year, month, day] = dateString.split("/");
      const paddedMonth = month.padStart(2, "0");
      const paddedDay = day.padStart(2, "0");
      return `${year}-${paddedMonth}-${paddedDay}`;
    
    }
  };
  const parseDate = (dateStr) => {
    const dateParts = dateStr.split('/');
    // Check if the parts are valid
    if (dateParts.length === 3) {
      const day = parseInt(dateParts[0], 10);
      const month = parseInt(dateParts[1], 10) - 1; // month is 0-indexed
      const year = parseInt(dateParts[2], 10);
      return new Date(year, month, day);
    }
    return null; // Handle invalid date format
  };
 // useEffect to set the initial date if dataObj is present
//  useEffect(() => {
//   if (dataObj && dataObj.DateOfTests) {
//     const initialDate = parseDate(dataObj.DateOfTests);
//     if (initialDate) {
//       setDateTest(initialDate);

//     }
  
//   }
// }, []);







  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box className="set-four-body">
        {/* <Typography className='set-four-body-title'>
          Q. Hypertension/ High blood pressure
        </Typography> */}

        <Grid container spacing={2} className="lower-set-four-answers">
          <Grid item xs={6}>
            <TextField
              label="Type of Tests"
              type="text"
              fullWidth
              variant="standard"
              className="underline-textField"
              value={typeTest ? typeTest : dataObj?.TypeOfTests || ""}
              onClick={(e) => {
                handleClick(e);
              }}
              onChange={(e) =>
                {
                  setTypeTest(e.target.value);
                  dispatch(
                    updateRelianceQAlist({
                      name: "TypeOfTests",
                      value: e.target.value,
                      pindex: pindex,
                      qdata: qdata,
                      parentqData: parentqData,
                    })
                  )
                }              }
            />
          </Grid>

          <Grid item xs={6} className="set-four-date-upper"
            onClick={(event) => {
                handleClick(event);
              }}
          >
            {/* <DesktopDatePicker
              label="Date of Tests"
              inputFormat="MM/yyyy"
              value={dateTest}
              onClick={(e) => {
                handleClick(e);
              }}
              onChange={(newValue) => {
                setDateTest(newValue);
                dispatch(
                  updateRelianceQAlist({
                    name: "DateOfTests",
                    value: formatDate(newValue),
                    pindex: pindex,
                    qdata: qdata,
                    parentqData: parentqData,
                  })
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  className="bordered-textField"
                />
              )}
            /> */}

<Flatpickr
          
          style={{marginTop:"6px"}}
          className="flat-date"
          value={dateTest ? dateTest : dataObj?.DateOfTests}
          options={{
            enableTime: false, // Disable the time picker
            dateFormat: "d/m/Y", // Set the desired date format
          }}
          placeholder="Date of Tests"
          onChange={(newValue) => {
            setDateTest(newValue);

            dispatch(
              updateRelianceQAlist({
                name: "DateOfTests",
                value: convertMMDate(newValue[0]),
                pindex: pindex,
                qdata: qdata,
                parentqData: parentqData,
              })
            );
          }}
        />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Findings of Tests"
              type="text"
              fullWidth
              variant="standard"
              className="underline-textField"
              value={findTest ? findTest : dataObj?.FindingsOfTests || ""}
              onClick={(e) => {
                handleClick(e);
              }}
              onChange={(e) =>
               {
                setFindTest(e.target.value);
                dispatch(
                  updateRelianceQAlist({
                    name: "FindingsOfTests",
                    value: e.target.value,
                    pindex: pindex,
                    qdata: qdata,
                    parentqData: parentqData,
                  })
                )
               }
              }
            />
          </Grid>
        </Grid>
      </Box>
    </LocalizationProvider>
  );
};

export default AnswerSetFour;
