import { configureStore } from "@reduxjs/toolkit";
import { PlanSlice, CreateProposalSlice } from "./Slice";
import { termsSlice } from "./TermRedux/Termslice";

export const Store = configureStore({
  reducer: {
    FetchPlan: PlanSlice.reducer,
    ProposalCreate: CreateProposalSlice.reducer,
    terms: termsSlice.reducer
  },
});