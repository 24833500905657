import React, { useState,useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';

const AfterPayment = () => {
	useEffect(()=>{
    window.scrollTo(0, 0)
    
   },[])
	const thankYouPageStyle = {
    textAlign: 'center',
    padding: '50px',
    backgroundColor: '#007bff', // Blue color code
    color: '#ffffff', // White text color
  };

  const headingStyle = {
    fontSize: '2em',
  };

  const paragraphStyle = {
    fontSize: '1.2em',
    marginTop: '20px',
  };

  return (
    <div style={thankYouPageStyle}>
      <h1 style={headingStyle}>Thank You for Your Payment!</h1>
      <p style={paragraphStyle}>We appreciate your business.</p>
    </div>
  );

}

export default AfterPayment
