import React from "react";
import "./Typeofpolicy.css";
import { AiFillThunderbolt } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { SlCalender } from "react-icons/sl";
import { TbPigMoney } from "react-icons/tb";
import { GiReceiveMoney } from "react-icons/gi";
import { LiaMoneyBillWaveAltSolid } from "react-icons/lia";
import { MdOutlineAssuredWorkload } from "react-icons/md";
import { MdPolicy } from "react-icons/md";
import { ImLifebuoy } from "react-icons/im";


const Typeofpolicy = () => {
  const policytype = [
    {
      id: 0,
      icon: <AiFillThunderbolt />,
      name: "Cost Effective ",
      discrip:
        "Most pocket-friendly and simple life insurance plans. The main motto is to provide financial security to the policyholder’s family, at the time of unfortunate events of death.",
    },
    {
      id: 1,
      icon: <SlCalender />,
      name: "Death Benefit in Lumpsum",
      discrip:
        "In case, policyholder passes away before the policy term is over, then the insurer provides their family with predetermined death benefit.",
    },
    {
      id: 2,
      icon: <TbPigMoney />,
      name: "Premium Payment Term",
      discrip:
        "Paying premiums for the complete coverage tenure is not required. Premium is paid until an individual is working. An individual has a free will to select a premium payment term which is aligned with the age of retirement.",
    },
    {
      id: 3,
      icon: <GiReceiveMoney />,
      name: "Return of Premium Benefits",
      discrip:
        "There are insurance companies who provide plans with return of premium benefits. In such type of plans, the policyholder survives the policy term and if no claim is raised, then the premiums are returned back to the policyholder.",
    },
    {
      id: 4,
      icon: <LiaMoneyBillWaveAltSolid />,
      name: "Receiving Payouts",
      discrip:
        "Complete malleability to opt for receiving payouts as 'monthly earnings' along with the lumpsum amount. This month on month gain is again divided into fixed monthly income plans or increasing monthly income plans.",
    },
    {
      id: 5,
      icon: <MdOutlineAssuredWorkload />,
      name: "Huge Sum Assured ",
      discrip:
        "The most highlighted feature of the best term plans in India is that those plans generally offer huge sum assured, which can extend up to INR 1 crore, assessable at low cost premiums.",
    },
    {
      id: 6,
      icon: <MdPolicy />,
      name: "Rider Options",
      discrip:
        "The policy holders have the provision to choose rider options, to make the pure term plan more broadly comprehensive in coverage.",
        listone:["Waiver Of Premium Rider (WOP)","Accidental Death Benefit Rider (ADB)","Critical Illness Rider (CI)"],
    },
    {
      id: 7,
      icon: <ImLifebuoy />,
      name: "Enhance Your Life Coverage",
      discrip:
        "The policyholder has the convenience to boost up the coverage at various important life stages such as marriage, new born in the family, education of children or purchasing a home, acquiring a property. without going through medicals or documentation"
  
        
    },
  ];
const nav = useNavigate()

  const handelcheck=()=>{
  nav('/term-insurance/termstep1')
  }

  return (

<div className="fixwidthDivtext">
<div className="typeofheading">
<h3>Various Features Of Term Insurance</h3>
{/* <p>There are 3 types of insurance policy in India Beema1 offers all of them.</p> */}
</div>
<div className="container">
  <div className="row justify-content-start">
    {policytype.map((data, index) => {
      return (
<div className="col-md-6 col-sm-12 col-xs-12 py-2" key={index}>
          <div className="cards">
            <div className="head">
              <p>{data.icon}</p>
              <h3>{data.name}</h3>
            </div>

            <div className="textclass">
              <p className="normaltext">{data.discrip}</p>
              {/* Check if listone exists and map over it */}
              {data.listone && data.listone.length > 0 && (
                <>
                  {data.listone.map((item, i) => (
                    <li key={i} className="listtagessterm">{item}</li>
                  ))}
                </>
              )}
            </div>

            <div className="buttontext">
              <button onClick={handelcheck}>Check Premium</button>
            </div>
          </div>
        </div>
      );
    })}
  </div>
</div>
</div>

  );
};

export default Typeofpolicy;
