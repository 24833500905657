import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import Slider from "@mui/material/Slider";
import "../SIP/SIP.css";
import { Link } from "react-router-dom";
import Rederict from '../../../AllPageComponents/Rederict'
import Calculatorlist from "../Calculator-Charat/Calculatorlist";
import { Helmet } from "react-helmet-async";

const CarLoan = () => {


  var convertRupeesIntoWords = require('convert-rupees-into-words');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
     // State variables
  const [loanAmount, setLoanAmount] = useState(100000);
  const [interestAmount, setInterestAmount] = useState(6.5);
  const [yearsAmount, setYearsAmount] = useState(5);
  const [monthlyPayment, setMonthlyPayment] = useState("");
  const [totalPayment, setTotalPayment] = useState("");
  const [totalInterest, setTotalInterest] = useState("");
  const [error, setError] = useState("");




  // Loan Amount Function
  const handleLoanAmountChange = (event) => {
    const value = event.target.value;
    const parsedValue = parseFloat(value);
    if (isNaN(parsedValue)) {
      setLoanAmount(''); // Clear the input if it's not a valid number
    } else {
      setLoanAmount(parsedValue); // Update the state with the valid number
    }
    if(parsedValue > 100000000){
      setLoanAmount(100000000)
    }
  };








  // Interest Rate Function
  const handleInterestRateChange = (event) => {
    const value = event.target.value;
    if (value === '' || (!isNaN(value) && /^[0-9]*\.?[0-9]*$/.test(value))) {
      if (parseFloat(value) > 30) {
        setInterestAmount(30);
      } else {
        setInterestAmount(value);
      }
    }
  };










  // Tenure Period Function
  const handleTenurePeriodChange = (event) => {
    const value = event.target.value;
    const parsedValue = parseFloat(value);

    if (isNaN(parsedValue)) {
      setYearsAmount(''); // Clear the input if it's not a valid number
    } else {
      setYearsAmount(parsedValue); // Update the state with the valid number
    }
    if(parsedValue > 40){
      setYearsAmount(40)
    }
  };

  // Calculate Results Function
  const calculateResults = () => {
    //console.log("Calculating...");

    // Validation
    if (loanAmount === "" || interestAmount === "" || yearsAmount === "") {
      setError("Please fill in all fields");
      return;
    }

    // Calculation Variables
    const principle = parseFloat(loanAmount);
    const calculatedInterest = parseFloat(interestAmount) / 100 / 12;
    const calculatedPayments = parseFloat(yearsAmount) * 12;

    // Calculate Monthly Payments
    const x = Math.pow(1 + calculatedInterest, calculatedPayments);
    const monthly = (principle * x * calculatedInterest) / (x - 1);

    if (isFinite(monthly)) {
      setMonthlyPayment(monthly.toFixed(2));
      setTotalPayment((monthly * calculatedPayments).toFixed(2));
      setTotalInterest((monthly * calculatedPayments - principle).toFixed(2));
      setError("");
    } else {
      setError("Please check your numbers");
      setMonthlyPayment("");
      setTotalPayment("");
      setTotalInterest("");
    }
  };

  // Show Results Function
  const showResults = () => {
    document.getElementById("results").style.display = "block";
  };

  // Calculate results when sliders change
  useEffect(() => {
    calculateResults();
  }, [loanAmount, interestAmount, yearsAmount]);

  const graphAmount = () => {
    const Interest = Math.floor(totalPayment);
    return Interest;
}


const TotalLaonAmountToword = convertRupeesIntoWords(Math.ceil(loanAmount))
const MonthlyEMIToWord = convertRupeesIntoWords(Math.ceil(monthlyPayment))
const TotalInterestToWord = convertRupeesIntoWords(Math.ceil(totalInterest))
const ToatlPaymentToWord = convertRupeesIntoWords(Math.ceil(totalPayment))
  return (
    <div>
<Helmet>
  <title>Online Car Insurance Calculator</title>
  <meta name="description" content="Estimate your car insurance premiums easily with our online car insurance calculator." />
  <meta name="keywords" content="car insurance estimate calculator, car insurance cost calculator, vehicle insurance calculator, online insurance calculator" />
</Helmet>








          <section className="margincalculator"></section>
    <div className="headingcalculator">
    <p>Home &nbsp;&gt;&nbsp;&nbsp;&nbsp;<Link to={'/calculator'}>calculator</Link>&nbsp;&nbsp;&nbsp;&nbsp;&gt;&nbsp;&nbsp;<span style={{color:'green'}}>Car-Loan</span></p>

  <h1 className="titlecalculator">Car insurance <span className='Mainheading'>Calculator</span></h1>


  <sapn className="CalculatorDiscription">A car loan calculator estimates monthly payments based on loan amount, interest rate, and 
  term, helping borrowers plan their budgets effectively</sapn>
</div>

      <div>
      
        <div className="calculatorhead">
          <div className="underdiv">
            <div className="calculatorse">
              {/* Calculator start div start hear */}
              <div className="calculaterse">
                <div className="calculator">
                  {/* Amount calculator */}
                  <div className="onecalculator">
                    <div className="amountdives">
                      <p>Loan Amount</p>
                      <input
                        type="text"
                        value={loanAmount}
                        onChange={
                          handleLoanAmountChange}
                      />
                    </div>
                    <div className="amountselectordiv">
                      <Slider
                        value={loanAmount}
                        onChange={
                          handleLoanAmountChange
                        }
                        min={100000}
                        max={10000000}
                        step={10000}
                        aria-label="Loan Amount"
                        valueLabelDisplay="auto"
                      />
                    </div>
                  </div>

                  {/* Amount calculator */}
                  <div className="onecalculator">
                    <div className="amountdives">
                      <p>Rate Of Interest %</p>
                      <input
                        type="text"
                        value={interestAmount}
                        onChange={(event) => {
                          handleInterestRateChange(event);
                        }}
                      />
                    </div>
                    <div className="amountselectordiv">
                      <Slider
                        value={interestAmount}
                        onChange={(event) => {
                          handleInterestRateChange(event);
                        }}
                        min={1}
                        max={30}
                        step={0.1}
                        aria-label="Interest Rate"
                        valueLabelDisplay="auto"
                      />
                    </div>
                  </div>

                  {/* Amount calculator */}
                  <div className="onecalculator">
                    <div className="amountdives">
                      <p>Loan Tanure</p>
                      <input
                        type="text"
                        value={yearsAmount}
                        onChange={(event) => {
                          handleTenurePeriodChange(event);
                        }}
                      />
                    </div>
                    <div className="amountselectordiv">
                      <Slider
                        value={yearsAmount}
                        onChange={(event) => {
                          handleTenurePeriodChange(event);
                        }}
                        min={1}
                        max={40}
                        aria-label="Time Period"
                        valueLabelDisplay="auto"
                      />
                    </div>
                  </div>
                  <div className="calculateamountdiv">
                    <div>
                    
                      <p>
                        Monthly EMI:{" "}
                        <span style={{ color: "#0166B3" }}>
  {Math.round(parseFloat(monthlyPayment)).toLocaleString("en-IN", {
    style: "currency",
    currency: "INR"
  })}
</span>
</p>
<p className='textword'>{MonthlyEMIToWord}</p>
                      <p>
                      Princepal Amount:{" "}
                      <span style={{ color: "#0166B3" }}>
                      {Math.round(parseFloat(loanAmount)).toLocaleString("en-IN", {
                        style: "currency",
                        currency: "INR"
                      })}
                      </span>
                      
                      </p>
<p className='textword'>{TotalLaonAmountToword}</p>
                      <p>
                        Total Interest:{" "}
                        <span style={{ color: "#0166B3" }}>
  {Math.round(parseFloat(totalInterest)).toLocaleString("en-IN", {
    style: "currency",
    currency: "INR"
  })}
</span>

                      </p>
                      <p className='textword'>{TotalInterestToWord}</p>
                      <p>
                         Total amount:{" "}
                        <span style={{ color: "#0166B3" }}>
  {Math.round(parseFloat(totalPayment)).toLocaleString("en-IN", {
    style: "currency",
    currency: "INR"
  })}
</span>

                      </p>
                      <p className='textword'>{ToatlPaymentToWord}</p>
                    </div>
                  </div>
                </div>
                <div className="graphdiv">
                  <Chart
        type='donut'
        width={400}
        height={445}
        series={[
          Number(loanAmount) || 100,
          Number(graphAmount()) || 100
        ]}
        options={{
          labels:['Loan Amount', ' Interest Amount']
        }}
      />
                </div>
              </div>
            </div>
            {/* Result part start hear */}
            <div className="resultse">
           <Calculatorlist/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CarLoan