import React from 'react';
import PartnerCompany from '../AllPageComponents/PartnerCompany/PartnerCompany';
import FAQ from '../AllPageComponents/FAQ/FAQ';
import './TermInsurence.css';
import TermInsuranceForm from './TermInsuranceForm';
import FastImage from "../FastImage";
import { auto } from '@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core'
import { LuAlarmClock } from "react-icons/lu";
import Redirect from '../AllPageComponents/Rederict';




const TermFamilyHealthInsurance = () => {


  return (
    <>
    <Redirect/>
      <section className="car-insurance insurance-all">
        <div className="container">
          <div className="row insurance-row-one">
            <div className="col-sm-12 col-md-4 col-lg-4 mian-one">
              <div className="healthdiscription">
                <h1>Family / Group / Senior Citizen Health Insurance</h1>
                <br />
                <span>
                  "Term insurance" refers to a type of life insurance that
                  provides coverage for a specific period, or "term," typically
                  ranging from 5 to 30 years. It offers a death benefit to the
                  beneficiaries if the insured individual passes away during the
                  term of the policy. Term insurance is known for its simplicity
                  and affordability compared to other types of life insurance,
                  such as whole life or universal life insurance.{" "}
                </span>
              </div>
              <div className="insurance-btn-set">
                <button
                  type="button"
                  className="reminderbutton"
                  data-toggle="modal"
                  data-target="#reminderpopup"
                >
                  {" "}
                  <LuAlarmClock
                    fontSize={"15pt"}
                    style={{ margin: "0 5px", color: "#FBA71B" }}
                  />{" "}
                  Set Reminder
                </button>
              </div>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4 side-image">

            <FastImage
            url={"Website%20Img/BikeInsurence.png?updatedAt=1718779269495"}
            width={auto}
            height={auto}
            alt={"ca-img"}
            className='fmailimagegifs'
            />




    
            </div>
            <TermInsuranceForm/>
         
          </div>
        </div>
      </section>
<br />
<br />
<br />

<div className="container">
    <h5 style={{color:"#2959B8"}}>What Is Family ,
              Group , Senior Citizen Insurance Policy?</h5>
    <div className="yellowline"></div>
    <br />
    <p>
      Currently there are 24 insurance companies that provide term insurance in
      India. Term insurance is a type of life insurance policy which provides a
      sum assured known as death benefit in case of an unfortunate demise of the
      life assured during the policy tenure. Term insurance can help an
      individual ensure financial security of their family. Hence it is very
      important to choose an insurance company whom you fully rely on. While
      choosing a term insurance company you should always consider the claim
      settlement ratio of the company.
    </p>
    <p>
      The Indian insurance market is full of term insurance companies that offer
      term insurance plans with high coverage at affordable premium amounts, to
      the insured. Currently, there are 24 insurance companies that offer term
      insurance plans in India.
    </p>
  </div>
<br />
<br />









<section className="full-space-block">
  <div className="container">
    <div className="row full-space-block-row">
      <div className="col-sm-12 col-md-12 col-lg-12">
        <div className="insurance-less-farage">
          <div className="simple-text-block">
      
            <h5 style={{color:"#2959B8",fontFamily:"Poppins"}}>  Benefits of Family / Group / Senior Citizen Insurance Policy</h5>
    <div className="yellowline"></div>
            <p>
              When you get your four-wheeler repaired at a cashless garage, you
              enjoy the following benefits:
            </p>
            <h6>1. It serves as a replacement for income</h6>
            <p>
              Term insurance plans are simply life insurance. This means that
              term insurance revolves entirely and exclusively around the
              concept of adequately securing future income of your family. This
              type of insurance covers the loss of income if one of the earning
              members of the family, or the only earning member, dies
              unexpectedly. The term insurance payouts paid to beneficiaries
              help to replace the income lost due to untimely death of the
              policyholder.
            </p>
            <h6>2. It helps in the maintenance of lifestyle of your family</h6>
            <p>
              Two incomes help support the rest of the family in most families
              with working couples. Even if there is just one earning member in
              the family, the family overall becomes accustomed to a specific
              way of life. The level of income earned is in line with the needs
              and goals. Term insurance payouts, on the other hand, help
              surviving family members to maintain their level of living when an
              earning member of the family dies early.
            </p>
            <h6>3. It fulfills dreams of your children</h6>
            <p>
              Future objectives of your children are determined by your income
              and that of your spouse (if you are both working). The children
              would be left without a source of income to support their growth,
              dreams, and aspirations if one or both earning members died
              unexpectedly. Term insurance can come in handy in this situation.
              Even if you are unable to provide for your children, the benefits
              acquired from a term policy can ensure that their life goals are
              not compromised.
            </p>
            <h6>
              4. It helps your family in meeting major expenses in the case of
              an emergency
            </h6>
            <p>
              Perhaps you and your family have some important life goals that
              you want to fulfill. It is possible that a few years down the
              road, you will want to purchase a home of your own. Alternatively,
              it could mean putting your children in the top universities
              available. There is a chance you have some high-interest bills
              that need to be paid off. The loss of income in the event of your
              unexpected death could make it difficult for your family's
              surviving members to meet these kinds of important expenses. Term
              insurance, on the other hand, allows them to cover these costs.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<PartnerCompany/>
<FAQ/>
    </>
  )
}

export default TermFamilyHealthInsurance