import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { MdArrowDropDown } from "react-icons/md";
import { Link, useParams } from "react-router-dom";
import "./Healthinsure.css";
import { GoEye } from "react-icons/go";
import { AiFillExclamationCircle } from "react-icons/ai";
import { Link as RouterLink } from "react-router-dom"; // Alias this Link as RouterLink
import { Link as ScrollLink, Element, Events, scrollSpy } from "react-scroll"; // Alias this Link as ScrollLink
import pfd from "./file-sample_150kB.pdf";
import { FaArrowRightLong } from "react-icons/fa6";
import { Helmet } from "react-helmet-async";
import FastImage from "../../FastImage";
import { auto } from "@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core";

const Healthinsure = () => {
  const [activeSection, setActiveSection] = useState("introduction");
  useEffect(() => {
    Events.scrollEvent.register("begin", function () {
      //console.log("begin", arguments);
    });

    Events.scrollEvent.register("end", function () {
      //console.log("end", arguments);
    });

    scrollSpy.update();

    return () => {
      Events.scrollEvent.remove("begin");
      Events.scrollEvent.remove("end");
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { selectInsurance, selectPolicy } = useParams();

  const Dataisnow = {
    Go_Digit: {
      "Double-Wallet-Plan-des": [
        {
          img: "Website%20Img/godigis.png?updatedAt=1718795342784",
          pdef: pfd,
          name: "Double Wallet Plan des",
          metaTag: "Care_Health",
          metadescription: "metadiscription",
          description:
            "Double Wallet Plan Desh is a revolutionary financial product that allows you to manage two separate wallets within one account. This innovative solution provides the convenience of having dedicated spaces for your personal and professional finances, simplifying budgeting and expense tracking.",
          fetures: [
            {
              id: 0,
              metaTag: "Care_Health",
              metadescription: "metadiscription",
              name: "Some Restoration Benefit",
              discription:
                "Even after you claim part of the cover on one occasion, you will have 100% of the cover restored, if you are hospitalized one more time in a bid to treat a different complication. In the same year, by the way.",
              popuptext:
                "The Restore Banifit is 10% this is an text cover is 25000-75000 INdian rupic price increse quterly by Yoy the madical infulation rate is 15% mens you buy a medicine today in 100 repic after some time you will buy same medicine pay of 115 rupic this call madical influation and india is lergest economic county with the 6% annual influation rate the care gives yoy upto 25L cover ... and cover PED (Pre - Exsisting Desies) india has 3rd largest county in this world ",
            },
            {
              id: 1,
              metaTag: "Care_Health",
              metadescription: "metadiscription",
              name: "Some Restoration Benefit",
              discription:
                "Even after you claim part of the cover on one occasion, you will have 100% of the cover restored, if you are hospitalized one more time in a bid to treat a different complication. In the same year, by the way.",
              popuptext:
                "The Restore Banifit is 10% this is an text cover is 25000-75000 INdian rupic price increse quterly by Yoy the madical infulation rate is 15% mens you buy a medicine today in 100 repic after some time you will buy same medicine pay of 115 rupic this call madical influation and india is lergest economic county with the 6% annual influation rate the care gives yoy upto 25L cover ... and cover PED (Pre - Exsisting Desies) india has 3rd largest county in this world ",
            },
            {
              id: 2,
              metaTag: "Care_Health",
              metadescription: "metadiscription",
              name: "Some Restoration Benefit",
              discription:
                "Even after you claim part of the cover on one occasion, you will have 100% of the cover restored, if you are hospitalized one more time in a bid to treat a different complication. In the same year, by the way.",
              popuptext:
                "The Restore Banifit is 10% this is an text cover is 25000-75000 INdian rupic price increse quterly by Yoy the madical infulation rate is 15% mens you buy a medicine today in 100 repic after some time you will buy same medicine pay of 115 rupic this call madical influation and india is lergest economic county with the 6% annual influation rate the care gives yoy upto 25L cover ... and cover PED (Pre - Exsisting Desies) india has 3rd largest county in this world ",
            },
            {
              id: 3,
              metaTag: "Care_Health",
              metadescription: "metadiscription",
              name: "Some Restoration Benefit",
              discription:
                "Even after you claim part of the cover on one occasion, you will have 100% of the cover restored, if you are hospitalized one more time in a bid to treat a different complication. In the same year, by the way.",
              popuptext:
                "The Restore Banifit is 10% this is an text cover is 25000-75000 INdian rupic price increse quterly by Yoy the madical infulation rate is 15% mens you buy a medicine today in 100 repic after some time you will buy same medicine pay of 115 rupic this call madical influation and india is lergest economic county with the 6% annual influation rate the care gives yoy upto 25L cover ... and cover PED (Pre - Exsisting Desies) india has 3rd largest county in this world ",
            },
          ],
          faqs: [
            {
              id: 0,
              metaTag: "Care_Health",
              metadescription: "metadiscription",
              Qustion: "How Godigit Help us to clame ?",
              Anwser:
                "Loran ipsome is an Examople text is starting right now hear is an exmple of thos",
            },
            {
              id: 1,
              metaTag: "Care_Health",
              metadescription: "metadiscription",
              Qustion: "Why GoDigit",
              Anwser:
                "Loran ipsome is an Examople text is starting right now hear is an exmple of thos",
            },
            {
              id: 2,
              metaTag: "Care_Health",
              metadescription: "metadiscription",
              Qustion: "GoDigit Provide indirence ?",
              Anwser:
                "Loran ipsome is an Examople text is starting right now hear is an exmple of thos",
            },
          ],
          policywarding: pfd,
          whatsok: [
            {
              metaTag: "Care_Health",
              metadescription: "metadiscription",
              qustion: "How can i cover my PED ?",
              anwsar:
                "You can cioudhuda hsdjuashdasudhg hausdgaydg iugshsgyudsg hasgduyasfd sAUHYSGASui uigdaugda uadgasudgas iuasdaudg nuihdudhas iohdasdass iuihdasdua",
            },
          ],

          whatswrong: [
            {
              metaTag: "Care_Health",
              metadescription: "metadiscription",
              qustion: "Sum Insured not Cover in case !",
              anwsar:
                "You can cioudhuda hsdjuashdasudhg hausdgaydg iugshsgyudsg hasgduyasfd sAUHYSGASui uigdaugda uadgasudgas iuasdaudg nuihdudhas iohdasdass iuihdasdua",
            },
          ],
          buynowlink: "https://www.google.com/beema1.com",
        },
      ],
      "Infinity-Wallet-Plan-des": [
        {
          img: "Website%20Img/godigis.png?updatedAt=1718795342784",
          pdef: pfd,
          name: "Infinity Wallet Plan des",
          metaTag: "Care_Health",
          metadescription: "metadiscription",
          description:
            "Infinity Wallet Plane is another innovative financial product that offers versatile solutions for managing your finances. With its flexible features and functionalities, it provides a seamless experience in handling your financial transactions and investments.",
          fetures: [
            {
              id: 0,
              metaTag: "Care_Health",
              metadescription: "metadiscription",
              name: "Some Restoration Benefit",
              discription:
                "Even after you claim part of the cover on one occasion, you will have 100% of the cover restored, if you are hospitalized one more time in a bid to treat a different complication. In the same year, by the way.",
              popuptext:
                "The Restore Banifit is 10% this is an text cover is 25000-75000 INdian rupic price increse quterly by Yoy the madical infulation rate is 15% mens you buy a medicine today in 100 repic after some time you will buy same medicine pay of 115 rupic this call madical influation and india is lergest economic county with the 6% annual influation rate the care gives yoy upto 25L cover ... and cover PED (Pre - Exsisting Desies) india has 3rd largest county in this world ",
            },
            {
              id: 1,
              metaTag: "Care_Health",
              metadescription: "metadiscription",
              name: "Some Restoration Benefit",
              discription:
                "Even after you claim part of the cover on one occasion, you will have 100% of the cover restored, if you are hospitalized one more time in a bid to treat a different complication. In the same year, by the way.",
              popuptext:
                "The Restore Banifit is 10% this is an text cover is 25000-75000 INdian rupic price increse quterly by Yoy the madical infulation rate is 15% mens you buy a medicine today in 100 repic after some time you will buy same medicine pay of 115 rupic this call madical influation and india is lergest economic county with the 6% annual influation rate the care gives yoy upto 25L cover ... and cover PED (Pre - Exsisting Desies) india has 3rd largest county in this world ",
            },
            {
              id: 2,
              metaTag: "Care_Health",
              metadescription: "metadiscription",
              name: "Some Restoration Benefit",
              discription:
                "Even after you claim part of the cover on one occasion, you will have 100% of the cover restored, if you are hospitalized one more time in a bid to treat a different complication. In the same year, by the way.",
              popuptext:
                "The Restore Banifit is 10% this is an text cover is 25000-75000 INdian rupic price increse quterly by Yoy the madical infulation rate is 15% mens you buy a medicine today in 100 repic after some time you will buy same medicine pay of 115 rupic this call madical influation and india is lergest economic county with the 6% annual influation rate the care gives yoy upto 25L cover ... and cover PED (Pre - Exsisting Desies) india has 3rd largest county in this world ",
            },
            {
              id: 3,
              metaTag: "Care_Health",
              metadescription: "metadiscription",
              name: "Some Restoration Benefit",
              discription:
                "Even after you claim part of the cover on one occasion, you will have 100% of the cover restored, if you are hospitalized one more time in a bid to treat a different complication. In the same year, by the way.",
              popuptext:
                "The Restore Banifit is 10% this is an text cover is 25000-75000 INdian rupic price increse quterly by Yoy the madical infulation rate is 15% mens you buy a medicine today in 100 repic after some time you will buy same medicine pay of 115 rupic this call madical influation and india is lergest economic county with the 6% annual influation rate the care gives yoy upto 25L cover ... and cover PED (Pre - Exsisting Desies) india has 3rd largest county in this world ",
            },
          ],
          faqs: [
            {
              id: 0,
              metaTag: "Care_Health",
              metadescription: "metadiscription",
              Qustion: "what is infinite wallet?",
              Anwser:
                "Loran ipsome is an Examople text is starting right now hear is an exmple of thos",
            },
            {
              id: 1,
              metaTag: "Care_Health",
              metadescription: "metadiscription",
              Qustion: "infinite wallter clames well",
              Anwser:
                "Loran ipsome is an Examople text is starting right now hear is an exmple of thos",
            },
            {
              id: 2,
              metaTag: "Care_Health",
              metadescription: "metadiscription",
              Qustion: "meening of infinite wallet",
              Anwser:
                "Loran ipsome is an Examople text is starting right now hear is an exmple of thos",
            },
          ],
          policywarding: pfd,

          whatsok: [
            {
              metaTag: "Care_Health",
              metadescription: "metadiscription",
              qustion: "How can i cover my PED ?",
              anwsar:
                "You can cioudhuda hsdjuashdasudhg hausdgaydg iugshsgyudsg hasgduyasfd sAUHYSGASui uigdaugda uadgasudgas iuasdaudg nuihdudhas iohdasdass iuihdasdua",
            },
          ],

          whatswrong: [
            {
              metaTag: "Care_Health",
              metadescription: "metadiscription",
              qustion: "Sum Insured not Cover in case !",
              anwsar:
                "You can cioudhuda hsdjuashdasudhg hausdgaydg iugshsgyudsg hasgduyasfd sAUHYSGASui uigdaugda uadgasudgas iuasdaudg nuihdudhas iohdasdass iuihdasdua",
            },
          ],
          buynowlink: "https://www.google.com/beema1.com",
        },
      ],
      "Worldwide-Treatment-Plan-des": [
        {
          img: "Website%20Img/godigis.png?updatedAt=1718795342784",
          pdef: pfd,
          metaTag: "Care_Health",
          metadescription: "metadiscription",
          name: "Worldwide Treatment Plan des",
          description:
            "Worldwide Treatment Plan Des is designed to provide comprehensive healthcare coverage across the globe. With its extensive network of healthcare providers and wide range of services, it ensures that you receive the best possible medical care wherever you are.",
          fetures: [
            {
              id: 0,
              metaTag: "Care_Health",
              metadescription: "metadiscription",
              name: "Some Restoration Benefit",
              discription:
                "Even after you claim part of the cover on one occasion, you will have 100% of the cover restored, if you are hospitalized one more time in a bid to treat a different complication. In the same year, by the way.",
              popuptext:
                "The Restore Banifit is 10% this is an text cover is 25000-75000 INdian rupic price increse quterly by Yoy the madical infulation rate is 15% mens you buy a medicine today in 100 repic after some time you will buy same medicine pay of 115 rupic this call madical influation and india is lergest economic county with the 6% annual influation rate the care gives yoy upto 25L cover ... and cover PED (Pre - Exsisting Desies) india has 3rd largest county in this world ",
            },
            {
              id: 1,
              metaTag: "Care_Health",
              metadescription: "metadiscription",
              name: "Some Restoration Benefit",
              discription:
                "Even after you claim part of the cover on one occasion, you will have 100% of the cover restored, if you are hospitalized one more time in a bid to treat a different complication. In the same year, by the way.",
              popuptext:
                "The Restore Banifit is 10% this is an text cover is 25000-75000 INdian rupic price increse quterly by Yoy the madical infulation rate is 15% mens you buy a medicine today in 100 repic after some time you will buy same medicine pay of 115 rupic this call madical influation and india is lergest economic county with the 6% annual influation rate the care gives yoy upto 25L cover ... and cover PED (Pre - Exsisting Desies) india has 3rd largest county in this world ",
            },
            {
              id: 2,
              metaTag: "Care_Health",
              metadescription: "metadiscription",
              name: "Some Restoration Benefit",
              discription:
                "Even after you claim part of the cover on one occasion, you will have 100% of the cover restored, if you are hospitalized one more time in a bid to treat a different complication. In the same year, by the way.",
              popuptext:
                "The Restore Banifit is 10% this is an text cover is 25000-75000 INdian rupic price increse quterly by Yoy the madical infulation rate is 15% mens you buy a medicine today in 100 repic after some time you will buy same medicine pay of 115 rupic this call madical influation and india is lergest economic county with the 6% annual influation rate the care gives yoy upto 25L cover ... and cover PED (Pre - Exsisting Desies) india has 3rd largest county in this world ",
            },
            {
              id: 3,
              metaTag: "Care_Health",
              metadescription: "metadiscription",
              name: "Some Restoration Benefit",
              discription:
                "Even after you claim part of the cover on one occasion, you will have 100% of the cover restored, if you are hospitalized one more time in a bid to treat a different complication. In the same year, by the way.",
              popuptext:
                "The Restore Banifit is 10% this is an text cover is 25000-75000 INdian rupic price increse quterly by Yoy the madical infulation rate is 15% mens you buy a medicine today in 100 repic after some time you will buy same medicine pay of 115 rupic this call madical influation and india is lergest economic county with the 6% annual influation rate the care gives yoy upto 25L cover ... and cover PED (Pre - Exsisting Desies) india has 3rd largest county in this world ",
            },
          ],
          faqs: [
            {
              id: 0,
              metaTag: "Care_Health",
              metadescription: "metadiscription",
              Qustion: "what is Worldwide Treatment?",
              Anwser:
                "Loran ipsome is an Examople text is starting right now hear is an exmple of thos",
            },
            {
              id: 1,
              metaTag: "Care_Health",
              metadescription: "metadiscription",
              Qustion: "meening of Wroldwide treatment ?",
              Anwser:
                "Loran ipsome is an Examople text is starting right now hear is an exmple of thos",
            },
            {
              id: 2,
              metaTag: "Care_Health",
              metadescription: "metadiscription",
              Qustion: "Worldwide cover pan india ?",
              Anwser:
                "Loran ipsome is an Examople text is starting right now hear is an exmple of thos",
            },
          ],
          policywarding: pfd,
          whatsok: [
            {
              metaTag: "Care_Health",
              metadescription: "metadiscription",
              qustion: "How can i cover my PED ?",
              anwsar:
                "You can cioudhuda hsdjuashdasudhg hausdgaydg iugshsgyudsg hasgduyasfd sAUHYSGASui uigdaugda uadgasudgas iuasdaudg nuihdudhas iohdasdass iuihdasdua",
            },
          ],

          whatswrong: [
            {
              metaTag: "Care_Health",
              metadescription: "metadiscription",
              qustion: "Sum Insured not Cover in case !",
              anwsar:
                "You can cioudhuda hsdjuashdasudhg hausdgaydg iugshsgyudsg hasgduyasfd sAUHYSGASui uigdaugda uadgasudgas iuasdaudg nuihdudhas iohdasdass iuihdasdua",
            },
          ],
          buynowlink: "https://www.google.com/beema1.com",
        },
      ],
    },
    Care_Health: {
      "Care-Supreme-des": [
        {
          img: "Website%20Img/care.png?updatedAt=1718795342791",
          pdef: pfd,
          metaTag: "Care_Health",
          metadescription: "metadiscription",
          name: "Care Supreme des",
          description:
            "Worldwide Treatment Plan Des is designed to provide comprehensive healthcare coverage across the globe. With its extensive network of healthcare providers and wide range of services, it ensures that you receive the best possible medical care wherever you are.",
          fetures: [
            {
              id: 0,
              metaTag: "Care_Health",
              metadescription: "metadiscription",
              name: "Some Restoration Benefit",
              discription:
                "Even after you claim part of the cover on one occasion, you will have 100% of the cover restored, if you are hospitalized one more time in a bid to treat a different complication. In the same year, by the way.",
              popuptext:
                "The Restore Banifit is 10% this is an text cover is 25000-75000 INdian rupic price increse quterly by Yoy the madical infulation rate is 15% mens you buy a medicine today in 100 repic after some time you will buy same medicine pay of 115 rupic this call madical influation and india is lergest economic county with the 6% annual influation rate the care gives yoy upto 25L cover ... and cover PED (Pre - Exsisting Desies) india has 3rd largest county in this world ",
            },
            {
              id: 1,
              metaTag: "Care_Health",
              metadescription: "metadiscription",
              name: "Some Restoration Benefit",
              discription:
                "Even after you claim part of the cover on one occasion, you will have 100% of the cover restored, if you are hospitalized one more time in a bid to treat a different complication. In the same year, by the way.",
              popuptext:
                "The Restore Banifit is 10% this is an text cover is 25000-75000 INdian rupic price increse quterly by Yoy the madical infulation rate is 15% mens you buy a medicine today in 100 repic after some time you will buy same medicine pay of 115 rupic this call madical influation and india is lergest economic county with the 6% annual influation rate the care gives yoy upto 25L cover ... and cover PED (Pre - Exsisting Desies) india has 3rd largest county in this world ",
            },
            {
              id: 2,
              metaTag: "Care_Health",
              metadescription: "metadiscription",
              name: "Some Restoration Benefit",
              discription:
                "Even after you claim part of the cover on one occasion, you will have 100% of the cover restored, if you are hospitalized one more time in a bid to treat a different complication. In the same year, by the way.",
              popuptext:
                "The Restore Banifit is 10% this is an text cover is 25000-75000 INdian rupic price increse quterly by Yoy the madical infulation rate is 15% mens you buy a medicine today in 100 repic after some time you will buy same medicine pay of 115 rupic this call madical influation and india is lergest economic county with the 6% annual influation rate the care gives yoy upto 25L cover ... and cover PED (Pre - Exsisting Desies) india has 3rd largest county in this world ",
            },
            {
              id: 3,
              metaTag: "Care_Health",
              metadescription: "metadiscription",
              name: "Some Restoration Benefit",
              discription:
                "Even after you claim part of the cover on one occasion, you will have 100% of the cover restored, if you are hospitalized one more time in a bid to treat a different complication. In the same year, by the way.",
              popuptext:
                "The Restore Banifit is 10% this is an text cover is 25000-75000 INdian rupic price increse quterly by Yoy the madical infulation rate is 15% mens you buy a medicine today in 100 repic after some time you will buy same medicine pay of 115 rupic this call madical influation and india is lergest economic county with the 6% annual influation rate the care gives yoy upto 25L cover ... and cover PED (Pre - Exsisting Desies) india has 3rd largest county in this world ",
            },
          ],
          faqs: [
            {
              id: 0,
              metaTag: "Care_Health",
              metadescription: "metadiscription",
              Qustion: "what is care health?",
              Anwser:
                "Loran ipsome is an Examople text is starting right now hear is an exmple of thos",
            },
            {
              id: 1,
              metaTag: "Care_Health",
              metadescription: "metadiscription",
              Qustion: "meening of care health ?",
              Anwser:
                "Loran ipsome is an Examople text is starting right now hear is an exmple of thos",
            },
            {
              id: 2,
              metaTag: "Care_Health",
              metadescription: "metadiscription",
              Qustion: "can care cover pan india ?",
              Anwser:
                "Loran ipsome is an Examople text is starting right now hear is an exmple of thos",
            },
          ],
          policywarding: pfd,
          whatsok: [
            {
              metaTag: "Care_Health",
              metadescription: "metadiscription",
              qustion: "How can i cover my PED ?",
              anwsar:
                "You can cioudhuda hsdjuashdasudhg hausdgaydg iugshsgyudsg hasgduyasfd sAUHYSGASui uigdaugda uadgasudgas iuasdaudg nuihdudhas iohdasdass iuihdasdua",
            },
          ],

          whatswrong: [
            {
              metaTag: "Care_Health",
              metadescription: "metadiscription",
              qustion: "Sum Insured not Cover in case !",
              anwsar:
                "You can cioudhuda hsdjuashdasudhg hausdgaydg iugshsgyudsg hasgduyasfd sAUHYSGASui uigdaugda uadgasudgas iuasdaudg nuihdudhas iohdasdass iuihdasdua",
            },
          ],
          buynowlink: "https://www.google.com/beema1.com",
        },
      ],
    },
  };

  {
    if (Dataisnow.hasOwnProperty(selectInsurance)) {
      if (Dataisnow[selectInsurance].hasOwnProperty(selectPolicy)) {
        const policyData = Dataisnow[selectInsurance][selectPolicy];

        // //console.log(Dataisnow)

        let insuranceData = null;

        // Check if selectInsurance matches any key in Dataisnow
        if (selectInsurance in Dataisnow) {
          // Get the corresponding insurance data
          insuranceData = Dataisnow[selectInsurance];
        }

        //console.log(activeSection);

        return (
          <>
            {policyData.map((data, index) => {
              const fetures = data.fetures;
              const faqs = data.faqs;
              const whatok = data.whatsok;
              const whatwrong = data.whatswrong;
              const policyname = data.name;
              const metatagse = data.metaTag;
              const metadiscriptionse = data.metadescription;
              const handelDownload = () => {
                const anchor = document.createElement("a");
                anchor.href = data.policywarding;
                anchor.target = "_blank"; // Open in a new tab/window
                anchor.click();
              };

              const handleClickOpenPDF = (event) => {
                event.preventDefault(); // Prevent the default behavior of the anchor tag
                const anchor = event.target;
                window.open(anchor.href, "_blank"); // Open the PDF in a new window/tab
              };

              //console.log(data);
              return (
                <div className="dataspolicy" key={index}>
                  <div className="sildebar">
                    <div className="carddiv">
                      <div className="undercard">
                        <Helmet>
                          <title>{metatagse}</title>
                          <meta
                            name="description"
                            content={metadiscriptionse}
                          />
                        </Helmet>
                        <Link to={`/health-insurance/${selectInsurance}/`}>
                          {" "}
                          <FastImage
                        url={data.img}
                        width={auto}
                        height={auto}
                        alt='Callbackagent'
                     
                      />
                        </Link>
                        <br />
                        <Link to={`/health-insurance/${selectInsurance}/`}>
                          <span>{data.name}</span>
                        </Link>
                        <div className="buttonsdivs">
                          <RouterLink to={data.buynowlink}>Buy now</RouterLink>

                          <button onClick={handelDownload}>
                            Policy Warding
                          </button>
                        </div>

                        <a
                          href={data.pdef}
                          download="file-sample_150kB.pdf"
                          onClick={handleClickOpenPDF}
                        >
                          View Policy Brochure <GoEye />
                        </a>
                      </div>
                      <div className="optionsslinding">
                        <ul>
                          <ScrollLink
                            to={"introduction"}
                            spy={true}
                            smooth={true}
                            duration={500}
                            // activeClass="active"
                            onSetActive={(section) => setActiveSection(section)}
                          >
                            <li
                              className={
                                activeSection === "introduction"
                                  ? "Activeclass"
                                  : "deactiveclss"
                              }
                            >
                              Overview
                            </li>
                          </ScrollLink>
                          <ScrollLink
                            to={"Fetures"}
                            spy={true}
                            smooth={true}
                            duration={500}
                            // activeClass="active"
                            onSetActive={(section) => setActiveSection(section)}
                          >
                            <li
                              className={
                                activeSection === "Fetures"
                                  ? "Activeclass"
                                  : "deactiveclss"
                              }
                            >
                              Fetures
                            </li>
                          </ScrollLink>

                          <ScrollLink
                            to={"whatsok"}
                            spy={true}
                            smooth={true}
                            duration={500}
                            // activeClass="active"
                            onSetActive={(section) => setActiveSection(section)}
                          >
                            <li
                              className={
                                activeSection === "whatsok"
                                  ? "Activeclass"
                                  : "deactiveclss"
                              }
                            >
                              what's OK
                            </li>
                          </ScrollLink>

                          <ScrollLink
                            to={"whatswrong"}
                            spy={true}
                            smooth={true}
                            duration={500}
                            // activeClass="active"
                            onSetActive={(section) => setActiveSection(section)}
                          >
                            <li
                              className={
                                activeSection === "whatswrong"
                                  ? "Activeclass"
                                  : "deactiveclss"
                              }
                            >
                              what's Wrong
                            </li>
                          </ScrollLink>

                          <ScrollLink
                            to={"FAQ's"}
                            spy={true}
                            smooth={true}
                            duration={500}
                            // activeClass="active"
                            onSetActive={(section) => setActiveSection(section)}
                          >
                            <li
                              className={
                                activeSection === "FAQ's"
                                  ? "Activeclass"
                                  : "deactiveclss"
                              }
                            >
                              FAQ's
                            </li>
                          </ScrollLink>
                          <ScrollLink
                            to={"relatedpolicys"}
                            spy={true}
                            smooth={true}
                            duration={500}
                            // activeClass="active"
                            onSetActive={(section) => setActiveSection(section)}
                          >
                            <li
                              className={
                                activeSection === "relatedpolicys"
                                  ? "Activeclass"
                                  : "deactiveclss"
                              }
                            >
                              Related Policy
                            </li>
                          </ScrollLink>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="contentdives">
                    <Element name="introduction">
                      <div className="contentsse">
                        <div className="policystatus">
                        
                          <FastImage
                        url={data.img}
                        width={auto}
                        height={auto}
                        alt='Callbackagent'
                     
                      />
                          <span>{data.name}</span>
                        </div>
                        <div className="contentdiscription">
                          <p>{data.description}</p>
                        </div>
                      </div>
                    </Element>
                    <div className="warningdivs">
                      <AiFillExclamationCircle
                        color="white"
                        fontSize={"20px"}
                      />{" "}
                      &nbsp; &nbsp;
                      <p>
                        If you are a smoker, you will likely find that the
                        premiums here are extremely affordable
                      </p>
                    </div>

                    <Element name="Fetures">
                      <div className="gridoverview">
                        {fetures.map((data, index) => {
                          return (
                            <>
                              <Helmet>
                                <title>{data.metaTag}</title>
                                <meta
                                  name="description"
                                  content={data.metadescription}
                                />
                              </Helmet>
                              <div className="griditems">
                                <h6>{data.name}</h6>
                                <p>{data.discription}</p>
                                <a
                                  href=""
                                  data-toggle="modal"
                                  data-target="#exampleModalCenter"
                                >
                                  Lern More{" "}
                                  <FaArrowRightLong fontSize={"10pt"} />
                                </a>
                              </div>
                              <div
                                class="modal fade"
                                id="exampleModalCenter"
                                tabindex="-1"
                                role="dialog"
                                aria-labelledby="exampleModalCenterTitle"
                                aria-hidden="true"
                              >
                                <div
                                  class="modal-dialog modal-dialog-centered"
                                  role="document"
                                >
                                  <div class="modal-content modelcontentpopup">
                                    <div class="modal-headerone">
                                      <h5
                                        class="modal-title"
                                        id="exampleModalLongTitle"
                                      >
                                        {data.name}
                                      </h5>
                                      <button
                                        type="button"
                                        class="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                      >
                                        <span aria-hidden="true">&times;</span>
                                      </button>
                                    </div>
                                    <div class="modal-body">
                                      <p className="popuptext">
                                        {data.discription}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </Element>

                    {/* Whats Ok div */}
                    <Element name="whatsok">
                      <h3 className="realtedpolicyh3">What's OK !</h3>
                      {whatok.map((data, index) => {
                        return (
                          <>
                            <Helmet>
                              <title>{data.metaTag}</title>
                              <meta
                                name="description"
                                content={data.metadescription}
                              />
                            </Helmet>
                            <div className="whatsok">
                              <h3>{data.qustion}</h3>
                              <span>{data.anwsar}</span>
                            </div>
                          </>
                        );
                      })}
                    </Element>

                    {/* Whats Wrong ! */}

                    <Element name="whatswrong">
                      <h3 className="realtedpolicyh3">What's wrong ?</h3>
                      {whatwrong.map((data, index) => {
                        return (
                          <>
                            <Helmet>
                              <title>{data.metaTag}</title>
                              <meta
                                name="description"
                                content={data.metadescription}
                              />
                            </Helmet>
                            <div className="whatsok">
                              <h3>{data.qustion}</h3>
                              <span>{data.anwsar}</span>
                            </div>
                          </>
                        );
                      })}
                    </Element>

                    {/* FAQ Compoennet in Bootstrap */}
                    <Element name="FAQ's">
                      <div className="faqdivs">
                        {faqs.map((data, index) => {
                          return (
                            <>
                              <Helmet>
                                <title>{data.metaTag}</title>
                                <meta
                                  name="description"
                                  content={data.metadescription}
                                />
                              </Helmet>
                              <Accordion>
                                <AccordionSummary
                                  expandIcon={
                                    <MdArrowDropDown fontSize={"25pt"} />
                                  }
                                  aria-controls="panel1-content"
                                  id="panel1-header"
                                >
                                  <span style={{ fontFamily: "Poppins" }}>
                                    {data.Qustion}
                                  </span>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <p
                                    style={{
                                      fontFamily: "Poppins",
                                      color: "gray",
                                    }}
                                  >
                                    {data.Anwser}
                                  </p>
                                </AccordionDetails>
                              </Accordion>
                            </>
                          );
                        })}
                      </div>
                    </Element>
                    {/* FAQ Compoennet in Bootstrap  End*/}

                    <Element name="relatedpolicys">
                      <h3 className="realtedpolicyh3">Related Policy</h3>
                      <div className="relatedpolicy">
                        {insuranceData &&
                          Object.keys(insuranceData).map((key) => {
                            return insuranceData[key].map((policy, index) => {
                              const routeName = policy.name.replace(
                                /\s+/g,
                                "-",
                              );
                              return (
                                <React.Fragment key={index}>
                                  <Helmet>
                                    <title>{policy.metaTag}</title>
                                    <meta
                                      name="description"
                                      content={policy.metadescription}
                                    />
                                  </Helmet>
                                  <RouterLink
                                    to={`/health-insurance/${selectInsurance}/${routeName}`}
                                  >
                                    <div className="policycardrelated">
                                      <div>
                                     
                                        <FastImage
                        url={policy.img}
                        width={auto}
                        height={auto}
                        alt='Callbackagent'
                     
                      />
                                        <br />
                                        <span>{policy.name}</span>
                                      </div>
                                    </div>
                                  </RouterLink>
                                </React.Fragment>
                              );
                            });
                          })}
                      </div>
                    </Element>
                  </div>
                </div>
              );
            })}
          </>
        );
      } else {
        return <div>Policy not found for the selected insurance provider.</div>;
      }
    } else {
      return <div>Insurance provider not found.</div>;
    }
  }
};

export default Healthinsure;
