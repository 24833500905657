import React from 'react';
import './TermInsurence.css';
import TermInsuranceForm from './TermInsuranceForm';
import FastImage from "../FastImage";
import { auto } from '@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core'
import { LuAlarmClock } from "react-icons/lu";
import PartnerCompany from '../AllPageComponents/PartnerCompany/PartnerCompany';
import FAQ from '../AllPageComponents/FAQ/FAQ';
import Redirect from '../AllPageComponents/Rederict';
import TermFormtwo from './TermFormtwo';



const TermTaxBenefits = () => {



  return (
    <>
    <Redirect/>
      <section className="car-insurance insurance-all">
        <div className="container">
          <div className="row insurance-row-one">
            <div className="col-sm-12 col-md-4 col-lg-4 mian-one">
              <div className="healthdiscription">
                <h1>Term Insurance Tax Benifit</h1>
                <br />
                <span>
                  "Term insurance" refers to a type of life insurance that
                  provides coverage for a specific period, or "term," typically
                  ranging from 5 to 30 years. It offers a death benefit to the
                  beneficiaries if the insured individual passes away during the
                  term of the policy. Term insurance is known for its simplicity
                  and affordability compared to other types of life insurance,
                  such as whole life or universal life insurance.{" "}
                </span>
              </div>
              <div className="insurance-btn-set">
                <button
                  type="button"
                  className="reminderbutton"
                  data-toggle="modal"
                  data-target="#reminderpopup"
                >
                  {" "}
                  <LuAlarmClock
                    fontSize={"15pt"}
                    style={{ margin: "0 5px", color: "#FBA71B" }}
                  />{" "}
                  Set Reminder
                </button>
              </div>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4 side-image">

            <FastImage
            url={"Website%20Img/BikeInsurence.png?updatedAt=1718779269495"}
            width={auto}
            height={auto}
            alt={"ca-img"}
            className='fmailimagegifs'
            />




    
            </div>
            {/* <TermInsuranceForm/> */}
            <TermFormtwo/>
         
          </div>
        </div>
      </section>
      <div className="container">
    <h5 style={{color:"#2959B8"}}>What is Term Insurece Tax Benifit ?</h5>
    <div className="yellowline"></div>
    <p>
      Currently there are 24 insurance companies that provide term insurance in
      India. Term insurance is a type of life insurance policy which provides a
      sum assured known as death benefit in case of an unfortunate demise of the
      life assured during the policy tenure. Term insurance can help an
      individual ensure financial security of their family. Hence it is very
      important to choose an insurance company whom you fully rely on. While
      choosing a term insurance company you should always consider the claim
      settlement ratio of the company.
    </p>
    <p>
      The Indian insurance market is full of term insurance companies that offer
      term insurance plans with high coverage at affordable premium amounts, to
      the insured. Currently, there are 24 insurance companies that offer term
      insurance plans in India.
    </p>
  </div>
<br />
<br />


<section className="full-space-block">
  <div className="container">
    <div className="row full-space-block-row">
      <div className="col-sm-12 col-md-12 col-lg-12">
        <div className="insurance-less-farage">
          <div className="simple-text-block">
           
    
            <h5 style={{color:"#2959B8",fontFamily:"Poppins"}} >Benefits of Tax Benefits Of Term Insurance Policy</h5>
    <div className="yellowline"></div>
            <p>
              When you buy a term plan, you can take advantage of a number of
              term insurance tax benefits.Learn about the tax benefits that come
              with buying a term life insurance policy.
            </p>
            <h6>1. Section 80C</h6>
            <p>
              Section 80C of the Income Tax Act of 1961 covers the most basic
              and fundamental term insurance tax benefit available to any Indian
              taxpayer. Many people believe this section to be the most popular
              tax-saving tool. Term insurance tax benefits of up to Rs. 1.5 lakh
              are available under this section for premiums paid to buy the
              plans. It is important to note that the maximum limit of tax
              deductions available under this Section includes tax benefits on
              PPF (Public Provident Fund) investments, tax-saving Fixed
              Deposits, and a range of other tax-saving products.{" "}
            </p>
            <p>
              By buying a comprehensive life insurance policy for yourself, you
              may maximise the tax benefits of term life insurance and benefit
              your family members in the long run.
            </p>
            <h6>2. Section 80D</h6>
            <p>
              Section 80D, in effect, allows you to deduct the cost of your
              health insurance premiums. However, since many insurers offer a
              term plan with a critical illness rider, further Section 80D
              benefits can be obtained.
            </p>
            <p>
              If you have selected health-related riders such as Critical
              Illness cover, Surgical Care cover, and related covers, you can
              take advantage of the term insurance tax benefit under section
              80D. In other words, by applying these riders to your term
              insurance premiums, you can save money on taxes while also
              receiving health insurance coverage.
            </p>
            <h6>3. Section 10 (10D)</h6>
            <p>
              Apart from the term insurance tax benefits, the life insured and
              his or her family members can save money by taking advantage of
              tax exemptions.This is covered in Section 10(10D).
            </p>
            <p>
              To put it differently, the death benefit or maturity benefit
              received from a term insurance policy is tax-free. This is also
              subject to the various terms and conditions therein. It means that
              the full amount paid to you or your loved ones under the term plan
              is tax-free. As a term insurance policyholder, you should be aware
              that the Section 10(10D) tax benefits are subject to specific
              conditions. It states that if the premium paid throughout the
              policy period does not exceed 20% of the predetermined sum
              assured, the maturity or death benefits under a term plan are not
              taxable.
            </p>
            <h6>4. Tax Benefits On Term Insurance Riders</h6>
            <p>
              Insurance companies provide a range of term insurance riders to
              provide extra coverage. Their benefits, on the other hand, are not
              limited to improving the main features of a term insurance policy.
            </p>
            <p>
              Additional term insurance tax benefits are available depending on
              the rider you select with a term plan and related conditions. Term
              plan riders can help you get more term life insurance tax benefits
              in a few different ways:
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<PartnerCompany/>
<FAQ/>

    </>
  )
}

export default TermTaxBenefits