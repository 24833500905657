import React, { useState,useEffect } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useNavigate } from "react-router-dom";
import Termstepper from "./Termstepper";
import arrow from '../TermInsurence/TermImages/arrow-animation.gif'
import Redirect from "../AllPageComponents/Rederict";
import { Helmet } from "react-helmet-async";

// Define custom theme with Poppins font
const theme = createTheme({
  typography: {
    fontFamily: 'Poppins, sans-serif',
  },
});



const Termstepper2 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);



const Marratialstatus = localStorage.getItem('MaritalStatus');
const occupation = localStorage.getItem('Occupation');
const Qulification = localStorage.getItem('Education')
const smoke = localStorage.getItem('Smoke');

 console.log("Marrage",Marratialstatus)
  const initialSomoke = smoke === 'true';

  const [Somoke, setSomoke] = useState(initialSomoke);
  const [EducationQulification, setEducationQulification] = useState(Qulification ? Qulification : '0');
  const [Occupation, setOccupation] = useState(occupation ? occupation : '0');
  const [MrratialStatus, setMrratialStatus] = useState(Marratialstatus ? Marratialstatus : '0')




  const nav = useNavigate()

  const handleSomoke = (value) => {
    setSomoke(value);
  };

  const handleEducationQulificationChange = (event) => {
    setEducationQulification(event.target.value);
  };

  const handleOccupationChange = (event) => {
    setOccupation(event.target.value);
  };
 const MarratialStatus =(event)=>{
  setMrratialStatus(event.target.value)
 }








 const [educationError, setEducationError] = useState('');
 const [occupationError, setOccupationError] = useState('');
 const [maritalStatusError, setMaritalStatusError] = useState('');

 const navigate = useNavigate();

 const handleDataCheck = () => {
   // Clear previous errors
   setEducationError('');
   setOccupationError('');
   setMaritalStatusError('');

   // Validate inputs and set errors
   let hasError = false;

   if (EducationQulification === "0") {
     setEducationError('Please Select Education Qualification');
     hasError = true;
   }

   if (Occupation === "0") {
     setOccupationError('Please Select Your Occupation');
     hasError = true;
   }

   if (MrratialStatus === "0") {
     setMaritalStatusError('Please Set Marital Status');
     hasError = true;
   }

   // If there are errors, stop further processing
   if (hasError) {
     return;
   }

   // Save data to localStorage
   if (Somoke) {
     localStorage.setItem('Smoke', Somoke);
   }

   if (EducationQulification) {
     localStorage.setItem('Education', EducationQulification);
   }

   if (Occupation) {
     localStorage.setItem('Occupation', Occupation);
   }

   if (MrratialStatus) {
     localStorage.setItem('MaritalStatus', MrratialStatus);
   }

   // Navigate to the next step
   navigate('/term-insurance/termstep3');
 };


  console.log('The Smooke',Somoke)

  return (
    <>
                <Helmet>
        <title>Termstep2</title>
        {/* <meta name="description" content="A brief description of your page for SEO." /> */}
        <meta name="keywords" content="Insurance Plan, Insurance Quotes, buy insurance" />
      </Helmet>
  <Redirect/>
    <ThemeProvider theme={theme}>
      <h1
  style={{
    textAlign: "center",
    padding: 20,
    color: "rgb(0, 85, 165)",
    marginTop: 20,
    fontFamily:'Poppins'
  }}
>
  Buy A Term Plane {" "}
  <span style={{ color: "rgb(229, 3, 24)" }}>upto 25% Mega</span> &nbsp;
  <span style={{ color: "rgb(253, 146, 2)" }}>discount</span>
  <sup>**</sup>
</h1>

       <Termstepper activeStep={2}/>
      <div className="underterminsurence">

<div className="undertermform">






        <div className="qustiondivs py-3">
          
           <div>

   
          <p>Do You Smoke ?</p>
          <div className="somkedivs">
            <div
              className={Somoke === true ? "activegender" : "deactivegender"}
              onClick={() => {
                handleSomoke(true);
              }}
            >
              Yes
              <input type="checkbox" name="" id="" checked={Somoke === true} className="smokeInput"/>
            </div>
            <div
              className={Somoke === false ? "activegender" : "deactivegender"}
              onClick={() => {
                handleSomoke(false);
              }}
            >
              No
              <input type="checkbox" name="" id="" checked={Somoke === false} className="smokeInput"/>
            </div>
          </div>
          </div>


        </div>

        <div className="Qulifictaiondiv py-3">
          <FormControl sx={{ width: '100%', border: '1px solid #ccc', borderRadius: 2 }}>
            <InputLabel id="education-label" sx={{ bgcolor: 'white' }}>
              Education Qualification
            </InputLabel>
            <Select
              labelId="education-label"
              id="education"
              value={EducationQulification}
              onChange={handleEducationQulificationChange}
              input={<OutlinedInput label="Education Qualification" />}
              sx={{ borderRadius: 'inherit' }}
            >
                <MenuItem value="0">Select</MenuItem>
              <MenuItem value="College Graduate & above">College Graduate & above</MenuItem>
              <MenuItem value="12th Pass">12th Pass</MenuItem>
              <MenuItem value="10th Pass & below">10th Pass & below</MenuItem>
            </Select>
          </FormControl>
        </div>
 <span className="termformerror">{educationError}</span>
        <div className="professiondiv py-3">
          <FormControl sx={{ width: '100%', border: '1px solid #ccc', borderRadius: 2 }}>
            <InputLabel id="occupation-label" sx={{ bgcolor: 'white' }}>
              Occupation Type
            </InputLabel>
            <Select
              labelId="occupation-label"
              id="occupation"
              value={Occupation}
              onChange={handleOccupationChange}
              input={<OutlinedInput label="Occupation Type" />}
              sx={{ borderRadius: 'inherit' }}
            >
              <MenuItem value="0">select</MenuItem>
              <MenuItem value="Salaried">Salaried</MenuItem>
              <MenuItem value="Self Employed">Self Employed</MenuItem>
            </Select>
          </FormControl>
        </div>
        <span className="termformerror">{occupationError}</span>
        <div className="professiondiv py-3">
      <FormControl sx={{ width: '100%', border: '1px solid #ccc', borderRadius: 2 }}>
        <InputLabel id="marital-status-label" sx={{ bgcolor: 'white' }}>
          Marital Status
        </InputLabel>
        <Select
          labelId="marital-status-label"
          id="marital-status"
          value={MrratialStatus}
          onChange={MarratialStatus}
          input={<OutlinedInput label="Marital Status" />}
          sx={{ borderRadius: 'inherit' }}
        >
             <MenuItem value="0">Select</MenuItem>
          <MenuItem value="Married">Married</MenuItem>
          <MenuItem value="Single">Single</MenuItem>
        </Select>
      </FormControl>
    </div>
    <span className="termformerror">{maritalStatusError}</span>
        <div className="underbutton py-2">
            <button onClick={handleDataCheck}>Continue</button>
            <img src={arrow} alt="" />
        </div>

        </div>


      </div>
    </ThemeProvider>
    </>
  );
};

export default Termstepper2;
