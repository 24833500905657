import React from 'react'
import '../FilnalClaim/FinalClaim.css';




const FinalClaim = () => {


  return (
    <>
<div className="fixwidthDivtext">

<div className="container">
  <div className="row">
    <div className="col-md-12">
    <h2 className='theheadingcolor'>This Is How The Insurance Claim Reimbursement Process Works:</h2>
    <div className="yellowline"></div>
    <span className='normaltext'>The process of medical treatments begins when you or your family members, those who are covered under medical insurance, receive medical treatments covered by your health insurance policy. This treatment could be because of illness, injuries, or medical procedures.</span>
 <br />
<br />
<br />
    </div>
  </div>
</div>



<div className="container stepmargin">

<div className="row">
   
<div className="col-md-12">




<div className="stateline">





             <div className="headingone">
            <div className="Discription">
             The process of medical treatments begins when you or your family members, those who are covered under medical insurance, receive medical treatments covered by your health insurance policy. This treatment could be because of illness, injuries, or medical procedures.
             <div className="blueline"></div>
             </div>
            <div className="line"></div>
            <div className="count">1</div>
            <div className="discriptionname">Hospital Discharge</div>
            </div> 




             <div className="headingtwo">
           
            <div className="discriptionname">Claim Submission</div>
            <div className="count2">2</div>
            <div className="line"></div>
            <div className="Discription">
            You (the policyholder) pay for the treatments given during hospitalization to the insurance company, and later on you can apply for the reimbursement process. And, under cashless hospitalization, the insurance company directly pays out of pocket to the hospital.

            <div className="blueline"></div>
             </div>
            </div>

            <div className="headingthree">
            <div className="Discription">
            After receiving treatment, you need to apply a claim to your insurance company. This typically involves filling out a claim form provided by your insurer. Make sure to attach all required documents, including medical bills and reports.
            <div className="blueline"></div>
             </div>
            <div className="line"></div>
            <div className="count">3</div>
            <div className="discriptionname">Claim Review</div>
            </div>


            <div className="headingfour">
            <div className="discriptionname">Approved Amount</div>
            <div className="count2">4</div>
            <div className="line"></div>
            <div className="Discription">
            Once the insurance company receives your claim, they will begin the processing and the verification process. This may involve reviewing your policy coverage, assessing the number of treatments received, and verifying the authenticity of the submitted documents.
            <div className="blueline"></div>
             </div>
            </div>
            


            <div className="headingfive">
            <div className="Discription">
            After reviewing your claim, the insurance company will determine the amount eligible for reimbursement based on your policy terms, coverage limits, deductibles, and co-payments. 
            <div className="blueline"></div>
             </div>
            <div className="line"></div>
            <div className="count">5</div>
            <div className="discriptionname">Reward Keeping</div>
            </div>

            <div className="headingsix">
            <div className="discriptionname">Renewble Amount</div>
            <div className="count2">6</div>
            <div className="line"></div>
            <div className="Discription">
            Once the insurance company receives your claim, they will begin the processing and the verification process. This may involve reviewing your policy coverage, assessing the number of treatments received, and verifying the authenticity of the submitted documents.
            <div className="blueline"></div>
             </div>
            </div> 

          
















































          <div className="headingonemob">
            <div className="Discription">
             The process of medical treatments begins when you or your family members, those who are covered under medical insurance, receive medical treatments covered by your health insurance policy. This treatment could be because of illness, injuries, or medical procedures.
             <div className="blueline"></div>
             </div>
            <div className="line"></div>
            <div className="blockone">

            <div className="count">1</div>
            <div className="discriptionname">Hospital Discharge</div>
            </div>
            </div>
{/*  */}
        <div className="headingtwomob">
        <div className="blockone">

            <div className="discriptionname">Claim Submission</div>
            <div className="count2">2</div>
        </div>
            <div className="line"></div>
          

            <div className="Discription">
            You (the policyholder) pay for the treatments given during hospitalization to the insurance company, and later on you can apply for the reimbursement process. And, under cashless hospitalization, the insurance company directly pays out of pocket to the hospital. Ensure that all the relevant documents, including medical bills, receipts, medical reports, X-ray reports, prescriptions, and the other documentation required to apply for reimbursement.
            <div className="blueline"></div>
            </div>
            </div>
{/*  */}

            <div className="headingthreemob">
            <div className="Discription">
            After receiving treatment, you need to apply a claim to your insurance company. This typically involves filling out a claim form provided by your insurer. Make sure to attach all required documents, including medical bills and reports.
            <div className="blueline"></div>
             </div>
            <div className="line"></div>
            <div className="blockone">

            <div className="count">3</div>
            <div className="discriptionname">Claim Review</div>
            </div>
            </div>
{/*  */}


         <div className="headingfourmob">
           <div className="blockone">

            <div className="discriptionname">Approved Amount</div>
            <div className="count2">4</div>
           </div>
            <div className="line"></div>
          

            <div className="Discription">
            Once the insurance company receives your claim, they will begin the processing and the verification process. This may involve reviewing your policy coverage, assessing the number of treatments received, and verifying the authenticity of the submitted documents.
            <div className="blueline"></div>
            </div>
            </div>

{/*  */}

<div className="headingfivemob">
            <div className="Discription">
            After receiving treatment, you need to apply a claim to your insurance company. This typically involves filling out a claim form provided by your insurer. Make sure to attach all required documents, including medical bills and reports.
            <div className="blueline"></div>
             </div>
            <div className="line"></div>
            <div className="blockone">

            <div className="count">5</div>
            <div className="discriptionname">Reward Keeping</div>
            </div>
            </div>

{/*  */}
<div className="headingsixmob">
           <div className="blockone">

            <div className="discriptionname">Renewble Amount</div>
            <div className="count2">6</div>
           </div>
            <div className="line"></div>
          

            <div className="Discription">
            Once the insurance company receives your claim, they will begin the processing and the verification process. This may involve reviewing your policy coverage, assessing the number of treatments received, and verifying the authenticity of the submitted documents.
            <div className="blueline"></div>
            </div>
            </div>








</div>

<div className="mobilescreenone">
  <div className="blockmob">
    <div className="countmobile">1</div>
     <div className="mobilename">Hospital Discharge</div>
     <div className="mobileline"></div>
     </div>
      <div className="mobilediscription">
        <span>
        The process of medical treatments begins when you or your family members, 
        those who are covered under medical insurance, receive medical treatments
         covered by your health insurance policy. This treatment could be because of illness, 
         injuries, or medical procedures.
        </span>
        <div className="yellowline"></div>
      </div>
</div>
<div className="mobilescreenone">
  <div className="blockmob">
    <div className="countmobile">2</div>
     <div className="mobilename">Claim Submission</div>
     <div className="mobileline"></div>
     </div>
      <div className="mobilediscription">
        <span>
        You (the policyholder) pay for the treatments given during hospitalization to the insurance company, and later on you can apply for the reimbursement process. And, under cashless hospitalization, the insurance company directly pays out of pocket to the hospital. Ensure that all the relevant documents, including medical bills, receipts, medical reports, X-ray reports, prescriptions, and the other documentation required to apply for reimbursement.

        </span>
        <div className="yellowline"></div>
      </div>
</div>
<div className="mobilescreenone">
  <div className="blockmob">
    <div className="countmobile">3</div>
     <div className="mobilename">Claim Review</div>
     <div className="mobileline"></div>
     </div>
      <div className="mobilediscription">
        <span>
        After receiving treatment, you need to apply a claim to your insurance company. This typically involves filling out a claim form provided by your insurer. Make sure to attach all required documents, including medical bills and reports.
        </span>
        <div className="yellowline"></div>
      </div>
</div>
<div className="mobilescreenone">
  <div className="blockmob">
    <div className="countmobile">4</div>
     <div className="mobilename">Approved Amount</div>
     <div className="mobileline"></div>
     </div>
      <div className="mobilediscription">
        <span>
        Once the insurance company receives your claim, they will begin the processing and the verification process. This may involve reviewing your policy coverage, assessing the number of treatments received, and verifying the authenticity of the submitted documents.
        </span>
        <div className="yellowline"></div>
      </div>
</div>
<div className="mobilescreenone">
  <div className="blockmob">
    <div className="countmobile">5</div>
     <div className="mobilename">Reward Keeping</div>
     <div className="mobileline"></div>
     </div>
      <div className="mobilediscription">
        <span>
        After reviewing your claim, the insurance company will determine the amount eligible for reimbursement based on your policy terms, coverage limits, deductibles, and co-payments. They may also verify if the treatment falls within the scope of coverage and meets any pre-authorization requirements
        </span>
        <div className="yellowline"></div>
      </div>
</div>
<div className="mobilescreenone">
  <div className="blockmob">
    <div className="countmobile">6</div>
     <div className="mobilename">Approved Amount</div>
     <div className="mobileline"></div>
     </div>
      <div className="mobilediscription">
        <span>
        Once the insurance company receives your claim, they will begin the processing and the verification process. This may involve reviewing your policy coverage, assessing the number of treatments received, and verifying the authenticity of the submitted documents.

        </span>
        <div className="yellowline"></div>
      </div>
</div>







</div>



</div>
</div>
<br />

</div>
</>


  )
}

export default FinalClaim