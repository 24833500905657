import React,{useState,useEffect} from "react";
import "./Editprofile.css";
import { FaUserEdit } from "react-icons/fa";
import axios from "axios";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FileBase64 from 'react-file-base64';
import Swal from "sweetalert2";
import Loader from '../../AllPageComponents/Loader'
import Redirect from "../../AllPageComponents/Rederict";
import FastImage from "../../FastImage";
import { auto } from '@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core'



const Editprofile = () => {

  const Token = localStorage.getItem('userToken')

  const [userGetData, setUserGetData] = useState({});
  async function getUserData() {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}user/me`, {
      token: Token 
    });
    setUserGetData(response.data.data); 
    //console.log("Updated user Data", response.data);
  } catch (error) {
    console.error("Error fetching user data:", error);
  }
}

useEffect(() => {
  getUserData();
}, []); 
//console.log("The Upadte Userdata is",userGetData)



function formatDate(isoDateString) {
  const date = new Date(isoDateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${day}/${month}/${year}`;
}


useEffect(() => {
  window.scrollTo(0, 0);
}, []);
// Start hear

const [firstName, setFirstName] = useState('');
const [lastName, setLastName] = useState('');
const [phoneNumber, setPhoneNumber] = useState("");
const [email, setEmail] = useState("");
const [dob, setDob] = useState("");
const [annualIncome, setAnnualIncome] = useState("");
const [vehicleInfo, setVehicleInfo] = useState("");
const [city, setCity] = useState("");
const [homeAddress, setHomeAddress] = useState("");
const [gender, setGender] = useState("");
const [useProfilepicture, setUseProfilepicture] = useState({ base64WithoutPrefix: '' }); // Initialize useProfilepicture as an object
const [isLoading, setisLoading] = useState(false)
const [refreshFlag, setRefreshFlag] = useState(false);

useEffect(() => {
  if (userGetData.first_name) {
    setFirstName(userGetData.first_name);
  }
  if (userGetData.last_name) {
    setLastName(userGetData.last_name);
  }
  if (userGetData.mobile_number) {
    setPhoneNumber(userGetData.mobile_number);
  }
  if (userGetData.email) {
    setEmail(userGetData.email);
  }
  if (userGetData.dob) {
    const date = new Date(userGetData.dob);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 because getMonth() returns zero-based months
    const day = String(date.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    setDob(formattedDate);
}
  if (userGetData.annual_income) {
    setAnnualIncome(userGetData.annual_income);
  }
  if (userGetData.vehicle_info) {
    setVehicleInfo(userGetData.vehicle_info);
  }
  if (userGetData.city) {
    setCity(userGetData.city);
  }
  if (userGetData.home_address) {
    setHomeAddress(userGetData.home_address);
  }
  if (userGetData.gender) {
    setGender(userGetData.gender);
  }
}, [userGetData]);

const handleSubmit = async (e) => {
  setisLoading(true)
  e.preventDefault();
  const formData = {
    first_name: firstName,
    last_name: lastName,
    email: email,
    mobile_number: phoneNumber,
    dob: dob,
    annual_income: annualIncome,
    vehicle_info: vehicleInfo,
    city: city,
    home_address: homeAddress,
    gender: gender,
    profile_image: useProfilepicture.base64WithoutPrefix // Access base64WithoutPrefix from useProfilepicture state
  };

  try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}user/update_profile`, formData, {
      params: { token: Token },
    });
    //console.log('Response:', response.data); 
    if(response.data.status === true){
      setRefreshFlag(prevFlag => !prevFlag);
      Swal.fire({
        title: "Success",
        text: "User Data Update Successful",
        icon: "success",
        confirmButtonText: "Back",
      });
      window.location.reload();
     
    } else {
      Swal.fire({
        title: "Error!",
        text: "Something Went Wrong",
        icon: "error",
        confirmButtonText: "Back",
      });
    }
  } catch (error) {
    console.warn('Something went wrong');
  } finally {
    setisLoading(false); // Set isLoading to false regardless of the response status
  }
};


useEffect(() => {

  }, [isLoading,userGetData]);

//console.log("The Dob is", dob);

const onFileSubmit = (file) => {
  const regexPNG = /^data:image\/png;base64,/;
  const regexJPG = /^data:image\/jpeg;base64,/;
  const regexSVG = /^data:image\/svg\+xml;base64,/;

  let base64WithoutPrefix;
  if (file.type === 'image/png') {
    base64WithoutPrefix = file.base64.replace(regexPNG, '');
  } else if (file.type === 'image/jpeg') {
    base64WithoutPrefix = file.base64.replace(regexJPG, '');
  } else if (file.type === 'image/svg+xml') {
    base64WithoutPrefix = file.base64.replace(regexSVG, '');
  }

  // Update useProfilepicture state with base64WithoutPrefix
  setUseProfilepicture({ base64WithoutPrefix });
};

//console.log("profile image", useProfilepicture);


  return (
   <>
   <Redirect/>
   {isLoading && <Loader/>}
    <div className="backgroundeditprofile">
      <div className="eaditprofile">


        <div className="userimage">
        <img
        src={`${process.env.REACT_APP_MAIN_URL}/uploads/profile_pic/${userGetData.profile_image}`} onError={(e) => { e.target.src = 'https://cdn.pixabay.com/photo/2020/07/01/12/58/icon-5359553_1280.png'; }}


            alt="Profile Image"
          />
          <div className="addressdivs">
            <span className="name">{firstName ? firstName : "Hello"}&nbsp;{lastName ? lastName : "Guest"}</span>
            <br />
            <span className="district">{userGetData.city ? userGetData.city : 'City'} &nbsp; {userGetData.email}</span>
            <FaUserEdit style={{ color: "#0166b3", marginRight: "15px" }} />
            </div>
            </div>
       

        <form>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="exampleInputEmail1" className="editlable">
                First Name
              </label>
              <input
            type="text"
            className="form-control editinput"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            placeholder="Enter First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
            </div>
            <div class="form-group col-md-6">
              <label for="exampleInputPassword1" className="editlable">
                Last Name
              </label>
              <input
                type="text"
                class="form-control editinput"
                id="exampleInputPassword1"
                placeholder="Enter Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>

            <div class="form-group col-md-6">
              <label for="exampleInputEmail1" className="editlable">
                Phone Number
              </label>
              <input
                type="text"
                class="form-control editinput"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Enter Phone Number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                disabled
              />
            </div>
            <div class="form-group col-md-6">
              <label for="exampleInputPassword1" className="editlable">
                Email
              </label>
              <input
                type="text"
                class="form-control editinput"
                id="exampleInputPassword1"
                placeholder="Enter Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div class="form-group col-md-6">
              <label for="exampleInputEmail1" className="editlable">
                Date of birth
              </label>
              <input
                type="date"
                class="form-control editinput"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Enter First Name"
                value={dob}
                onChange={(e) => setDob(e.target.value)}
              />
            </div>
            <div class="form-group col-md-6">
              <label for="exampleInputPassword1" className="editlable">
                Annual Income
              </label>
              <input
                type="number"
                class="form-control editinput"
                id="exampleInputPassword1"
                placeholder="Enter Annual Income"
                value={annualIncome}
                onChange={(e) => setAnnualIncome(e.target.value)}
              />
            </div>

            <div class="form-group col-md-6">
              <label for="exampleInputEmail1" className="editlable">
                Vehicle Information
              </label>
              <input
                type="text"
                class="form-control editinput"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Enter Vehicle Informatione"
                value={vehicleInfo}
                onChange={(e) => setVehicleInfo(e.target.value)}
              />
            </div>
            <div class="form-group col-md-6">
              <label for="exampleInputPassword1" className="editlable">
                City
              </label>
              <input
                type="text"
                class="form-control editinput"
                id="exampleInputPassword1"
                placeholder="Enter City"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </div>

            <div class="form-group col-md-6">
              <label for="exampleInputEmail1" className="editlable">
                Home Address
              </label>
              <input
                type="text"
                class="form-control editinput"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Enter Home Address"
                value={homeAddress}
                onChange={(e) => setHomeAddress(e.target.value)}
              />
            </div>



            <div class="form-group col-md-6">
            <FormControl>
      <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={gender}
        onChange={(e)=>setGender(e.target.value)}
      >
      <FormControlLabel value="male" control={<Radio />} label="Male" />
        <FormControlLabel value="female" control={<Radio />} label="Female" />
      </RadioGroup>
    </FormControl>
            </div>

            <div class="form-group col-md-12">
            
            <div className="upload-cv-file">
                   <label className="">
                   <div className="upload-icon">
                   <FileBase64 multiple={false} onDone={onFileSubmit}type="file"
                    name="file"
                    className="FileChange"
                            id="file"
                             accept=".jpg, .jpeg, .png"
                            />
                        <FastImage
            url={"Website%20Img/upppp.png?updatedAt=1718799402584"}
            width={auto}
            height={auto}
            alt={"ca-img"}
            />
                        </div>
                        <h4>
                         Upload Profile Image<span className="text-danger">*</span>
                        </h4>
                        <span id="selectedFileName" />
                        <p>Browse to choose a picture</p>
                        <span>File size: 10MB | File format: picture</span>
                      </label>
                   </div>
            </div>



          </div>
          <div className="divadd">
            <button  class="btn btn-primary" onClick={handleSubmit}>
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </div>
    </>
  );
};

export default Editprofile;
