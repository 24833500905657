import React, { useState } from "react";
import "./Understandpolicy.css";

import { Link, useNavigate, Route, BrowserRouter as Router } from "react-router-dom";
import Healthinsure from "./Healthinsure";
import FastImage from "../../FastImage";
import { auto } from "@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core";

const Understandpolicy = () => {
  const nav = useNavigate();
  const [selectedProduct, setSelectedProduct] = useState("*");
  const [selectInsure, setselectInsure] = useState("*");
  const [selectPolicy, setSelectPolicy] = useState("*");

  const handleProductChange = (event) => {
    setSelectedProduct(event.target.value);
  };
  
  const handleInsureChange = (event) => {
    setselectInsure(event.target.value);
  };
  
  const handlePolicyChange = (event) => {
    setSelectPolicy(event.target.value);
  };

  const submittd = () => {
    const formattedSelectInsure = selectInsure.replace(/ /g, "_");
    const formattedSelectPolicy = selectPolicy.replace(/ /g, "-");
    const linkTo = `/health-insurence/${formattedSelectInsure}/${formattedSelectPolicy}`;
    //console.log(linkTo); // Log the link to the console
    nav(linkTo);
  };

  const PolicyDatas = [
    {
      product: "Health Insurance",
      Insurer: [
        {
          name: "Go Digit",
          products: ["Double Wallet Plan des", "Infinity Wallet Plan des", "Worldwide Treatment Plan des"],
        },
        {
          name: "Care Health",
          products: ["Care Supreme des"],
        },
        {
          name: "ICICI Lombard",
          products: ["HAE_Individual Adult_1YR_Apex Plus des"],
        },
        {
          name: "HDFC Ergo",
          products: ["Optima Secure des"],
        },
      ],
    }
  ];



  return (
    <>



        <div className="understatndpolicy container">
          <div className="headerunderstand">
           
            <div className="tile-text-with-text-shadow">
        <h1>
         Understand Policy
        </h1>
      </div>
      </div>

        <div className="commonone">

        <h2 className="theheadingcolor">Calculate All Things In Few Clicks</h2>
          <p style={{color:'gray'}}>
              Understand what your policy has to offer. Read terms and conditions
              in plain English. And discover the good, the bad, and the lacking
              features in your insurance plan.
            </p>
        </div>
         

          <div className="policytagse">
            <select
              name="product"
              id="product"
              value={selectedProduct}
              onChange={handleProductChange}
            >
              <option value="*">Select Product</option>
              {PolicyDatas.map((policyData, index) => (
                <option key={index} value={policyData.product}>
                  {policyData.product}
                </option>
              ))}
            </select>

            <select
              name="Insurer"
              id="Insurer"
              value={selectInsure}
              onChange={handleInsureChange}
              disabled={selectedProduct === "*"}
            >
              <option value="*">Select Insurer</option>
              {PolicyDatas.find(
                (policyData) => policyData.product === selectedProduct,
              )?.Insurer.map((insurer, index) => (
                <option key={index} value={insurer.name}>
                  {insurer.name}
                </option>
              ))}
            </select>

            <select
              name="policy"
              id="policy"
              value={selectPolicy}
              onChange={handlePolicyChange}
              disabled={selectedProduct === "*" || selectInsure === "*"}
            >
              <option value="*">Select The Product</option>
              {PolicyDatas.find(
                (policyData) => policyData.product === selectedProduct,
              )
                ?.Insurer.find((insurer) => insurer.name === selectInsure)
                ?.products.map((policy, index) => (
                  <option key={index} value={policy}>
                    {policy}
                  </option>
                ))}
            </select>
            <button className="policycheckbutton" disabled={selectPolicy === "*"} onClick={submittd}>
              Check
            </button>
          </div>

          <div className="policyimagemotal">
            
            <FastImage
           
              url={"Website%20Img/Travel%20insurance%20(1).gif?updatedAt=1718795343541"}
              width={auto}
              height={auto}
              alt={"travel"}
              src={"Website%20Img/Travel%20insurance%20(1).gif?updatedAt=1718795343541"}
            />
          
          </div>
        </div>

     
  
    </>
  );
};

export default Understandpolicy;
