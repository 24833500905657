import React, { useState, useEffect } from "react";
import "../../CommonCssFiles/planstyle.css";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useSelector, useDispatch } from "react-redux";
import { Proposal, ProposerForm } from "../ProposalPage/Proposal";
import { HDFCQuestionAnswer, HDFCPersion } from "../../commonComponents/HDFC";
import Alert from "@mui/material/Alert";
import {
  ICICIQuestionAnswer,
  ICICIPersion,
} from "../../commonComponents/ICICI";
import {
  findSelf,
  companyId,
  getMonthYear,
  changeDateFormat,
} from "../../helper/common";
import {RelianceQuestion,  ReliancePersion } from "../../commonComponents/RelianceQuestion";
import { payload } from "../../helper/godigit";
import Summary from "../../commonComponents/Summary";
import Loader from "../../../AllPageComponents/Loader";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import {afterPlanFetchApi} from "../../Api/Api";
import Swal from "sweetalert2";

import axios from "axios";

import {
  selectProposal,
  updateData,
  addInsuredData,
  updateInsuredData,
  updatecheckedQuestion,
  selectcheckedQuestion,
  updatecheckedPersion,
  selectcheckedPersion,
  updateCareQAlist,
  updateHDFCQAlist,
  updateGodigitQAlist,
} from "../../redux/Slice";
import "../../commonComponents/HDFC.css";
import RelianceQuestionAnswer from "../../commonComponents/RelianceQuestion";
import Redirect from "../../../AllPageComponents/Rederict";
import { Helmet } from "react-helmet-async";

const MedicalDetails = () => {
  const history = useNavigate();

  const goBack = () => {
    history(-1);
  };

  const [questionData, setQuestionData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [stopLoading , setStopLoading] = useState(false)


  const { 
    userdata,
     fetchPlandata } = useSelector((state) => {
    return state.FetchPlan;
  });

  //   let insuredData = userdata.InsuredDetail; -----commented out this because not full data of insureddetails is coming here

  const proposalData = useSelector(selectProposal);
  console.log("the proposaldata in medicaldetailspage", proposalData);

  // ---------using this proposalData because here the whole data of insureddetails are coming

  // let insuredData = proposalData.InsuredDetail;

  const dispatch = useDispatch();

  const getSelfKey = () => {
    for (let i = 0; i < insuredData.length; i++) {
      if (insuredData[i].InsuredRelation == "Self") {
        return i;
        break;
      }
    }
    return null;
  };

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const unique_id = params.get("req_id");
  let plan_id = params.get("plan_id");
  const c_id = params.get("c_id");
  const company = companyId();

  const [addonArr, setAddonArr] = useState([]);

  const [insuredData , setInsuredData] = useState('');


  // const [loading, setLoading] = useState(true);
  const [apiResponse, setApiResponse] = useState("");
  const [errordata , setErrorData] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [addonDataList, setAddonDataList] = useState([]);


  // const [userdata , setUserData] = useState('')


  const set_error = (error, message) => {
    setErrorData(error);
    setErrorMessage(message);
  };


  const handleError = (message) => {
 
    Swal.fire({
      title: 'Error!',
      text: message,
      icon: 'error',
      confirmButtonText: 'OK'
    }).then((result) => {
      if (result.isConfirmed) {
        // Reload the page when user clicks "OK"
        window.location.reload();
      }})
    setStopLoading(true)
    
  };


  const fetchPlan = async () => {
    try {
     
      // Start loading
      const response = await afterPlanFetchApi(unique_id, c_id, plan_id).then((response)=>{
        setLoader(false); 

        if(response.data.response == null){
          setStopLoading(true);
          handleError("Oops there is an error !")
        }
        
        console.log("the response in medicaldetails", response);
        setApiResponse(response.data);
        setAddonDataList(response.data.request.addonPrice)
    
     
  
        if (response.data.request.addons) {
          let currentAddons = response.data.request.addons
            ? response.data.request.addons.split(",")
            : [];
  
          setAddonArr(currentAddons);
        }

        
      })
      
   

   
    } catch (error) {
      console.log("the error in afterfetchplan", error);
    } finally {
      // setLoading(false); // Stop loading
    }
  };


  useEffect(() => {
    fetchPlan();

  }, []);




  useEffect(() => {
    window.scrollTo(0, 0);
    getQuestionData();
  }, []);

  function getQuestionData() {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url:
        process.env.REACT_APP_BASE_URL +
        "health/fetch_question/" +
        company[c_id] +
        "/" +
        plan_id,
      headers: {},
      async: false,
    };

    axios
      .request(config)
      .then((response) => {
        if (response.data.question_answer.status == true) {
          setQuestionData(response.data.question_answer.data);
          setLoader(false);
        } else {
          setQuestionData([]);
        }
      })
      .catch((error) => {
        setQuestionData([]);
        // console.log(error);
      });
  }

  const medicalQsubmit = (e) => {
    e.preventDefault();
    // console.log('medicalQsubmit')
    const diseaseNameValues = Array.from(e.target.elements)
      .filter((element) => element.name === "Disease Name")
      .map((element) => element.value);
    const surgeryDateValues = Array.from(e.target.elements)
      .filter((element) => element.name === "Surgery Date")
      .map((element) => element.value);
    const dateOfDiagnosisValues = Array.from(e.target.elements)
      .filter((element) => element.name === "Date of Diagnosis")
      .map((element) => element.value);
    const lastConsultationDateValues = Array.from(e.target.elements)
      .filter((element) => element.name === "Last Consultation Date")
      .map((element) => element.value);

    const formData = {
      diseaseName: diseaseNameValues,
      surgeryDate: surgeryDateValues,
      dateOfDiagnosis: dateOfDiagnosisValues,
      lastConsultationDate: lastConsultationDateValues,
    };

    console.log("formData", formData);
  };



 
  useEffect(() => {
    if (apiResponse && apiResponse.response == null) {
      setStopLoading(true);
      handleError('Oops there is an Error');
    }
  }, [apiResponse]);

  if (!apiResponse && !stopLoading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }


  return (
    (stopLoading == false )?
    <>
      <Helmet>
      <title>Medical details</title>


        {/* <meta name="description" content="A brief description of your page for SEO." /> */}
        <meta name="keywords" content="Insurance Plan, Insurance Quotes, buy insurance" />
      </Helmet>
      {loader  ?<Loader />:
      <div className="container">
        <Redirect/>
        <h1 className="mainheading">
          We Sell Happyness* <span className="middelheading">We Buy</span>
          <span className="endheading">Your Fear</span>{" "}
        </h1>
        {errordata && (
          <Alert
            variant="filled"
            severity="error"
            style={{ width: "770px" }}
            onClose={() => {
              set_error(false, "");
            }}
          >
            {errorMessage}
          </Alert>
        )}

        <form onSubmit={medicalQsubmit}>
          <div className="row">
            <div className="col-lg-8 medicalform">
              <div className="nextsection">
                <h1 className="Proposalheader">Proposal form (step 2 of 3)</h1>

                <p className="Medicalheaderp">Medical Details</p>
                {/* <div className="row ppv10 my-3"> */}
                <div
                  style={{ display: "flex", flexWrap: "wrap" }}
                  className="col-md-12"
                >
                  {c_id == "HDFC"
                    ? questionData.map((data, index) => {
                        return (
                          <>
                            <HDFCQuestionAnswer
                              data={data}
                              index={index}
                              insuredData={proposalData.InsuredDetail}
                            />

                          <div className="med-person-div col-md-12">
                          {proposalData.InsuredDetail.map((persion, pindex) => {
                              return (
                                <>
                       
                                <HDFCPersion
                                    data={data}
                                    persion={persion}
                                    pindex={pindex}
                                  />
                            
                              
                                </>
                              );
                            })}
                          </div>
                         
                          </>
                        );
                      })
                    : 
                    c_id == "ICICI"
                    ? questionData.map((data, index) => {
                        return (
                          <>
                            <ICICIQuestionAnswer
                              data={data}
                              index={index}
                              insuredData={proposalData.InsuredDetail}
                            />

                            {proposalData.InsuredDetail.map((persion, pindex) => {
                              return (
                                <>
                                  <ICICIPersion
                                    data={data}
                                    persion={persion}
                                    pindex={pindex}
                                  />
                                </>
                              );
                            })}
                          </>
                        );
                      })
                    : 

                    c_id == "Reliance"
                    ? questionData.map((data, index) => {
                        return (
                          <>
                           

                            <RelianceQuestionAnswer
                              data={data}
                              index={index}
                              insuredData={proposalData.InsuredDetail}
                            />


                          
                            {proposalData.InsuredDetail.map((persion, pindex) => {
                              return (
                                <>
                                 
                                  <ReliancePersion
                                    data={data}
                                    persion={persion}
                                    pindex={pindex}
                                  />
                                </>
                              );
                            })}
                          </>
                        );
                      })
                    
                    
                    
                    :questionData.map((data, index) => {
                        return (
                          <>
                            <DigitQuestionAnswer
                              data={data}
                              index={index}
                              insuredData={proposalData.InsuredDetail}
                            />
                            <Persion
                              data={data}
                              insuredData={proposalData.InsuredDetail}
                              index={index}
                            />
                          </>
                        );
                      })}
                  {/* </div> */}
                </div>
              </div>
              <div></div>
            </div>

            <div className="col-lg-4">
            
              <Summary set_error={set_error}  nextPage="kyc" addondataList={addonDataList} setAddonDataList={setAddonDataList} addonsArray={addonArr} setAddonArr={setAddonArr} planInfo={ apiResponse ? apiResponse :""} apiResponse={apiResponse} setApiResponse={setApiResponse} />
            </div>
          </div>
        </form>
      </div>
      }
    </>
    :""
  );
};

export default MedicalDetails;

const DigitQuestionAnswer = ({ data, index, insuredData }) => {
  const dispatch = useDispatch();

  const checkedQuestion = useSelector(selectcheckedQuestion);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const c_id = params.get("c_id");

  return (
    <>
      <div className="row col-md-12">
        <div className="col pmd">
          {/*<span className='nfh1 cash'>Q.{data.question}</span>*/}
          <FormControlLabel
            value={index}
            onChange={(e) =>
              dispatch(
                updatecheckedQuestion({
                  val: e.target.value,
                  questionSetCode: data.QuestionSetCode,
                  c_id: c_id,
                  question_id: data.question_id,
                })
              )
            }
            control={<Checkbox />}
            label={"Q." + data.question}
            checked={checkedQuestion.includes(index.toString())}
          />
        </div>
      </div>
    </>
  );
};

const DigitSubQuestionAnswer = ({ data, qid, pid, parentData }) => {

  const [ objData , setObjData] = useState();
  const [ fieldData , setFieldData] = useState("")
  const checkedPersion = useSelector(selectcheckedPersion);
  const proposal = useSelector(selectProposal);
  const dispatch = useDispatch();

  console.log("proposal", proposal);

  console.log("the med questiondata", proposal.InsuredDetail[pid].medicalQuestions);

  console.log("the q id", qid);

  console.log("the p id", pid);

  console.log("the parentdata", parentData);

  console.log("the data", data);


  useEffect(()=>{

   if(proposal?.InsuredDetail[pid]?.medicalQuestions){
    let val = proposal?.InsuredDetail[pid]?.medicalQuestions;

      console.log("the val", val);

      val.map((item,index)=>{
       if(item.questionCode == parentData.question_id){
        console.log("item.questionCode , parentData.question_id",item.questionCode , parentData.question_id )
     

        item.subQuestions.map((value,index)=>{
          if(value.questionCode == data.question_id){
        setObjData(value);

          }
        })
       }

    
      })

   }
      


      

      





  },[])




if(objData){
  console.log("objData", objData)
}

  const check = () => {
    // console.log("check")
    if (checkedPersion.hasOwnProperty(qid)) {
      if (checkedPersion[qid].includes(pid)) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  return (
    <>
      <div className="row col-md-12" hidden={check()}>
        <div className="col pmd">
          <span className="nfh1 cash">Q.{data.question}</span>

          <FormGroup>
            <TextField
              id="standard-select-currency-native"
              select
              label=""
              value={fieldData ? fieldData : objData?.detailAnswer }
              SelectProps={{
                native: true,
              }}
              variant="standard"
              onChange={(e) =>{
                setFieldData(e.target.value);
                dispatch(
                  updateGodigitQAlist({
                    qid: qid,
                    pid: pid,
                    qdata: data,
                    parentqData: parentData,
                    value: e.target.value,
                  })
                )
              }
                
              }
            >
              <option>Select</option>

              {data.answers.map((data) => {
                return (
                  <option value={data.answer_code}>
                    {data.answer_description}
                  </option>
                );
              })}
            </TextField>
          </FormGroup>
        </div>
      </div>
    </>
  );
};

const convertDateFormat = (dateString) => {
  // Split the date string into day, month, and year components
  const parts = dateString.split("-");

  // Ensure that there are three parts (day, month, and year)
  if (parts.length !== 3) {
    throw new Error("Invalid date format");
  }
  const formattedDate = `${parts[1]}-${parts[0]}`;

  return formattedDate;
};

const getCurrentMonthYear = () => {
  // Get the current date using dayjs
  const currentDate = dayjs();

  // Format the current date to "MM-YYYY" format
  const currentMonthYear = currentDate.format("MM-YYYY");

  return currentMonthYear;
};

const CareSubQuestionAnswer = ({ data, qid, pid, parentData, dobdata }) => {
  console.log("the stringdata in care", toString(dobdata));
  const monthdata = convertDateFormat(dobdata);

  console.log("the monthdata in care", monthdata);
  const currentmonthyear = getCurrentMonthYear();
  console.log("the currentmonthyear", currentmonthyear);

  console.log("the dobdata in CareSubQuestionAnswer", dobdata);
  const checkedPersion = useSelector(selectcheckedPersion);
  const dispatch = useDispatch();
  const proposalData = useSelector(selectProposal);
  console.log("proposalData", proposalData);
  const oldqdata = proposalData.InsuredDetail[pid];
  let value = null;
  if (oldqdata.hasOwnProperty("partyQuestionDOList")) {
    let partyQuestionDOList = oldqdata.partyQuestionDOList;

    for (let i = 0; i < partyQuestionDOList.length; i++) {
      if (partyQuestionDOList[i].questionCd == data.question_id) {
        value = partyQuestionDOList[i].response;
        // console.log('questionCd',data.questionCd)
      }
    }
  }

  const check = () => {
    // console.log("check")
    if (checkedPersion.hasOwnProperty(qid)) {
      if (checkedPersion[qid].includes(pid)) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  let yes_no_array = ["pedYesNo", "H001", "H002", "H003", "H004"];

  return (
    <>
      <div className="row ppv10 my-3 col-12" hidden={check()}>
        <div className="col px-0">
          {/* <div className="prpfbd"> */}
          {console.log("the data in medical", data)}
          {data.question_id == "OtherSmokeDetails" ||
          data.question_id == "otherDiseasesDescription" ? (
            <TextField
              id="standard-helperText"
              label={data.question}
              defaultValue={value}
              variant="standard"
              onChange={(e) =>
                dispatch(
                  updateCareQAlist({
                    qid: qid,
                    pid: pid,
                    qdata: data,
                    parentqData: parentData,
                    value: e.target.value,
                  })
                )
              }
            />
          ) : yes_no_array.includes(data.question_id) ? (
            <TextField
              id="standard-select-currency-native"
              select
              label={data.question}
              SelectProps={{
                native: true,
              }}
              variant="standard"
              onChange={(e) =>
                dispatch(
                  updateCareQAlist({
                    qid: qid,
                    pid: pid,
                    qdata: data,
                    parentqData: parentData,
                    value: e.target.value,
                  })
                )
              }
            >
              <option value="NO" selected={value == "NO"}>
                NO
              </option>
              <option value="YES" selected={value == "YES"}>
                YES
              </option>
            </TextField>
          ) : (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DesktopDatePicker"]}>
                <DesktopDatePicker
                  variant="standard"
                  id="standard-helperText"
                  label={data.question}
                  defaultValue={dayjs(
                    value != null ? changeDateFormat(value) : null, 'MM-YYYY'
                  )}
                  onChange={(e) =>
                    dispatch(
                      updateCareQAlist({
                        qid: qid,
                        pid: pid,
                        qdata: data,
                        parentqData: parentData,
                        value: getMonthYear(e),
                      })
                    )
                  }
                  // Specify the minimum selectable date
                  minDate={dayjs(monthdata, "MM-YYYY")}
                  // Specify the maximum selectable date
                  maxDate={dayjs(currentmonthyear, "MM-YYYY")}
                  format="MM-YYYY"
                />
              </DemoContainer>
            </LocalizationProvider>

          )}
          
        </div>
      </div>
    </>
  );
};

const Persion = ({ data, insuredData, index }) => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const unique_id = params.get("req_id");
  const plan_id = params.get("plan_id");
  const c_id = params.get("c_id");

  const checkedQuestion = useSelector(selectcheckedQuestion);
  const [clickPersion, setClickPersion] = useState(false);
  const checkedPersion = useSelector(selectcheckedPersion);
  const proposalData = useSelector(selectProposal);

  const handleClick = (pindex) => {
    dispatch(
      updatecheckedPersion({
        qid: index,
        pid: pindex,
        question_id: data.question_id,
        questionSetCd: data.QuestionSetCode,
        c_id: c_id,
        qdata: data,
      })
    );
  };

  const dispatch = useDispatch();

  return (
    <>
      <div
        className="col-md-12"
        hidden={!checkedQuestion.includes(index.toString())}
      >
        {insuredData.map((persion, pindex) => {
          console.log("the persion in persion component", persion);
          const check = () => {
            // console.log("check")
            if (checkedPersion.hasOwnProperty(index)) {
              if (checkedPersion[index].includes(pindex)) {
                return "sumboxone";
              } else {
                return "";
              }
            } else {
              return "";
            }
          };

          return (
            <>
              <div className="center">
                <div className="scroll">
                  <div
                    className={"row boxdiv " + check()}
                    onClick={() => handleClick(pindex)}
                  >
                    <div className="col-12">
                      <span className="proposalname">
                        {proposalData.InsuredDetail[pindex].InsuredName} (
                        {persion.InsuredRelation}){" "}
                      </span>
                    </div>
                    <div className="col-3 text-right"></div>
                  </div>

                  {data.get_child_question.map((child_data) => {
                    if (c_id == "GoDigit") {
                      return (
                        <DigitSubQuestionAnswer
                          data={child_data}
                          parentData={data}
                          qid={index}
                          pid={pindex}
                        />
                      );
                    } else if (c_id == "Care") {
                      return (
                        <CareSubQuestionAnswer
                          data={child_data}
                          parentData={data}
                          qid={index}
                          pid={pindex}
                          dobdata={persion.dateOfbirth}
                        />
                      );
                    } else {
                    }
                  })}
                </div>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};
