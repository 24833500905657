import React from 'react';
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import axios from "axios";
import { useState,useEffect } from 'react';
import Loader from '../AllPageComponents/Loader';
import { FaArrowRotateLeft } from "react-icons/fa6";
import FastImage from '../FastImage';
import { auto } from '@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core'




const TermFormtwo = () => {


    const FullName = localStorage.getItem('ProposalName')
    const PhoneNumber = localStorage.getItem('Proposalphonenumber')
  
  const [ValidName, setValidName] = useState('')
  const [ValidPhonNumber, setValidPhonNumber] = useState('')
  
    useEffect(()=>{
      if(FullName){
        setValidName(FullName)
      }
      if(PhoneNumber){
        setValidPhonNumber(PhoneNumber)
      }
  
    },[FullName,PhoneNumber])
  
      const [gender, setSelectedGender] = useState("");
      const [phone_no, setPhoneNumber] = useState( ValidPhonNumber);
      const [name, setFullName] = useState(ValidName);
      const [dob, setDob] = useState("");
      const [formValues, setFormValues] = useState([]);
      const [isLoading , setIsLoading] = useState(false)
  
  
      const [genderError , setGenderError ] = useState();
      const [phoneNumError , setPhoneNumError] = useState();
      const [nameError , setNameError] = useState();
      const [dobError , setDobError] = useState();
  
  
  
    const [generatedCaptcha, setGeneratedCaptcha] = useState('');
    const [userInput, setUserInput] = useState('');
    const [resultMessage, setResultMessage] = useState('');
  
    // Function to generate a random alphanumeric string
    const generateRandomString = (length) => {
      const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = '';
      for (let i = 0; i < length; i++) {
        result += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return result;
    };
  
    // Function to generate the CAPTCHA
    const generateCaptcha = () => {
      const captchaText = generateRandomString(6); 
      setGeneratedCaptcha(captchaText);
      setResultMessage('');
    };
  
  
  
    useEffect(()=>{
      generateCaptcha();
    },[])
  
  
  
  
  
  
  
  
  
  
  
  
  
  // onclicking submit button this function is called 
      const handleButtonClick = async () => {
          const formData = {
            gender: gender,
            phone_no: phone_no ,
            name: name ,
            dob: dob,
          };
          setFormValues([formData]);
          
          genderValid(formData.gender);
          phoneNumValid(formData.phone_no);
          validateFullName(formData.name);
          validateDob(formData.dob);
  
  
          if (userInput !== generatedCaptcha) {
            Swal.fire({
              title: "Error!",
              text: "Wrong Captcha", // Changed punctuation for better readability
              icon: "error",
              confirmButtonText: "Back",
            });
        setUserInput('')
        generateCaptcha();
        return
      } 
  
      
            if(!((gender === "") ||
              phone_no === "" ||
              name === "" ||
              dob === ""
            )){
              setIsLoading(true);
              try {
                const response = await axios.post(
                  `${process.env.REACT_APP_BASE_URL}cms/enquiry_mail`,
                  formData
                );
                //console.log("Post Successful", response);
                if (response.status === 200) {
                  setIsLoading(false);
                  Swal.fire({
                    title: "Sucess!",
                    text: "Your Request Submitted Sucessfully", // Changed punctuation for better readability
                    icon: "success",
                    confirmButtonText: "Done",
                  });
                  setSelectedGender("");
                  setPhoneNumber("");
                  setFullName("");
                  setDob("");
                } else {
                  Swal.fire({
                    title: "Error!",
                    text: "Somthing Whent Wrong", // Changed punctuation for better readability
                    icon: "error",
                    confirmButtonText: "Back",
                  });
                }
              } catch (error) {
                Swal.fire({
                  title: "Error!",
                  text: "Somthing Whent Wrong", // Changed punctuation for better readability
                  icon: "error",
                  confirmButtonText: "Back",
                });
              }
            }
          
          
        };
  
  
  // All form validations are here 
  
  const isValidIndianPhoneNumber = (phoneNumber) => {
    const indianPhoneNumberRegex = /^[6-9]\d{9}$/;
    return indianPhoneNumberRegex.test(phoneNumber);
  };
  
      const genderValid = (data)=>{
        let isValid = true;
        if (data === "") {
          setGenderError("Gender is required");
          isValid = false;
        }  else {
          setGenderError("");
        }
      }
  
      const phoneNumValid = (data) => {
        let isValid = true;
        if (data === "") {
          setPhoneNumError("Phone number is required");
          isValid = false;
        } else if (!isValidIndianPhoneNumber(data)) {
          setPhoneNumError("Please enter a valid Indian phone number");
          isValid = false;
        } else if (data.length !== 10) {
          setPhoneNumError("Phone number must be 10 digits");
          isValid = false;
        } else {
          setPhoneNumError("");
        }
      };
    
      const validateFullName = (data) => {
        let isValid = true;
    
        if (data === "") {
          setNameError("Full name is required");
          isValid = false;
        } else {
          setNameError("");
        }
      };
  
  
      const validateDob = (data) => {
        let isValid = true;
    
        if (data === "") {
          setDobError("Date of birth is required");
          isValid = false;
        } else {
          setDobError("");
        }
      };
  
  
  // form validation ends here
  
  
  
      const handleNameChange = (event) => {
          setFullName(event.target.value);
        };
      
        const handleGenderChange = (data) => {
          setSelectedGender(data);
        };
        const handelphoneNumber = (event) => {
          setPhoneNumber(event.target.value);
        };
        const handleDobChange = (event) => {
          setDob(event.target.value);
        };
  
  return (
    <>
    {isLoading && <Loader/>}
    <div className="col-sm-12 col-md-4 col-lg-4 popup-main">
              <div className="form-main">
                <form
                  id="insurance_form_get_plan"
               
                >
                  <input
                    type="hidden"
                    name="_token"
                    defaultValue="J55cO62BBGUJtLxVJ5SJYmmxeRW96qUDg3eML6qy"
                  />{" "}
                  <div className="d-flex align-items-center form-input main-boxxes check-boxx">
                    <h6>
                      Secure Family With 1 Crore Cover Starting At ₹16/Day*
                    </h6>
                    <div className="form-inner-input form-group">
                      <label htmlFor="form_name">Name</label>
                      <span className="text-danger">*</span>
                      <input
                        id="name"
                        type="text"
                        name="fullname"
                        className="form-control txtOnly"
                        placeholder="Enter your name"
                        value={name}
                        onChange={(e)=>{handleNameChange(e);validateFullName(e.target.value)}}
                      />
                       {nameError && <span className="error">{nameError}</span>}
                    </div>
                   

                    <div className="form-inner-input form-group">
                      <label htmlFor="phone">Mobile Number</label>
                      <span className="text-danger">*</span>
                      <input
                        type="tel"
                        id="mobile"
                        name="mobile"
                        placeholder="Enter your mobile number"
                        className="form-control"
                        maxLength={10}
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                        value={phone_no}
                        onChange={(e)=>{handelphoneNumber(e);phoneNumValid(e.target.value)}}
                      />
                         {phoneNumError && <span className="error">{phoneNumError}</span>}
                    </div>

                 

                    {/* Start Select option male of Female */}
             

           
                    <div className="form-inner-input form-group check-boxx form-box">

                      <div className="family-checkbox">
                        <div className="checkbox-wrapper term-wrapper">
                          <div
                            className={
                              gender === "male"
                                ? "termgenderselect"
                                : "deactive"
                            }
                            onClick={(e) => {handleGenderChange("male");genderValid(e.target.value)}}
                          >
                            <div className="imag-bg">
                            <FastImage
            url={"Website%20Img/man-self-icon.svg?updatedAt=1718801472073"}
            width={auto}
            height={auto}
            alt={"ca-img"}
            />
                            </div>
                            <span>Male</span>
                          </div>
                        </div>
                        {genderError && <span className="error">{genderError}</span>}
                      </div>
                      <div className="family-checkbox">
                        <div className="checkbox-wrapper">
                          <div
                            className={
                              gender === "female"
                                ? "termgenderselect"
                                : "deactive"
                            }
                            onClick={(e) => {handleGenderChange("female");genderValid(e.target.value)}}
                          >
                            <div className="imag-bg">
                            <FastImage
            url={"Website%20Img/woman-self-icon.svg?updatedAt=1718801472103"}
            width={auto}
            height={auto}
            alt={"ca-img"}
            />
                            </div>
                            <span>Female</span>
                          </div>
                        </div>
                      </div>
             
                    </div>
         
              

                   

                    {/* End Select option male of Female */}

                  









                    <div className="form-inner-input form-group">
                      <label htmlFor="dob">Date Of Birth</label>
                      <span className="text-danger">*</span>
                      <input
                        type="date"
                        id="dob"
                        name="dob"
                        className="form-control"
                        placeholder='Date of birth'
                        max="2006-03-03"
                        value={dob}
                        onChange={(e)=>{handleDobChange(e);validateDob(e.target.value)}}
                      />
                                          {dobError && <span className="error">{dobError}</span>}
                    </div>



<br />

                    
                    <div className="containerdivcaptcha">
      <div className="captchaSection">
        <div id="captcha" className="genarateCaptcha">{generatedCaptcha}</div>
        <button onClick={generateCaptcha} style={{border:"none"}}><FaArrowRotateLeft /></button>
      </div>



      <div id="inputSection_captcha">
        <input
          type="text"
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
          placeholder="Enter CAPTCHA"
          className='Captachcode'
        />

        <p id="result">{resultMessage}</p>
      </div>
    </div>
    





                    <div className="blue-btn term-plan-btn">
                      <a
                        href="javascript:void(0)"
                        className="open-halth-insurance-term-plan"
                        onClick={handleButtonClick}
                      >
                        <input
                          type="button"
                          className="form-btn"
                          value="Get Quotation"
                          id="termFormSubmitbtn"
                        />
                     
                      </a>
                    </div>

                    <p className="insurance-text-light">
                      By clicking, I agree to{" "}
                      <Link to={"/termsandcondition"}>
                        *terms &amp; conditions
                      </Link>{" "}
                      and <Link to={"/privacy-policy"}>privacy policy</Link>.
                    </p>
                  </div>
                  {/* </form> */}
                </form>
              </div>
            </div>
    </>
  )
}

export default TermFormtwo