import React from 'react';
import FAQ from '../AllPageComponents/FAQ/FAQ';
import PartnerCompany from '../AllPageComponents/PartnerCompany/PartnerCompany';
import BikeInsuranceForm from './BikeInsuranceForm';
import FastImage from "../FastImage";
import { auto } from '@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core'

const BikeComprehensive = () => {

  return (
   <>
   <section className="car-insurance insurance-all insurance-background">
  <div className="container">
    <div className="row insurance-row-one" style={{justifyContent:"space-around"}}>
      <div className="col-sm-12 col-md-4 col-lg-4 mian-one">
        <div className="car-insurance-first-inner">
          <h4> Bike Insurance Comprehencive</h4>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer too
          </p>
        </div>
      </div>
      <div className="col-sm-12 col-md-4 col-lg-4 side-image">
      <FastImage
            url={"Website%20Img/BikeInsurence.png?updatedAt=1718779269495"}
            width={auto}
            height={auto}
            alt={"ca-img"}
            />
      </div>
      <style
        dangerouslySetInnerHTML={{
          __html: "\n    .hide{\n        display: none;\n    }\n"
        }}
      />
      <BikeInsuranceForm/>
   
    
    </div>
  </div>
</section>
<section className="half-space-block">
  <div className="container">
    <div className="row insurance-row-two-less-farage">
      <div className="col-sm-12 col-md-12 col-lg-12">
        <div className="insurance-less-farage">
          <div className="simple-text-block">
            <h5>What Is Cashless Bike Insurance Policy?</h5>
            <p>
              In motor insurance to make the entire claim process more
              hassle-free insurance providers offer cashless compensation for
              repairs. This is done by insurance companies that have tie-ups
              with certain garages (bike repair shops). At these partner
              garages, an insurance company settles a bike insurance claim in a
              cashless manner, i.e, it settles the repair bill directly with the
              garage. Thus, the policyholder is not required to pay anything to
              the garage for the repairs of their bike except their own
              liabilities.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section className="full-space-block">
  <div className="container">
    <div className="row full-space-block-row">
      <div className="col-sm-12 col-md-12 col-lg-12">
        <div className="insurance-less-farage">
          <div className="simple-text-block">
            <p>
              In case of a mishap, you can inform your bike insurance company
              and get your bike transported directly to one of these cashless
              garages. Here the bike will be inspected and repaired and the cost
              communicated to the insurance provider all without bothering you
              about it. Additionally, these places ensure a high quality of
              service as well as spare parts which ensures you have one less
              thing to think about.
            </p>
            <h5>Benefits of Cashless Bike Insurance Policy</h5>
            <p>
              When you get your four-wheeler repaired at a cashless garage, you
              enjoy the following benefits:
            </p>
            <h6>1. Hassle-free Experience</h6>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer too Lorem Ipsum is
              simply dummy text of the printing and typesetting industry. Lorem
              Ipsum has been the industry's standard dummy text ever since the
              1500s, when an unknown printer too
            </p>
            <h6>2. Priority in Service</h6>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer too Lorem Ipsum is
              simply dummy text of the printing and typesetting industry. Lorem
              Ipsum has been the industry's standard dummy text ever since the
              1500s, when an unknown printer too
            </p>
            <h6>3. Quick Claim Settlement</h6>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer too Lorem Ipsum is
              simply dummy text of the printing and typesetting industry. Lorem
              Ipsum has been the industry's standard dummy text ever since the
              1500s, when an unknown printer too
            </p>
            <h6>4. Guaranteed Quality Service</h6>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer too Lorem Ipsum is
              simply dummy text of the printing and typesetting industry. Lorem
              Ipsum has been the industry's standard dummy text ever since the
              1500s, when an unknown printer too
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<PartnerCompany/>
<FAQ/>
   </>
  )
}

export default BikeComprehensive