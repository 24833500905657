import React from 'react';
import { Link, useNavigate } from "react-router-dom";
import arrow from '../TermInsurence/TermImages/arrow-animation.gif'


const TermInsuranceForm = () => {

  const FullName = localStorage.getItem('ProposalName')
  const PhoneNumber = localStorage.getItem('Proposalphonenumber')

const nav = useNavigate()



// onclicking submit button this function is called 
    const handleButtonClick = async () => {
      nav('/term-insurance/termstep1')
      };

  return (
    <>

    <div className="col-sm-12 col-md-4 col-lg-4 popup-main">
              <div className="form-main">
                <form
                  id="insurance_form_get_plan"
                  method="POST"
                 
                  noValidate="novalidate"
                >
                
                  <div className="align-items-center form-input main-boxxes check-boxx">
                    <h6 className='termheading'>
                      Secure Family With 1 Crore Cover Starting At ₹16/Day*
                    </h6>
             

            

      
          
           <button className='qutationbutton' onClick={handleButtonClick}>View Planes      <img src={arrow} alt="" /></button>








                    <div className="termsconditiondiv">

<p className="checkboxterm" >
<input type="checkbox" name="" id="" checked/> &nbsp;
                      By clicking, I agree to{" "}
                      <Link to={"/termsandcondition"}>
                        *terms &amp; conditions
                      </Link>{" "}
                      and <Link to={"/privacy-policy"}>privacy policy</Link>.
                    </p>
                    </div>
                  
              
                   </div>
                </form>
              </div>
            </div>
    </>
  )
}

export default TermInsuranceForm