import React,{useEffect} from 'react'
import './privecypolicy.css'
import { Helmet } from 'react-helmet-async';
const Privacypolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);
  return (
<div>
<Helmet>
        <title>Privacypolicy</title>
        {/* <meta name="description" content="A brief description of your page for SEO." /> */}
        {/* <meta name="keywords" content="best term insurance in india , buy term life insurance, compare and purchase term insurance" /> */}
      </Helmet>
    {/*Stection start */}
    <div className="margintermsnadpolicy"></div>
<div className="privecy">
<h1>Privecy <span style={{color:'red'}}>Policy*</span></h1>
<p>Guide2 Protect insurence Web Aggregator PVT.LTD</p>
</div>
<div className="privecyfooter">
<div className="undertext">


<div className="fixwidthDivtext">

<p className="normaltext">By registering on the website <a href="https://www.beema1.com">beema1.com </a>you authorize us, our affiliates & our associate partners to connect with you via email, phone call, or SMS and propose their services for the product you have opted for, imparting knowledge on the products, offering promotional discounts running on website & the offers are provided by the interrelated third parties, for which reasons, also recognizable as web aggregation, personally identifiable data, may be collected as detailed below.
Even though also you have listed yourself under DND DNC or NCPR service, you still authorize and empower us to give you a call from Beema1.com/Guide2protect Insurance Web Aggregator for the aforementioned intents and purposes of your registration with Beema1. This Privacy Policy covers Beema1’s treatment of personally identifiable information that Beema1 contains when you are on the website of Beema1, and when you use the services of Beema1.
This policy also covers Beema1’s treatment of any personally distinctive and specifically identifiable information that Beema1’s business partners share with Beema1. This policy does not apply to the practices of companies that Beema1 does not own or control or to people that Beema1 does not employ or regulate. Beema1 collects personally recognizable information when you register for a Beema1 account, when you use certain products or services from Beema1 when you visit the Beema1 website pages, and when entering promotions. Beema1 may also receive personally identifiable information from our business partners. When you register with Beema1, we ask you to enter your first name, last name, state, city, email address, birth date, and gender. Once you register with your personal information with Beema1 and sign in to our services, you are not anonymous or unidentified to us. Also, during registration, you may be requested to register your mobile number and email ID, pager, or other devices to receive text messages, notifications, and other services to your wireless device. By registration, you authorize us to send SMS/email alerts to you for your login details and any other service requirements or some advertising messages/emails from us
Being faithful and trustworthy is a major and significant angle of any relationship. With our clients, we are determined to build a great relationship and a long journey laying a foundation with a strong, systematic base, and a supportive backbone. Hence, your privacy and security are of ultimate importance to us.
We practice to ensure that your personal information is well-protected and remains confide</p>


<p className='normaltext'>1. Only subject-specific and content-related information is collected from our customers. Information that is exclusively needed for an insurance policy or any other financial services or financial products we offer through our online platform.</p>
<p className='normaltext'>2. Abiding by the legal guidelines, we are sometimes required to share information or data with Government bodies or Government regulators. This is practised under appropriate supervision and strict authority.</p>
<p className='normaltext'>3. At times, your information could be used for the improvement and betterment of our services and product offerings to our customers.</p>
<p className='normaltext'>4. Our security systems are thoughtfully planned, technically sound,  and has been designed in such a way that helps in preventing unlawful or inappropriate access.</p>
</div>







<div className="fixwidthDivtext">
<h6>Gathering Information:</h6>
<p className="normaltext">1. At <a href="https://www.beema1.com">beema1.com </a>, we collect information through the online forms available on our company website or via mailing campaigns and telephonic conversations. The collected information is stored in a secure physical/electronic environment at all times</p>
<p className="normaltext">2. The use of cookies on your visit to our website may be registered and recorded for analysing the number of visitors to the site and general patterns of usage. Some of the information will be collected by "cookies" which are small bits of information that are automatically stored on a person's web browser in their computers or laptops. If you ever wish to disable these cookies, you may do so by changing or altering your browser settings.</p>
</div>


<div className="fixwidthDivtext">
<h6>Storing Information:</h6>
<p className="normaltext">1.  <a href="https://www.beema1.com">beema1.com </a> uses 2048-bit SSL encryption.</p>
<p className="normaltext">2. Our servers are protected by firewalls to prevent any illegal or accidental access.</p>
<p className="normaltext">3.  Physical, electronic, and managerial measures are intact to safeguard and secure all your personal information. We do train our employees with sound skills to handle customer information providing the highest levels of security.      
</p>
</div>



<div className="fixwidthDivtext">

<h6>Sharing Information:</h6>
<p className="normaltext">1. Information will be shared with our partners to fully serve your needs.</p>
<p className="normaltext">2. Your contact details will be used by us and our partners to connect with you.</p>
<p className="normaltext">3. We may also need to contact you for other available products and services provided by <a href="https://www.beema1.com">beema1.com </a> We may also share information that you provide with our trusted partners who may also contact you to offer additional products and services that may be of interest to you or may be useful to you and your family.</p>
<p className="normaltext">4. Personal information that is not needed by partners will not be shared.

For further clarifications, please write to us at admin@beema1.com</p>
</div>





</div>
</div>
</div>
  )
}

export default Privacypolicy