import React from 'react';
import FastImage from '../../FastImage';
import { auto } from '@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core'
import { useNavigate } from 'react-router-dom';
import './ErroBiundry.css'

const ErrorDisplay = () => {

 const nav = useNavigate()




  const styles = {
    container: {
      fontFamily: 'Arial, sans-serif',
      textAlign: 'center',

      padding: '20px',


      margin: '20px auto',
      maxWidth: '600px',
      '@media (max-width: 768px)': {
        padding: '10px',
        maxWidth: '90%',
      },
    },

    errorMessage: {
      fontSize: '1.5em',
      color: '#2959B8',
      fontFamily:"Poppins",
      marginBottom: '20px',
      '@media (max-width: 768px)': {
        fontSize: '1.2em',
        marginBottom: '10px',
      },
    },
    message: {
      fontSize: '1.2em',
      color: 'gray',
      '@media (max-width: 768px)': {
        fontSize: '1em',
      },
    },
    image: {
      width: '100%',
      height: 'auto',
      maxWidth: '400px',
      marginBottom: '20px',
      '@media (max-width: 768px)': {
        maxWidth: '300px',
      },
    },
  };

  return (
    <div style={styles.container}>
       <FastImage
            url={"Website%20Img/404%20Error%20Page%20not%20Found%20with%20people%20connecting%20a%20plug.190924929b0402405b68.gif?updatedAt=1718860167824"}
            width={auto}
            height={auto}
            alt={"ca-img"}
            />
      <div style={styles.errorMessage}>OOPs Somthing Whent Wrong !!</div>
      <div style={styles.message}>We Are Working on it</div>
      <button  className='errorboundrybtn' onClick={nav('/')}>Back To Home</button>
    </div>
  );
};

export default ErrorDisplay;
