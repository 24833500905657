import React from 'react'
import './BeemaAdvantage.css'
import FastImage from '../../FastImage'
import { auto } from '@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core'



const BeemaAdvantage = () => {
const ClameArray = [
    {
        id:0,
        name: "One of the best Prices",
        discription:"Guaranteed",
        img: 'Website%20Img/Beemaadvantage1.png?updatedAt=1718792852909'
    },{
        id: 1,
        name:"Unbiased Advice",
        discription:"Keeping customers first",
        img: 'Website%20Img/Beemaadvantage2.png?updatedAt=1718792870048'

    },{
        id: 2,
        name:'100% Reliable',
        discription:"Regulated by IRDAI"  ,
        img: 'Website%20Img/Beemaadvantage3.png?updatedAt=1718792889299'
    },{
        id:3,
        name:"Claims Support",
        discription:"Made stress-free"  ,
        img: 'Website%20Img/Beemaadvantage4.png?updatedAt=1718792908550'
    },{
        id:4,
        name:"Happy to Help",
        discription:"Every day of the week"  ,
        img: 'Website%20Img/Beemaadvantage5.png?updatedAt=1718792954309'
    }
]





  return (
    <>
<div className="fixwidthDivtext">
<div className="container ">
<div className="tile-text-with-text-shadow">
<h2>Why Beema1.com ?</h2>
</div>

<div className="textheading commonone">
    <h4 className='theheadingcolor'>Why Beema<span className="onespan">1</span>.com How We Work ?</h4>
    <div className="yellowline"></div>
<p className='textformat'>When you buy insurance from us, you get more than just financial safety. You also get: our promise of simplifying complex insurance terms and conditions, quick stress-free claims, instant quotes from top insurers and being present for you in the toughest of times.</p>
</div>
<a data-toggle="modal" data-target=".bd-example-modal-lg" className='knowmore'>Know More</a>


<div className="advantages">

{
    ClameArray.map((data)=>{
        return(
            <div className="advantagecard" key={data.id}>
        <FastImage
            url={data.img}
            width={auto}
            height={auto}
            alt={"ca-img"}
            />
<h6>
{data.name}</h6>
<p>{data.discription}</p>
</div>
        )
    })
}

</div>




</div>
{/* Motal Information start Heasr */}
<div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
<div class="modal-dialog modal-lg ">
 <div class="modal-content extrapadding">
<div className="buttonunderduvs">
 <button type="button" class="close" data-dismiss="modal" aria-label="Close">
 <span aria-hidden="true">&times;</span></button>
</div>
    <h4 className='theheadingcolor'>Welcome to Beema1.com</h4>
    <br />


 
        <p className='textformat'>Beema1.com is a leading insurance company located in the heart of Kolkata City Center. We specialize in providing comprehensive insurance solutions to individuals and businesses, catering to their diverse needs and requirements
At Beema1.com, we collaborate with several renowned insurance companies to offer a wide range of insurance products and services. Some of our esteemed partners include:</p>


<div className="container">
    <div className="row">
    <div className="col-md-6 d-flex justify-content-center align-items-center">
     <p style={{textAlign:'justify'}}>
        <span className='qustions'>How we Diffrante then others ?</span>
        <div className="yellowline"></div>
        Whether you're looking for health insurance, life insurance, property insurance, or any other type of insurance coverage, Beema1.com is your trusted partner. Our team of experienced professionals is dedicated to providing personalized assistance and guidance to ensure that you make informed decisions and secure the best insurance policies.
     </p>
        </div>
        <div className="col-md-6 d-flex justify-content-center align-items-center">
        <FastImage url='/Website%20Img/Tour%20guide-pana.png?updatedAt=1722340631338' width={auto} height={auto} className='underimg'/>
        </div>
    </div>
</div>
        
     
      




          
         
           
    </div>
  </div>
</div>
</div>


    </>
  )
}

export default BeemaAdvantage