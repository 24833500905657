import React, { useEffect, useRef, useState } from "react";
import StepSlider from "../../commonComponents/StepSlider";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./step2.css";
import { useDispatch, useSelector } from "react-redux";
import { updateInsuredData } from "../../redux/Slice";
import relation from "../../../relation.json";
import { useSpring, animated } from "@react-spring/web";
import { GiUmbrella } from "react-icons/gi";
import { selectProposal } from "../../redux/Slice";
// material Component
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Helmet } from "react-helmet-async";

const HealthStep2 = () => {
  const dispatch = useDispatch();
  const [familyData, setFamilyData] = useState([]);
  const [selectedAge, setSelectedAge] = useState(
    Array(familyData.length).fill("")
  );
  const [isSelectOpen, setIsSelectOpen] = useState({});
  const [insuredRelation, setInsuredRelation] = useState([]);
  const navigate = useNavigate();
  const selectRef = useRef(null);
  const ages = Array.from({ length: 121 }, (_, i) => i);
  const selfWifeAges = Array.from({ length: 82 }, (_, i) => i + 18);
  const parentAges = Array.from({ length: 60 }, (_, i) => i + 40);
  const sonDaughterAges = Array.from({ length: 30 }, (_, i) =>
    i === 0 ? "<91" : i
  );
  const grandparentAges = Array.from({ length: 46 }, (_, i) => i + 54);

  const proposalData = useSelector(selectProposal);

  const personType = localStorage.getItem("selectedType");

  useEffect(() => {
    //console.log(proposalData);

    const retrievedInsuredDetail = localStorage.getItem("InsuredDetail");
    if (retrievedInsuredDetail) {
      const parsedInsuredDetail = JSON.parse(retrievedInsuredDetail);
      setFamilyData(parsedInsuredDetail);
      // //console.log(familyData)
    }

    // Retrieve data from local storage
    const storedInsuredDetail = localStorage.getItem("InsuredDetail");
    if (storedInsuredDetail) {
      const parsedInsuredDetail = JSON.parse(storedInsuredDetail);
      const storedInsuredRelation = parsedInsuredDetail.map(
        (detail) => detail.InsuredRelation
      );
      const storedInsuredAge = parsedInsuredDetail.map((detail) =>
        String(detail.InsuredAge)
      );
      setInsuredRelation(storedInsuredRelation);

      setSelectedAge(storedInsuredAge);
    }

    //use for toggle
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleAgeChange = (index, event) => {
    const { value } = event.target;
    dispatch(
      updateInsuredData({
        index: index,
        data: [{ key: "InsuredAge", value: value }],
      })
    );
    const updatedInsuredRelation = [...insuredRelation];
    updatedInsuredRelation[index] = familyData[index].InsuredRelation;
    // //console.log(value)
    localStorage.setItem("ageadded", value);
    setSelectedAge((prevSelectedAge) => {
      const updatedSelectedAge = [...prevSelectedAge];
      updatedSelectedAge[index] = value;
      return updatedSelectedAge;
    });
    setInsuredRelation(updatedInsuredRelation);
  };

  const toggleSelect = (index) => {
    setIsSelectOpen((prevIsSelectOpen) => ({
      ...prevIsSelectOpen,
      [index]: !prevIsSelectOpen[index],
    }));
  };

  const handleClickOutside = (e) => {
    if (selectRef.current && !selectRef.current.contains(e.target)) {
      setIsSelectOpen(false);
    }
  };

  const handleSetValue = () => {
    const isAllAgesSelected = selectedAge.every((age) => age !== "");
    // Please select the age for all family members to proceed.
    if (!isAllAgesSelected) {
      toast.error("Please select the age for all family members to proceed.", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return; // Prevent further execution if ages are not selected
    }

    const InsuredDetail = selectedAge.map((age, index) => ({
      InsuredRelation: insuredRelation[index],
      InsuredAge: age ? parseInt(age) : "",
    }));

    localStorage.setItem("InsuredDetail", JSON.stringify(InsuredDetail));
    navigate("/healthStep3");
  };

  // Map Function

  const getPersonImage = (persion , typedata) => {
    const familydata = relation.find((value) =>(value.level == persion )&&(value.type == typedata)

    );
    return familydata["image1"];
  };

  //   Animation
  const [isVisible, setIsVisible] = React.useState(false);

  const animations = useSpring({
    opacity: isVisible ? 1 : 0,
    transform: isVisible ? "translateY(0%)" : "translateY(50%)",
    config: { mass: 1, tension: 100, friction: 20, duration: 500 },
  });

  useEffect(() => {
    setIsVisible(true);
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
       <Helmet>
        <title>HealthStep2</title>
        {/* <meta name="description" content="A brief description of your page for SEO." /> */}
        {/* <meta name="keywords" content="best term insurance in india , buy term life insurance, compare and purchase term insurance" /> */}
      </Helmet>
      <h1
        style={{
          textAlign: "center",
          padding: "20px",
          color: "#0055A5",
          marginTop: 20,
        }}
      >
        We Offer You Best Insurance Plan{" "}
        <span style={{ color: "#E50318" }}>In Your </span>
        <span style={{ color: "#FD9202" }}>Budget</span>
        <sup>**</sup>
      </h1>

      <StepSlider activeStep={2} />

      <animated.div style={animations}>
        <div className="health-insurance-view-plan">
          <div className="modal-body">
            <div className="modal-content">
              {/* Tool tip bar Starty Hear */}
              {/* Tool tip bar End Hear */}

              <div className="modle-title">
                <h6 className="selectmemberh6">
                  Select Members You Want To Insure
                </h6>
              </div>

              <div className="container tooltipposition">
                <div className="tooltips">
                  <div>
                    <div className="toolchild">
                      <span className="umbrellaspan">
                        <GiUmbrella />
                      </span>
                      <h6>Best Discount Only for you in limited Price </h6>
                    </div>
                  </div>
                </div>

                <div className="row ">
                  <div className="col-md-8" style={{ margin: "0 auto" }}>
                    {familyData.map((item, index) => (
                      <div key={index} className="row agerow">
                        <div className="col-xs-9 helthtwoimagediv">
                          <img
                            src={getPersonImage(item.InsuredRelation , personType)}
                            alt=""
                          />
                          <span className="font20">
                            {item.InsuredRelation}{" "}
                          </span>
                        </div>
                        <div className="col-xs-3 text-right selectdiv">
                          <div
                            style={{
                              position: "relative",
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                            ref={selectRef}
                          >
                            
                          

<FormControl sx={{ m: 1, minWidth: 120 }}>
  <Select
    className="selectvalues"
    id={`age.${item}`}
    name={`age.${item}`}
    value={selectedAge[index] || ""} // Ensures placeholder shows when no value is selected
    onChange={(event) => handleAgeChange(index, event)}
    onClick={() => toggleSelect(index)}
    onBlur={() =>
      setIsSelectOpen({
        ...isSelectOpen,
        [index]: false,
      })
    }
    sx={{
      boxShadow: "none",
      ".MuiOutlinedInput-notchedOutline": { border: 0 },
      "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
        {
          border: 0,
        },
      "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
        {
          border: 0,
        },
    }}
    displayEmpty // Important to ensure the placeholder can be shown
  >
    <MenuItem value="" disabled>Age</MenuItem> {/* Placeholder with disabled */}
    
    {["Self", "Wife", "Husband", "Brother", "Sister"].includes(item.InsuredRelation)
      ? selfWifeAges.map((age) => (
          <MenuItem key={age} value={age}>
            {age} Years
          </MenuItem>
        ))
      : ["Father", "Mother", "Uncle", "Aunt", "Father In Law", "Mother In Law"].includes(item.InsuredRelation)
      ? parentAges.map((age) => (
          <MenuItem key={age} value={age}>
            {age} Years
          </MenuItem>
        ))
      : ["Son", "Daughter", "Grand Daughter", "Grand Son"].includes(item.InsuredRelation)
      ? sonDaughterAges.map((age) => (
          <MenuItem key={age} value={age === "<91" ? 0 : age}>
            {age} {age === "<91" ? "Days" : "Years"}
          </MenuItem>
        ))
      : ["Grand Father", "Grand Mother"].includes(item.InsuredRelation)
      ? grandparentAges.map((age) => (
          <MenuItem key={age} value={age}>
            {age} Years
          </MenuItem>
        ))
      : ages.map((age) => (
          <MenuItem key={age} value={age}>
            {age} Years
          </MenuItem>
        ))}
  </Select>
</FormControl>




                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="buttons-container">
          <button className="continuebutton myBtn" onClick={handleSetValue}>
            Continue
          </button>
        </div>
      </animated.div>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
};

export default HealthStep2;
