import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { useState } from "react";
import { useEffect } from "react";
import Loader from "../AllPageComponents/Loader";
import { FaArrowRotateLeft } from "react-icons/fa6";

const CarInsuranceForm = () => {
  const FullName = localStorage.getItem("ProposalName");
  const PhoneNumber = localStorage.getItem("Proposalphonenumber");
  const PinCode = localStorage.getItem("PinCode");


  const [ValidNumber, setValidNumber] = useState('')
  const [ValidPin, setValidPin] = useState('')
  const [ValidateName, setValidateName] = useState('')

useEffect(()=>{
  if(PinCode){
    setValidPin(PinCode)
  }
  if(PhoneNumber){
    setValidNumber(PhoneNumber)
  }
  if(FullName){
    setValidateName(FullName)
  }

},[PhoneNumber,PinCode])




  const [formData, setFormData] = useState({
    car_number: "",
    phone_no: "" || ValidNumber,
    pin: "" || ValidPin,
    car_type: "fourwheeler",
    name: "" || ValidateName,
  });

  const [nameError, setNameError] = useState("");
  const [carNumberError, setCarNumberError] = useState("");
  const [phoneNumError, setPhoneNumError] = useState("");
  const [pinError, setPinError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const carNumber = formData.car_number;

  const [generatedCaptcha, setGeneratedCaptcha] = useState("");
  const [userInput, setUserInput] = useState("");
  const [resultMessage, setResultMessage] = useState("");

  // Function to generate a random alphanumeric string
  const generateRandomString = (length) => {
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
  };

  // Function to generate the CAPTCHA
  const generateCaptcha = () => {
    const captchaText = generateRandomString(6); // You can adjust the length as needed
    setGeneratedCaptcha(captchaText);
    setResultMessage("");
  };

  useEffect(() => {
    generateCaptcha();
  }, []);

  const isValidIndianPhoneNumber = (phoneNumber) => {
    const indianPhoneNumberRegex = /^[6-9]\d{9}$/;
    return indianPhoneNumberRegex.test(phoneNumber);
  };

  const validateFullName = (event) => {
    let isValid = true;

    if (event.target.value === "") {
      setNameError("Full name is required");
      isValid = false;
    } else {
      setNameError("");
    }
  };

  const validateCarNum = (event) => {
    let isValid = true;

    if (event.target.value === "") {
      setCarNumberError("Car number is required");
      isValid = false;
    } else {
      setCarNumberError("");
    }
  };

  const validatePhoneNumber = (event) => {
    let isValid = true;
    if (event.target.value === "") {
      setPhoneNumError("Phone number is required");
      isValid = false;
    } else if (!isValidIndianPhoneNumber(event.target.value)) {
      setPhoneNumError("Please enter a valid Indian phone number");
      isValid = false;
    } else if (event.target.value.length !== 10) {
      setPhoneNumError("Phone number must be 10 digits");
      isValid = false;
    } else {
      setPhoneNumError("");
    }
  };

  const validatePinCode = (event) => {
    let isValid = true;

    if (event.target.value === "") {
      setPinError("Pin code is required");
      isValid = false;
    } else {
      setPinError("");
    }
  };

  const validateCarNumber = () => {
    const carValidateRegex = /^[A-Z]{2}[0-9]{2}[A-Z]{2}[0-9]{4}$/;
    const isValid = carNumber.length === 10 && carValidateRegex.test(carNumber);
    if (isValid) {
      //console.log("Valid car number");
    } else {
      //console.log("Invalid car number");
    }
    return isValid;
  };
  useEffect(() => {
    validateCarNumber();
  }, [carNumber]);
  const isValid = validateCarNumber();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // form validations ends here -------------------------------

  const handleSubmit = async (event) => {
    event.preventDefault();

    let isValid = true;

    if (userInput !== generatedCaptcha) {
      setUserInput("");
      Swal.fire({
        title: "Error!",
        text: "Wrong Captcha !", // Changed punctuation for better readability
        icon: "error",
        confirmButtonText: "Back",
      });
      generateCaptcha();

      return;
    }

    if (formData.name === "") {
      setNameError("Full name is required");
      isValid = false;
    } else {
      setNameError("");
    }

    if (formData.car_number === "") {
      setCarNumberError("Car number is required");
      isValid = false;
    } else {
      setCarNumberError("");
    }

    if (formData.phone_no === "") {
      setPhoneNumError("Phone number is required");
      isValid = false;
    } else if (!isValidIndianPhoneNumber(formData.phone_no)) {
      setPhoneNumError("Please enter a valid Indian phone number");
      isValid = false;
    } else if (formData.phone_no.length !== 10) {
      setPhoneNumError("Phone number must be 10 digits");
      isValid = false;
    } else {
      setPhoneNumError("");
    }

    if (formData.pin === "") {
      setPinError("Pin code is required");
      isValid = false;
    } else {
      setPinError("");
    }

    if (
      !(
        formData.car_number === "" ||
        formData.name === "" ||
        formData.phone_no === "" ||
        formData.pin === ""
      )
    ) {
      try {
        setIsLoading(true);
        // Log the entire form data
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}cms/car_enquiry`,
          formData,
        );
        //console.log("Data Sent", response);
        if (response.status === 200) {
          setIsLoading(false);
          Swal.fire({
            title: "Success",
            text: "Your Form is Successfully Submitted",
            icon: "success",
            confirmButtonText: "Back",
          });
          generateCaptcha();
          // Reset form data here
          setFormData({
            car_number: "",
            phone_no: "",
            pin: "",
            car_type: "fourwheeler",
            name: "",
          });
        } else {
          Swal.fire({
            title: "Error!",
            text: "Somthing Whent Wrong", // Changed punctuation for better readability
            icon: "error",
            confirmButtonText: "Back",
          });
        }

        // Add any further logic here based on the response
      } catch (err) {
        Swal.fire({
          title: "Error!",
          text: "Somthing Whent Wrong", // Changed punctuation for better readability
          icon: "error",
          confirmButtonText: "Back",
        });
      }
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className="col-sm-12 col-md-4 col-lg-4 popup-main">
        <div className="form-main">
          <form
            id="insurance_form_get_plan"
            method="POST"
            action="javscript:void(0);"
          >
            <div className="d-flex align-items-center form-input">
              <h6>
                Secure Your Car &amp; Save upto 75%* on Plans offered by
                Insurers
              </h6>

              <form onSubmit={handleSubmit} className="carformsubmited">
                <div className="form-inner-input form-group">
          
<div>


                  <input
                    type="text"
                    name="car_number"
                    value={formData.car_number}
                    onChange={(event) => {
                      handleChange(event);
                      validateCarNum(event);
                    }}
                    className="form-control"
                    placeholder="Ex. GJ 01 AB 3263"
                    style={{ margin: "10px 0" }}
                    required
                    max={10}
                    min={8}
                  />

                  <span
                   style={{color:'red',fontSize: '9pt', position:'relative',bottom:'5px'}}
                    hidden={
                      carNumber === "" ? true : false || isValid ? true : false
                    }
                  >
                    {isValid
                      ? null
                      : "Car Number is Not Valid !! Enter Valid one"}
                  </span>
                  {carNumberError && (
                    <span  style={{color:'red',fontSize: '9pt', position:'relative',bottom:'5px'}} >{carNumberError}</span>
                  )}
</div>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={(event) => {
                      handleChange(event);
                      validateFullName(event);
                    }}
                    className="form-control"
                    placeholder="Full Name"
                    required
                    max={10}
                    min={8}
                  />
                  {nameError && <span  style={{color:'red',fontSize: '9pt', position:'relative',bottom:'-3px'}}>{nameError}</span>}
                  <input
                    type="text"
                    name="phone_no"
                    value={formData.phone_no}
                    onChange={(event) => {
                      handleChange(event);
                      validatePhoneNumber(event);
                    }}
                    className="form-control"
                    placeholder="Mobile Number"
                    style={{ marginBottom: 8, marginTop: 8 }}
                    required
                  />
                  {phoneNumError && (
                    <span  style={{color:'red',fontSize: '9pt', position:'relative',bottom:'5px'}}>{phoneNumError}</span>
                  )}
                  <input
                    type="text"
                    name="pin"
                    value={formData.pin}
                    onChange={(event) => {
                      handleChange(event);
                      validatePinCode(event);
                    }}
                    className="form-control"
                    placeholder="Zip/Pin Code"
                    required
                  />
                  {pinError && <span  style={{color:'red',fontSize: '9pt', position:'relative',bottom:'-3px'}}>{pinError}</span>}

                  <label
                    style={{color:'red',fontSize: '9pt', position:'relative',bottom:'5px'}}
                   
                  />
                </div>

                <div className="containerdivcaptcha">
                  <div className="captchaSection">
                    <div id="captcha" className="genarateCaptcha">
                      {generatedCaptcha}
                    </div>
                    <button
                      onClick={generateCaptcha}
                      style={{ border: "none" }}
                    >
                      <FaArrowRotateLeft />
                    </button>
                  </div>

                  <div id="inputSection_captcha">
                    <input
                      type="text"
                      value={userInput}
                      onChange={(e) => setUserInput(e.target.value)}
                      placeholder="Enter CAPTCHA"
                      className="Captachcode"
                    />

                    <p id="result">{resultMessage}</p>
                  </div>
                </div>

                <div className="blue-btn">
                  <button onClick={handleSubmit} className="form-btn">
                    Get Quotation
                  </button>
                </div>
              </form>

              {/* <p className="insurance-text">
                Don't know your car number?{" "}
                <a href="javascript:void(0)">Click Here</a>
              </p> */}
              <p className="insurance-text-light">
                By clicking, I agree to{" "}
                <Link to={"/termsandcondition"}>*terms &amp; conditions</Link>{" "}
                and <Link to={"/privacy-policy"}>privacy policy</Link>.
              </p>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default CarInsuranceForm;
