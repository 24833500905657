import React,{useEffect} from 'react'
import '../Calculator-Charat/Calculatorchart.css'
import { Link } from 'react-router-dom'
import Redirect from '../../../AllPageComponents/Rederict'
import FastImage from "../../../FastImage";
import { auto } from '@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core'
import { Helmet } from 'react-helmet-async';

const Calculatorchart = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

const Data = [
    {
        id:0,
        name:"Sip",
        discription:"A SIP calculator estimates future investment value based on factors like amount, duration, and expected return rate. It aids investors in planning their financial goals.",
        Link:"sip-calculator",
        img: "Website%20Img/sip.9a7e31c6f7f3e0a79d40.png?updatedAt=1718716335859"
    },
    {
        id: 1,
        name:'Lumpsum',
        discription:"Investments in Mutual Funds can be broadly classified into two types- lumpsum and SIP. A lumpsum investment ",
        Link:"lumpsum-calculator",
        img: "Website%20Img/Pigg.png?updatedAt=1718716822741"
    },
    {
        id: 2,
        name:'BMI',
        discription:"A BMI calculator assesses body fat using height and weight, indicating if someone is underweight, normal weight, overweight, or obese, aiding in understanding associated health risks. ",
        Link:"bmi-calculator",
        img: "Website%20Img/bmi.41f21b83db1ff8ab049d.jpg?updatedAt=1718716905323"
    },
    {
        id:3,
        name:"EMI",
        discription:"An EMI calculator helps borrowers estimate their monthly loan repayments based on factors like principal amount, interest rate, and tenure, facilitating financial planning.",
        Link:"emi-calculator",
        img: 'Website%20Img/Lumpsum.210a60013b525f0ab708.jpg?updatedAt=1718716978926'
    },
    {
        id: 4,
        name:'Car-loan',
        discription:"Investments in Mutual Funds can be broadly classified into two types- lumpsum and SIP. A lumpsum investment ",
        Link:"car-loan-calculator",
        img: "Website%20Img/car.jpg?updatedAt=1718717055472"
    },
    {
        id: 5,
        name:'Home- Loan',
        discription:"A home loan calculator helps you figure out how much you'll pay each month for your mortgage.It considers things like the loan amount, interest rate, and how long you'll take to repay ",
        Link:"home-loan-calculator",
        img: 'Website%20Img/home.1f66b12cb36fda98bf41.jpg?updatedAt=1718717120039'
    },
    // {
    //     id: 6,
    //     name:'SSY-Calculator',
    //     discription:"Use the Sukanya Samriddhi Yojana Calculator to plan your long-term investments wisely. It helps estimate yearly investments, interest growth, and the maturity amount, aiding",
    //     Link:"ssy-calculator",
    //     img: "Website%20Img/girl.671a28966cadbf9730d5.jpg?updatedAt=1718717213360"
    // },
    {
        id: 7,
        name:'FD',
        discription:"A loan’s tenure may get reduced or extended. Subsequently, there will be an increase or a decrease in the EMI amount as well. ",
        Link:"fd-calculator",
        img: 'Website%20Img/FD.bae8728da99b916caa5b.png?updatedAt=1718717272230'
    },
    {
        id: 8,
        name:'RD',
        discription:"A loan’s tenure may get reduced or extended. Subsequently, there will be an increase or a decrease in the EMI amount as well. ",
        Link:"rd-calculator",
        img: "Website%20Img/RD.af25a78d72f630edf2dd.png?updatedAt=1718717337062"
    },
    {
        id: 9,
        name:'Simple interest',
        discription:"A Simple Interest calculator computes interest on a principal amount at a fixed rate over a specified time, offering a quick way to find total interest without compounding.",
        Link:"simple-interest-calculator",
        img: "Website%20Img/sic.2ffd678cdd30151b43d3.jpg?updatedAt=1718717409036"
    },

    // {
    //     id: 10,
    //     name:'SWP',
    //     discription:"An SWP calculator helps estimate regular withdrawals from an investment fund, aiding investors in managing their cash flow needs effectively",
    //     Link:"swp-calculator",
    //     img: "Website%20Img/SWP.c7f282941173bb441f9d.jpg?updatedAt=1718717492672"
    // },
    {
        id: 11,
        name:'NPS',
        discription:"A loan’s tenure may get reduced or extended. Subsequently, there will be an increase or a decrease in the EMI amount as well. ",
        Link:"nps-calculator",
        img: "Website%20Img/penson.f0574e6fbc69b3e05551.jpg?updatedAt=1718717562719"
    },{
        id: 12,
        name:'Marrige',
        discription:"A loan’s tenure may get reduced or extended. Subsequently, there will be an increase or a decrease in the EMI amount as well. ",
        Link:"child-marrige-calculator",
        img: "Website%20Img/marrige.16e2ce5869e9b1dccc3e.png?updatedAt=1718717620009"
    },
    {
        id: 13,
        name:'Pension',
        discription:"A loan’s tenure may get reduced or extended. Subsequently, there will be an increase or a decrease in the EMI amount as well. ",
        Link:"pension-calculator",
        img: "Website%20Img/Pensonblu.png?updatedAt=1718773210050"
    },{
        
        id: 14,
        name:'Human Life',
        discription:"A loan’s tenure may get reduced or extended. Subsequently, there will be an increase or a decrease in the EMI amount as well. ",
        Link:"human-life-vlue-calculator",
        img: "Website%20Img/Hlv.ec3a3671038e44bbc4ed.png?updatedAt=1718773285236"
    
    },
    {
        id: 15,
        name:'Child Education',
        discription:"A loan’s tenure may get reduced or extended. Subsequently, there will be an increase or a decrease in the EMI amount as well. ",
        Link:"child-education-calculator",
        img: "Website%20Img/ChildEdu.5fe5e19c83fba64dadf5.png?updatedAt=1718773343063"
    
    },
    {
        id: 16,
        name:'Retairment',
        discription:"A loan’s tenure may get reduced or extended. Subsequently, there will be an increase or a decrease in the EMI amount as well. ",
        Link:"retairment-calculator",
        img: "Website%20Img/Retairment.ba68069c44fffdc32ac8.png?updatedAt=1718773398668"
    
    },
    {
        id: 17,
        name:'ULIPS',
        discription:"A loan’s tenure may get reduced or extended. Subsequently, there will be an increase or a decrease in the EMI amount as well. ",
        Link:"ulip-calculator",
        img: "Website%20Img/ulips.791c925099aa1c8d559b.png?updatedAt=1718773483106"
    
    },
    {
        id: 18,
        name:'Bond Calculator',
        discription:"A loan’s tenure may get reduced or extended. Subsequently, there will be an increase or a decrease in the EMI amount as well. ",
        Link:"bond-calculator",
        img: "Website%20Img/Bonds.39d0834384109cbbc5f1.png?updatedAt=1718773549756"
    
    },
    {
        id: 19,
        name:' Long Term Capital Calculator',
        discription:"A loan’s tenure may get reduced or extended. Subsequently, there will be an increase or a decrease in the EMI amount as well. ",
        Link:"longtermcapitalgain",
        img: "Website%20Img/Currency-bro.png?updatedAt=1720009664844"
    
    },
    {
        id: 20,
        name:' Short Term Capital Calculator',
        discription:"A loan’s tenure may get reduced or extended. Subsequently, there will be an increase or a decrease in the EMI amount as well. ",
        Link:"shorttermcapitalgain",
        img: "Website%20Img/Currency-bro.png?updatedAt=1720009664844"
    
    },

]








  return (
    <>
           <Helmet>
        <title>Online calculator</title>
        {/* <meta name="description" content="A brief description of your page for SEO." /> */}
        <meta name="keywords" content="online health insurance calculator , online insurance policy calculator , health insurance premium calculator" />
      </Helmet>




          <section className="mainnargin-insurance"></section>
    <Redirect/>
    <div className="maincalculator container">
        <p><Link to={'/'}>Home</Link> &nbsp;&gt;&nbsp;&nbsp;&nbsp;calculator</p>
  
      <span className="titlecalculators">Calculators</span>
  </div>

    <div className="calculatemain">
   
    <div class="container">

    <div class="row justify-content-start">
    {
    Data.map((calcul,index)=>{
        return(
            <div class="col-md-3">
            <Link to={calcul.Link}>
            <div class="cardcalculator">
            <div className="imgcalculator"><span>{calcul.name}</span>


  
            <FastImage
            url={calcul.img}
            width={auto}
            height={auto}
            alt={"ca-img"}
            />
            
            </div>
            <div className="calculatediscription">{calcul.discription && calcul.discription.slice(0, -40)}</div>
            </div>
            </Link>
        </div> 
        )
    })
}
       
    
        
    </div>
</div>
</div>
</>

  )
}

export default Calculatorchart