import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import Slider from "@mui/material/Slider";
import "../SIP/SIP.css";
import { Link, useNavigate } from "react-router-dom";
import { ConvertNumberToword } from "../CalculatortoWord";
import Redirect from "../../../AllPageComponents/Rederict";
import Calculatorlist from "../Calculator-Charat/Calculatorlist";
import { Helmet } from "react-helmet-async";

const Simpleinterest = () => {

  var convertRupeesIntoWords = require('convert-rupees-into-words');

  const nav = useNavigate()

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
     // State variables
  const [loanAmount, setLoanAmount] = useState(100000);


  const handleLoanAmountChange = (event) => {
    const value = event.target.value;
    const parsedValue = parseFloat(value);
    if (isNaN(parsedValue)) {
      setLoanAmount(''); // Clear the input if it's not a valid number
    } else {
      setLoanAmount(parsedValue); // Update the state with the valid number
    }
    if(parsedValue > 10000000){
      setLoanAmount(10000000)
    }
  
  };

  const [interestAmount, setInterestAmount] = useState(6);

  const handleInterestRateChange = (event) => {
    const value = event.target.value;
    if (value === '' || (!isNaN(value) && /^[0-9]*\.?[0-9]*$/.test(value))) {
      if (parseFloat(value) > 30) {
        setInterestAmount(30);
      } else {
        setInterestAmount(value);
      }
    }
  };


  const [yearsAmount, setYearsAmount] = useState(5);

  const handleTenurePeriodChange = (event) => {
    const value = event.target.value;
    const parsedValue = parseFloat(value);
  
    if (isNaN(parsedValue)) {
      setYearsAmount(''); // Clear the input if it's not a valid number
    } else {
      setYearsAmount(parsedValue); // Update the state with the valid number
    }
    if(parsedValue > 40){
      setYearsAmount(40)
    }
  };







  // Tenure Period Function


  // Calculate Results Function
  const calculateResults = () => {
    const result = (Number(loanAmount) * Number(interestAmount) * Number(yearsAmount)) / 100;
    return result;
}

//console.log(loanAmount+calculateResults())
calculateResults()




const LaonAmountToWord = convertRupeesIntoWords(Math.ceil(loanAmount))
const TotalInterest = convertRupeesIntoWords(Math.ceil(calculateResults()))
const TotalAmount = convertRupeesIntoWords(Math.ceil(loanAmount+calculateResults()))




  return (
    <div>

<Helmet>
  <title>Simple Interest Loan Calculator</title>
  <meta name="description" content="Calculate your simple interest loan payments easily with our tool." />
  <meta name="keywords" content="simple interest loan calculator, personal loan interest calculator, loan payment calculator" />
</Helmet>






                    <section className="margincalculator"></section>
      {/* Divided the Div */}
      <div className="headingcalculator">
      <p>Home &nbsp;&gt;&nbsp;&nbsp;&nbsp;<Link to={'/calculator'}>calculator</Link>&nbsp;&nbsp;&nbsp;&nbsp;&gt;&nbsp;&nbsp;<span style={{color:'green'}}>Simple Interest Calculator</span></p>
  
    <h1 className="titlecalculator">Simple interest loan  <span className='Mainheading'>Calculator</span></h1>
    
    <sapn className="CalculatorDiscription">A Simple Interest calculator computes interest on a principal amount at a fixed rate over a 
    specified time, offering a quick way to find total interest without compounding</sapn>
  </div>
      <div>
        <div className="calculatorhead">
          <div className="underdiv">
            <div className="calculatorse">
              {/* Calculator start div start hear */}
              <div className="calculaterse">
                <div className="calculator">
                  {/* Amount calculator */}
                  <div className="onecalculator">
                    <div className="amountdives">
                      <p>Loan Amount</p>
                      <input
                        type="number"
                        value={loanAmount}
                        onChange={
                          handleLoanAmountChange
                        }
                      />
                    </div>
                    <div className="amountselectordiv">
                      <Slider
                        value={loanAmount}
                        onChange={
                          handleLoanAmountChange
                        }
                        min={1000}
                        max={10000000}
                        step={1000}
                        aria-label="Loan Amount"
                        valueLabelDisplay="auto"
                      />
                    </div>
                  </div>

                  {/* Amount calculator */}
                  <div className="onecalculator">
                    <div className="amountdives">
                      <p>Rate of interest % (P.A)</p>
                      <input
                        type="text"
                        value={interestAmount}
                        onChange={
                          handleInterestRateChange}
                      />
                    </div>
                    <div className="amountselectordiv">
                      <Slider
                        value={interestAmount}
                        onChange={
                          handleInterestRateChange}
                        min={1}
                        max={30}
                        step={0.1}
                        aria-label="Interest Rate"
                        valueLabelDisplay="auto"
                      />
                    </div>
                  </div>

                  {/* Amount calculator */}
                  <div className="onecalculator">
                    <div className="amountdives">
                      <p> Loan Tanure (Year) </p>
                      <input
                        type="text"
                        value={yearsAmount}
                        onChange={
                          handleTenurePeriodChange
                        }
                      />
                    </div>
                    <div className="amountselectordiv">
                      <Slider
                        value={yearsAmount}
                        onChange={
                          handleTenurePeriodChange
                        }
                        min={1}
                        max={40}
                        aria-label="Time Period"
                        valueLabelDisplay="auto"
                      />
                    </div>
                  </div>
                  <div className="calculateamountdiv">
                    <div>
                    

                      <p>
                        Princepal Amount:{" "}
                        <span style={{ color: "#0166B3" }}>
  {Math.round(parseFloat(loanAmount)).toLocaleString("en-IN", {
    style: "currency",
    currency: "INR"
  })}
</span>

                      </p>
     <p className='textword'>{LaonAmountToWord}</p>
                      <p>
                        Total Interest:{" "}
                        <span style={{ color: "#0166B3" }}>
  {Math.round(parseFloat(calculateResults())).toLocaleString("en-IN", {
    style: "currency",
    currency: "INR"
  })}
</span>

                      </p>
                      <p className='textword'>{TotalInterest}</p>
                      <p>
                        Total Amount:{" "}
                        <span style={{ color: "#0166B3" }}>
  {Math.round(parseFloat(loanAmount+calculateResults())).toLocaleString("en-IN", {
    style: "currency",
    currency: "INR"
  })}
</span>
                      </p>

 <p className='textword'>{TotalAmount}</p>



                    </div>
                  </div>
                </div>
                <div className="graphdiv">
            <Chart
        type='donut'
        width={400}
        height={445}
        series={[loanAmount, calculateResults()]}
        options={{
          labels:['Princepal Amount', ' Interest Amount']
        }}
      />





                </div>
              </div>
            </div>
            {/* Result part start hear */}
            <div className="resultse">


          <Calculatorlist/>
            
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Simpleinterest