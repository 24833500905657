import React,{useState,useEffect} from 'react'
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { FaArrowRotateLeft } from "react-icons/fa6";
import axios from 'axios'
import Loader from '../AllPageComponents/Loader';
import './TravelInsurence.css'

const TravelForm = () => {

    const FullName = localStorage.getItem('ProposalName')
    const PhoneNumber = localStorage.getItem('Proposalphonenumber')
  
    const [generatedCaptcha, setGeneratedCaptcha] = useState('');
    const [userInput, setUserInput] = useState('');
    const [resultMessage, setResultMessage] = useState('');

    // Error States ------------------------------------
     const [NameError, setNameError] = useState('')
     const [travelPalceError, setTravelPalceError] = useState('')
     const [phoneNumberError, setphoneNumberError] = useState('')
     const [pinNumberError, setpinNumberError] = useState('')
    // End Hear -----------------------------------------

    // Function to generate a random alphanumeric string
    const generateRandomString = (length) => {
      const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = '';
      for (let i = 0; i < length; i++) {
        result += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return result;
    };
  
    const generateCaptcha = () => {
      const captchaText = generateRandomString(6); // You can adjust the length as needed
      setGeneratedCaptcha(captchaText);
      setResultMessage('');
    };
  
    useEffect(()=>{
      generateCaptcha();
      window.scrollTo(0, 0);
    },[])
  
  




     const [UserPhoneUndefne, setUserPhoneUndefne] = useState('')
 
     useEffect(() => {

    if(PhoneNumber === undefined){
        setUserPhoneUndefne('')
    }else{
        setUserPhoneUndefne(PhoneNumber)
    }

}, [PhoneNumber]);





const [ValidName, setValidName] = useState('')
useEffect(()=>{
if(FullName){
    setValidName(FullName)
}




},[FullName])






    const [formData, setFormData] = useState({
      car_type: 'Travel-Insurence',
      phone_no: UserPhoneUndefne ,
      car_number: '',
      pin: '' ,
      name:'' || ValidName
    });

console.log(formData)







    
  
    const [isLoading, setisLoading] = useState(false)
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value
      });
    };



    const ValidateName = (e) =>{
        if(e.target.value ==  ''){
          setNameError('Name is Requird')
    
        }else{
          setNameError('')
        }
      }

      const ValidatePhone = (e) =>{
        if(e.target.value == ''){
            setphoneNumberError("Enter a Valida Phone Number");

          }else{
            setphoneNumberError('');
          }
      }
      const ValidateAge = (e) =>{
        if(e.target.value == ''){
            setpinNumberError("Enter a Valida Phone Number");
          }else{
            setpinNumberError('');
          }
      }

      const ValidateTravelPlace = (e) =>{
        if(e.target.value == ''){
            setTravelPalceError("Enter a Valida Phone Number");
          }else{
            setTravelPalceError('');
          }
      }



    const handleSubmit = async (e) => {
        e.preventDefault();

   

        // Check if userInput matches generatedCaptcha
        if (userInput !== generatedCaptcha) {
            Swal.fire({
              title: "Error",
              text: "Wrong Captcha",
              icon: "error",
              confirmButtonText: "Back"
            });
            setUserInput('');
            generateCaptcha(); // Assuming this function regenerates the captcha
            return;
          }
         let validate = true
  
          if(formData.name ==  ''){
            setNameError('Name is Requird')
            validate = false
          }else{
            setNameError('')
          }


          if(formData.phone_no == ''){
            setphoneNumberError("Enter a Valida Phone Number");
            validate = false
          }else{
            setphoneNumberError('');
          }

          if(formData.pin == '' ){
            setpinNumberError("Enter a Valida age");
            validate = false
          }else{
            setpinNumberError('')
          }

          if(formData.car_number == ''){
            setTravelPalceError("Plese Enter a Valid Place Name")
            validate = false
          }else{
            setTravelPalceError('')
          }
        


      if(!validate){
        return
      }else{

     
        // If all validations pass, proceed with form submission
        setisLoading(true);
        try {
          const response = await axios.post(`${process.env.REACT_APP_BASE_URL}cms/car_enquiry`, formData);
          if (response.status === 200) {
            setisLoading(false);
            Swal.fire({
              title: 'Success!',
              text: 'Your Form is Successfully Submitted',
              icon: "success",
              confirmButtonText: 'Back'
            });
      
            // Reset form data after successful submission
            setTimeout(() => {
              setFormData({
                car_type: 'Travel-Insurance',
                phone_no: '',
                car_number: '',
                pin: '',
                name: ''
              });
              setUserInput('');
              generateCaptcha();
            }, 2000);
          } else {
            Swal.fire({
              title: 'Error!',
              text: 'Something went Wrong',
              icon: 'error',
              confirmButtonText: 'Back'
            });
          }
        } catch (err) {
          console.error(err);
          Swal.fire({
            title: 'Error!',
            text: 'Something went Wrong',
            icon: 'error',
            confirmButtonText: 'Back'
          });
        }
      };
      
    }






  return (

<>

{isLoading && <Loader/> }
<div className="col-sm-12 col-md-4 col-lg-4 popup-main">
    <div className="form-main">
      <form id="insurance_form_get_plan">
        <div className="d-flex align-items-center form-input">
          <h6>Buy Best Travel Insurance Plans for your Overseas Journey</h6>



  







<div className="form-inner-input form-group">

<input
type="text"
name='name'
placeholder="FULL NAME"
className="form-control margindiv"
value={formData.name}
onChange={(e)=>{
    handleChange(e);
    ValidateName(e)
}}
required={true}
/>
<span className='error'>{NameError}</span> 

  <input
    type="tel"
    name='phone_no'
    placeholder="Enter your mobile number"
    className="form-control margindiv"
    value={formData.phone_no}
    onChange={(e)=>{handleChange(e);ValidatePhone(e)}}
    required={true}
  />



  <span className='error'>{phoneNumberError}</span> 

  <input
    type="text"
    name='car_number'
    placeholder="Travel Place"
    className="form-control margindiv"
    value={formData.car_number}
    onChange={(e)=>{handleChange(e);ValidateTravelPlace(e)}}
    required={true}
  />
 <span className='error'>{travelPalceError}</span> 
  <input
    type="number"
    name='pin'
    placeholder="Age"
    className="form-control margindiv"
    value={formData.pin}
    onChange={(e)=>{handleChange(e);ValidateAge(e)}}
    required={true}
  />
 <span className='error'>{pinNumberError}</span> 
 <br/>
</div>




<div className=" carformsubmited">
<div className="captchaSection">
  <div id="captcha" className="genarateCaptcha">{generatedCaptcha}</div>
  <button onClick={generateCaptcha} style={{border:"none"}}><FaArrowRotateLeft /></button>
</div>



<div id="inputSection_captcha">
  <input
    type="text"
    value={userInput}
    onChange={(e) => setUserInput(e.target.value)}
    placeholder="Enter CAPTCHA"
    className='Captachcode'
  />

  <p id="result">{resultMessage}</p>
</div>
</div>





          



    

          <div className="blue-btn carformsubmited">
          <button onClick={handleSubmit}>Get Quotation</button>
        </div>

        <p className="insurance-text-light">
            I agree to{" "}
            <Link to={'/termsandcondition'}>
            *terms &amp; conditions
           {" "}
            </Link>
            and{" "}
            <Link to={'/privacy-policy'}>
            privacy policy
            </Link>
          </p>


        </div>
      </form>
    </div>
  </div>
  </>
  )
}

export default TravelForm