import React, { useState,useEffect } from 'react'
import { Link } from 'react-router-dom';
import "../CommonCssFiles/planstyle.css"
import { Checkbox, FormControlLabel, FormGroup, TextField } from '@mui/material'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import {getDate} from '../helper/common';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectProposal,
  updateICICIQuestion,
  updateICICIPersion,
  updateICICIQAlist,
  selectICICIcheckedQuestion
} from '../redux/Slice'

export const ICICIQuestionAnswer = ({data,index,insuredData}) => {
    const dispatch = useDispatch()
    const ICICIcheckedQuestion = useSelector(selectICICIcheckedQuestion)
    //console.log('ICICIcheckedQuestion',ICICIcheckedQuestion)
    return (<>
        <div className='row  ppv10 my-3 col-md-12' >
            <div className='col pmd col-md-12'>
                <FormControlLabel value = {data.question_id} onChange = {e => dispatch(updateICICIQuestion({val:e.target.value}))}  control={<Checkbox />} label={"Q."+data.question} checked = {ICICIcheckedQuestion.hasOwnProperty(data.question_id)} />
            </div>     
        </div>
    </>)
}

export const ICICIPersion = ({data,persion,pindex}) => {
   
    const proposalData = useSelector(selectProposal)
    const ICICIcheckedQuestion = useSelector(selectICICIcheckedQuestion)

    
    const check = () => 
    {
        if(ICICIcheckedQuestion.hasOwnProperty(data.question_id))
        {
           if(ICICIcheckedQuestion[data.question_id].hasOwnProperty(pindex))
           {
              return true;
           }
           else
           {
            return false;
           }
        }
        else
        {
            return false;
        }
    }
    
    const dispatch = useDispatch()


    return(<>
	        <div className='col-md-5' style={{margin:"10px"}} hidden = {!ICICIcheckedQuestion.hasOwnProperty(data.question_id)} onClick = {e => dispatch(updateICICIPersion({pindex:pindex,question_id:data.question_id}))} >
	            <div className={check() ? "row sumbox sumboxone icicisum" : "row sumbox"} style={{padding: "13px"}} >
	            <div className='col-10 '>
	                <span className='para'>{proposalData.InsuredDetail[pindex].InsuredName} ({persion.InsuredRelation}) </span>
	            </div>
	            <div className='col-3  text-right'> 
	            </div>
	           </div>
	       </div>
        </>)
}

