import React, { useState,useEffect } from 'react';
import Chart from "react-apexcharts";
import Slider from "@mui/material/Slider";
import '../SIP/SIP.css';
import { Link } from 'react-router-dom';
import Calculatorlist from '../Calculator-Charat/Calculatorlist';
import { Helmet } from 'react-helmet-async';

const RD = () => {

  
  var convertRupeesIntoWords = require('convert-rupees-into-words');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
   
    const [Amount, setAmount] = useState(50000);


    const handleLoanAmountChange = (event) => {
      const value = event.target.value;
      const parsedValue = parseFloat(value);
      if (isNaN(parsedValue)) {
        setAmount(''); // Clear the input if it's not a valid number
      } else {
        setAmount(parsedValue); // Update the state with the valid number
      }
      if(parsedValue > 1000000){
        setAmount(1000000)
      }
    
    };





    const [InterestAmount, setInterestAmount] = useState(6.5);
    const handleInterestRateChange = (event) => {
      const value = event.target.value;
      if (value === '' || (!isNaN(value) && /^[0-9]*\.?[0-9]*$/.test(value))) {
        if (parseFloat(value) > 30) {
          setInterestAmount(30);
        } else {
          setInterestAmount(value);
        }
      }
    };




    const [tanure, settanure] = useState(3);


    const handleTenurePeriodChange = (event) => {
      const value = event.target.value;
      const parsedValue = parseFloat(value);
    
      if (isNaN(parsedValue)) {
        settanure(''); // Clear the input if it's not a valid number
      } else {
        settanure(parsedValue); // Update the state with the valid number
      }
      if(parsedValue > 40){
        settanure(40)
      }
    };





    const [maturity, setMaturity] = useState(0);



 const totalInvested = () =>{
    const year = tanure * 12
    const totalamount = year * Amount
    return totalamount
 }




    useEffect(() => {
        const calculateMaturity = () => {
            const amt = Amount;
            const rate = InterestAmount;
            const years = tanure;
            const freq = 12; // Assuming compounding frequency is monthly for RD
            const months = years * 12;
            let maturityAmount = 0;
            
            for (let i = 1; i <= months; i++) {
                maturityAmount += parseFloat(amt) * Math.pow(1 + rate / (100 * freq), freq * ((months - i + 1) / 12));
            }



            
            setMaturity(maturityAmount);
        };
        calculateMaturity();
    }, [Amount, InterestAmount, tanure]);


//console.log(maturity)




const Totalreturn = () =>{
    return maturity - totalInvested()
}


const TotalInvestmentToWord = convertRupeesIntoWords(Math.ceil(totalInvested()))
const ESTReturnToWord = convertRupeesIntoWords(Math.ceil(Totalreturn()))
const maturityToWord = convertRupeesIntoWords(Math.ceil(maturity))



  return (
    <div>


<Helmet>
  <title>Recurring Deposit Interest Calculator</title>
  <meta name="description" content="Calculate your recurring deposit interest and returns with our online RD interest calculator." />
  <meta name="keywords" content="recurring deposit interest calculator, RD interest rates, RD return rate, recurring deposit calculator, calculate RD returns" />
</Helmet>





  <section className="margincalculator"></section>
    <div className="headingcalculator">
    <p>Home &nbsp;&gt;&nbsp;&nbsp;&nbsp;<Link to={'/calculator'}>calculator</Link>&nbsp;&nbsp;&nbsp;&nbsp;&gt;&nbsp;&nbsp;<span style={{color:'green'}}>RD calculator</span></p>

  <h1 className="titlecalculator">Recurring deposit  <span className='Mainheading'>Calculator</span></h1>
</div>

        <div className="calculatorhead">
        <div className="underdiv">
          <div className="calculatorse">
            {/* Calculator start div start hear */}
            <div className="calculaterse">
              <div className="calculator">
                {/* Amount calculator */}
                <div className="onecalculator">
                  <div className="amountdives">
                    <p>Monthly investment</p>
                    <input
                      type="text"
                      value={Amount}
                      onChange={
                        handleLoanAmountChange
                      }
                    />
                  </div>
                  <div className="amountselectordiv">
                  <Slider
                  value={Amount}
                  onChange={
                    handleLoanAmountChange
                  }
                  min={500}
                  max={1000000}
                  step={500}
                  aria-label="Monthly Investment"
                  valueLabelDisplay="auto"
              />
                  </div>
                </div>

                {/* Amount calculator */}
                <div className="onecalculator">
                  <div className="amountdives">
                    <p>Rate Of Interest %</p>
                    <input
                      type="text"
                      value={InterestAmount}
                      onChange={
                        handleInterestRateChange
                      }
                    />
                  </div>
                  <div className="amountselectordiv">
                    <Slider
                    value={InterestAmount}
                      onChange={
                        handleInterestRateChange
                      }
                      min={1}
                      max={30}
                      step={0.1}
                      aria-label="Interest Rate"
                      valueLabelDisplay="auto"
                    />
                  </div>
                </div>

                {/* Amount calculator */}
                <div className="onecalculator">
                  <div className="amountdives">
                    <p>Time Period</p>
                    <input
                      type="text"
                      value={tanure}
                      onChange={
                        handleTenurePeriodChange
                      }
                    />
                  </div>
                  <div className="amountselectordiv">
                    <Slider
                    value={tanure}
                    onChange={
                      handleTenurePeriodChange
                    }
                      min={1}
                      max={40}
                      aria-label="Time Period"
                      valueLabelDisplay="auto"
                    />
                  </div>
                </div>

 <div className="calculateamountdiv">
    <div>
    <p>
        Total Invest:{" "}
        <span style={{ color: "#0166B3" }}>
{Math.round(parseFloat(totalInvested())).toLocaleString("en-IN", {
style: "currency",
currency: "INR"
})}
</span>

    </p>
    <p className='textword'>{TotalInvestmentToWord}</p>

    <p>
        Estemeted Return:{" "}
        <span style={{ color: "#0166B3" }}>
{Math.round(parseFloat(Totalreturn())).toLocaleString("en-IN", {
style: "currency",
currency: "INR"
})}
</span>

    </p>

    <p className='textword'>{ESTReturnToWord}</p>

    <p>
        Total amount:{" "}
        <span style={{ color: "#0166B3" }}>
{Math.round(parseFloat(maturity)).toLocaleString("en-IN", {
style: "currency",
currency: "INR"
})}
</span>

    </p>
    
    <p className='textword'>{maturityToWord}</p>   
    </div>
</div>






              </div>
              <div className="graphdiv">
       <Chart
      type='donut'
      width={400}
      height={445}
      series={[totalInvested(), Totalreturn()]}
      options={{
        labels:['Loan Amount', ' Interest Amount']
      }}
    />
              </div>
            </div>
          </div>
          {/* Result part start hear */}
          <div className="resultse">
        <Calculatorlist/>
          </div>
        </div>
      </div>





    </div>
  )
}

export default RD