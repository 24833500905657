import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import Slider from "@mui/material/Slider";
import "../SIP/SIP.css";
import { Link, useNavigate } from "react-router-dom";
import { ConvertNumberToword } from "../CalculatortoWord";
import Redirect from "../../../AllPageComponents/Rederict";
import Calculatorlist from "../Calculator-Charat/Calculatorlist";
import { Helmet } from "react-helmet-async";

const HomeLoan = () => {

  var convertRupeesIntoWords = require('convert-rupees-into-words');

  const nav = useNavigate()

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // State variables
  const [loanAmount, setLoanAmount] = useState(1000000);
  const [interestAmount, setInterestAmount] = useState(6.5);
  const [yearsAmount, setYearsAmount] = useState(5);
  const [monthlyPayment, setMonthlyPayment] = useState("");
  const [totalPayment, setTotalPayment] = useState("");
  const [totalInterest, setTotalInterest] = useState("");
  const [error, setError] = useState("");

  // Loan Amount Function
  const handleLoanAmountChange = (event) => {
    const value = event.target.value;
    const parsedValue = parseFloat(value);
    if (isNaN(parsedValue)) {
      setLoanAmount(''); // Clear the input if it's not a valid number
    } else {
      setLoanAmount(parsedValue); // Update the state with the valid number
    }
    if(parsedValue > 100000000){
      setLoanAmount(100000000)
    }

  };





  // Interest Rate Function
  const handleInterestRateChange = (event) => {
    const value = event.target.value;
    if (value === '' || (!isNaN(value) && /^[0-9]*\.?[0-9]*$/.test(value))) {
      if (parseFloat(value) > 30) {
        setInterestAmount(30);
      } else {
        setInterestAmount(value);
      }
    }
  };





  // Tenure Period Function
  const handleTenurePeriodChange = (event) => {
    const value = event.target.value;
    const parsedValue = parseFloat(value);

    if (isNaN(parsedValue)) {
      setYearsAmount(''); // Clear the input if it's not a valid number
    } else {
      setYearsAmount(parsedValue); // Update the state with the valid number
    }
    if(parsedValue > 40){
      setYearsAmount(40)
    }
  };

  // Calculate Results Function
  const calculateResults = () => {
    //console.log("Calculating...");

    // Validation
    if (loanAmount === "" || interestAmount === "" || yearsAmount === "") {
      setError("Please fill in all fields");
      return;
    }

    // Calculation Variables
    const principle = parseFloat(loanAmount);
    const calculatedInterest = parseFloat(interestAmount) / 100 / 12;
    const calculatedPayments = parseFloat(yearsAmount) * 12;

    // Calculate Monthly Payments
    const x = Math.pow(1 + calculatedInterest, calculatedPayments);
    const monthly = (principle * x * calculatedInterest) / (x - 1);

    if (isFinite(monthly)) {
      setMonthlyPayment(monthly.toFixed(2));
      setTotalPayment((monthly * calculatedPayments).toFixed(2));
      setTotalInterest((monthly * calculatedPayments - principle).toFixed(2));
      setError("");
    } else {
      setError("Please check your numbers");
      setMonthlyPayment("");
      setTotalPayment("");
      setTotalInterest("");
    }
  };

  // Show Results Function
  const showResults = () => {
    document.getElementById("results").style.display = "block";
  };

  // Calculate results when sliders change
  useEffect(() => {
    calculateResults();
  }, [loanAmount, interestAmount, yearsAmount]);



  const graphAmount = () => {
    const Interest = Math.floor(totalPayment);
    return Interest;
}


const LoanAmountToWord = convertRupeesIntoWords(Math.ceil(loanAmount))
const InterestToWord = convertRupeesIntoWords(Math.ceil(totalInterest))
const TotalAmountToWord = convertRupeesIntoWords(Math.ceil(totalPayment))
const MonthlyEMIToWord = convertRupeesIntoWords(Math.ceil(monthlyPayment))
  return (
    <div>

<Helmet>
  <title>Online Home Loan Comparison</title>
  <meta name="description" content="Compare home loan options easily with our online home loan comparison tool. Find the best mortgage rates and eligibility." />
  <meta name="keywords" content="mortgage loan calculator, home loan comparison, home loan eligibility calculator, compare home loans, best mortgage rates" />
</Helmet>





      {/* Divided the Div */}
      <section className="margincalculator"></section>
      <div>
      <div className="headingcalculator">
      <p>Home &nbsp;&gt;&nbsp;&nbsp;&nbsp;<Link to={'/calculator'}>calculator</Link>&nbsp;&nbsp;&nbsp;&nbsp;&gt;&nbsp;&nbsp;<span style={{color:'green'}}>Home-Loan</span></p>

    <h1 className="titlecalculator">Mortgage loan <span className='Mainheading'>Calculator</span></h1>
    <br />
    <sapn className="CalculatorDiscription">A home loan calculator helps you figure out how much you'll pay each month for your mortgage.
    It considers things like the loan amount, interest rate, and how long you'll take to repay, helping 
    you plan your finances better</sapn>
</div>

        <div className="calculatorhead">
          <div className="underdiv">
            <div className="calculatorse">
              {/* Calculator start div start hear */}
              <div className="calculaterse">
                <div className="calculator">
                  {/* Amount calculator */}
                  <div className="onecalculator">
                    <div className="amountdives">
                      <p>Loan Amount</p>
                      <input
                        type="text"
                        value={loanAmount}
                        onChange={(event) => {
                          handleLoanAmountChange(event);
                        }}
                      />
                    </div>
                    <div className="amountselectordiv">
                      <Slider
                        value={loanAmount}
                        onChange={(event) => {
                          handleLoanAmountChange(event);
                        }}
                        min={100000}
                        max={100000000}
                        step={10000}
                        aria-label="Loan Amount"
                        valueLabelDisplay="auto"
                      />
                    </div>
                  </div>

                  {/* Amount calculator */}
                  <div className="onecalculator">
                    <div className="amountdives">
                      <p>Rate of interest % (P.A)</p>
                      <input
                        type="text"
                        value={interestAmount}
                        onChange={(event) => {
                          handleInterestRateChange(event);
                        }}
                      />
                    </div>
                    <div className="amountselectordiv">
                      <Slider
                        value={interestAmount}
                        onChange={(event) => {
                          handleInterestRateChange(event);
                        }}
                        min={1}
                        max={30}
                        step={0.1}
                        aria-label="Interest Rate"
                        valueLabelDisplay="auto"
                      />
                    </div>
                  </div>

                  {/* Amount calculator */}
                  <div className="onecalculator">
                    <div className="amountdives">
                      <p> Loan Tanure (Year) </p>
                      <input
                        type="text"
                        value={yearsAmount}
                        onChange={(event) => {
                          handleTenurePeriodChange(event);
                        }}
                      />
                    </div>
                    <div className="amountselectordiv">
                      <Slider
                        value={yearsAmount}
                        onChange={(event) => {
                          handleTenurePeriodChange(event);
                        }}
                        min={1}
                        max={40}
                        aria-label="Time Period"
                        valueLabelDisplay="auto"
                      />
                    </div>
                  </div>
                  <div className="calculateamountdiv">
                    <div>
                      <p>
                        Monthly EMI:{" "}
                        <span style={{ color: "#0166B3" }}>
  {Math.round(parseFloat(monthlyPayment)).toLocaleString("en-IN", {
    style: "currency",
    currency: "INR"
  })}
</span>

                      </p>
<p className='textword'>{MonthlyEMIToWord}</p>
                      <p>
                        Princepal Amount:{" "}
                        <span style={{ color: "#0166B3" }}>
  {Math.round(parseFloat(loanAmount)).toLocaleString("en-IN", {
    style: "currency",
    currency: "INR"
  })}
</span>
</p>
<p className='textword'>{LoanAmountToWord}</p>
             
                      <p>
                        Total Interest:{" "}
                        <span style={{ color: "#0166B3" }}>
  {Math.round(parseFloat(totalInterest)).toLocaleString("en-IN", {
    style: "currency",
    currency: "INR"
  })}
</span>

                      </p>
                      <p className='textword'>{InterestToWord}</p>
                      <p>
                        Total Amount:{" "}
                        <span style={{ color: "#0166B3" }}>
  {Math.round(parseFloat(totalPayment)).toLocaleString("en-IN", {
    style: "currency",
    currency: "INR"
  })}
</span>

                      </p>
                      <p className='textword'>{TotalAmountToWord}</p>
                    </div>
                  </div>
                </div>
                <div className="graphdiv">
                  <Chart
        type='donut'
        width={400}
        height={445}
        series={[
          Number(loanAmount) || 100,
          Number(graphAmount()) || 100
        ]}
        
        options={{
          labels:['Princepal Amount', ' Interest Amount']
        }}
      />
                </div>
              </div>
            </div>
            {/* Result part start hear */}
            <div className="resultse">
           <Calculatorlist/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeLoan;
