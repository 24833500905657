import React from 'react';
import FAQ from "../AllPageComponents/FAQ/FAQ";
import PartnerCompany from "../AllPageComponents/PartnerCompany/PartnerCompany";
import "../CarInsurnce/Carinsurence.css";
import CarInsuranceForm from './CarInsuranceForm';
import FastImage from "../FastImage";
import { auto } from '@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core'
import Redirect from '../AllPageComponents/Rederict';

const ClaimSettlement = () => {

  


  return (
    <>
    <Redirect/>
        <section className="car-insurance insurance-all insurance-background">
  <div className="container">
    <div className="row insurance-row-one">
      <div className="col-sm-12 col-md-4 col-lg-4 mian-one">
        <div className="car-insurance-first-inner">

          <h4 style={{color:"#0166B3",fontFamily:"Poppins",fontWeight:500,fontSize:"18pt"}}>Car Insurance Claim Settlement</h4>
   
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer too
          </p>
        </div>
      </div>
      <div className="col-sm-12 col-md-4 col-lg-4 side-image">
      <FastImage
            url={"Website%20Img/carImagMascord.png?updatedAt=1718773961090"}
            width={auto}
            height={auto}
            alt={"ca-img"}
            />
      </div>
      <style
        dangerouslySetInnerHTML={{
          __html: "\n    .hide{\n        display: none;\n    }\n"
        }}
      />
      <CarInsuranceForm/>
   
      <section className="insurance-popup popup-all">
        <div className="insurance-popup-inner container">
          <div className="insurance-popup-sub">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="modal-header">
                  <button href="javascript:void(0)" className="trigger-back">
                
                  </button>
                  <button className="item-4 modal-close modal-toggle" />
                </div>
                <div className="form-wizard">
                  <div className="steps">
                    <ul>
                      <li className="one active">
                        <span>1</span>
                        <div className="check" />
                      </li>
                      <li className="two">
                        <span>2</span>
                        <div className="check" />
                      </li>
                      <li className="three">
                        <span>3</span>
                        <div className="check" />
                      </li>
                      <li className="four">
                        <span>4</span>
                        <div className="check" />
                      </li>
                      <li className="five">
                        <span>5</span>
                        <div className="check" />
                      </li>
                      <li className="six">
                        <span>6</span>
                        <div className="check" />
                      </li>
                      <li className="seven">
                        <span>7</span>
                        <div className="check" />
                      </li>
                      <li className="eight">
                        <span>8</span>
                        <div className="check" />
                      </li>
                      {/* <li class="nine">
                              <span>9</span>
                              <div class="check"></div>
                          </li> */}
                    </ul>
                  </div>
                  <div className="myContainer">
                    <div className="form-container animated make active">
                      <h2 className="text-center form-title">
                        What is your car's brand?
                      </h2>
                      <div className="input-group add-on">
                        <input
                          className="form-control"
                          placeholder="Search"
                          name="makeSearchValue"
                          id="makeSearchValue"
                          type="text"
                        />
                        <div className="input-group-btn">
                          <button
                            type="button"
                            className="btn btn-primary"
                            id="makeSearch"
                          >
       
                          </button>
                        </div>
                      </div>
                      <div className="scroll-body">
                        <div
                          className="row company-insurenanc"
                          id="makeData"
                        ></div>
                        <div
                          className="row add-more-company"
                          id="load-more-make-button"
                        >
                          <div className="col-sm-12 col-md-12 col-lg-12">
                            <a
                              href="javascript:void(0)"
                              className="show-more-logo"
                            >
                              Show more 20 brand
                            </a>
                          </div>
                        </div>
                      </div>
                      <span className="bg-img-beema-d">
                      
                      </span>
                    </div>
                    <div className="form-container animated vmodel">
                      <h2
                        className="text-center form-title"
                        id="modelQuestion"
                      ></h2>
                      <div className="input-group add-on">
                        <input
                          className="form-control"
                          placeholder="Search"
                          name="modelSearchValue"
                          id="modelSearchValue"
                          type="text"
                        />
                        <div className="input-group-btn">
                          <button
                            type="button"
                            className="btn btn-primary"
                            id="modelSearch"
                          >
                         
                          </button>
                        </div>
                      </div>
                      <div className="scroll-body">
                        <div
                          className="row company-insurenanc car-modal"
                          id="modelData"
                        ></div>
                        <span className="bg-img-beema-d">
                        
                        </span>
                      </div>
                    </div>
                    <div className="form-container animated car-fual-type">
                      <h2 className="text-center form-title">
                        Select your fuel type
                      </h2>
                      <div className="scroll-body">
                        <div
                          className="row company-insurenanc"
                          id="fuelData"
                        ></div>
                      </div>
                      <span className="bg-img-beema-d">
                     
                      </span>
                    </div>
                    <div className="form-container animated variant-drive">
                      <h2
                        className="text-center form-title"
                        id="varientQuestion"
                      />
                      <div className="scroll-body">
                        <div
                          className="row company-insurenanc"
                          id="variantData"
                        ></div>
                      </div>
                      <span className="bg-img-beema-d">
                      
                      </span>
                    </div>
                    <div className="form-container animated car-use-type">
                      <h2 className="text-center form-title">
                        Vehicle use as?
                      </h2>
                      <div className="scroll-body">
                        <div className="row company-insurenanc">
                          <div
                            className="col-sm-12 col-md-4 col-lg-4"
                            id="vehicleUse"
                          >
                            <div className="company-icon">
                              <a
                                href="javascript:void(0)"
                                className="next"
                                data-activediv="car-use-type"
                              >
                                <h6>Private</h6>
                              </a>
                            </div>
                          </div>
                          <div
                            className="col-sm-12 col-md-4 col-lg-4"
                            id="vehicleUse"
                          >
                            <div className="company-icon">
                              <a
                                href="javascript:void(0)"
                                className="next"
                                data-activediv="car-use-type"
                              >
                                <h6>Commercial</h6>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <span className="bg-img-beema-d">
                      
                      </span>
                    </div>
                    <div className="form-container animated registered-year">
                      <h2 className="text-center form-title">
                        In which year your car got registered?
                      </h2>
                      <div className="scroll-body">
                        <div className="row company-insurenanc">
                          <div
                            className="col-sm-12 col-md-4 col-lg-4"
                            id="yearOptClick"
                          >
                            <div className="company-icon">
                              <a
                                href="javascript:void(0)"
                                className="next"
                                data-activediv="registered-year"
                              >
                                <h6>2024</h6>
                              </a>
                            </div>
                          </div>
                          <div
                            className="col-sm-12 col-md-4 col-lg-4"
                            id="yearOptClick"
                          >
                            <div className="company-icon">
                              <a
                                href="javascript:void(0)"
                                className="next"
                                data-activediv="registered-year"
                              >
                                <h6>2023</h6>
                              </a>
                            </div>
                          </div>
                          <div
                            className="col-sm-12 col-md-4 col-lg-4"
                            id="yearOptClick"
                          >
                            <div className="company-icon">
                              <a
                                href="javascript:void(0)"
                                className="next"
                                data-activediv="registered-year"
                              >
                                <h6>2022</h6>
                              </a>
                            </div>
                          </div>
                          <div
                            className="col-sm-12 col-md-4 col-lg-4"
                            id="yearOptClick"
                          >
                            <div className="company-icon">
                              <a
                                href="javascript:void(0)"
                                className="next"
                                data-activediv="registered-year"
                              >
                                <h6>2021</h6>
                              </a>
                            </div>
                          </div>
                          <div
                            className="col-sm-12 col-md-4 col-lg-4"
                            id="yearOptClick"
                          >
                            <div className="company-icon">
                              <a
                                href="javascript:void(0)"
                                className="next"
                                data-activediv="registered-year"
                              >
                                <h6>2020</h6>
                              </a>
                            </div>
                          </div>
                          <div
                            className="col-sm-12 col-md-4 col-lg-4"
                            id="yearOptClick"
                          >
                            <div className="company-icon">
                              <a
                                href="javascript:void(0)"
                                className="next"
                                data-activediv="registered-year"
                              >
                                <h6>2019</h6>
                              </a>
                            </div>
                          </div>
                          <div
                            className="col-sm-12 col-md-4 col-lg-4"
                            id="yearOptClick"
                          >
                            <div className="company-icon">
                              <a
                                href="javascript:void(0)"
                                className="next"
                                data-activediv="registered-year"
                              >
                                <h6>2018</h6>
                              </a>
                            </div>
                          </div>
                          <div
                            className="col-sm-12 col-md-4 col-lg-4"
                            id="yearOptClick"
                          >
                            <div className="company-icon">
                              <a
                                href="javascript:void(0)"
                                className="next"
                                data-activediv="registered-year"
                              >
                                <h6>2017</h6>
                              </a>
                            </div>
                          </div>
                          <div
                            className="col-sm-12 col-md-4 col-lg-4"
                            id="yearOptClick"
                          >
                            <div className="company-icon">
                              <a
                                href="javascript:void(0)"
                                className="next"
                                data-activediv="registered-year"
                              >
                                <h6>2016</h6>
                              </a>
                            </div>
                          </div>
                          <div
                            className="col-sm-12 col-md-4 col-lg-4"
                            id="yearOptClick"
                          >
                            <div className="company-icon">
                              <a
                                href="javascript:void(0)"
                                className="next"
                                data-activediv="registered-year"
                              >
                                <h6>2015</h6>
                              </a>
                            </div>
                          </div>
                          <div
                            className="col-sm-12 col-md-4 col-lg-4"
                            id="yearOptClick"
                          >
                            <div className="company-icon">
                              <a
                                href="javascript:void(0)"
                                className="next"
                                data-activediv="registered-year"
                              >
                                <h6>2014</h6>
                              </a>
                            </div>
                          </div>
                          <div
                            className="col-sm-12 col-md-4 col-lg-4"
                            id="yearOptClick"
                          >
                            <div className="company-icon">
                              <a
                                href="javascript:void(0)"
                                className="next"
                                data-activediv="registered-year"
                              >
                                <h6>2013</h6>
                              </a>
                            </div>
                          </div>
                          <div
                            className="col-sm-12 col-md-4 col-lg-4"
                            id="yearOptClick"
                          >
                            <div className="company-icon">
                              <a
                                href="javascript:void(0)"
                                className="next"
                                data-activediv="registered-year"
                              >
                                <h6>2012</h6>
                              </a>
                            </div>
                          </div>
                          <div
                            className="col-sm-12 col-md-4 col-lg-4"
                            id="yearOptClick"
                          >
                            <div className="company-icon">
                              <a
                                href="javascript:void(0)"
                                className="next"
                                data-activediv="registered-year"
                              >
                                <h6>2011</h6>
                              </a>
                            </div>
                          </div>
                          <div
                            className="col-sm-12 col-md-4 col-lg-4"
                            id="yearOptClick"
                          >
                            <div className="company-icon">
                              <a
                                href="javascript:void(0)"
                                className="next"
                                data-activediv="registered-year"
                              >
                                <h6>2010</h6>
                              </a>
                            </div>
                          </div>
                          <div
                            className="col-sm-12 col-md-4 col-lg-4"
                            id="yearOptClick"
                          >
                            <div className="company-icon">
                              <a
                                href="javascript:void(0)"
                                className="next"
                                data-activediv="registered-year"
                              >
                                <h6>2009</h6>
                              </a>
                            </div>
                          </div>
                          <div
                            className="col-sm-12 col-md-4 col-lg-4"
                            id="yearOptClick"
                          >
                            <div className="company-icon">
                              <a
                                href="javascript:void(0)"
                                className="next"
                                data-activediv="registered-year"
                              >
                                <h6>2008</h6>
                              </a>
                            </div>
                          </div>
                          <div
                            className="col-sm-12 col-md-4 col-lg-4"
                            id="yearOptClick"
                          >
                            <div className="company-icon">
                              <a
                                href="javascript:void(0)"
                                className="next"
                                data-activediv="registered-year"
                              >
                                <h6>2007</h6>
                              </a>
                            </div>
                          </div>
                          <div
                            className="col-sm-12 col-md-4 col-lg-4"
                            id="yearOptClick"
                          >
                            <div className="company-icon">
                              <a
                                href="javascript:void(0)"
                                className="next"
                                data-activediv="registered-year"
                              >
                                <h6>2006</h6>
                              </a>
                            </div>
                          </div>
                          <div
                            className="col-sm-12 col-md-4 col-lg-4"
                            id="yearOptClick"
                          >
                            <div className="company-icon">
                              <a
                                href="javascript:void(0)"
                                className="next"
                                data-activediv="registered-year"
                              >
                                <h6>2005</h6>
                              </a>
                            </div>
                          </div>
                          <div
                            className="col-sm-12 col-md-4 col-lg-4"
                            id="yearOptClick"
                          >
                            <div className="company-icon">
                              <a
                                href="javascript:void(0)"
                                className="next"
                                data-activediv="registered-year"
                              >
                                <h6>2004</h6>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <span className="bg-img-beema-d">
                       
                      </span>
                    </div>
                    {/* STARTED */}
                    <div className="form-container animated previouspolicy">
                      <div className="d-flex align-items-center form-input two-form-detail">
                        <h2 className="text-center form-title w-100">
                          Do you have any existing policy?
                        </h2>
                        <form
                          id="4wDetails"
                          className="twowdetail-modal w-100"
                          name="4wDetails"
                          action="https://api.beema1.com/godigit/quickQuote4w"
                          method="post"
                        >
                          <input
                            type="hidden"
                            name="_token"
                            defaultValue="dNUlFinPuF4owzTj9J2k5pGvCFJkh03anFyF2hXC"
                          />{" "}
                          <div className="scroll-body">
                            <div className="custom-radio-top">
                              Yes{" "}
                              <input
                                type="radio"
                                name="rollover"
                                defaultValue="yes"
                                className="rollover"
                                data-id="yes"
                              />
                              No{" "}
                              <input
                                type="radio"
                                name="rollover"
                                defaultValue="no"
                                className="rollover"
                                data-id="no"
                                defaultChecked=""
                              />
                            </div>
                            <div className="rolloverDetails hide">
                              <div className="row company-insurenanc">
                                <div className="form-inner-input form-group">
                                  <label htmlFor="form_phone">
                                    Previous Insurer Name*
                                  </label>
                                  <select
                                    className="form-control"
                                    name="prename"
                                    id="prename"
                                  >
                                    <option value="">
                                      Select Pervious Insurance
                                    </option>
                                    <option value="Acko General Insurance Limited">
                                      Acko General Insurance Limited
                                    </option>
                                    <option value="Aditya Birla Health Insurance Co. Limited">
                                      Aditya Birla Health Insurance Co. Limited
                                    </option>
                                    <option value="Agriculture Insurance Co. of India Ltd.">
                                      Agriculture Insurance Co. of India Ltd.
                                    </option>
                                    <option value="Apollo Munich Health Insurance Company Limited">
                                      Apollo Munich Health Insurance Company
                                      Limited
                                    </option>
                                    <option value="BAJAJ ALIANZ">
                                      Bajaj Allianz General Insurance Co. Ltd.
                                    </option>
                                    <option value="BHARATI AXA">
                                      Bharti AXA General Insurance Company
                                      Limited
                                    </option>
                                    <option
                                      value="CHOLAMANDALAM
"
                                    >
                                      Cholamandalam MS General Insurance Co.
                                      Ltd.
                                    </option>
                                    <option value="Cigna TTK Health Insurance Company Ltd.">
                                      Cigna TTK Health Insurance Company Ltd.
                                    </option>
                                    <option value="DHFL General Insurance Limited">
                                      DHFL General Insurance Limited
                                    </option>
                                    <option value="Edelweiss General Insurance">
                                      Edelweiss General Insurance
                                    </option>
                                    <option value="Export Credit Guarantee Corporation of India Ltd.">
                                      Export Credit Guarantee Corporation of
                                      India Ltd.
                                    </option>
                                    <option
                                      value="FGI
"
                                    >
                                      Future Generali India Insurance Company
                                      Limited
                                    </option>
                                    <option value="Go Digit General Insurance Limited">
                                      Go Digit General Insurance Limited
                                    </option>
                                    <option value="HDFC CHUBB">
                                      HDFC ERGO General Insurance Co. Ltd.
                                    </option>
                                    <option
                                      value="ICICI LOMBARD
"
                                    >
                                      ICICI Lombard General Insurance Co. Ltd.
                                    </option>
                                    <option
                                      value="IFFCO TOKIO
"
                                    >
                                      IFFCO Tokio General Insurance Co. Ltd.
                                    </option>
                                    <option value="Kotak Mahindra General Insurance Company Limited">
                                      Kotak Mahindra General Insurance Company
                                      Limited
                                    </option>
                                    <option value="Liberty Videocon General Insurance Company Limited,">
                                      Liberty Videocon General Insurance Company
                                      Limited,
                                    </option>
                                    <option
                                      value="MAGMA
"
                                    >
                                      Magma HDI General Insurance Company
                                      Limited
                                    </option>
                                    <option value="Max Bupa Health Insurance Company Ltd.">
                                      Max Bupa Health Insurance Company Ltd.
                                    </option>
                                    <option
                                      value="NATIONAL
"
                                    >
                                      National Insurance Co. Ltd.
                                    </option>
                                    <option value="Others  Not available">
                                      Others Not available
                                    </option>
                                    <option value="Raheja QBE General Insurance Company Limited">
                                      Raheja QBE General Insurance Company
                                      Limited
                                    </option>
                                    <option
                                      value="RELIANCE
"
                                    >
                                      Reliance General Insurance Co. Ltd.
                                    </option>
                                    <option value="Religare Health Insurance Company Limited">
                                      Religare Health Insurance Company Limited
                                    </option>
                                    <option value="ROYAL SUNDARAM">
                                      Royal Sundaram General Insurance Co.
                                      Limited
                                    </option>
                                    <option value="SBI General Insurance Company Limited">
                                      SBI General Insurance Company Limited
                                    </option>
                                    <option value="SHRI RAM GIC">
                                      Shriram General Insurance Company Limited,
                                    </option>
                                    <option value="Star Health and Allied Insurance Company Limited">
                                      Star Health and Allied Insurance Company
                                      Limited
                                    </option>
                                    <option value="TATA AIG">
                                      Tata AIG General Insurance Co. Ltd.
                                    </option>
                                    <option value="NIA">
                                      The New India Assurance Co. Ltd.
                                    </option>
                                    <option value="ORIENTAL">
                                      The Oriental Insurance Co. Ltd.
                                    </option>
                                    <option
                                      value="UIIC
"
                                    >
                                      United India Insurance Co. Ltd.
                                    </option>
                                    <option value="USGI">
                                      Universal Sompo General Insurance Co. Ltd.
                                    </option>
                                  </select>
                                </div>
                                <div className="form-inner-input form-group">
                                  <label htmlFor="form_phone">
                                    Previous Policy Number*
                                  </label>
                                  <input
                                    type="text"
                                    name="prenumber"
                                    id="prenumber"
                                    defaultValue=""
                                    className="form-control"
                                  />
                                </div>
                                <div className="form-inner-input form-group">
                                  <label htmlFor="form_phone">
                                    Previous Policy Type*
                                  </label>
                                  <select
                                    className="form-control"
                                    name="prepolty"
                                    id="prepolty"
                                  >
                                    <option value="">Policy Type</option>
                                    <option value="Third Party">
                                      Third Party
                                    </option>
                                    <option value="Comprehensive Package">
                                      Comprehensive
                                    </option>
                                  </select>
                                </div>
                                <div className="form-inner-input form-group">
                                  <label htmlFor="form_phone">
                                    Previous Policy Term
                                  </label>
                                  <select
                                    className="form-control"
                                    name="odtp"
                                    id="odtp"
                                  >
                                    <option value="1OD_1TP">
                                      1 Year OD and 1 Year TP
                                    </option>
                                    <option value={-1}>Policy Type Term</option>
                                    <option value="1OD_3TP">
                                      1 Year OD and 3 Year TP
                                    </option>
                                    <option value="0OD_5TP">
                                      5 Year TP only
                                    </option>
                                    <option value="5OD_5TP">
                                      5 Year OD and 5 year TP
                                    </option>
                                    <option value="1OD_5TP">
                                      1 Year OD and 5 Year TP
                                    </option>
                                    <option value="3OD_3TP">
                                      3 Year OD and 3 Year TP
                                    </option>
                                    <option value="1T_1TP">
                                      1 Year Theft+1 Year TP
                                    </option>
                                    <option value="1F_1TP">
                                      1 Year Fire+1 Year TP
                                    </option>
                                    <option value="2OD_2TP">
                                      2 Years OD and 2 Years TP
                                    </option>
                                    <option value="1F_0TP">
                                      1 Year Fire Only
                                    </option>
                                    <option value="1OD_0TP">
                                      1 Year Own Damage only
                                    </option>
                                  </select>
                                </div>
                                <div className="form-inner-input form-group">
                                  <label htmlFor="form_phone">
                                    Claim On Previous Policy*
                                  </label>
                                  {/* <input type="text" name="cprepol" value="" class="form-control" id="cprepol"> */}
                                  <select
                                    name="cprepol"
                                    id="cprepol"
                                    className="form-control"
                                  >
                                    <option value={0}>0</option>
                                    <option value={1}>1</option>
                                    <option value={2}>2</option>
                                    <option value={3}>3</option>
                                    <option value={4}>4</option>
                                    <option value={5}>5</option>
                                  </select>
                                </div>
                                <div className="form-inner-input form-group">
                                  <label htmlFor="form_phone">
                                    Total No Of OD Claims*
                                  </label>
                                  {/* <input type="text" name="preodpol" id="preodpol" value="" class="form-control"> */}
                                  <select
                                    name="preodpol"
                                    id="preodpol"
                                    className="form-control"
                                  >
                                    <option value={0}>0</option>
                                    <option value={1}>1</option>
                                    <option value={2}>2</option>
                                    <option value={3}>3</option>
                                    <option value={4}>4</option>
                                    <option value={5}>5</option>
                                  </select>
                                </div>
                                <div className="form-inner-input form-group">
                                  <label htmlFor="form_phone">
                                    Bonus On Previous Policy*
                                  </label>
                                  <input
                                    type="text"
                                    name="prebopol"
                                    id="prebopol"
                                    defaultValue=""
                                    className="form-control"
                                  />
                                </div>
                                <div className="form-inner-input form-group">
                                  <label htmlFor="form_phone">
                                    No Of Claims On Previous Policy*
                                  </label>
                                  {/* <input type="text" name="preclipol" id="preclipol" value="" class="form-control"> */}
                                  <select
                                    name="preclipol"
                                    id="preclipol"
                                    className="form-control"
                                  >
                                    <option value={0}>0</option>
                                    <option value={1}>1</option>
                                    <option value={2}>2</option>
                                    <option value={3}>3</option>
                                    <option value={4}>4</option>
                                    <option value={5}>5</option>
                                  </select>
                                </div>
                                <div className="form-inner-input form-group">
                                  <label htmlFor="form_phone">
                                    Previous Policy Start Date*
                                  </label>
                                  <input
                                    type="date"
                                    max="2999-12-31"
                                    name="prepolsdate"
                                    id="prepolsdate"
                                    defaultValue=""
                                    className="form-control"
                                  />
                                </div>
                                <div className="form-inner-input form-group">
                                  <label htmlFor="form_phone">
                                    Previous Policy End Date*
                                  </label>
                                  <input
                                    type="date"
                                    max="2999-12-31"
                                    name="prepoledate"
                                    id="prepoledate"
                                    defaultValue=""
                                    className="form-control"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                      <label className="error valierror hide">
                        *All the fields are required
                      </label>
                      <div className="blue-btn">
                        <a
                          href="javascript:void(0)"
                          className="next nextbtn validatenext"
                          data-activediv="previouspolicy"
                        >
                          NEXT
                          
                        </a>
                      </div>
                    </div>
                    <div className="form-container animated customerdetail">
                      <div className="d-flex align-items-center form-input two-form-detail">
                        <h2 className="text-center form-title w-100">
                          Please provide your personal details.
                        </h2>
                        <div className="scroll-body">
                          <div className="row company-insurenanc">
                            <div className="form-inner-input form-group">
                              <label htmlFor="form_name">
                                Name* (As per KYC document)
                              </label>
                              <input
                                type="text"
                                name="name"
                                id="name"
                                className="form-control"
                              />
                            </div>
                            <div className="form-inner-input form-group">
                              <label htmlFor="form_phone">Phone*</label>
                              <input
                                type="text"
                                name="phone"
                                id="phone"
                                className="form-control"
                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                max={10}
                                min={10}
                              />
                              <div id="phone-vali" className="error hide">
                                Please enter a valid number.
                              </div>
                            </div>
                            <div className="form-inner-input form-group">
                              <label htmlFor="form_phone">Email*</label>
                              <input
                                type="text"
                                name="email"
                                id="personalemail"
                                className="form-control"
                              />
                            </div>
                            <div className="form-inner-input form-group">
                              <label htmlFor="form_name">Date of birth*</label>
                              <input
                                type="date"
                                name="birth_date"
                                id="birth_date"
                                className="form-control"
                                max="2024-03-18"
                              />
                            </div>
                            <div className="form-inner-input form-group">
                              <label htmlFor="form_phone">Pincode*</label>
                              <input
                                type="text"
                                name="pincode"
                                id="personalcity"
                                className="form-control"
                              />
                            </div>
                            <div className="form-inner-input form-group">
                              <label htmlFor="form_phone">Address*</label>
                              <input
                                type="text"
                                name="address"
                                id="address"
                                className="form-control"
                              />
                            </div>
                            <div className="form-inner-input form-group">
                              <label htmlFor="form_phone">City*</label>
                              <input
                                type="text"
                                name="city"
                                id="personalcity"
                                className="form-control"
                              />
                            </div>
                            <div className="form-inner-input form-group">
                              <label htmlFor="form_phone">State*</label>
                              <select
                                className="form-control"
                                id="state"
                                name="state"
                              >
                                <option value="">Select</option>
                                <option value="ANDAMAN AND NICOBAR ISLANDS">
                                  ANDAMAN AND NICOBAR ISLANDS
                                </option>
                                <option value="ANDHRA PRADESH">
                                  ANDHRA PRADESH
                                </option>
                                <option value="ARUNACHAL PRADESH">
                                  ARUNACHAL PRADESH
                                </option>
                                <option value="ASSAM">ASSAM</option>
                                <option value="BIHAR">BIHAR</option>
                                <option value="CHATTISGARH">CHATTISGARH</option>
                                <option value="CHANDIGARH">CHANDIGARH</option>
                                <option value="DAMAN AND DIU">
                                  DAMAN AND DIU
                                </option>
                                <option value="DELHI">DELHI</option>
                                <option value="DADRA AND NAGAR HAVELI">
                                  DADRA AND NAGAR HAVELI
                                </option>
                                <option value="GOA">GOA</option>
                                <option value="GUJARAT">GUJARAT</option>
                                <option value="HIMACHAL PRADESH">
                                  HIMACHAL PRADESH
                                </option>
                                <option value="HARYANA">HARYANA</option>
                                <option value="JAMMU AND KASHMIR">
                                  JAMMU AND KASHMIR
                                </option>
                                <option value="JHARKHAND">JHARKHAND</option>
                                <option value="KERALA">KERALA</option>
                                <option value="KARNATAKA">KARNATAKA</option>
                                <option value="LAKSHADWEEP">LAKSHADWEEP</option>
                                <option value="MEGHALAYA">MEGHALAYA</option>
                                <option value="MAHARASHTRA">MAHARASHTRA</option>
                                <option value="MANIPUR">MANIPUR</option>
                                <option value="MADHYA PRADESH">
                                  MADHYA PRADESH
                                </option>
                                <option value="MIZORAM">MIZORAM</option>
                                <option value="NAGALAND">NAGALAND</option>
                                <option value="ORISSA">ORISSA</option>
                                <option value="PUNJAB">PUNJAB</option>
                                <option value="PONDICHERRY">PONDICHERRY</option>
                                <option value="RAJASTHAN">RAJASTHAN</option>
                                <option value="SIKKIM">SIKKIM</option>
                                <option value="TAMIL NADU">TAMIL NADU</option>
                                <option value="TRIPURA">TRIPURA</option>
                                <option value="UTTARAKHAND">UTTARAKHAND</option>
                                <option value="UTTAR PRADESH">
                                  UTTAR PRADESH
                                </option>
                                <option value="WEST BENGAL">WEST BENGAL</option>
                                <option value="TELANGANA">TELANGANA</option>
                              </select>
                            </div>
                            <div className="form-inner-input form-group">
                              <label htmlFor="nomination_name">
                                Nominee Name
                              </label>
                              <input
                                id="nomination_name"
                                type="text"
                                name="nomination_name"
                                className="form-control"
                                placeholder="Enter Nomination Name"
                              />
                            </div>
                            <div className="form-inner-input form-group">
                              <label htmlFor="nomination_relation">
                                Nominee Relation
                              </label>
                              <select
                                name="nomination_relation"
                                className="form-control"
                                id="nomination_relation"
                              >
                                <option value="">Select Relation</option>
                                <option value="SELF">Self</option>
                                <option value="SPOUSE">Spouse</option>
                                <option value="SON">Son</option>
                                <option value="DAUGHTER">Daughter</option>
                                <option value="FATHER">Father</option>
                                <option value="MOTHER">Mother</option>
                                <option value="FATHER IN LAW">
                                  Father In Law
                                </option>
                                <option value="MOTHER IN LAW">
                                  Mother In Law
                                </option>
                                <option value="BROTHER">Brother</option>
                                <option value="SISTER">Sister</option>
                                <option value="UNCLE">Uncle</option>
                                <option value="AUNT">Aunt</option>
                                <option value="GRAND SON">Grand Son</option>
                                <option value="GRAND DAUGHTER">
                                  Grand Daughter
                                </option>
                              </select>
                            </div>
                            <div className="form-inner-input form-group">
                              <label htmlFor="nomination_dob">
                                Nominee Date of Birth
                              </label>
                              <input
                                id="nomination_dob"
                                type="date"
                                name="nomination_dob"
                                className="form-control"
                                placeholder="Enter Nomination Date Of Birth"
                                max="2024-03-18"
                              />
                            </div>
                            <div className="form-inner-input form-group">
                              <label htmlFor="nomination_gender">
                                Nominee Gender
                              </label>
                              <select
                                name="nomination_gender"
                                id="nomination_gender"
                                className="form-control"
                              >
                                <option value="" disabled="" selected="">
                                  Select Gender
                                </option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                              </select>
                            </div>
                            <div className="form-inner-input form-group">
                              <label htmlFor="form_name">Engine Number*</label>
                              <input
                                type="text"
                                name="engine_number"
                                id="engine_number"
                                className="form-control"
                              />
                            </div>
                            <div className="form-inner-input form-group">
                              <label htmlFor="form_name">Chassis Number*</label>
                              <input
                                type="text"
                                name="chassis_number"
                                id="chassis_number"
                                className="form-control"
                              />
                            </div>
                            <div className="form-inner-input form-group">
                              <label htmlFor="form_phone">
                                Vehicle Registration Date*
                              </label>
                              <input
                                type="date"
                                name="vrd"
                                id="vrd"
                                className="form-control"
                                max="2024-03-18"
                              />
                            </div>
                            <input
                              type="hidden"
                              name="is_form_submit"
                              defaultValue="Yes"
                            />
                          </div>
                        </div>
                      </div>
                      <label className="error valierrorper hide">
                        *All the fields are required
                      </label>
                      <div className="blue-btn">
                        <a
                          href="javascript:void(0)"
                          className="next nextbtn"
                          data-activediv="customerdetail"
                        >
                          NEXT
                         
                        </a>
                      </div>
                    </div>
                    {/* END */}
                    <div className="form-container animated submitData">
                      <h2 className="text-center form-title">
                        All details about your car
                      </h2>
                      <div className="scroll-body">
                        <input
                          type="hidden"
                          id="inputCarNumber"
                          name="inputCarNumber"
                        />
                        <input type="hidden" id="inputMake" name="inputMake" />
                        <input
                          type="hidden"
                          id="inputModel"
                          name="inputModel"
                        />
                        <input
                          type="hidden"
                          id="inputVarient"
                          name="inputVarient"
                        />
                        <input type="hidden" id="inputFuel" name="inputFuel" />
                        <input
                          type="hidden"
                          id="inputVehicleUse"
                          name="inputVehicleUse"
                        />
                        <input
                          type="hidden"
                          id="inputRegYear"
                          name="inputRegYear"
                        />
                        <div className="last-step-result">
                          <h4 className="form-title" id="showCarNumber" />
                          <h4 className="form-title" id="showMake" />
                          <h4 className="form-title" id="showModel" />
                          <h4 className="form-title" id="showVarient" />
                          <h4 className="form-title" id="showFuel" />
                          <h4 className="form-title" id="showYear">
                            {" "}
                          </h4>
                          <h4 className="form-title" id="showVehicleUse" />
                        </div>
                      </div>
                      <span className="bg-img-beema-d">
                      
                      </span>
                      <div className="blue-btn multi-form-submit">
                        <a href="javascript:void(0)">
                          <input
                            type="submit"
                            className="form-btn finalfrmsubt"
                          />{" "}
                         
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <input type="hidden" id="rollverval" />
      </section>
    </div>
  </div>
</section>

<div className="container">
    <h5 style={{color:"#2959B8"}}>What is Car Insurance Claim Settlement ?
</h5>
    <div className="yellowline"></div>
    <p>Car Insurance Claim is the process under which a car insurance policyholders requests the insurer to compensate for the expenses/damage incurred by him/her due to an unfortunate event involving his/her car. The amount and extent of the claim amount depend on the Insured Declared Value (IDV) of your car and the type of car insurance policy bought by you - third party, own damage or comprehensive.
            </p>    
  </div>




<section className="full-space-block">
  <div className="container">
    <div className="row full-space-block-row">
      <div className="col-sm-12 col-md-12 col-lg-12">
        <div className="insurance-less-farage">
          <div className="simple-text-block">
     
            <section
              className="car-claim-settlement"
              style={{
                margin: "54px 0px",
                fontFamily:
                  '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
              }}
            >
              <div
                className="container"
                style={{
                  width: 1200,
                  padding: "0px 15px !important",
                  maxWidth: "1200px !important"
                }}
              >
                <div
                  className="row car-claim-settlement-row-two justify-content-center align-items-center"
                  style={{ marginRight: "-15px", marginLeft: "-15px" }}
                >
                  <div
                    className="col-sm-12 col-md-12 col-lg-12"
                    style={{ width: 1200, paddingRight: 15, paddingLeft: 15 }}
                  >
                    <div className="insurance-less-farage">
                      <div
                        className="simple-text-block text-center"
                        style={{ marginBottom: 39 }}
                      >
                


                        <h5 style={{color:"#2959B8", fontSize: 26,
                            fontFamily:"Poppins",}}>What is Car Insurance Claim Settlement ?
</h5>




                        <p
                          style={{
                            fontFamily:"Poppins",
                            lineHeight: 1.3,
                            color: "rgb(20, 20, 20)"
                          }}
                        >
                          A car insurance claim can be settled in either of the
                          two ways:
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="row car-claim-settlement-row-two"
                  style={{ marginRight: "-15px", marginLeft: "-15px" }}
                >
                  <div
                    className="col-sm-12 col-md-6 col-lg-6"
                    style={{ width: 600, paddingRight: 15, paddingLeft: 15 }}
                  >
                    <div
                      className="back-ground-gray"
                      style={{
                        background: "0% center rgb(242, 245, 251)",
                        borderRadius: 20,
                        padding: "32px 20px"
                      }}
                    >
                      <div
                        className="head-box"
                        style={{ position: "relative" }}
                      >
                        <h4
                          style={{
                            marginBottom: 0,
                            fontWeight: "bold",
                            lineHeight: 1.3,
                            fontSize: 100,
                            fontFamily:"Poppins",
                            color: "rgba(20, 20, 20, 0.1)"
                          }}
                        >
                          01
                        </h4>
                        <h6
                          style={{
                            fontWeight: "bold",
                            lineHeight: 1.3,
                            fontSize: 26,
                            position: "absolute",
                            top: 55,
                            left: "15.8906px",
                            transform: "translateY(-50%)",
                            fontFamily:"Poppins",
                            color:"#2959B8"
                          }}
                        >
                          Cashless Claim
                        </h6>
                      </div>
                      <div className="car-claim-settlement-box-body">
                        <p
                          style={{
                            marginBottom: 0,
                            fontFamily:"Poppins",
                            lineHeight: 1.3,
                            color: "rgb(20, 20, 20)"
                          }}
                        >
                          If you get your car repaired at a garage authorised by
                          the insurer then you enter a cashless claim settlement
                          process. As the name suggests, under this type of
                          claim the entire process is cashless which means that
                          the policyholder is not required to pay any cash/money
                          to the insurer authorised garage for the repair of
                          his/her car. The insurer makes the payment to the
                          garage directly subject to the claim amount. The
                          policyholder is only liable to pay for the compulsory
                          deductible and voluntary deductible if opted for at
                          the time of policy purchase.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-sm-12 col-md-6 col-lg-6"
                    style={{ width: 600, paddingRight: 15, paddingLeft: 15 }}
                  >
                    <div
                      className="back-ground-gray"
                      style={{
                        background: "0% center rgb(242, 245, 251)",
                        borderRadius: 20,
                        padding: "32px 20px"
                      }}
                    >
                      <div
                        className="head-box"
                        style={{ position: "relative" }}
                      >
                        <h4
                          style={{
                            marginBottom: 0,
                            fontWeight: "bold",
                            lineHeight: 1.3,
                            fontSize: 100,
                            fontFamily:"Poppins",
                            color: "rgba(20, 20, 20, 0.1)"
                          }}
                        >
                          02
                        </h4>
                        <h6
                          style={{
                            fontWeight: "bold",
                            lineHeight: 1.3,
                            fontSize: 26,
                            position: "absolute",
                            top: 55,
                            left: "15.8906px",
                            transform: "translateY(-50%)",
                            fontFamily:"Poppins",
                            color:"#2959B8"
                          }}
                        >
                          Reimbursement Claim
                        </h6>
                      </div>
                      <div className="car-claim-settlement-box-body">
                        <p
                          style={{
                            marginBottom: 0,
                            fontFamily:"Poppins",
                            lineHeight: 1.3,
                            color: "rgb(20, 20, 20)"
                          }}
                        >
                          When you get your car repaired at a garage/workshop
                          outside the authorised network of the insurer, you
                          enter a reimbursement claim settlement process. Under
                          this process, the policyholder makes the payment for
                          the repair cost at the garage of his/her choice and
                          gets the repair cost reimbursed from the insurer later
                          subject to the claim amount. The insurer reimburses
                          the entire claim amount to the policyholder after
                          factoring in the applicable deductibles - compulsory
                          and voluntary (if opted for).
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              className="full-space-block"
              style={{
                marginBottom: 67,
                fontFamily:
                  '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
              }}
            >
              <div
                className="container"
                style={{
                  width: 1200,
                  padding: "0px 15px !important",
                  maxWidth: "1200px !important"
                }}
              >
                <div
                  className="row full-space-block-row"
                  style={{ marginRight: "-15px", marginLeft: "-15px" }}
                >
                  <div
                    className="col-sm-12 col-md-12 col-lg-12"
                    style={{ width: 1200, paddingRight: 15, paddingLeft: 15 }}
                  >
                    <div className="insurance-less-farage">
                      <div className="simple-text-block">
                        <h5
                          style={{
                            fontWeight: 500,
                            lineHeight: 1.3,
                            fontSize: 26,
                            fontFamily:"Poppins",
                            color:"#2959B8"
                          }}
                        >
                          Documents Required for Car Insurance Claim Settlement
                        </h5>
                  
                        <p
                          style={{
                            fontFamily:"Poppins",
                            lineHeight: 1.3,
                            color: "rgb(20, 20, 20)"
                          }}
                        >
                          EYou need the following documents at the time of claim
                          settlement of your car insurance:
                        </p>
                        <div className="yellowline"></div>
                        
                        
                        <p
                          style={{
                            fontFamily:"Poppins",
                            lineHeight: 1.3,
                            color: "rgb(20, 20, 20)"
                          }}
                        >
                            <FastImage
            url={"Website%20Img/Hand.png?updatedAt=1718777726806"}
            width={auto}
            height={auto}
            alt={"ca-img"}
            />
                          &nbsp; &nbsp;
                          <span
                            style={{
                              backgroundColor: "initial",
                              fontSize: "1rem"
                            }}
                          >
                            Duly signed and filled claim form
                          </span>
                        </p>
                        <p
                          style={{
                            fontFamily:"Poppins",
                            lineHeight: 1.3,
                            color: "rgb(20, 20, 20)"
                          }}
                        >
                                           <FastImage
            url={"Website%20Img/Hand.png?updatedAt=1718777726806"}
            width={auto}
            height={auto}
            alt={"ca-img"}
            />
                          &nbsp; &nbsp;
                          <span
                            style={{
                              backgroundColor: "initial",
                              fontSize: "1rem"
                            }}
                          >
                            Insurance Policy Document
                          </span>
                        </p>
                        <p
                          style={{
                            fontFamily:"Poppins",
                            lineHeight: 1.3,
                            color: "rgb(20, 20, 20)"
                          }}
                        >
                                            <FastImage
            url={"Website%20Img/Hand.png?updatedAt=1718777726806"}
            width={auto}
            height={auto}
            alt={"ca-img"}
            />
                          &nbsp; &nbsp;
                          <span
                            style={{
                              backgroundColor: "initial",
                              fontSize: "1rem"
                            }}
                          >
                            Car’s Registration Certificate (RC)
                          </span>
                        </p>
                        <p
                          style={{
                            fontFamily:"Poppins",
                            lineHeight: 1.3,
                            color: "rgb(20, 20, 20)"
                          }}
                        >
                                            <FastImage
            url={"Website%20Img/Hand.png?updatedAt=1718777726806"}
            width={auto}
            height={auto}
            alt={"ca-img"}
            />
                          &nbsp; &nbsp;
                          <span
                            style={{
                              backgroundColor: "initial",
                              fontSize: "1rem"
                            }}
                          >
                            Driver’s licence (DL)
                          </span>
                        </p>
                        <p
                          style={{
                            fontFamily:"Poppins",
                            lineHeight: 1.3,
                            color: "rgb(20, 20, 20)"
                          }}
                        >
                                            <FastImage
            url={"Website%20Img/Hand.png?updatedAt=1718777726806"}
            width={auto}
            height={auto}
            alt={"ca-img"}
            />
                          &nbsp; &nbsp;
                          <span
                            style={{
                              backgroundColor: "initial",
                              fontSize: "1rem"
                            }}
                          >
                            Pollution Under Control (PUC) Certificate
                          </span>
                        </p>
                        <p
                          style={{
                            fontFamily:"Poppins",
                            lineHeight: 1.3,
                            color: "rgb(20, 20, 20)"
                          }}
                        >
                                           <FastImage
            url={"Website%20Img/Hand.png?updatedAt=1718777726806"}
            width={auto}
            height={auto}
            alt={"ca-img"}
            />
                          &nbsp; &nbsp;
                          <span
                            style={{
                              backgroundColor: "initial",
                              fontSize: "1rem"
                            }}
                          >
                            Original receipt/bills of repairs (in case of
                            reimbursement claim)
                          </span>
                        </p>
                        <p
                          style={{
                            fontFamily:"Poppins",
                            lineHeight: 1.3,
                            color: "rgb(20, 20, 20)"
                          }}
                        >
                                          <FastImage
            url={"Website%20Img/Hand.png?updatedAt=1718777726806"}
            width={auto}
            height={auto}
            alt={"ca-img"}
            />
                          &nbsp; &nbsp;
                          <span
                            style={{
                              backgroundColor: "initial",
                              fontSize: "1rem"
                            }}
                          >
                            FIR (in case of fatal injuries/accidents or third
                            party liabilities)
                          </span>
                        </p>
                        <p
                          style={{
                            fontFamily:"Poppins",
                            lineHeight: 1.3,
                            color: "rgb(20, 20, 20)"
                          }}
                        >                   <FastImage
                        url={"Website%20Img/Hand.png?updatedAt=1718777726806"}
                        width={auto}
                        height={auto}
                        alt={"ca-img"}
                        />
                          &nbsp; &nbsp;
                          <span
                            style={{
                              backgroundColor: "initial",
                              fontSize: "1rem"
                            }}
                          >
                            A copy of PAN Card (if the claim amount is more than
                            Rs. 1 lakh).
                          </span>
                        </p>
                        <p
                          style={{
                            fontFamily:"Poppins",
                            lineHeight: 1.3,
                            color: "rgb(20, 20, 20)"
                          }}
                        >
                                           <FastImage
            url={"Website%20Img/Hand.png?updatedAt=1718777726806"}
            width={auto}
            height={auto}
            alt={"ca-img"}
            />
                          &nbsp; &nbsp;
                          <span
                            style={{
                              backgroundColor: "initial",
                              fontSize: "1rem"
                            }}
                          >
                            Duly signed Satisfaction Voucher (SV) or Discharge
                            Voucher (DV)&nbsp;
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<PartnerCompany/>
<FAQ/>
    </>
  )
}

export default ClaimSettlement