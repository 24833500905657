import React, { useState, useEffect, useRef } from 'react';
import './ProgressStepper.css';
import { MdHealthAndSafety } from "react-icons/md";

const steps = [
  { id: 1, title: 'Notify the Insurer', description: 'Inform your insurer about the claim as soon as possible.' },
  { id: 2, title: 'Document the Damage', description: 'Take photos and videos of the damage or loss.' },
  { id: 3, title: 'Fill Claim Form', description: 'Fill out the claim form provided by your insurer.' },
  { id: 4, title: 'Submit Documents', description: 'Submit all required documents along with the claim form.' },
  { id: 5, title: 'Claim Assessment', description: 'The insurer will assess your claim and documents.' },
  { id: 6, title: 'Approval & Payout', description: 'Once approved, you will receive the claim payout.' },
];

const ProgressStepper = () => {
  const [activeStep, setActiveStep] = useState(0);
  const stepRefs = useRef([]);

  
  return (
    <div className="insurance-claim-process">
    
      <h2 className="process-title"> <MdHealthAndSafety /> Insurance Claim Process</h2>
      {/* <hr className='process-title-hr'></hr> */}
      <div className="steps">
        {steps.map((step, index) => (
          <div
            key={step.id}
            
            className={"step"}
          >
            <div className="step-number">{step.id}</div>
            <div className="step-content">
              <h3 className="step-title">{step.title}</h3>
              <hr className='step-title-hr'></hr>
              <p className="step-description">{step.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProgressStepper;
