import React,{useEffect} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import '../IncomeTax/Incometax.css'
import Calculatorlist from '../Calculator-Charat/Calculatorlist'





const Incometax = () => {

  const nav = useNavigate()

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>


    <div className="headingcalculator">
    <p>Home &nbsp;&gt;&nbsp;&nbsp;&nbsp;<Link to={'/calculator'}>calculator</Link>&nbsp;&nbsp;&nbsp;&nbsp;&gt;&nbsp;&nbsp;<span style={{color:'green'}}>income tax Calculator</span></p>
    <span className="titlecalculator">Income-Tax Calculator</span>
    </div>





<div className="taxmain">



<div className="calculatetaxdiv">

<p>
  <a class="btn btn-primary" data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
    Link with href
  </a>
  <button class="btn btn-primary" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
    Button with data-target
  </button>
</p>
<div class="collapse" id="collapseExample">
  <div class="card card-body">
    Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.
  </div>
</div>

</div>











<div className="resultse">
<Calculatorlist/>
</div>
</div>
</div>



  )
}

export default Incometax