import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import axios from "axios";
import Swal from "sweetalert2";
import Loader from "./Loader";
import { FaArrowRotateLeft } from "react-icons/fa6";

import FastImage from "../FastImage";
import { auto } from "@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core";

const Floater = () => {
  const [generatedCaptcha, setGeneratedCaptcha] = useState("");
  const [userInput, setUserInput] = useState("");
  const [resultMessage, setResultMessage] = useState("");

  // Function to generate a random alphanumeric string
  const generateRandomString = (length) => {
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
  };

  // Function to generate the CAPTCHA
  const generateCaptcha = (event) => {
    if (event) event.preventDefault();
    const captchaText = generateRandomString(6); // You can adjust the length as needed
    setGeneratedCaptcha(captchaText);
    setResultMessage("");
  };

  // Function to check if the entered CAPTCHA is correct
  // const checkCaptcha = () => {
  //   if (userInput === generatedCaptcha) {
  //     setResultMessage('Correct');
  //   } else {
  //     setResultMessage('Captcha is wrong');
  //     setUserInput('');
  //   }
  // };

  useEffect(() => {
    generateCaptcha();
  }, []);

  const [ErrorName, setErrorName] = useState("");
  const [ErrorNumber, setErrorNumber] = useState("");
  const [ErrorMail, setErrorMail] = useState("");
  const [ErrorPolicyType, setErrorPolicyType] = useState("");
  const [PolicyStatusError, setPolicyStatusError] = useState("");
  const [ErrorEndDate, setErrorEndDate] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    phone_number: "",
    email: "",
    policy_type: "*",
    policy_number: "",
    expiry_date: "",
  });

  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("userToken");

  // Handle input changes and update state
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    if (userInput === generatedCaptcha) {
      setResultMessage("Correct");
      setUserInput("");
    } else {
      setResultMessage("Captcha is wrong");
      setUserInput("");
      return;
    }

    if (formData.name === "") {
      setErrorName("Name is Requird");
    }
    if (formData.phone_number === "") {
      setErrorNumber("Mobile Number is Requird");
    }
    if (formData.email === "") {
      setErrorMail("Email is Requird");
    }
    if (formData.policy_type === "*") {
      setErrorPolicyType("Choose a Policy");
    }
    if (formData.policy_number === "") {
      setPolicyStatusError("Policy Number is Requirs");
    }
    if (!formData.expiry_date) {
      return setErrorEndDate("End Date must be required");
    }
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}user/dashboard/set_reminder`,
        formData,
        {
          params: { token: token },
        }
      );
      //console.log(response.data.status);

      if (response.data.status === true) {
        setLoading(false);
        Swal.fire({
          title: "Success!",
          text: "Your Request Submitted Successfully",
          icon: "success",
          confirmButtonText: "Done",
        });
      } else {
        console.error(response.data.message || "Error submitting request");
      }
    } catch (error) {
      console.error(error);
      setLoading(true);
    }
  };

  return (
    <>
      <section className="float">
        <div className="float-icon-sub">
          <Link
            to="#"
            className="float-icon flaot-icon-clock"
            target="_blank"
            data-toggle="modal"
            data-target="#reminderpopup"
          >
            <FastImage
              url={"Website%20Img/clock.gif?updatedAt=1718788143758"}
              width={auto}
              height={auto}
              alt={"clock"}
              src={"Website%20Img/clock.gif?updatedAt=1718788143758"}
            />
          </Link>
          <Link
            to="#"
            className="float-icon flaot-icon-phone"
            target="_blank"
            data-toggle="modal"
            data-target="#reminderpopupcalling"
          >
            <FastImage
              url={"Website%20Img/float-phone.svg?updatedAt=1718787185084"}
              width={auto}
              height={auto}
              alt={"phone"}
              src={"Website%20Img/float-phone.svg?updatedAt=1718787185084"}
            />
          </Link>
          <Link to="#" className="float-icon flaot-icon-chat" target="_blank">
            <FastImage
              url={"Website%20Img/float-chat.svg?updatedAt=1718787185081"}
              width={auto}
              height={auto}
              alt={"chat"}
              src={"Website%20Img/float-chat.svg?updatedAt=1718787185081"}
            />
          </Link>
        </div>
      </section>

      {/* Reminder Popup Clock */}
      {loading ? <Loader /> : null}

      <div
        class="modal fade"
        id="reminderpopup"
        tabindex="-1"
        role="dialog"
        aria-labelledby="reminderpopupTitle"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered extrawidthpopup modal-media"
          role="document"
        >
          <div class="modal-content ">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle"></h5>
              <button
                type="button"
                class="close close-float"
                data-dismiss="modal"
                aria-label="Close"
                id="close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body ">
              <h2 className="floater-h2">Get a Quick Reminder</h2>
              {/* <p style={{color:'red',fontSize:'8pt',textAlign:"center"}}>{SetError}</p> */}

              <FastImage
                className={"floater-img-data"}
                url={"Website%20Img/pop-up_a.webp?updatedAt=1718775653542"}
                width={auto}
                height={auto}
                alt={"mashquad"}
                src={"Website%20Img/pop-up_a.webp?updatedAt=1718775653542"}
              />

              <div className="container">
                <form action="" className="row">
                  <div className="col-md-6 mb-2">
                    <label htmlFor="name">
                      Name <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      id="name"
                      className="floater-inp"
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleInputChange}
                      onError={ErrorName}
                      style={{ border: "none" }}
                    />
                    <p
                      style={{
                        color: "red",
                        fontSize: "10pt",
                        marginLeft: "5px",
                      }}
                      hidden={ErrorName ? false : true}
                    >
                      {ErrorName}
                    </p>
                  </div>
                  <div className="col-md-6 mb-2">
                    <label htmlFor="mobile">
                      Mobile Number <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      id="phone_number"
                      className="floater-inp"
                      placeholder="Mobile Number"
                      value={formData.phone_number}
                      onChange={handleInputChange}
                      style={{ border: "none" }}
                    />
                    <p
                      style={{
                        color: "red",
                        fontSize: "10pt",
                        marginLeft: "5px",
                      }}
                      hidden={ErrorNumber ? false : true}
                    >
                      {ErrorNumber}
                    </p>
                  </div>
                  <div className="col-md-6 mb-2">
                    <label htmlFor="email">
                      Email <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="email"
                      id="email"
                      className="floater-inp"
                      placeholder="Email"
                      value={formData.email}
                      onChange={handleInputChange}
                      style={{ border: "none" }}
                    />
                    <p
                      style={{
                        color: "red",
                        fontSize: "10pt",
                        marginLeft: "5px",
                      }}
                      hidden={ErrorMail ? false : true}
                    >
                      {ErrorMail}
                    </p>
                  </div>
                  <div className="col-md-6 mb-2">
                    <label htmlFor="insuranceType">
                      Insurance Type <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      id="policy_type"
                      className="floater-inp"
                      value={formData.policy_type}
                      onChange={handleInputChange}
                      style={{ border: "none" }}
                    >
                      <option value="*">--Select--</option>
                      <option value="Health-insurance">Health Insurance</option>
                      <option value="term-insurance">Term Insurance</option>
                      <option value="Travel-insurance">Travel Insurance</option>
                      <option value="Car-insurance">Car Insurance</option>
                      <option value="Bike-insurance">Bike Insurance</option>
                      <option value="General-insurance">
                        General Insurance
                      </option>
                      <option value="Life-insurance">Life Insurance</option>
                    </select>
                    <p
                      style={{
                        color: "red",
                        fontSize: "10pt",
                        marginLeft: "5px",
                      }}
                      hidden={ErrorPolicyType ? false : true}
                    >
                      {ErrorPolicyType}
                    </p>
                  </div>
                  <div className="col-md-6 mb-2">
                    <label htmlFor="policyNumber">
                      Policy Number <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      id="policy_number"
                      className="floater-inp"
                      placeholder="Policy Number"
                      value={formData.policy_number}
                      onChange={handleInputChange}
                      style={{ border: "none" }}
                    />
                    <p
                      style={{
                        color: "red",
                        fontSize: "10pt",
                        marginLeft: "5px",
                      }}
                      hidden={PolicyStatusError ? false : true}
                    >
                      {PolicyStatusError}
                    </p>
                  </div>
                  <div className="col-md-6 mb-2">
                    <label htmlFor="startDate">
                      Policy End Date <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="date"
                      id="expiry_date"
                      className="floater-inp"
                      value={formData.expiry_date}
                      onChange={handleInputChange}
                      style={{ border: "none" }}
                    />
                    <p
                      style={{
                        color: "red",
                        fontSize: "10pt",
                        marginLeft: "5px",
                      }}
                      hidden={ErrorEndDate ? false : true}
                    >
                      {ErrorEndDate}
                    </p>
                  </div>

                  <div className="col-md-6 mb-2">
                    <div className="containerdivcaptcha">
                      <div className="captchaSection">
                        <div
                          id="captcha"
                          className="genarateCaptcha float-captcha"
                        >
                          {generatedCaptcha}
                        </div>
                        <button
                          onClick={(event) => generateCaptcha(event)}
                          style={{ border: "none" }}
                        >
                          <FaArrowRotateLeft />
                        </button>
                      </div>
                      <div id="inputSection_captcha">
                        <input
                          className="floater-inp"
                          type="text"
                          value={userInput}
                          onChange={(e) => setUserInput(e.target.value)}
                          placeholder="Enter CAPTCHA"
                          style={{ border: "none" }}
                        />

                        <p id="result">{resultMessage}</p>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div className="popupmainsubmit">
              <button type="button" onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>

      {/*End Hear */}

      <div
        className="modal fade"
        id="ScheduleCallBack"
        tabindex="-1"
        role="dialog"
        aria-labelledby="reminderpopupTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="call-modle-img">
                <FastImage
                  url={"Website%20Img/modal-call.svg?updatedAt=1718787185165"}
                  width={auto}
                  height={auto}
                  alt={"call-modal"}
                  src={"Website%20Img/modal-call.svg?updatedAt=1718787185165"}
                />
              </div>
              <div className="call-modal-content">
                <h3>Need help on deciding the right insurance plan for you?</h3>
                <p>Lorem Ipsum is simply dummy text of the printing and</p>
                <Link className="toll-free-number" to="tel:+91 80000 88888">
                  <div className="left-icon">
                    <FastImage
                      url={
                        "Website%20Img/toll-free-icon.svg?updatedAt=1718787185542"
                      }
                      width={auto}
                      height={auto}
                      alt={"toll-free"}
                      src={
                        "Website%20Img/toll-free-icon.svg?updatedAt=1718787185542"
                      }
                    />
                  </div>
                  <div className="tollfree-text">
                    <p>Toll Free No.</p>
                    <a href="tel:+91 3323593438">
                    +91 3323593438
</a>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="reminderpopupcalling"
        tabindex="-1"
        role="dialog"
        aria-labelledby="reminderpopupTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <FastImage
              url={"Website%20Img/pop-up_a.webp?updatedAt=1718775653542"}
              width={auto}
              height={auto}
              alt={"mashquad"}
              src={"Website%20Img/pop-up_a.webp?updatedAt=1718775653542"}
              className={"call-modal-photo"}
            />

            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle"></h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div className="modal-body">
                <div className="call-modle-img callingnmodal">
                  <FastImage
                    url={"Website%20Img/modal-call.svg?updatedAt=1718787185165"}
                    width={auto}
                    height={auto}
                    alt={"phone"}
                    src={"Website%20Img/modal-call.svg?updatedAt=1718787185165"}
                   
                  />
                </div>
                <div className="call-modal-content">
                  <h3>
                    Need help on deciding the right insurance plan for you?
                  </h3>
                  <p />
                  <a className="toll-free-number" href="tel:+91 3323593438">
                    <div className="tollfree-text">
                      <p>Toll Free No.</p>
                      <a href="tel:+91 3323593438">
    <p>+91 3323593438</p>
</a>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Floater;
