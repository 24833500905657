import React from "react";

import "../Stepper/Stepper.css";
// import "../../components/GetStartedPop.css";
import { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { Link, useNavigate } from "react-router-dom";
import FastImage from "../../FastImage";
import { auto } from "@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core";

const Stepper = () => {
  const [captcha, setCaptcha] = useState(generateCaptcha());
  const [userInput, setUserInput] = useState("");
  const [isValid, setIsValid] = useState(false);

  const nav = useNavigate();

  function generateCaptcha() {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < 6; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  }





  return (
    <>
    <div className="fixwidthDivtext">
      <div className="container">
    
  
              <div className="tile-text-with-text-shadow">
                <h2>HOW IT WORKS</h2>
              </div>

<div className="commonone">


              <p className="textformat">
                <h4 className="theheadingcolor">We Have The Best Team And The Best Process</h4>
                <div className="yellowline"></div>
                <span className="textformat">
                Compare and buy the best insurance plans hassle-free with Beema1. Our experts help you 
                to make the right choice of insurance plan for you and your family to secure your future&nbsp; Beema1 will act as your ally at every stage
                  to make informed decisions.
                </span>
                <br />
              </p>
              <div className="blue-btn">
                <Link to={'/health-insurance'}>
                  <span>Get started.</span>
               

                  <FastImage
              url={"Website%20Img/button-arrow.png?updatedAt=1718712281133"}
              width={auto}
              height={auto}
              alt={"arrow"}
              src={"Website%20Img/button-arrow.png?updatedAt=1718712281133"}
            />

                </Link>

                
     




              </div>
              </div>










      
  


        <div className="imgdiv">
      
          <FastImage
            className={"stepper-img-home"}
              url={"Website%20Img/oie_qmOTvKYV1hPY.png?updatedAt=1718793255066"}
              width={auto}
              height={auto}
              alt={"stepper"}
              src={"Website%20Img/oie_qmOTvKYV1hPY.png?updatedAt=1718793255066"}
            />
        </div>
        <div className="textdivss">
          <div className="onesdivs">
         
            <FastImage
       
              url={"Website%20Img/About%20us%20page-rafiki.png?updatedAt=1718793255086"}
              width={auto}
              height={auto}
              alt={"about"}
              src={"Website%20Img/About%20us%20page-rafiki.png?updatedAt=1718793255086"}
            />
            <br />
            <span>Tell Us About Yourself</span>
            <p>Enter your information to get instant access to competitive insurance quotes. Easily compare 
            insurance policy premiums from top-rated insurers..</p>
          </div>
          <div className="onesdivs">
          

            <FastImage
       
       url={"Website%20Img/About%20me-amico.png?updatedAt=1718793255442"}
       width={auto}
       height={auto}
       alt={"compare"}
       src={"Website%20Img/About%20me-amico.png?updatedAt=1718793255442"}
     />
            <br />
            <span>Compare & Select The Right Plan</span>
            <p>Compare plans based on features and costs, align with your needs and budget, and then 
            choose the best-fit plan</p>
          </div>
          <div className="onesdivs">
         
            <FastImage
       
       url={"Website%20Img/Credit%20card-bro.43a29722030a978b448e.png?updatedAt=1718780532640"}
       width={auto}
       height={auto}
       alt={"pay"}
       src={"Website%20Img/Credit%20card-bro.43a29722030a978b448e.png?updatedAt=1718780532640"}
     />
            <br />
            <span>Pay & Get policy Within 5 Minutes</span>
            <p>Buy your policy online and receive it instantly in your email inbox.</p>
          </div>
          <div className="onesdivs">
         
            <FastImage
       
       url={"Website%20Img/handsakhe.png?updatedAt=1718793255291"}
       width={auto}
       height={auto}
       alt={"handshake"}
       src={"Website%20Img/handsakhe.png?updatedAt=1718793255291"}
     />
            <br />
            <span>Always There for You</span>
            <p>Our customer service team is dedicated to helping you with any queries.</p>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default Stepper;
