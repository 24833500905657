import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import Slider from "@mui/material/Slider";
import "../SIP/SIP.css";
import { Link } from "react-router-dom";
import Redirect from "../../../AllPageComponents/Rederict";
import Calculatorlist from "../Calculator-Charat/Calculatorlist";
import { Helmet } from "react-helmet-async";


const EMI = () => {
  var convertRupeesIntoWords = require('convert-rupees-into-words');


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
     // State variables
  const [loanAmount, setLoanAmount] = useState(100000);
  const [interestAmount, setInterestAmount] = useState(6.5);
  const [yearsAmount, setYearsAmount] = useState(5);
  const [monthlyPayment, setMonthlyPayment] = useState("");
  const [totalPayment, setTotalPayment] = useState("");
  const [totalInterest, setTotalInterest] = useState("");
  const [error, setError] = useState("");





  
  // Loan Amount Function
  const handleLoanAmountChange = (event) => {
    const value = event.target.value;
    const parsedValue = parseFloat(value);
  
    if (isNaN(parsedValue)) {
      setLoanAmount(''); // Clear the input if it's not a valid number
    } else {
      setLoanAmount(parsedValue); // Update the state with the valid number
    }
  
    if(parsedValue > 100000000){
      setLoanAmount(100000000)
    }

  };
  






  










  // Interest Rate Function
  const handleInterestRateChange = (event) => {


    const value = event.target.value;
    if (value === '' || (!isNaN(value) && /^[0-9]*\.?[0-9]*$/.test(value))) {
      if (parseFloat(value) > 30) {
        setInterestAmount(30);
      } else {
        setInterestAmount(value);
      }
    }
  };
  






  // Tenure Period Function
  const handleTenurePeriodChange = (event) => {
    // setYearsAmount(event.target.value);


    const value = event.target.value;
    const parsedValue = parseFloat(value);
  
    if (isNaN(parsedValue)) {
      setYearsAmount(''); // Clear the input if it's not a valid number
    } else {
      setYearsAmount(parsedValue); // Update the state with the valid number
    }
  
    if(parsedValue > 40){
      setYearsAmount(40)
    }





console.log("Tanure",parsedValue)



  };















  // Calculate Results Function
  const calculateResults = () => {
    //console.log("Calculating...");

    // Validation
    if (loanAmount === "" || interestAmount === "" || yearsAmount === "") {
      setError("Please fill in all fields");
      return;
    }

    // Calculation Variables
    const principle = parseFloat(loanAmount);
    const calculatedInterest = parseFloat(interestAmount) / 100 / 12;
    const calculatedPayments = parseFloat(yearsAmount) * 12;

    // Calculate Monthly Payments
    const x = Math.pow(1 + calculatedInterest, calculatedPayments);
    const monthly = (principle * x * calculatedInterest) / (x - 1);

    if (isFinite(monthly)) {
      setMonthlyPayment(monthly.toFixed(2));
      setTotalPayment((monthly * calculatedPayments).toFixed(2));
      setTotalInterest((monthly * calculatedPayments - principle).toFixed(2));
      setError("");
    } else {
      setError("Please check your numbers");
      setMonthlyPayment("");
      setTotalPayment("");
      setTotalInterest("");
    }
  };

  // Show Results Function
  const showResults = () => {
    document.getElementById("results").style.display = "block";
  };

  // Calculate results when sliders change
  useEffect(() => {
    calculateResults();
  }, [loanAmount, interestAmount, yearsAmount]);
  const graphAmount = () => {
    const Interest = Math.floor(totalPayment);
    return Interest;
}


const monthlyPaymenttoWord = convertRupeesIntoWords(Math.ceil(monthlyPayment))
const LoanAmountToWord = convertRupeesIntoWords(Math.ceil(loanAmount))
const LoanInterestToword = convertRupeesIntoWords(Math.ceil(totalInterest))
const TotalpaymentToword = convertRupeesIntoWords(Math.ceil(totalPayment))



  return (
    <div>
<Helmet>
  <title>Online EMI Loan Calculator</title>
  <meta name="description" content="Calculate your EMI for personal, car, and business loans with our online EMI loan calculator." />
  <meta name="keywords" content="personal loan EMI calculator, car loan EMI calculator, business loan EMI calculator, online EMI calculator" />
</Helmet>





                    <section className="margincalculator"></section>
    <div className="headingcalculator">
    <p>Home &nbsp;&gt;&nbsp;&nbsp;&nbsp;<Link to={'/calculator'}>calculator</Link>&nbsp;&nbsp;&nbsp;&nbsp;&gt;&nbsp;&nbsp;<span style={{color:'green'}}>EMI calculator</span></p>

  <h1 className="titlecalculator">EMI <span className='Mainheading'>Calculator</span></h1>

  <sapn className="CalculatorDiscription">An EMI calculator helps borrowers estimate their monthly loan repayments based on factors like
  principal amount, interest rate, and tenure, facilitating financial planning. It offers insights into 
  loan affordability and repayment schedules, making it easier to manage finances effectively</sapn>
</div>

      <div>
        <div className="calculatorhead">
          <div className="underdiv">
            <div className="calculatorse">
              {/* Calculator start div start hear */}
              <div className="calculaterse">
                <div className="calculator">
                  {/* Amount calculator */}









                  <div className="onecalculator">
                    <div className="amountdives">
                      <p>Loan Amount</p>
                      <input
                        type="text"
                        value={loanAmount}
                        onChange={handleLoanAmountChange}
                      />
                    </div>
                    <div className="amountselectordiv">
                      <Slider
                        value={loanAmount}
                        onChange={handleLoanAmountChange}
                        min={100000}
                        max={100000000}
                        step={1000}
                        aria-label="Loan Amount"
                        valueLabelDisplay="auto"
                      />
                    </div>
                  </div>













                  {/* Amount calculator */}
                  <div className="onecalculator">
                    <div className="amountdives">
                      <p>Rate of Interest % (P.A)</p>
                      <input
                        type="text"
                        value={interestAmount}
                        onChange={handleInterestRateChange
                        }
                      />
                    </div>
                    <div className="amountselectordiv">
                      <Slider
                        value={interestAmount}
                        onChange={
                          handleInterestRateChange}
                        min={1}
                        max={30}
                        step={0.1}
                        aria-label="Interest Rate"
                        valueLabelDisplay="auto"
                      />
                    </div>
                  </div>

                  {/* Amount calculator */}
                  <div className="onecalculator">
                    <div className="amountdives">
                      <p>Tanure (Year) </p>
                      <input
                        type="text"
                        value={yearsAmount}
                        onChange={(event) => {
                          handleTenurePeriodChange(event);
                        }}
                      />
                    </div>
                    <div className="amountselectordiv">
                      <Slider
                        value={yearsAmount}
                        onChange={(event) => {
                          handleTenurePeriodChange(event);
                        }}
                        min={1}
                        max={100}
                        aria-label="Time Period"
                        valueLabelDisplay="auto"
                      />
                    </div>
                  </div>
                  <div className="calculateamountdiv">
                    <div>
                      <p>
                  Monthly EMI:{" "}
                        <span style={{ color: "#0166B3" }}>
  {Math.round(parseFloat(monthlyPayment)).toLocaleString("en-IN", {
    style: "currency",
    currency: "INR"
  })}
</span>


</p>
<p className='textword'>{monthlyPaymenttoWord}</p>

                      <p>
                  Loan Amount:{" "}
                        <span style={{ color: "#0166B3" }}>
  {Math.round(parseFloat(loanAmount)).toLocaleString("en-IN", {
    style: "currency",
    currency: "INR"
  })}
</span>

                      </p>

<p className='textword'>{LoanAmountToWord}</p>

                      <p>
                        Total interest:{" "}
                        <span style={{ color: "#0166B3" }}>
  {Math.round(parseFloat(totalInterest)).toLocaleString("en-IN", {
    style: "currency",
    currency: "INR"
  })}
</span>

                      </p>

<p className='textword'>{LoanInterestToword}</p>
                      <p>
                        Total Amount:{" "}
                        <span style={{ color: "#0166B3" }}>
  {Math.round(parseFloat(totalPayment)).toLocaleString("en-IN", {
    style: "currency",
    currency: "INR"
  })}
</span>

                      </p>
                      <p className='textword'>{TotalpaymentToword}</p>
                    </div>
                  </div>
                </div>
                <div className="graphdiv">
                  <Chart
        type='donut'
        width={400}
        height={445}
        series={[
          Number(loanAmount) || 100,
          Number(graphAmount()) || 100
        ]}
        options={{
          labels:['Princepal Amount', ' Interest Amount']
        }}
      />
                </div>
              </div>
            </div>
            {/* Result part start hear */}
            <div className="resultse">
           <Calculatorlist/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EMI