import React, { useState } from "react";
import { TextField, Box, Typography, Grid } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "./AnswerSetOne.css";
import {
  updateRelianceQAlist,
  selectReliancecheckedQuestion,
} from "../../redux/Slice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import dayjs from "dayjs";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";

const AnswerSetOne = ({ pindex, qdata, parentqData }) => {
  const [sufferingSince, setSufferingSince] = useState("");
  const [consultationDate, setConsultationDate] = useState("");
  const [diagnoseData, setDiagnoseData] = useState();
  const [otherDiseaseData, setOtherDiseaseData] = useState();
  const [treatmentData, setTreatmentData] = useState();
  const [exactData, setExactData] = useState();
  const [hospitalData, setHospitalData] = useState();

  const dispatch = useDispatch();

  const handleClick = (event) => {
    event.stopPropagation();
  };



  // const formatDate = (dateStr) => {
  //   const date = new Date(dateStr);

  //   // Extract day, month, and year
  //   const day = String(date.getDate()).padStart(2, "0");
  //   const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  //   const year = date.getFullYear();

  //   return `${month}/${day}/${year}`;
  // };

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  //  console.log('The Anwsar Indexing',pindex,qdata)

  const [dataObj, setDataObj] = useState();

  const ReliancecheckedQuestion = useSelector(selectReliancecheckedQuestion);

  console.log("the answer set one data", ReliancecheckedQuestion);

  console.log("the pindex", pindex);

  console.log("the q data in set one", qdata);

  useEffect(() => {
    if (ReliancecheckedQuestion) {
      console.log("hdfc checked question", ReliancecheckedQuestion);
      const keys = Object.keys(ReliancecheckedQuestion);
      const numbersToCheck = [1];
      const anyNumberPresent = numbersToCheck.some((number) =>
        keys.includes(number.toString())
      );

      const resultObjects = numbersToCheck
        .filter((number) => keys.includes(number.toString()))
        .map((number) => ReliancecheckedQuestion[number.toString()]);

      console.log("the resultObjects", resultObjects);
      let val;

      for (let key in resultObjects) {
        if (resultObjects[key].hasOwnProperty(pindex)) {
          let data_one = resultObjects[key][pindex];
          // console.log("data_one",qdata.question_id)
          let val = data_one.find(
            (item) => item.Options[0].OptionId == qdata.QuestionSetCode
          );
          if (val != undefined) {
            console.log("val in for", val);
            let newobject = val;
            console.log("the newObject", newobject);

            if (newobject) {
              newobject.Options.map((item, index) => {
                if (item.OptionId == qdata.QuestionSetCode) {
                  setDataObj(item);
                  return;
                }
              });
            }
          }
        }
      }
    }
  }, [ReliancecheckedQuestion]);

  const convertMMDate = (dateString) =>{
    const date = new Date(dateString);
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getUTCFullYear();
  
    return `${month}/${year}`;
  }
  console.log("the final data in set one", dataObj);


  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box className="set-one-body">
        {/* <Typography className='set-one-body-title'>
          Q. Hypertension/ High blood pressure
        </Typography> */}

        <Grid container spacing={2} className="lower-set-one-answers">
          <Grid item xs={6}>
            <TextField
              label="Other Disease"
              type="text"
              fullWidth
              variant="standard"
              className="underline-textField"
              value={
                otherDiseaseData
                  ? otherDiseaseData
                  : dataObj?.OtherDisease || ""
              }
              onClick={(e) => {
                handleClick(e);
              }}
              onChange={(e) => {
                handleClick(e);
                console.log(
                  "the data in other disease",
                  pindex,
                  qdata,
                  parentqData
                );
                setOtherDiseaseData(e.target.value);

                dispatch(
                  updateRelianceQAlist({
                    name: "OtherDisease",
                    value: e.target.value,
                    pindex: pindex,
                    qdata: qdata,
                    parentqData: parentqData,
                  })
                );
              }}
            />
          </Grid>
          <Grid
            item
            xs={6}
            className="set-one-date-upper"
            onClick={(e) => {
              handleClick(e);
            }}
          >
            {/* <DesktopDatePicker
              label="Suffering Since"
              inputFormat="dd/mm/yyyy"
              value={sufferingSince ? sufferingSince : ""}
              onClick={(e)=>{handleClick(e)}}
              onChange={( newValue) => {setSufferingSince(newValue);
      
                dispatch(
                      updateRelianceQAlist({
                        name: "SufferingSince",
                        value: formatDate(newValue),
                        pindex: pindex,
                        qdata: qdata,
                        parentqData: parentqData,
                      })
                    )
              }}
              renderInput={(params) => <TextField {...params} fullWidth className='bordered-textField' />
              
              }
            /> */}

            <Flatpickr
              className="flat-date"
              value={sufferingSince ? sufferingSince : dataObj?.SufferingSince}
              options={{
                enableTime: false, // Disable the time picker
                dateFormat: "d/m/Y", // Set the desired date format
              }}
              placeholder="SufferingSince"
              onChange={(newValue) => {
                setSufferingSince(newValue);
                console.log("the suffering since data", newValue)
                dispatch(
                  updateRelianceQAlist({
                    name: "SufferingSince",
                    value:convertMMDate(newValue[0]),
                    pindex: pindex,
                    qdata: qdata,
                    parentqData: parentqData,
                  })
                );
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              label="Treatment Taken"
              type="text"
              fullWidth
              variant="standard"
              className="underline-textField"
              onClick={(e) => {
                handleClick(e);
              }}
              value={
                treatmentData ? treatmentData : dataObj?.TreatmentTaken || ""
              }
              onChange={(e) => {
                handleClick(e);
                setTreatmentData(e.target.value);
                dispatch(
                  updateRelianceQAlist({
                    name: "TreatmentTaken",
                    value: e.target.value,
                    pindex: pindex,
                    qdata: qdata,
                    parentqData: parentqData,
                  })
                );
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Exact Diagnosis"
              type="text"
              fullWidth
              variant="standard"
              className="underline-textField"
              onClick={(e) => {
                handleClick(e);
              }}
              value={exactData ? exactData : dataObj?.ExactDiagnosis || ""}
              onChange={(e) => {
                handleClick(e);
                setExactData(e.target.value);
                dispatch(
                  updateRelianceQAlist({
                    name: "ExactDiagnosis",
                    value: e.target.value,
                    pindex: pindex,
                    qdata: qdata,
                    parentqData: parentqData,
                  })
                );
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Hospital Name"
              type="text"
              fullWidth
              variant="standard"
              className="underline-textField"
              onClick={(e) => {
                handleClick(e);
              }}
              value={hospitalData ? hospitalData : dataObj?.HospitalName || ""}
              onChange={(e) => {
                handleClick(e);
                setHospitalData(e.target.value);
                dispatch(
                  updateRelianceQAlist({
                    name: "HospitalName",
                    value: e.target.value,
                    pindex: pindex,
                    qdata: qdata,
                    parentqData: parentqData,
                  })
                );
              }}
            />
          </Grid>
          <Grid
            item
            xs={6}
            className="set-one-date lower-date"
            onClick={(e) => {
              handleClick(e);
            }}
          >
            {/* <DesktopDatePicker
              label="Consultation Date"
              inputFormat="dd/mm/yyyy"
              value={consultationDate ? consultationDate : ""}
              onChange={(newValue) => {
                setConsultationDate(newValue);

                dispatch(
                  updateRelianceQAlist({
                    name: "ConsultationDate",
                    value: formatDate(newValue),
                    pindex: pindex,
                    qdata: qdata,
                    parentqData: parentqData,
                  })
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  className="bordered-textField"
                />
              )}
            /> */}

            <Flatpickr
          
              style={{marginTop:"6px"}}
              className="flat-date"
              value={consultationDate ? consultationDate : dataObj?.ConsultationDate}
              options={{
                enableTime: false, // Disable the time picker
                dateFormat: "d/m/Y", // Set the desired date format
              }}
              placeholder="Consultation Date"
              onChange={(newValue) => {
                setConsultationDate(newValue);

                dispatch(
                  updateRelianceQAlist({
                    name: "ConsultationDate",
                    value: convertMMDate(newValue[0]),
                    pindex: pindex,
                    qdata: qdata,
                    parentqData: parentqData,
                  })
                );
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </LocalizationProvider>
  );
};

export default AnswerSetOne;
