import React, { useEffect, useState } from "react";
import StepSlider from "../../commonComponents/StepSlider";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import relation from "../../../relation.json";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet-async";
import { useSelector, useDispatch } from "react-redux";
import {
  selectProposal,
  addInsuredPersion,
  deleteInsuredPersion,
  addRemoveInsuredPersion,
  incrementInsuredPersion,
  decrementInsuredPersion,
  addInsuredData,
  updateInsuredData,
  settingGender
} from "../../redux/Slice";

import { useSpring, animated } from "@react-spring/web";
import Preferredpolicy from "../../../AllPageComponents/ Preferredpolicy";

const HealthStep1 = () => {
  const navigate = useNavigate();
  const [countSon, setCountSon] = useState(0);
  const [countDaughter, setCountDaughter] = useState(0);
  const [selectedRelation, setSelectedRelation] = useState([]);
  const isInitialMount = useRef(true);
  const dispatch = useDispatch();

  const incrementSon = () => {
    if (countSon < 6 && countDaughter < 6) {
      setCountSon((prevCount) => {
        const newCount = prevCount + 1;
        localStorage.setItem("countSon", newCount.toString());
        updateLocalStorage("Son", "");
        dispatch(incrementInsuredPersion({ reletion: "Son" }));
        return newCount;
      });
    }
  };

  const decrementSon = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (countSon > 0) {
      setCountSon((prevCount) => {
        const newCount = prevCount - 1;
        localStorage.setItem("countSon", newCount.toString());
        return newCount;
      });
      const storedData =
        JSON.parse(localStorage.getItem("InsuredDetail")) || [];
      let lastIndex = -1;
      // for (let i = storedData.length - 1; i >= 0; i--) {
      // 	if (storedData[i].InsuredRelation === 'Son' && storedData[i].InsuredAge === '') {
      // 		lastIndex = i;
      // 		break;
      // 	}
      // }
      // if (lastIndex !== -1) {
      storedData.splice(lastIndex, 1);
      localStorage.setItem("InsuredDetail", JSON.stringify(storedData));
      dispatch(decrementInsuredPersion({ reletion: "Son" }));

      // }
    }
  };

  const incrementDaughter = () => {
    if (countDaughter < 6 && countSon < 6) {
      setCountDaughter((prevCount) => {
        const newCount = prevCount + 1;
        localStorage.setItem("countDaughter", newCount.toString());
        updateLocalStorage("Daughter", "");
        dispatch(incrementInsuredPersion({ reletion: "Daughter" }));

        return newCount;
      });
    }
  };

  const decrementDaughter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (countDaughter > 0) {
      setCountDaughter((prevCount) => {
        const newCount = prevCount - 1;
        localStorage.setItem("countDaughter", newCount.toString());
        return newCount;
      });
      const storedData =
        JSON.parse(localStorage.getItem("InsuredDetail")) || [];
      let lastIndex = -1;
     
      storedData.splice(lastIndex, 1);
      localStorage.setItem("InsuredDetail", JSON.stringify(storedData));
      dispatch(decrementInsuredPersion({ reletion: "Daughter" }));
      // }
    }
  };

  const updateLocalStorage = (relation, age) => {
    const storedData = JSON.parse(localStorage.getItem("InsuredDetail")) || [];
    storedData.push({
      InsuredRelation: relation,
      InsuredAge: age,
    });
    localStorage.setItem("InsuredDetail", JSON.stringify(storedData));
  };

  const isSonCheckboxDisabled = () => countSon + countDaughter === 6;
  const isDaughterCheckboxDisabled = () => countSon + countDaughter === 6;
  const isDaughterDisabled = () => countSon === 6;
  const isSonDisabled = () => countDaughter === 6;

  const [data, setData] = useState([]);

  const storedSelectedType = localStorage.getItem("selectedType");
  const [selectedType, setSelectedType] = useState(storedSelectedType || "");

  const handleMaleClick = async (type) => {
    try {
      const response = relation;
      const filteredData = response.filter((e) => e.type === type);
      setData(filteredData);
      localStorage.setItem("Gender", type);
      localStorage.setItem("selectedType", type);
      if (!(selectedType === type)) {
        localStorage.removeItem("countDaughter");
        localStorage.removeItem("countSon");
        localStorage.removeItem("InsuredDetail");
        localStorage.removeItem("ProposalName");
        localStorage.removeItem("Proposalphonenumber");
        localStorage.removeItem("District");
        localStorage.removeItem("State");
        localStorage.removeItem("PinCode");
        localStorage.removeItem("PED");

        setCountSon("");
        setCountDaughter("");
        dispatch(addInsuredData([]));
      }
      setSelectedType("M");
    } catch (error) {}
  };

  const handleFemaleClick = (type) => {
    try {
      const response = relation;
      const filteredData = response.filter((e) => e.type === type);
      setData(filteredData);
      localStorage.setItem("Gender", type);
      localStorage.setItem("selectedType", type);
      setSelectedType("F");
      if (!(selectedType === type)) {
        localStorage.removeItem("countDaughter");
        localStorage.removeItem("countSon");
        localStorage.removeItem("InsuredDetail");
        localStorage.removeItem("ProposalName");
        localStorage.removeItem("Proposalphonenumber");
        localStorage.removeItem("District");
        localStorage.removeItem("State");
        localStorage.removeItem("PinCode");
        localStorage.removeItem("PED");
        setCountSon("");
        setCountDaughter("");
        dispatch(addInsuredData([]));
      }
    } catch (error) {}
  };

  const [noOfElement, setnoOfElement] = useState(8);
  const [loadMore, setLoadMore] = useState(true);
  const slice = data.slice(0, noOfElement);
  const handleMoreOptionsClick = () => {
    setnoOfElement(noOfElement + noOfElement);
    setLoadMore(false);
  };

  const [countMother, setCountMother] = useState(0);
  const [countBrother, setCountBrother] = useState(0);
  const [countSelf, setCountSelf] = useState(0);
  const [countWife, setCountWife] = useState(0);
  const [countHusband, setCountHusband] = useState(0);
  const [countFather, setCountFather] = useState(0);
  const [countGrand_Father, setCountGrand_Father] = useState(0);
  const [countGrand_Mother, setCountGrand_Mother] = useState(0);
  const [countFather_In_Law, setCountFather_In_Law] = useState(0);
  const [countMother_In_Law, setCountMother_In_Law] = useState(0);
  const [countSister, setCountSister] = useState(0);
  const [countUncle, setCountUncle] = useState(0);
  const [countAunt, setCountAunt] = useState(0);
  const [countGrand_Daughter, setCountGrand_Daughter] = useState(0);
  const [countGrand_Son, setCountGrand_Son] = useState(0);

  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const handleLinkClick = (event) => {
    if (localStorage.getItem("InsuredDetail") === null) {
      toast.error("Select Minmum one Option ", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      setShowErrorMessage(true);
      event.preventDefault();
    } else {
      dispatch(settingGender(localStorage.getItem('Gender')))
      setShowErrorMessage(false);
      navigate("/healthStep2");
    }
  };

  const handleFirstEnter = () => {
    localStorage.setItem("selectedType", "M");
  };

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    const storedSelectedType = localStorage.getItem("selectedType");
    if (storedSelectedType) {
      if (storedSelectedType === "M") {
        handleMaleClick("M");
      } else if (storedSelectedType === "F") {
        handleFemaleClick("F");
      }
    }
  }, [selectedType]);
  const proposalData = useSelector(selectProposal);
  //console.log("proposalData", proposalData);
  const handleChangeCheckbox = (event) => {
    switch (event.target.value) {
      case "Mother":
        setCountMother((prevCount) => (prevCount === 0 ? 1 : 0));
        break;
      case "Father":
        setCountFather((prevCount) => (prevCount === 0 ? 1 : 0));
        break;
      case "Grand Father":
        setCountGrand_Father((prevCount) => (prevCount === 0 ? 1 : 0));
        break;
      case "Grand Mother":
        setCountGrand_Mother((prevCount) => (prevCount === 0 ? 1 : 0));
        break;
      case "Brother":
        setCountBrother((prevCount) => (prevCount === 0 ? 1 : 0));
        break;
      case "Sister":
        setCountSister((prevCount) => (prevCount === 0 ? 1 : 0));
        break;
      case "Self":
        setCountSelf((prevCount) => (prevCount === 0 ? 1 : 0));
        break;
      case "Husband":
        setCountHusband((prevCount) => (prevCount === 0 ? 1 : 0));
        break;
      case "Wife":
        setCountWife((prevCount) => (prevCount === 0 ? 1 : 0));
        break;
      case "Father In Law":
        setCountFather_In_Law((prevCount) => (prevCount === 0 ? 1 : 0));
        break;
      case "Mother In Law":
        setCountMother_In_Law((prevCount) => (prevCount === 0 ? 1 : 0));
        break;
      // case "Uncle":
      //   setCountUncle((prevCount) => (prevCount === 0 ? 1 : 0));
      //   break;
      // case "Aunt":
      //   setCountAunt((prevCount) => (prevCount === 0 ? 1 : 0));
      //   break;
      // case "Grand Daughter":
      //   setCountGrand_Daughter((prevCount) => (prevCount === 0 ? 1 : 0));
      //   break;
      // case "Grand Son":
      //   setCountGrand_Son((prevCount) => (prevCount === 0 ? 1 : 0));
      //   break;
      default:
        break;
    }

    const updatedSelectedRelation = [...selectedRelation];
    const existingIndex = updatedSelectedRelation.findIndex(
      (item) => item.InsuredRelation === event.target.value
    );
    if (event.target.checked) {
      if (existingIndex !== -1) {
        updatedSelectedRelation[existingIndex].InsuredAge = "";
      } else {
        updatedSelectedRelation.push({
          InsuredRelation: event.target.value,
          InsuredAge: "",
        });
      }
    } else {
      if (existingIndex !== -1) {
        updatedSelectedRelation.splice(existingIndex, 1);
      }
    }

    var existingData = JSON.parse(localStorage.getItem("InsuredDetail")) || [];
    if (event.target.checked) {
      updatedSelectedRelation.forEach((newItem) => {
        const existingItemIndex = existingData.findIndex(
          (item) => item.InsuredRelation === newItem.InsuredRelation
        );
        if (existingItemIndex !== -1) {
          existingData[existingItemIndex] = newItem;
        } else {
          existingData.push(newItem);
        }
      });
    } else {
      existingData = existingData.filter(
        (item) => item.InsuredRelation !== event.target.value
      );
    }
    localStorage.setItem("InsuredDetail", JSON.stringify(existingData));
    dispatch(addRemoveInsuredPersion({ reletion: event.target.value }));
  };

  const getCheckboxStateMF = (radiotarget) => {
    if (localStorage.getItem("selectedType") === null) {
      handleFirstEnter();
    }
    return selectedType === radiotarget;
  };

  useEffect(() => {
    const storedSelectedType = localStorage.getItem("selectedType");
    const insured_detail = JSON.parse(localStorage.getItem("InsuredDetail"));
    if (insured_detail) {
      for (let i = 0; i < insured_detail.length; i++) {
        dispatch(
          updateInsuredData({
            index: i,
            data: [
              {
                key: "InsuredRelation",
                value: insured_detail[i]["InsuredRelation"],
              },
              { key: "InsuredAge", value: insured_detail[i]["InsuredAge"] },
            ],
          })
        );
      }
    }

    if (storedSelectedType) {
      setSelectedType(storedSelectedType);
      if (storedSelectedType === "M") {
        handleMaleClick("M");
      } else if (storedSelectedType === "F") {
        handleFemaleClick("F");
      }
    }
  }, []);

  const isCheckboxChecked = (value) => {
    const storedSelectedRelation = localStorage.getItem("InsuredDetail");
    if (storedSelectedRelation != null) {
      if (storedSelectedRelation.length === 2) {
        localStorage.removeItem("InsuredDetail");
      }
    } else if (storedSelectedRelation === null) {
      localStorage.removeItem("InsuredDetail");
    }
    const parsedData = storedSelectedRelation
      ? JSON.parse(storedSelectedRelation)
      : [];
    return parsedData.some((item) => item.InsuredRelation === value);
  };

  const isCheckboxCheckedSon = () => {
    setCountSon((prevCount) => {
      const newCount = prevCount > 0 ? 0 : 1;
      let storedData = JSON.parse(localStorage.getItem("InsuredDetail")) || [];
      if (newCount === 1) {
        storedData = storedData.filter(
          (item) => item.InsuredRelation !== "Son"
        );
        storedData.push({
          InsuredRelation: "Son",
          InsuredAge: "",
        });
        localStorage.setItem("countSon", 1);
        localStorage.setItem("InsuredDetail", JSON.stringify(storedData));
      } else if (newCount === 0) {
        storedData = storedData.filter(
          (item) => item.InsuredRelation !== "Son"
        );
        localStorage.setItem("countSon", 0);
        localStorage.setItem("InsuredDetail", JSON.stringify(storedData));
      }
      return newCount;
    });
    dispatch(addRemoveInsuredPersion({ reletion: "Son" }));
  };

  const isCheckboxCheckedDaughter = () => {
    setCountDaughter((prevCount) => {
      const newCount = prevCount > 0 ? 0 : 1;
      let storedData = JSON.parse(localStorage.getItem("InsuredDetail")) || [];
      if (newCount === 1) {
        storedData = storedData.filter(
          (item) => item.InsuredRelation !== "Daughter"
        );
        storedData.push({
          InsuredRelation: "Daughter",
          InsuredAge: "",
        });
        localStorage.setItem("countDaughter", 1);
        localStorage.setItem("InsuredDetail", JSON.stringify(storedData));
      } else if (newCount === 0) {
        storedData = storedData.filter(
          (item) => item.InsuredRelation !== "Daughter"
        );
        localStorage.setItem("countDaughter", 0);
        localStorage.setItem("InsuredDetail", JSON.stringify(storedData));
      }
      return newCount;
    });
    dispatch(addRemoveInsuredPersion({ reletion: "Daughter" }));
  };

  useEffect(() => {
    const storedCountson = localStorage.getItem("countSon");
    if (storedCountson) {
      setCountSon(parseInt(storedCountson, 10));
    }
    const storedCountdat = localStorage.getItem("countDaughter");
    if (storedCountdat) {
      setCountDaughter(parseInt(storedCountdat, 10));
    }
  }, [handleFemaleClick, handleMaleClick]);

  //   Animation
  const [isVisible, setIsVisible] = React.useState(false);

  const animations = useSpring({
    opacity: isVisible ? 1 : 0,
    transform: isVisible ? "translateY(0%)" : "translateY(50%)",
    config: { mass: 1, tension: 100, friction: 20, duration: 500 },
  });

  useEffect(() => {
    setIsVisible(true);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>HealthStep1</title>
        <meta name="description" content="test" />
      </Helmet>
      <animated.div style={animations}>
        <h1
          style={{
            textAlign: "center",
            padding: "20px",
            color: "#0055A5",
            marginTop: 20,
          }}
        >
          Find top plans for you with{" "}
          <span style={{ color: "#E50318" }}>upto 25% </span>
          <span style={{ color: "#FD9202" }}>discount</span>
          <sup>**</sup>
        </h1>
      </animated.div>

      <StepSlider activeStep={1} />
      <animated.div style={animations}>
        <div className="btn-group btn-group-lg mybutton" role="group">
          <div className="radio-block">
            <input
              type="radio"
              name="animations"
              id="animations-on"
              className="radiohide"
              checked={getCheckboxStateMF("M")}
              onChange={() => handleMaleClick("M")}
            />
            <label for="animations-on" className="labelMF">
              Male
            </label>
            <input
              type="radio"
              name="animations"
              className="radiohide"
              id="animations-off"
              checked={getCheckboxStateMF("F")}
              onChange={() => handleFemaleClick("F")}
            />
            <label for="animations-off" className="off-label labelMF">
              Female
            </label>
            <span className="selected" aria-hidden="true"></span>
          </div>
        </div>
      </animated.div>
      <animated.div style={animations}>

       <div className="container">
        <div className="row">
          <div className="col-md-12" style={{margin:'auto'}}>

          <div className="usercenter">
          <fieldset className="checkbox-group">
            {slice?.map((e, index) => {
              return (
                <>
                  {e.value === "son" && (
                    <div className="checkbox">
                      <label className="checkbox-wrapper">
                        <input
                          type="checkbox"
                          className="checkbox-input"
                          value={e.value}
                          checked={isCheckboxChecked(e.level) || countSon > 0}
                          onClick={isCheckboxCheckedSon}
                          disabled={isSonDisabled()}
                        />
                        <span className="checkbox-tile">
                          <span className="checkbox-icon">
                            <img
                              src={e.image1}
                              style={{ height: "50px" }}
                            />
                          </span>
                          <span className="checkbox-label">{e.level}</span>
                          {countSon > 0 && (
                            <div className="form_button">
                              <button
                                className="value-button minus"
                                onClick={(e)=>decrementSon(e)}
                              >
                                -
                              </button>
                              <input
                                type="number"
                                className="number"
                                value={countSon}
                                checked={countSon > 0}
                                onChange={() => {
                                  setCountSon((prevCount) =>
                                    prevCount > 0 ? 0 : 1
                                  );
                                }}
                              />
                              <button
                                className="value-button plus"
                                onClick={incrementSon}
                                disabled={isSonCheckboxDisabled()}
                              >
                                +
                              </button>
                            </div>
                          )}
                        </span>
                      </label>
                    </div>
                  )}
                  {e.value === "daughter" && (
                    <div className="checkbox">
                      <label className="checkbox-wrapper">
                        <input
                          type="checkbox"
                          className="checkbox-input"
                          value={e.value}
                          checked={
                            isCheckboxChecked(e.level) || countDaughter > 0
                          }
                          onClick={(e)=>isCheckboxCheckedDaughter(e)}
                          disabled={isDaughterDisabled()}
                        />
                        <span className="checkbox-tile">
                          <span className="checkbox-icon">
                            <img
                              src={e.image1}
                              style={{ height: "50px" }}
                            />
                          </span>
                          <span className="checkbox-label">Daughter</span>
                          {countDaughter > 0 && (
                            <div className="form_button">
                              <button
                                className="value-button minus"
                                onClick={decrementDaughter}
                              >
                                -
                              </button>
                              <input
                                type="number"
                                className="number"
                                value={countDaughter}
                                checked={countDaughter > 0}
                                onChange={() => {
                                  setCountDaughter((prevCount) =>
                                    prevCount > 0 ? 0 : 1
                                  );
                                }}
                              />
                              <button
                                className="value-button plus"
                                onClick={incrementDaughter}
                                disabled={isDaughterCheckboxDisabled()}
                              >
                                +
                              </button>
                            </div>
                          )}
                        </span>
                      </label>
                    </div>
                  )}
                  {e.value !== "son" && e.value !== "daughter" && (
                    <div className="checkbox">
                      <label className="checkbox-wrapper">
                        <input
                          type="checkbox"
                          checked={isCheckboxChecked(e.level)}
                          className="checkbox-input"
                          onChange={handleChangeCheckbox}
                          name={e.level}
                          value={e.level}
                        />
                        <span className="checkbox-tile">
                          <span className="checkbox-icon">
                            <img
                              src={e.image1}
                              style={{ height: "50px" }}
                            />
                          </span>
                          <span className="checkbox-label">
                            {e.level === "Wife" ? "Spouse" : e.level}
                          </span>
                        </span>
                      </label>
                    </div>
                  )}
                </>
              );
            })}
          </fieldset>
        </div>
          </div>
        </div>
       </div>
      



      </animated.div>



      <button
        type="button"
        className="btn btn-link loadmore"
        onClick={handleMoreOptionsClick}
        hidden={!loadMore || noOfElement.length >= data.length}
      >
        More option...
      </button>

      <div className="buttons-container">
        <button className="continuebutton myBtn" onClick={handleLinkClick}>
          Continue
        </button>
      </div>

      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
};

export default HealthStep1;