export const countries = [
    { code: "af", label: "93", flag: "🇦🇫" },
    { code: "al", label: "355", flag: "🇦🇱" },
    { code: "dz", label: "213", flag: "🇩🇿" },
    { code: "as", label: "1", flag: "🇦🇸" },
    { code: "ad", label: "376", flag: "🇦🇩" },
    { code: "ao", label: "244", flag: "🇦🇴" },
    { code: "ai", label: "1", flag: "🇦🇮" },
    { code: "aq", label: "672", flag: "🇦🇶" },
    { code: "ag", label: "1", flag: "🇦🇬" },
    { code: "ar", label: "54", flag: "🇦🇷" },
    { code: "am", label: "374", flag: "🇦🇲" },
    { code: "aw", label: "297", flag: "🇦🇼" },
    { code: "au", label: "61", flag: "🇦🇺" },
    { code: "at", label: "43", flag: "🇦🇹" },
    { code: "az", label: "994", flag: "🇦🇿" },
    { code: "bs", label: "1", flag: "🇧🇸" },
    { code: "bh", label: "973", flag: "🇧🇭" },
    { code: "bd", label: "880", flag: "🇧🇩" },
    { code: "bb", label: "1", flag: "🇧🇧" },
    { code: "by", label: "375", flag: "🇧🇾" },
    { code: "be", label: "32", flag: "🇧🇪" },
    { code: "bz", label: "501", flag: "🇧🇿" },
    { code: "bj", label: "229", flag: "🇧🇯" },
    { code: "bm", label: "1", flag: "🇧🇲" },
    { code: "bt", label: "975", flag: "🇧🇹" },
    { code: "bo", label: "591", flag: "🇧🇴" },
    { code: "ba", label: "387", flag: "🇧🇦" },
    { code: "bw", label: "267", flag: "🇧🇼" },
    { code: "br", label: "55", flag: "🇧🇷" },
    { code: "io", label: "246", flag: "🇮🇴" },
    { code: "vg", label: "1", flag: "🇻🇬" },
    { code: "bn", label: "673", flag: "🇧🇳" },
    { code: "bg", label: "359", flag: "🇧🇬" },
    { code: "bf", label: "226", flag: "🇧🇫" },
    { code: "bi", label: "257", flag: "🇧🇮" },
    { code: "kh", label: "855", flag: "🇰🇭" },
    { code: "cm", label: "237", flag: "🇨🇲" },
    { code: "ca", label: "1", flag: "🇨🇦" },
    { code: "cv", label: "238", flag: "🇨🇻" },
    { code: "ky", label: "1", flag: "🇰🇾" },
    { code: "cf", label: "236", flag: "🇨🇫" },
    { code: "td", label: "235", flag: "🇹🇩" },
    { code: "cl", label: "56", flag: "🇨🇱" },
    { code: "cn", label: "86", flag: "🇨🇳" },
    { code: "cx", label: "61", flag: "🇨🇽" },
    { code: "cc", label: "61", flag: "🇨🇨" },
    { code: "co", label: "57", flag: "🇨🇴" },
    { code: "km", label: "269", flag: "🇰🇲" },
    { code: "ck", label: "682", flag: "🇨🇰" },
    { code: "cr", label: "506", flag: "🇨🇷" },
    { code: "hr", label: "385", flag: "🇭🇷" },
    { code: "cu", label: "53", flag: "🇨🇺" },
    { code: "cw", label: "599", flag: "🇨🇼" },
    { code: "cy", label: "357", flag: "🇨🇾" },
    { code: "cz", label: "420", flag: "🇨🇿" },
    { code: "cd", label: "243", flag: "🇨🇩" },
    { code: "dk", label: "45", flag: "🇩🇰" },
    { code: "dj", label: "253", flag: "🇩🇯" },
    { code: "dm", label: "1", flag: "🇩🇲" },
    { code: "do", label: "1", flag: "🇩🇴" },
    { code: "tl", label: "670", flag: "🇹🇱" },
    { code: "ec", label: "593", flag: "🇪🇨" },
    { code: "eg", label: "20", flag: "🇪🇬" },
    { code: "sv", label: "503", flag: "🇸🇻" },
    { code: "gq", label: "240", flag: "🇬🇶" },
    { code: "er", label: "291", flag: "🇪🇷" },
    { code: "ee", label: "372", flag: "🇪🇪" },
    { code: "et", label: "251", flag: "🇪🇹" },
    { code: "fk", label: "500", flag: "🇫🇰" },
    { code: "fo", label: "298", flag: "🇫🇴" },
    { code: "fj", label: "679", flag: "🇫🇯" },
    { code: "fi", label: "358", flag: "🇫🇮" },
    { code: "fr", label: "33", flag: "🇫🇷" },
    { code: "pf", label: "689", flag: "🇵🇫" },
    { code: "ga", label: "241", flag: "🇬🇦" },
    { code: "gm", label: "220", flag: "🇬🇲" },
    { code: "ge", label: "995", flag: "🇬🇪" },
    { code: "de", label: "49", flag: "🇩🇪" },
    { code: "gh", label: "233", flag: "🇬🇭" },
    { code: "gi", label: "350", flag: "🇬🇮" },
    { code: "gr", label: "30", flag: "🇬🇷" },
    { code: "gl", label: "299", flag: "🇬🇱" },
    { code: "gd", label: "1", flag: "🇬🇩" },
    { code: "gu", label: "1", flag: "🇬🇺" },
    { code: "gt", label: "502", flag: "🇬🇹" },
    { code: "gg", label: "44", flag: "🇬🇬" },
    { code: "gn", label: "224", flag: "🇬🇳" },
    { code: "gw", label: "245", flag: "🇬🇼" },
    { code: "gy", label: "592", flag: "🇬🇾" },
    { code: "ht", label: "509", flag: "🇭🇹" },
    { code: "hn", label: "504", flag: "🇭🇳" },
    { code: "hk", label: "852", flag: "🇭🇰" },
    { code: "hu", label: "36", flag: "🇭🇺" },
    { code: "is", label: "354", flag: "🇮🇸" },
    { code: "in", label: "91", flag: "🇮🇳" },
    { code: "id", label: "62", flag: "🇮🇩" },
    { code: "ir", label: "98", flag: "🇮🇷" },
    { code: "iq", label: "964", flag: "🇮🇶" },
    { code: "ie", label: "353", flag: "🇮🇪" },
    { code: "im", label: "44", flag: "🇮🇲" },
    { code: "il", label: "972", flag: "🇮🇱" },
    { code: "it", label: "39", flag: "🇮🇹" },
    { code: "ci", label: "225", flag: "🇨🇮" },
    { code: "jm", label: "1", flag: "🇯🇲" },
    { code: "jp", label: "81", flag: "🇯🇵" },
    { code: "je", label: "44", flag: "🇯🇪" },
    { code: "jo", label: "962", flag: "🇯🇴" },
    { code: "kz", label: "7", flag: "🇰🇿" },
    { code: "ke", label: "254", flag: "🇰🇪" },
    { code: "ki", label: "686", flag: "🇰🇮" },
    { code: "xk", label: "383", flag: "🇽🇰" },
    { code: "kw", label: "965", flag: "🇰🇼" },
    { code: "kg", label: "996", flag: "🇰🇬" },
    { code: "la", label: "856", flag: "🇱🇦" },
    { code: "lv", label: "371", flag: "🇱🇻" },
    { code: "lb", label: "961", flag: "🇱🇧" },
    { code: "ls", label: "266", flag: "🇱🇸" },
    { code: "lr", label: "231", flag: "🇱🇷" },
    { code: "ly", label: "218", flag: "🇱🇾" },
    { code: "li", label: "423", flag: "🇱🇮" },
    { code: "lt", label: "370", flag: "🇱🇹" },
    { code: "lu", label: "352", flag: "🇱🇺" },
    { code: "mo", label: "853", flag: "🇲🇴" },
    { code: "mk", label: "389", flag: "🇲🇰" },
    { code: "mg", label: "261", flag: "🇲🇬" },
    { code: "mw", label: "265", flag: "🇲🇼" },
    { code: "my", label: "60", flag: "🇲🇾" },
    { code: "mv", label: "960", flag: "🇲🇻" },
    { code: "ml", label: "223", flag: "🇲🇱" },
    { code: "mt", label: "356", flag: "🇲🇹" },
    { code: "mh", label: "692", flag: "🇲🇭" },
    { code: "mr", label: "222", flag: "🇲🇷" },
    { code: "mu", label: "230", flag: "🇲🇺" },
    { code: "yt", label: "262", flag: "🇾🇹" },
    { code: "mx", label: "52", flag: "🇲🇽" },
    { code: "fm", label: "691", flag: "🇫🇲" },
    { code: "md", label: "373", flag: "🇲🇩" },
    { code: "mc", label: "377", flag: "🇲🇨" },
    { code: "mn", label: "976", flag: "🇲🇳" },
    { code: "me", label: "382", flag: "🇲🇪" },
    { code: "ms", label: "1", flag: "🇲🇸" },
    { code: "ma", label: "212", flag: "🇲🇦" },
    { code: "mz", label: "258", flag: "🇲🇿" },
    { code: "mm", label: "95", flag: "🇲🇲" },
    { code: "na", label: "264", flag: "🇳🇦" },
    { code: "nr", label: "674", flag: "🇳🇷" },
    { code: "np", label: "977", flag: "🇳🇵" },
    { code: "nl", label: "31", flag: "🇳🇱" },
    { code: "nc", label: "687", flag: "🇳🇨" },
    { code: "nz", label: "64", flag: "🇳🇿" },
    { code: "ni", label: "505", flag: "🇳🇮" },
    { code: "ne", label: "227", flag: "🇳🇪" },
    { code: "ng", label: "234", flag: "🇳🇬" },
    { code: "nu", label: "683", flag: "🇳🇺" },
    { code: "kp", label: "850", flag: "🇰🇵" },
    { code: "mp", label: "1", flag: "🇲🇵" },
    { code: "no", label: "47", flag: "🇳🇴" },
    { code: "om", label: "968", flag: "🇴🇲" },
    { code: "pk", label: "92", flag: "🇵🇰" },
    { code: "pw", label: "680", flag: "🇵🇼" },
    { code: "pa", label: "507", flag: "🇵🇦" },
    { code: "pg", label: "675", flag: "🇵🇬" },
    { code: "py", label: "595", flag: "🇵🇾" },
    { code: "pe", label: "51", flag: "🇵🇪" },
    { code: "ph", label: "63", flag: "🇵🇭" },
    { code: "pn", label: "64", flag: "🇵🇳" },
    { code: "pl", label: "48", flag: "🇵🇱" },
    { code: "pt", label: "351", flag: "🇵🇹" },
    { code: "pr", label: "1", flag: "🇵🇷" },
    { code: "qa", label: "974", flag: "🇶🇦" },
    { code: "ro", label: "40", flag: "🇷🇴" },
    { code: "ru", label: "7", flag: "🇷🇺" },
    { code: "rw", label: "250", flag: "🇷🇼" },
    { code: "bl", label: "590", flag: "🇧🇱" },
    { code: "sh", label: "290", flag: "🇸🇭" },
    { code: "kn", label: "1", flag: "🇰🇳" },
    { code: "lc", label: "1", flag: "🇱🇨" },
    { code: "mf", label: "590", flag: "🇲🇫" },
    { code: "pm", label: "508", flag: "🇵🇲" },
    { code: "vc", label: "1", flag: "🇻🇨" },
    { code: "ws", label: "685", flag: "🇼🇸" },
    { code: "sm", label: "378", flag: "🇸🇲" },
    { code: "st", label: "239", flag: "🇸🇹" },
    { code: "sa", label: "966", flag: "🇸🇦" },
    { code: "sn", label: "221", flag: "🇸🇳" },
    { code: "rs", label: "381", flag: "🇷🇸" },
    { code: "sc", label: "248", flag: "🇸🇨" },
    { code: "sl", label: "232", flag: "🇸🇱" },
    { code: "sg", label: "65", flag: "🇸🇬" },
    { code: "sx", label: "1", flag: "🇸🇽" },
    { code: "sk", label: "421", flag: "🇸🇰" },
    { code: "si", label: "386", flag: "🇸🇮" },
    { code: "sb", label: "677", flag: "🇸🇧" },
    { code: "so", label: "252", flag: "🇸🇴" },
    { code: "za", label: "27", flag: "🇿🇦" },
    { code: "kr", label: "82", flag: "🇰🇷" },
    { code: "ss", label: "211", flag: "🇸🇸" },
    { code: "es", label: "34", flag: "🇪🇸" },
    { code: "lk", label: "94", flag: "🇱🇰" },
    { code: "sd", label: "249", flag: "🇸🇩" },
    { code: "sr", label: "597", flag: "🇸🇷" },
    { code: "sj", label: "47", flag: "🇸🇯" },
    { code: "sz", label: "268", flag: "🇸🇿" },
    { code: "se", label: "46", flag: "🇸🇪" },
    { code: "ch", label: "41", flag: "🇨🇭" },
    { code: "sy", label: "963", flag: "🇸🇾" },
    { code: "tw", label: "886", flag: "🇹🇼" },
    { code: "tj", label: "992", flag: "🇹🇯" },
    { code: "tz", label: "255", flag: "🇹🇿" },
    { code: "th", label: "66", flag: "🇹🇭" },
    { code: "tg", label: "228", flag: "🇹🇬" },
    { code: "tk", label: "690", flag: "🇹🇰" },
    { code: "to", label: "676", flag: "🇹🇴" },
    { code: "tt", label: "1", flag: "🇹🇹" },
    { code: "tn", label: "216", flag: "🇹🇳" },
    { code: "tr", label: "90", flag: "🇹🇷" },
    { code: "tm", label: "993", flag: "🇹🇲" },
    { code: "tc", label: "1", flag: "🇹🇨" },
    { code: "tv", label: "688", flag: "🇹🇻" },
    { code: "vi", label: "1", flag: "🇻🇮" },
    { code: "ug", label: "256", flag: "🇺🇬" },
    { code: "ua", label: "380", flag: "🇺🇦" },
    { code: "ae", label: "971", flag: "🇦🇪" },
    { code: "gb", label: "44", flag: "🇬🇧" },
    { code: "us", label: "1", flag: "🇺🇸" },
    { code: "uy", label: "598", flag: "🇺🇾" },
    { code: "uz", label: "998", flag: "🇺🇿" },
    { code: "vu", label: "678", flag: "🇻🇺" },
    { code: "va", label: "379", flag: "🇻🇦" },
    { code: "ve", label: "58", flag: "🇻🇪" },
    { code: "vn", label: "84", flag: "🇻🇳" },
    { code: "wf", label: "681", flag: "🇼🇫" },
    { code: "eh", label: "212", flag: "🇪🇭" },
    { code: "ye", label: "967", flag: "🇾🇪" },
    { code: "zm", label: "260", flag: "🇿🇲" },
    { code: "zw", label: "263", flag: "🇿🇼" },
  ];
  