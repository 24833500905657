import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // This Innitial value for collecting userdata 
  userData: {
    gender: "",
    name: "",
    phone_no: "",
    dob: "",
    smoke: "",
    education: "",
    occupation: "",
    annual_income: "",
    pin_code: "",
    district: "",
    state: "",
    annual_income: "",
    marital_status:"",
    plan_code:"",
    plan_name:""
  },

  // This innitial Value For terminsurence Addon
  termAddons: [],

  // This innitial value For TermPlane update


  updatePlane:{
    total_cover_amount: "",
    policy_term: "",
    payment_frequency: "",
    plan_type:"",
    premium_paying_term:"",
  },
  getPlaneToSummery: []





};





export const termsSlice = createSlice({
  name: "terms",
  initialState,
  reducers: {


  // UserData Reducer
  setUserData: (state, action) => {
      state.userData = {
        ...state.userData,
        ...action.payload,
      };
      // alert(JSON.stringify(state.userData));
    },

  // Addon Reducer
  TermAddAdons: (state, action) => {
      state.termAddons.push(action.payload);
    },

  // update Terminsurece Plane for fermfetchplane.jsx page
   UpdateTermPlane: (state, action) =>{
   state.updatePlane = {...state.updatePlane, ...action.payload}

   },
    
   getSummerydata:(state,action) =>{
     state.getPlaneToSummery = action.payload
    //  alert(JSON.stringify(state.getPlaneToSummery))
   }


  },
});

// Expoerted Reducers
export const { setUserData, TermAddAdons, UpdateTermPlane, getSummerydata } = termsSlice.actions;

export default termsSlice.reducer;
