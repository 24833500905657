import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {
  companyId,
  getLastKey,
  insuredDataSequence,
  encryption,
  addZeroToMonth,
} from "../helper/common";
import { act } from "react";


export const FetchPlanApi = createAsyncThunk("fetchplan", async () => {
  try {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const unique_id = params.get("req_id");
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}health/fetch_quickquote/${unique_id}`,
      { headers: { Token: encryption() } }
    );
    const responseData = response.data;
    const requestData = responseData.request;
    //console.log("requestData: ", requestData);
    return responseData;
  } catch (error) {
    //console.log(error);
    throw error;
  }
});

export const AddOnApi = createAsyncThunk("addon", async () => {
  try {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    // const unique_id = params.get('req_id');
    // const plan_id = params.get('plan_id');
    const company = companyId();
    const c_id = params.get("c_id");
    const plan_id = params.get("plan_id");

    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}health/fetch_question/${company[c_id]}/${plan_id}`,
      { headers: { Token: encryption() } }
    );
    const responseData = response.data;
    return responseData;
  } catch (error) {
    console.error("AddOnApi Error:", error);
    throw error;
  }
});

export const UpdatePlanApi = createAsyncThunk("tenureApi", async (filters) => {
  // //console.log('ii captaon',updatedUserdata)
  try {
    const search = window.location.search;
    const params = new URLSearchParams(search);

    const unique_id = params.get("req_id");
    //console.log("unique id in api", unique_id);
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}health/updatequickquote/${unique_id}`,
      filters,
      { headers: { Token: encryption() } }
    );
    const responseData = response.data;
    const requestData = responseData.request;
    //console.log("ggg", response);
    return response.data;
  } catch (error) {
    //console.log("error:", error);
    if (error && error.response) {
      //console.log(error.response);
    } else {
      //console.log(error);
    }
  }
});

export const UpdatePlanAgeApi = createAsyncThunk(
  "tenureApi",
  async (filters) => {
    try {
      const search = window.location.search;
      const params = new URLSearchParams(search);

      const unique_id = params.get("req_id");
      //console.log("unique id in ageApi", unique_id);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}health/updatequickquote_by_com/${unique_id}`,
        filters,
        { headers: { Token: encryption() } }
      );
      // const responseData = response.data;
      // const requestData = responseData.request;
      //console.log("ggg", response);
      return response.data;
    } catch (error) {
      //console.log("error:", error);
      if (error && error.response) {
        //console.log(error.response);
      } else {
        //console.log(error);
      }
    }
  }
);

// vhecalapi call hear


export const VehicalApi = createAsyncThunk(
  "Vehicalapi",
  async (vehicleInsurance) => {
    try {
      const responce = await axios.post(
        `${process.env.REACT_APP_BASE_URL}cms/car_enquiry`,
        vehicleInsurance
      );
      const ResponceData = responce.data;
      //console.log("Vehical Responce", ResponceData);
      return ResponceData;
    } catch (error) {
      throw error;
    }
  }
);

export const AddAddOnApi = createAsyncThunk(
  "addAddonApi",
  async (updatedUserdata) => {
    try {
      const search = window.location.search;
      const params = new URLSearchParams(search);
      const unique_id = params.get("req_id");
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}health/add_addon/${unique_id}}`,
        updatedUserdata,
        { headers: { Token: encryption() } }
      );
      const responseData = response.data;

      //console.log("AddAddOnApi Data:", updatedUserdata.tenure);
      return responseData;
    } catch (error) {
      console.error("AddOnApi Error:", error);
      throw error;
    }
  }
);

export const PlanSlice = createSlice({
  name: "FetchPlan",
  initialState: {
    loader: false,
    hdfcPremium:"",
    userdata: [],
    insuredMembers: [],
    city: "",
    pinCode: "",
    district: "",
    ped: [],
    fetchPlandata: [],
    addOndata: [],
    updateduserdata: [],
    updatedPlandata: [],
    updatedPlandataOne: false,
    financialData: {
      totalAmount: "",
    },
    sum_insured: "",
    tenure: "1 Year",
    addAddOnsdata: [],
    addAddOnsRequestdata: [],
    coverAmount: {},
    quickquote: {},
    proposalClick: false,
    sumInsuredData: "",
    proposerCheck: false,
    proposalCheck: false,

    filters: {
      sum_insured: 0,
      tenure: "1 Year",
    },
    oldSumInsured: 10,
    oldTenure: "1 Year",

    oneyear: 0,
    twoyear: 0,
    threeyear: 0,
    booldata: false,

    // car and bike Insurence innitial value
    vehicleInsurance: [],
    // Term Insurence innitial value
    terminsurence: [],
    // Travel Insurence innitial value
    travelInsurence: [],
    errorResult: false,
    proposalErrorResult: false,
    // For Gattingt DOB in kyc form
    getDobInKyc: [],
    updatefirstname: "",
    updatemiddelname: "",
    updatelastename: "",
    // Comapir policy innitlizer
    Recevedpoliydata: [],
    RecevedpolicyCode: [],
    RecevedPlaneDataComapir: [],
    Receved_cid: [],
    loginUser: {},
    validCheckProposer: true,
    newPhoneNum: null,
    QuoteNoOne : null,
    quoteIdOne : null,
    HDCRiderPremium : null,
    shortprceData: 'asc',
    unic_id:''
  },

  reducers: {

// Shorting asyncData
shortPrimium:(state,action)=>{
 state.shortprceData = action.payload
//  alert(state.shortprceData)
},


// Shorting asyncData end
    storeHdfcPremium : (state , action) =>{

      state.hdfcPremium  =  action.payload ;
      // alert(JSON.stringify(state.hdfcPremium))

    },

    updateFullHdfcPremium : (state , action) =>{

      state.hdfcPremium = action.payload

      // alert(JSON.stringify(state.hdfcPremium))

    },

    updateHdfcPremium : (state , action) =>{
      if(state?.hdfcPremium){
        Object.keys(action.payload).map(key=>{
          state.hdfcPremium[key] = action.payload[key];
          
        })

      }
   
      // alert("updating:", JSON.stringify(action.payload))
    },

    updateCityDetails : (state , action) =>{
      state.pinCode = action.payload.Pincode;
      state.district = action.payload.District;
      state.State = action.payload.StateName;


    },

    updatedPlanInfo: (state, action) => {
      state.updatedPlandata = action.payload;
      // alert(JSON.stringify(state.updatedPlandata));
    },

    updatePinNum:(state,action) =>{

      let newdata = {"PinCode":Number(action.payload)};
      let pindata = { ...state.data , ...newdata};
      // alert(JSON.stringify(pindata));
      state.data = pindata
 
  },

    updatePlan:(state,action)=>{
      state.updatedPlandata = action.payload
    },

    healthInsurePortData:(state,action)=>{
      state.HealthInsure_Port_Data = action.payload
      // alert(JSON.stringify(state.HealthInsure_Port_Data))
      },
    updateSumInsuredData: (state, action) => {
      state.sumInsuredData = action.payload;
    },

    updateUserPhoneNum: (state, action) => {
      state.newPhoneNum = action.payload;
    },

    updatePremium: (state, action) => {
      alert(JSON.stringify(state))
      // state.userdata.premiumBreakup.totalPremium = action.payload;
    },

    updateValidCheckProposer: (state, action) => {
      state.validCheckProposer = action.payload;
    },

    updateOldSumTen: (state, action) => {
      let data = action.payload;
      if (data.key == "tenure") {
        state.oldTenure = data.value;
      } else if (data.key == "sum_insured") {
        state.oldSumInsured = data.value;
      }
    },
    updatedLoginUser: (state, action) => {
      // alert(JSON.stringify(action.payload))
      state.loginUser = action.payload;
    },
    Recevedpolicyc_id: (state, action) => {
      state.Receved_cid = action.payload;
    },
    // policy Reducer Start Hear
    SendthePolicyData: (state, action) => {
      state.RecevedPlaneDataComapir = action.payload;
    },




    RecvedPolicyCode: (state, action) => {
      state.RecevedpolicyCode = action.payload;
      // alert(JSON.stringify(state.RecevedpolicyCode))
    },



    


    SendUnicID: (state, action)=>{
      state.unic_id = action.payload
    },




    Compairpolicy: (state, action) => {
      state.Recevedpoliydata = action.payload;
    },
    ClearPolicyComapirL: (state, action) => {
      state.Recevedpoliydata = action.payload;
    },
    // Policy Rage End hear

    updatenamesekyc: (state, action) => {
      state.updatefirstname = action.payload.FirstName;
      state.updatemiddelname = action.payload.Middlename;
      state.updatelastename = action.payload.LastName;
    },

    resetAddonData: (state, action) => {
      state.addAddOnsdata = [];
    },
    resetAddonList: (state, action) => {
      state.addAddOnsRequestdata = [];
    },

    updateBoolData: (state, action) => {
      state.booldata = action.payload;
    },

    // To get Date of birth data and usd in kyc form

    getDateofbirth: (state, action) => {
      state.getDobInKyc = action.payload;
    },

    // End Hear
    updateTotalAmount: (state, action) => {
      state.financialData.totalAmount = action.payload;
    },
    updateErrorResult: (state, action) => {
      state.errorResult = action.payload;
    },
    updateErrorResultProposal: (state, action) => {
      state.proposalErrorResult = action.payload;
    },
    // vehicleInsurance reducer
    vehicleInsur: (state, action) => {
      state.vehicleInsurance = action.payload;
    },
    // Post vehicleInsurance via Api

    // Term Insurence reducer

    GetTerm: (state, action) => {
      state.terminsurence = action.payload;
    },
    //  Travel Insurence
    GetTravelInsure: (state, action) => {
      state.travelInsurence = action.payload;
    },

    updateProposalClick: (state, action) => {
      state.proposalClick = action.payload;
    },
    // update filter reducer
    updateFilter: (state, action) => {
      if (action.payload.sum_insured) {
        // alert(action.payload.sum_insured)
        state.filters.sum_insured = action.payload.sum_insured;
      }
      if (action.payload.tenure) {
        // alert(action.payload.tenure)

        state.filters.tenure = action.payload.tenure;
      }
    },

    // //////////////////////////////////////////////////////////////////////////////////

    updateCoverAmount: (state, action) => {
      if (Object.keys(action.payload).length === 0) {
        state.coverAmount = {};
      } else {
        state.coverAmount[action.payload.cname] = action.payload.amount;
      }
    },

    updateQuickQuote: (state, action) => {
      state.quickquote = action.payload;
    },

    updateProposerCheck: (state, action) => {
      state.proposerCheck = action.payload;
    },

    updateSum_insured: (state, action) => {
      state.sum_insured = action.payload;
    },
    updateTenure: (state, action) => {
      //console.log("reducer action", action);
      state.tenure = action.payload;
    },
    updateInsuredMembers: (state, action) => {
      // alert(JSON.stringify(state.userdata))
      state.insuredMembers = action.payload;
      
      state.userdata["InsuredDetail"] = action.payload;
    },

   


    updateCity: (state, action) => {
      state.city = action.payload;
      state.pinCode = action.payload;
      state.district = action.payload;

      // let payloadData = action.payload;

      // state.userdata["District"] = payloadData.District;
      // state.userdata["State"] = payloadData.StateName;
      // alert(JSON.stringify(state))
    },


    updateName: (state, action) => {
      state.userdata.ProposalName = action.payload;
    },
    updatePed: (state, action) => {
      state.ped = action.payload;
      state.userdata.PED = action.payload;
      // alert(JSON.stringify(state.userdata.PED))
      // alert(JSON.stringify(action.payload))
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(FetchPlanApi.pending, (state) => {
        state.loader = true;
      })
      .addCase(FetchPlanApi.fulfilled, (state, { payload }) => {
        state.loader = false;
        state.fetchPlandata = payload.response;
        state.userdata = payload.request;
        //console.log("userdata:", state.userdata);
      })
      .addCase(FetchPlanApi.rejected, (state) => {
        state.loader = false;
        alert("error");
      })
      .addCase(AddOnApi.pending, (state) => {
        state.loader = true;
      })
      .addCase(AddOnApi.fulfilled, (state, { payload }) => {
        state.loader = false;
        state.addondata = payload.addon_data;
      })
      .addCase(AddOnApi.rejected, (state) => {
        state.loader = false;
        alert("error");
      })
      .addCase(UpdatePlanApi.pending, (state) => {
        state.loader = true;
      })
      .addCase(UpdatePlanApi.fulfilled, (state, { payload }) => {
        state.loader = false;
        state.updatedPlandata = payload?.response;
        state.updateduserdata = payload?.request;
        // state.updatedPlandataOne = payload?.response;

        // //console.log("updatedPlandata:", state.updatedPlandata)
      })
      .addCase(UpdatePlanApi.rejected, (state) => {
        state.loader = false;
        alert("error");
      })
      .addCase(AddAddOnApi.pending, (state) => {
        state.loader = true;
      })
      .addCase(AddAddOnApi.fulfilled, (state, { payload }) => {

        state.loader = false;
        state.addAddOnsdata = payload?.response;

        // Save the Tenure data.......

        if (payload?.response ?.premiumBreakup.term === "1") {
          state.oneyear = payload.response.premiumBreakup.premium;
        }
        if (payload?.response ?.premiumBreakup.term === "2") {
          state.twoyear = payload.response.premiumBreakup.premium;
        }
        if (payload?.response ?.premiumBreakup.term === "3") {
          state.threeyear = payload.response.premiumBreakup.premium;
        }

        //console.log(JSON.stringify(state.addAddOnsdata));
        state.addAddOnsRequestdata = payload?.request;
        state.userdata["sum_insured"] = payload?.request?.sum_insured;
        state.fetchPlandata['InsuredDetail'] = payload.request.InsuredDetail;
        state.tenure = payload?.request?.tenure;
        state.updatedPlandataOne = payload.response;
        state.QuoteNoOne = payload.response.QuoteNo;
        state.quoteIdOne = payload.response.quoteId;
        state.HDCRiderPremium = payload.response.HDCRiderPremium;
        
      })
      .addCase(AddAddOnApi.rejected, (state) => {
        state.loader = false;
        alert("error");
      });
  },
});

export const {
  shortPrimium,
  updateCityDetails,
  updateHdfcPremium,
  updateFullHdfcPremium,
  storeHdfcPremium,
  updatePlan,
  updatePinNum,
  healthInsurePortData,
  updateOldSumTen,
  updatedLoginUser,
  SendthePolicyData,
  Recevedpolicyc_id,
  RecvedPolicyCode,
  SendUnicID,
  ClearPolicyComapirL,
  Compairpolicy,
  resetAddonList,
  resetAddonData,
  updatenamesekyc,
  getDateofbirth,
  updateTotalAmount,
  updateProposalClick,
  updateCoverAmount,
  updateInsuredMembers,
  updateCity,
  updatePed,
  updateTenure,
  updateSum_insured,
  updateName,
  updateFilter,
  updateoneyear,
  updatetwoyears,
  updatethreeyears,
  vehicleInsur,
  updateQuickQuote,
  updateErrorResult,
  updateErrorResultProposal,
  updateProposerCheck,
  updatedPlanInfo,
  updateBoolData,
  updateValidCheckProposer,
  updatePremium,
  updateUserPhoneNum,
  updateSumInsuredData,
} = PlanSlice.actions;

export const CreateProposalSlice = createSlice({
  name: "ProposalCreate",
  initialState: {
    data: { InsuredDetail: [] },
    checkedQuestion: [],
    checkedPersion: {},
    HDFCcheckedQuestion: {},
    ReliancecheckedQuestion: {},
    HDFCcheckedPersion: {},
    ICICIcheckedQuestion: {},
    userData: { data: false },
    loginForm: { show: false, phone: null },
    otpForm: { show: false },
  },
  reducers: {

    settingDob : (state , action) =>{
      alert("state")
      if(state.data.KycDateOfbirth){
        state.data.KycDateOfbirth = action.payload;
        alert(JSON.stringify(state.data.KycDateOfbirth))

      }
      else{
        state.data['KycDateOfbirth'] = action.payload;
        alert(JSON.stringify(state.data.KycDateOfbirth))

      }


    },
    settingProposal : (state , action) =>{
      state.data = action.payload
      // alert(JSON.stringify(state.data))
    },
   
    settingGender : (state , action) =>{
      if(state.data.Gender){
        state.data.Gender = action.payload

      }
      else{
      state.data['Gender'] = action.payload

      }
    },
    addkycfield : (state , action) =>{
      if(state.data[action.payload.field]==undefined || state.data[action.payload.field]==null ){  
        state.data[action.payload.field] = action.payload.value
      }
 

    },


    addfield : (state , action) =>{
      state.data['PED'] = action.payload
    },


    updateProposalName : (state , action) =>{
      state.data.ProposalName = action.payload
    },


    updatePremiumData : (state , action) =>{
      // alert(JSON.stringify(state.data))

      // state.data.premiumBreakup.totalPremium = action.payload
    },

    updateProposalData : (state , action) =>{
      state.data = action.payload
    },

    updateSateKyc : (state , action) =>{

      state.data.Sate = action.payload

    },




    updatePinKyc : (state , action) =>{
      state.data = ({...state.data , PinCode : action.payload } )
      // alert(JSON.stringify(state.data));  

    },
    updateInsuredArray : (state,action) =>{
      state.data.InsuredDetail = action.payload
    },
    updateHdcProposal : (state , action) =>{
      state.data.HDCRiderSI = action.payload
      // alert(JSON.stringify(state.data))
    },


    updateFirstData : (state , action) =>{
      state.data = action.payload;

      // alert(JSON.stringify(state.data))
    },


    updateData: (state, action) => {
      let payloadData = action.payload;
      let storeData = state.data;

      for (let i = 0; i < payloadData.length; i++) {
        storeData[payloadData[i]["key"]] = payloadData[i]["value"];
      }
      //  alert(JSON.stringify(payloadData))
    },


    updateInsuredData: (state, action) => {
      let payloadData = action.payload;
      let data = payloadData.data;
      let index = payloadData.index;
      let storeData = state.data.InsuredDetail;
      for (let i = 0; i < data.length; i++) {
        if (storeData[index]) {
          storeData[index][data[i]["key"]] = data[i]["value"];
        } else {
          if (index != null) {
            storeData[index] = { [data[i]["key"]]: data[i]["value"] };
          }
        }
      }
      // alert(JSON.stringify(payloadData))
    },
    addInsuredData: (state, action) => {
      let payloadData = action.payload;
      let storeData = state.data;

      storeData["InsuredDetail"] = insuredDataSequence(payloadData);
      localStorage.setItem(
        "InsuredDetail",
        JSON.stringify(state.data.InsuredDetail)
      );
    },
    addInsuredPersion: (state, action) => {
      let payloadData = action.payload;
      state.data.InsuredDetail.push({
        InsuredRelation: payloadData.reletion,
        InsuredAge: "",
      });
      state.data.InsuredDetail = insuredDataSequence(state.data.InsuredDetail);

      localStorage.setItem(
        "InsuredDetail",
        JSON.stringify(state.data.InsuredDetail)
      );
    },
    deleteInsuredPersion: (state, action) => {
      let payloadData = action.payload;
      let oldData = state.data.InsuredDetail;
      let updateData = delete_insured_persion(oldData, payloadData.reletion);

      state.data.InsuredDetail = updateData;

      state.data.InsuredDetail = insuredDataSequence(state.data.InsuredDetail);

      localStorage.setItem(
        "InsuredDetail",
        JSON.stringify(state.data.InsuredDetail)
      );
    },
    addRemoveInsuredPersion: (state, action) => {
      let payloadData = action.payload;
      let insuredData = state.data.InsuredDetail;

      let findPersion = insuredData.find(
        (value, index) => value.InsuredRelation == payloadData.reletion
      );
      // alert(payloadData.reletion)
      if (findPersion == undefined) {
        //if not found
        insuredData.push({
          InsuredRelation: payloadData.reletion,
          InsuredAge: "",
        });
      } else {
        //if found

        state.data.InsuredDetail = delete_insured_persion(
          insuredData,
          payloadData.reletion
        );
      }
      state.data.InsuredDetail = insuredDataSequence(state.data.InsuredDetail);

      localStorage.setItem(
        "InsuredDetail",
        JSON.stringify(state.data.InsuredDetail)
      );
      // alert(localStorage.getItem('InsuredDetail'))
    },
    incrementInsuredPersion: (state, action) => {
      let payloadData = action.payload;
      let oldData = state.data.InsuredDetail;
      let lastkey = getLastKey(payloadData.reletion, oldData);
      // alert(lastkey)
      if (oldData != false) {
        oldData.splice(lastkey + 1, 0, {
          InsuredRelation: payloadData.reletion,
          InsuredAge: "",
        });
        // state.data.InsuredDetail =  oldData;
      }
      state.data.InsuredDetail = insuredDataSequence(state.data.InsuredDetail);

      localStorage.setItem(
        "InsuredDetail",
        JSON.stringify(state.data.InsuredDetail)
      );
    },
    decrementInsuredPersion: (state, action) => {
      let payloadData = action.payload;
      let oldData = state.data.InsuredDetail;
      let lastkey = getLastKey(payloadData.reletion, oldData);

      if (oldData != false) {
        oldData.splice(lastkey, 1);
        state.data.InsuredDetail = oldData;
      }
      // alert(JSON.stringify(oldData))
      state.data.InsuredDetail = insuredDataSequence(state.data.InsuredDetail);

      localStorage.setItem(
        "InsuredDetail",
        JSON.stringify(insuredDataSequence(state.data.InsuredDetail))
      );
    },
    updatecheckedQuestion: (state, action) => {
      let payloadData = action.payload;
      let storeData = state.checkedQuestion;
      let c_id = payloadData.c_id;

      if (storeData.includes(payloadData.val)) {
        let index = storeData.indexOf(payloadData.val);
        storeData.splice(index, 1);

        let insuredData = state.data.InsuredDetail;

        if (c_id == "Care") {
          let questionSetCd = payloadData.questionSetCode;
          // alert(questionSetCd);
          for (let i = 0; i < insuredData.length; i++) {
            if (insuredData[i].hasOwnProperty("partyQuestionDOList")) {
              let qa = insuredData[i].partyQuestionDOList;
              let tempArray = [];

              for (let j = 0; j < qa.length; j++) {
                if (qa[j].questionSetCd != questionSetCd) {
                  tempArray.push(qa[j]);
                }
              }
              // alert(JSON.stringify(tempArray))
              insuredData[i].partyQuestionDOList = tempArray;
            }
          }
        } else if (c_id == "GoDigit") {
          let question_id = payloadData.question_id;
          // alert(questionCode);
          for (let i = 0; i < insuredData.length; i++) {
            if (insuredData[i].hasOwnProperty("medicalQuestions")) {
              let qa = insuredData[i].medicalQuestions;
              let tempArray = [];

              for (let j = 0; j < qa.length; j++) {
                if (qa[j].questionCode != question_id) {
                  tempArray.push(qa[j]);
                }
              }
              // alert(JSON.stringify(tempArray))
              insuredData[i].medicalQuestions = tempArray;
            }
          }
        }
      } else {
        storeData.push(payloadData.val);
      }
    },
    updatecheckedPersion: (state, action) => {
      let payloadData = action.payload;
      let storeData = state.checkedPersion;
      let c_id = payloadData.c_id;

      if (storeData.hasOwnProperty(payloadData.qid)) {
        if (storeData[payloadData.qid].includes(payloadData.pid)) {
          let index = storeData[payloadData.qid].indexOf(payloadData.pid);

          storeData[payloadData.qid].splice(index, 1);

          let pid = action.payload.pid;
          let persionData = state.data.InsuredDetail[pid];

          if (c_id == "Care") {
            let qa = persionData.partyQuestionDOList;
            let tempArray = [];
            let question_id = payloadData.question_id;
            let questionSetCd = payloadData.questionSetCd;

            if (qa) {
              for (let i = 0; i < qa.length; i++) {
                if (qa[i].questionSetCd != questionSetCd) {
                  tempArray.push(qa[i]);
                }
              }
            }
            persionData.partyQuestionDOList = tempArray;
          } else if (c_id == "GoDigit") {
            let qa = persionData.medicalQuestions;
            let tempArray = [];
            let question_id = payloadData.question_id;

            if (qa) {
              for (let i = 0; i < qa.length; i++) {
                if (qa[i].questionCode != question_id) {
                  tempArray.push(qa[i]);
                }
              }
            }
            persionData.medicalQuestions = tempArray;
          }
        } else {
          storeData[payloadData.qid].push(payloadData.pid);
        }
      } else {
        storeData[payloadData.qid] = [payloadData.pid];
      }
    },
    updateCareQAlist: (state, action) => {
      let payloadData = action.payload;
      let storeData = state.data.InsuredDetail;

      let persionData = storeData[payloadData.pid];
      let qdata = payloadData.qdata;
      let value = payloadData.value;
      let parentqData = payloadData.parentqData;

      if (!persionData.hasOwnProperty("partyQuestionDOList")) {
        persionData.partyQuestionDOList = [];
      }

      let qa = persionData.partyQuestionDOList;

      let yes_no_array = ["pedYesNo", "H001", "H002", "H003", "H004"];

      if (questionExist(qa, qdata.question_id)) {
        for (let i = 0; i < qa.length; i++) {
          if (qa[i].questionCd == qdata.question_id) {
            qa[i].response =
              qdata.question_id == "OtherSmokeDetails" ||
              qdata.question_id == "otherDiseasesDescription" ||
              yes_no_array.includes(qdata.question_id)
                ? value
                : addZeroToMonth(value);
            break;
          }
        }
      } else {
        if (questionExist(qa, parentqData.question_id) != true) {
          qa.push({
            questionSetCd: parentqData.QuestionSetCode,
            questionCd: parentqData.question_id,
            response: "YES",
          });
        }

        if (!yes_no_array.includes(qdata.question_id)) {
          qa.push({
            questionSetCd: qdata.QuestionSetCode,
            questionCd: qdata.question_id,
            response:
              qdata.question_id == "OtherSmokeDetails" ||
              qdata.question_id == "otherDiseasesDescription"
                ? value
                : addZeroToMonth(value),
          });
        }
      }
    },
    updateHDFCQuestion: (state, action) => {
      let payloadData = action.payload;
      let storeData = state.HDFCcheckedQuestion;

      if (storeData.hasOwnProperty(payloadData.val)) {
        delete storeData[payloadData.val];
      } else {
        storeData[payloadData.val] = {};
      }
    },
    updateRelianceQuestion: (state, action) => {
      let payloadData = action.payload;
      let storeData = state.ReliancecheckedQuestion;

      if (storeData.hasOwnProperty(payloadData.val)) {
        delete storeData[payloadData.val];
      } else {
        storeData[payloadData.val] = {};
      }
    },
    updateHDFCPersion: (state, action) => {
      let payloadData = action.payload;
      let storeData = state.HDFCcheckedQuestion;
      let question_id = payloadData.question_id;
      let pindex = payloadData.pindex;
      let question = storeData[question_id];

      if (question.hasOwnProperty(pindex)) {
        delete question[pindex];
      } else {
        question[pindex] = [];
      }
    },
    updateReliancePersion: (state, action) => {
      let payloadData = action.payload;
      let storeData = state.ReliancecheckedQuestion;
      let question_id = payloadData.question_id;
      let pindex = payloadData.pindex;
      let question = storeData[question_id];

      if (question.hasOwnProperty(pindex)) {
        delete question[pindex];
      } else {
        question[pindex] = [];
      }
    },
    updateHDFCQAlist: (state, action) => {
      let payloadData = action.payload;
      let storeData = state.HDFCcheckedQuestion;
      let parentqData = payloadData.parentqData;
      let parentQid = parentqData.question_id;
      let pindex = payloadData.pindex;
      let question = storeData[parentQid];
      let qa = question[pindex];
      let qdata = payloadData.qdata;
      let name = payloadData.name;
      let value = payloadData.value;
      // alert(parentqData.question_id)
      if (!HDFCquestionExist(qa, parentqData.question_id)) {
        let obj = {
          QuestionId: parentqData.question_id,
          QuestionText: parentqData.question,
          Options: [
            HDFCgetAnswerSet(qdata, name, value, parentqData.question_id),
          ],
        };
        // alert('test')
        qa.push(obj);
      } else {
        let objKey = qa.findIndex(
          (item) => item.QuestionId == parentqData.question_id
        );
        let obj = qa[objKey]["Options"];
        let objOne = obj.findIndex(
          (item) => item.OptionId == qdata.question_id
        );

        if (objOne == "-1") {
          qa[objKey]["Options"].push(
            HDFCgetAnswerSet(qdata, name, value, parentqData.question_id)
          );
        } else {
          qa[objKey]["Options"][objOne][name] = value;
        }
      }
    },

    // updateRelianceQAlist: (state, action) => {
    //   // alert(JSON.stringify(state.ReliancecheckedQuestion));
    //   let payloadData = action.payload;
    //   let storeData = state.ReliancecheckedQuestion;
    //   let parentqData = payloadData.parentqData;
    //   let parentQid = parentqData.question_id;
    //   let pindex = payloadData.pindex;
    //   let question = storeData[parentQid];
    //   let qa = question[pindex];
    //   let qdata = payloadData.qdata;
    //   let name = payloadData.name;
    //   let value = payloadData.value;
    //   // alert(parentqData.question_id)
    //   if (!ReliancequestionExist(qa, parentqData.question_id , qdata)) {
    //     let obj = {
    //       QuestionId: parentqData.question_id,
    //       QuestionText: parentqData.question,
    //       Options: [
    //         ReliancegetAnswerSet(qdata, name, value, parentqData.question_id),
    //       ],
    //     };
    //     // alert('test')
    //     qa.push(obj);
    //   } else {
    //     let objKey = qa.findIndex(
    //       (item) => item.QuestionId == parentqData.question_id
    //     );
    //     let obj = qa[objKey]["Options"];
    //     let objOne = obj.findIndex(
    //       (item) => item.OptionId == qdata.question_id
    //     );

    //     if (objOne == "-1") {
    //       qa[objKey]["Options"].push(
    //         ReliancegetAnswerSet(qdata, name, value, parentqData.question_id)
    //       );
    //     } else {
    //       qa[objKey]["Options"][objOne][name] = value;
    //     }
    //   }
    // },

updateRelianceQAlist :(state, action) => {
      let payloadData = action.payload;
      let storeData = state.ReliancecheckedQuestion;
      let parentqData = payloadData.parentqData;
      let parentQid = parentqData.question_id;
      let pindex = payloadData.pindex;
      let question = storeData[parentQid];
      let qa = question[pindex];
      let qdata = payloadData.qdata;
      let name = payloadData.name;
      let value = payloadData.value;
    
      let { exists, questionIndex, optionIndex } = ReliancequestionExist(qa, parentQid, qdata);
    
      if (!exists) {
        let obj = {
          QuestionId: parentQid,
          QuestionText: parentqData.question,
          Options: [
            ReliancegetAnswerSet(qdata, name, value, parentQid),
          ],
        };
        qa.push(obj);
      } else {
        let option = qa[questionIndex].Options[optionIndex];
        option[name] = value;
      }
    },
    



    updateICICIQuestion: (state, action) => {
      let payloadData = action.payload;
      let storeData = state.ICICIcheckedQuestion;

      if (storeData.hasOwnProperty(payloadData.val)) {
        delete storeData[payloadData.val];
      } else {
        storeData[payloadData.val] = {};
      }
    },
    updateICICIPersion: (state, action) => {
      let payloadData = action.payload;
      let storeData = state.ICICIcheckedQuestion;
      let question_id = payloadData.question_id;
      let pindex = payloadData.pindex;
      let question = storeData[question_id];

      if (question.hasOwnProperty(pindex)) {
        delete question[pindex];
      } else {
        question[pindex] = [];
      }
    },
    updateICICIQAlist: (state, action) => {
      let payloadData = action.payload;
      let storeData = state.HDFCcheckedQuestion;
      let parentqData = payloadData.parentqData;
      let parentQid = parentqData.question_id;
      let pindex = payloadData.pindex;
      let question = storeData[parentQid];
      let qa = question[pindex];
      let qdata = payloadData.qdata;
      let name = payloadData.name;
      let value = payloadData.value;
    },
    updateGodigitQAlist: (state, action) => {
      let payloadData = action.payload;
      let storeData = state.data.InsuredDetail;

      let persionData = storeData[payloadData.pid];
      let qdata = payloadData.qdata;
      let value = payloadData.value;
      let parentqData = payloadData.parentqData;

      if (!persionData.hasOwnProperty("medicalQuestions")) {
        persionData.medicalQuestions = [];
      }

      let qa = persionData.medicalQuestions;

      if (godigitquestionExist(qa, parentqData.question_id)) {
        let key = qa.findIndex(
          (element) => element.questionCode == parentqData.question_id
        );

        let keyone = qa[key].subQuestions.findIndex(
          (element) => element.questionCode == qdata.question_id
        );

        if (keyone == "-1") {
          qa[key].subQuestions.push({
            questionCode: qdata.question_id,
            answerType: "Text_Descript",
            isApplicable: true,
            detailAnswer: value,
            subQuestions: [],
          });
        } else {
          qa[key].subQuestions[keyone].detailAnswer = value;
        }
      } else {
        qa.push({
          questionCode: parentqData.question_id,
          answerType: "BOOLEAN",
          isApplicable: true,
          detailAnswer: "FALSE",
          subQuestions: [
            {
              questionCode: qdata.question_id,
              answerType: "Text_Descript",
              isApplicable: true,
              detailAnswer: value,
              subQuestions: [],
            },
          ],
        });
      }
    },
    updatedUserdata: (state, action) => {
      let payloadData = action.payload;
      let storeData = state.userData;

      storeData.data = payloadData;
    },
    updatedLoginPopup: (state, action) => {
      let payloadData = action.payload;
      let storeData = state.loginForm;
      // alert(payloadData)
      storeData.show = payloadData;
    },
    updatedPhone: (state, action) => {
      let payloadData = action.payload;
      let storeData = state.loginForm;
      // alert(payloadData)
      storeData.phone = payloadData;
    },
    updatedOtpPopup: (state, action) => {
      let payloadData = action.payload;
      let storeData = state.otpForm;
      // alert(payloadData)
      storeData.show = payloadData;
    },
  },
});

export const selectProposal = (state) => state.ProposalCreate.data;
export const selectcheckedQuestion = (state) =>
  state.ProposalCreate.checkedQuestion;
export const selectcheckedPersion = (state) =>
  state.ProposalCreate.checkedPersion;
export const selectHDFCcheckedQuestion = (state) =>
  state.ProposalCreate.HDFCcheckedQuestion;

export const selectReliancecheckedQuestion = (state) =>
  state.ProposalCreate.ReliancecheckedQuestion;

export const selectHDFCcheckedPersion = (state) =>
  state.ProposalCreate.HDFCcheckedPersion;
export const selectICICIcheckedQuestion = (state) =>
  state.ProposalCreate.ICICIcheckedQuestion;
export const selectUserData = (state) => state.ProposalCreate.userData;
export const selectLoginForm = (state) => state.ProposalCreate.loginForm;
export const selectOtpForm = (state) => state.ProposalCreate.otpForm;

export const {
  settingDob,
  addfield,
  addkycfield,
  updateSateKyc,
  settingGender,
  settingProposal,
  updateData,
  updateFirstData,
  updateHdcProposal,
  updateInsuredData,
  updateInsuredArray,
  addInsuredData,
  updatecheckedQuestion,
  updatecheckedPersion,
  updateCareQAlist,
  updatePinKyc,
  updateheight,
  updateHDFCQAlist,
  updateRelianceQAlist,
  updateGodigitQAlist,
  updateHDFCQuestion,
  updateRelianceQuestion,
  updateHDFCPersion,
  updateReliancePersion,
  updateICICIQuestion,
  updateICICIPersion,
  updateICICIQAlist,
  updatedUserdata,
  updatedLoginPopup,
  updatedOtpPopup,
  updatedPhone,
  addInsuredPersion,
  deleteInsuredPersion,
  addRemoveInsuredPersion,
  incrementInsuredPersion,
  decrementInsuredPersion,
  updatePremiumData,
  updateProposalData,
  updateProposalName
} = CreateProposalSlice.actions;
export const {} = PlanSlice.actions;

function questionExist(qa, qid) {
  for (let i = 0; i < qa.length; i++) {
    if (qa[i].questionCd == qid) {
      return true;
      break;
    }
  }
  return false;
}

function HDFCquestionExist(qa, qid) {
  for (let i = 0; i < qa.length; i++) {
    if (qa[i].QuestionId == qid) {
      return true;
      break;
    }
  }
  return false;
}



// function ReliancequestionExist(qa, qid , qdata) {
//   for (let i = 0; i < qa.length; i++) {
//     if (qa[i].QuestionId == qid ) {

//       qa[i].Options.map((item,index)=>{
       
//         if(item.OptionText == qdata.question){
//           return false;
          
          
//         }
//         else{
//           return true
//         }
//       })
//     }
//   }
 
// }




function HDFgetObjKey(qa, qid) {
  for (let i = 0; i < qa.length; i++) {
    if (qa[i].QuestionId == qid) {
      return i;
      break;
    }
  }
}
function godigitquestionExist(qa, qid) {
  for (let i = 0; i < qa.length; i++) {
    if (qa[i].questionCode == qid) {
      return true;
      break;
    }
  }
  return false;
}

function HDFCgetAnswerSet(qdata, name, value, parentQid) {
  if (parentQid == "14") {
    return {
      OptionId: qdata.question_id,
      OptionText: qdata.question,
      ExactDiagnosis: name == "ExactDiagnosis" ? value : "",
      ConsultationDate: name == "ConsultationDate" ? value : "",
      DiagnosisDate: name == "DiagnosisDate" ? value : "",
      CurrentStatus: name == "CurrentStatus" ? value : "",
      LineOfManagement: name == "LineOfManagement" ? value : "",
      TreatmentDetails: name == "TreatmentDetails" ? value : "",
    };
  } else if (parentQid == "18") {
    return {
      OptionId: qdata.question_id,
      OptionText: "Yes",
      ExactDiagnosis: name == "ExactDiagnosis" ? value : "",
      ConsultationDate: name == "ConsultationDate" ? value : "",
      DiagnosisDate: name == "DiagnosisDate" ? value : "",
      CurrentStatus: name == "CurrentStatus" ? value : "",
      LineOfManagement: name == "LineOfManagement" ? value : "",
      TreatmentDetails: name == "TreatmentDetails" ? value : "",
    };
  } else if (parentQid == "15") {
    return {
      OptionId: qdata.question_id,
      OptionText: "Yes",
      ExactDiagnosis: name == "ExactDiagnosis" ? value : "",
      ConsultationDate: name == "ConsultationDate" ? value : "",
      SurgeryDate: name == "SurgeryDate" ? value : "",
      CurrentStatus: name == "CurrentStatus" ? value : "",
      SurgeryFor: name == "SurgeryFor" ? value : "",
    };
  } else if (parentQid == "16") {
    return {
      OptionId: qdata.question_id,
      OptionText: "Yes",
      ExactDiagnosis: name == "ExactDiagnosis" ? value : "",
      ConsultationDate: name == "ConsultationDate" ? value : "",
      DiagnosisDate: name == "DiagnosisDate" ? value : "",
      CurrentStatus: name == "CurrentStatus" ? value : "",
      TreatmentDetails: name == "TreatmentDetails" ? value : "",
    };
  } else if (parentQid == "17") {
    return {
      OptionId: qdata.question_id,
      OptionText: "Yes",
      TestFinding: name == "TestFinding" ? value : "",
      TestDate: name == "TestDate" ? value : "",
      TypeOfTest: name == "TypeOfTest" ? value : "",
    };
  } else if (parentQid == "19") {
    // alert(parentQid)
    return {
      OptionId: qdata.question_id,
      OptionText: "Yes",
      DeliveryDate: name == "DeliveryDate" ? value : "",
    };
  }
}

function ReliancequestionExist(qa, qid, qdata) {
  for (let i = 0; i < qa.length; i++) {
    if (qa[i].QuestionId == qid) {
      for (let j = 0; j < qa[i].Options.length; j++) {
        if (qa[i].Options[j].OptionText == qdata.question) {
          return { exists: true, questionIndex: i, optionIndex: j };
        }
      }
    }
  }
  return { exists: false };
}



function ReliancegetAnswerSet(qdata, name, value, parentQid) {
  if (parentQid == "1") {
    return {
   
      OptionId: qdata.QuestionSetCode,
      OptionIndex:1,
      OptionText: qdata.question,
      SufferingSince: name == "SufferingSince" ? value : "",
      OtherDisease: name == "OtherDisease" ? value : "",
      TreatmentTaken: name == "TreatmentTaken" ? value : "",
      ExactDiagnosis: name == "ExactDiagnosis" ? value : "",
      HospitalName: name == "HospitalName" ? value : "",
      ConsultationDate: name == "ConsultationDate" ? value : "",
    };
  } else if (parentQid == "2") {
    return {
      OptionId: qdata.question_id,
      OptionText: qdata.question,
      OptionIndex:2,
      ExactDiagnosis: name == "ExactDiagnosis" ? value : "",
      DiagnosisDate: name == "DiagnosisDate" ? value : "",
      ConsultationDate: name == "ConsultationDate" ? value : "", 
      HospitalName: name == "HospitalName" ? value : "",
  
    };
  } else if (parentQid == "3") {
    return {
      OptionId: qdata.question_id,
      OptionIndex:3,
      OptionText: qdata.question,
      ExactDiagnosis: name == "ExactDiagnosis" ? value : "",
    
      DiagnosisDate: name == "DiagnosisDate" ? value : "",
      ConsultationDate: name == "ConsultationDate" ? value : "",
      MedicineName: name == "MedicineName" ? value : "",
     
    };
  } else if (parentQid == "4") {
    return {
      OptionId: qdata.question_id,
      OptionIndex:4,
      OptionText: qdata.question,
      TypeOfTests: name == "TypeOfTests" ? value : "",
      DateOfTests: name == "DateOfTests" ? value : "",
      FindingsOfTests: name == "FindingsOfTests" ? value : "",
   
    };
  } else if (parentQid == "5") {
    return {
      OptionId: qdata.question_id,
      OptionIndex:5,
      OptionText: qdata.question,
      ExactDiagnosis: name == "ExactDiagnosis" ? value : "",
      DiagnosisDate: name == "DiagnosisDate" ? value : "",
      ConsultationDate: name == "ConsultationDate" ? value : "",
      HospitalName: name == "HospitalName" ? value : "",
      DetailsOfYourPastMedicalCondition: name == "DetailsOfYourPastMedicalCondition" ? value : "",

    };
  } 
  else if (parentQid == "6") {
    // alert(parentQid)
    return {
      OptionId: qdata.question_id,
      OptionIndex:6,
      OptionText: qdata.question,
      ExpectedDeliveryDate: name == "ExpectedDeliveryDate" ? value : "",
    };
  }

  else if (parentQid == "7") {
 
    return {
      OptionId: qdata.question_id,
      OptionText: qdata.question,
      OptionIndex:7,
     
    };
  }

  else if (parentQid == "8") {
 
    return {
      OptionId: qdata.question_id,
      OptionText: qdata.question,
      OptionIndex:8,
      HasFiledClaimWithCurrPreviousInsured: name == "HasFiledClaimWithCurrPreviousInsured" ? value : "",
    };
  }

  else if (parentQid == "9") {
   
    return {
      OptionId: qdata.question_id,
      OptionIndex:9,
      OptionText: qdata.question,
      HasChargedCancelledHighPremium: name == "HasChargedCancelledHighPremium" ? value : "",
    };
  }
}



function delete_insured_persion(oldData, reletion) {
  let updateData = [];

  for (let i = 0; i < oldData.length; i++) {
    if (oldData[i].InsuredRelation != reletion) {
      updateData.push(oldData[i]);
    }
  }

  return updateData;
}
