import React, { useEffect, useState } from 'react'
import StepSlider from '../../commonComponents/StepSlider';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Loader from '../../../AllPageComponents/Loader';
import { useSpring, animated } from '@react-spring/web';
import { GiUmbrella } from "react-icons/gi";
import { encryption} from "../../helper/common";
import "./Step4.css";
import { selectProposal , addfield } from "../../redux/Slice";
import { useSelector } from 'react-redux';
import { quickQuote } from "../../Api/Api";
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet-async';


const HealthStep4 = () => {

  const medical_history = ["Diabetes", "Blood Pressure", 'Cholesterol', "Asthma", "Other disease", "None of these"];
  const [validationError, setValidationError] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(() => {
    const storedData = localStorage.getItem('PED');
    return storedData ? JSON.parse(storedData) : [];
  });
  const [loading, setLoading] = useState(false);

  let dispatch = useDispatch();
  
  // const userApi = async()=>{
  //   try{
      
  //     setLoading(true);
  //     // const response = await axios.post(`${process.env.REACT_APP_BASE_URL}health/quickquote`, userDetails,{headers: {'Token':encryption()}})
     
  //     const response = quickQuote(userDetails).then(response => {
  //       console.log("the response in new api generation", response);
        
  //     navigate(`/plans?req_id=${response.data.unique_id}`); 
  //     return response; 

  //       // Make sure to return the response if you need to use it further
  //     })

  // }catch(error){
  //     //console.log(error.response)
  // }finally {
  //   setLoading(false); 
  // }
  // }

  const userApi = async () => {
    try {
      setLoading(true);
      // const response = await axios.post(`${process.env.REACT_APP_BASE_URL}health/quickquote`, userDetails,{headers: {'Token':encryption()}})
      
      const response = await quickQuote(userDetails); // Use await to wait for the promise to resolve
      console.log("the response in new api generation", response);
      navigate(`/plans?req_id=${response.data.unique_id}`);
      return response; // Make sure to return the response if you need to use it further
      
    } catch (error) {
      console.error("Error fetching quote:", error);
    } finally {
      setLoading(false);
    }
  };
  
  
  const handleCheckboxChange = (event) => {
    const value = event.target.name;
    const isChecked = event.target.checked;

    if (value === "None of these" && isChecked) {
      setSelectedOptions(["None of these"]);
    } else if (value === "None of these" && !isChecked) {
      setSelectedOptions([]);
    } else {
      if (isChecked && selectedOptions.includes("None of these")) {
        setSelectedOptions(selectedOptions.filter(option => option !== "None of these"));
      }
      setSelectedOptions(prevOptions =>
        isChecked ? [...prevOptions, value] : prevOptions.filter(option => option !== value)
      );
    }
    // //console.log('selectedOptions:', selectedOptions);
  };

  const proposalData = useSelector(selectProposal);

  //console.log("the proposaldata",proposalData)


  useEffect(() => {
    localStorage.setItem('PED', JSON.stringify(selectedOptions));
  }, [selectedOptions]);

  const number = localStorage.getItem('Proposalphonenumber').slice(0 -10);

  const navigate = useNavigate()
  const userDetails = {}
  const handleOnClick =()=>{
    if (selectedOptions.length === 0) {
      setValidationError(true);
      return; 
    }
   
    userDetails['ProposalName']=localStorage.getItem('ProposalName')
    userDetails['Proposalphonenumber']=number
    userDetails['PinCode']=localStorage.getItem('PinCode')
    userDetails['District']=localStorage.getItem('District')
    userDetails['State']=localStorage.getItem('State')
    userDetails['PED']=JSON.parse(localStorage.getItem('PED'))
    userDetails['Gender']=localStorage.getItem('selectedType')
    userDetails['tenure']= "1 Year" 
    userDetails['sum_insured']= 10 
    userDetails['InsuredDetail']=JSON.parse(localStorage.getItem('InsuredDetail')) 

    dispatch(addfield(JSON.parse(localStorage.getItem('PED'))))

    
    // //console.log(userDetails)
    userApi()
}

//   Animation
const [isVisible, setIsVisible] = React.useState(false);

const animations = useSpring({
  opacity: isVisible ? 1 : 0,
  transform: isVisible ? 'translateY(0%)' : 'translateY(50%)',
config: { mass: 1, tension: 100, friction: 20, duration: 500 },
});

useEffect(() => {
  setIsVisible(true);
}, []);

useEffect(() => {
  window.scrollTo(0, 0)
}, [])


const [isActive, setIsActive] = useState(true);
const toggleButton = () => {
  setIsActive(!isActive);
};

  return (
    <>
       <Helmet>
        <title>HealthStep4</title>
        {/* <meta name="description" content="A brief description of your page for SEO." /> */}
        {/* <meta name="keywords" content="best term insurance in india , buy term life insurance, compare and purchase term insurance" /> */}
      </Helmet>
    {loading && (
        <Loader/>
      )}
      
      <h1 style={{ textAlign: 'center', padding: '20px', color: "#0055A5", marginTop: 20 }}>
      Make Safe Your Future <span style={{ color: "#E50318" }}>With </span><span style={{ color: '#FD9202' }}>Beema1</span><sup>**</sup>
      </h1>
      <StepSlider activeStep={4} />
      <animated.div style={animations}>
      <h3 style={{ textAlign: 'center', padding: '20px' }}>Medical History</h3>

      <h5 style={{ textAlign: 'center' }}>Do Any Member(s) Have Any Existing Illnesses For Which They Take Regular Medication?</h5>
       {/* Tool tip bar Starty Hear */}
    
       {/* Tool tip bar End Hear gridcontainer*/}






       {/* <div className="row col-md-6 margindivs">
       {medical_history.map((item, index) => (
  <div key={index} className="inputGroup col-md-4 col-6">
    <input
      id={`option${index}`}
      name={item}
      type="checkbox"
      checked={selectedOptions.includes(item)}
      onChange={handleCheckboxChange}
    />
    <label htmlFor={`option${index}`} className='font20'>{item}</label>
  </div>
))}
        </div> */}




<div className="container mb-5 mt-5">
  <div className="row mx-auto col-md-10 col-sm-12">
    {medical_history.map((item, index) => {
      return (
        <div key={index} className="col-6 col-sm-6 col-md-4 px-0 flexdisplayhealthstep4">
          <div className="inputGroup">
            <input
              id={`option${index}`}
              name={item}
              type="checkbox"
              checked={selectedOptions.includes(item)}
              onChange={handleCheckboxChange}
            />
            <label htmlFor={`option${index}`} className='font20'>{item}</label>
          </div>
        </div>
      );
    })}
  </div>
</div>







        <div className="whatsappbtn">
       <div className={`sliding-toggle ${isActive ? 'active' : ''}`} onClick={toggleButton}>
      <div className="toggle-circle"></div>
    </div>
    <p>Get Updates on WhatsApp </p>
       </div>
      {validationError && (
        <p style={{ textAlign: 'center', color: 'red' }}>Please select at least one option.</p>
      )}
      <div className="buttons-container">
        <button className="continuebutton myBtn" onClick={handleOnClick}>View Plans</button>
      </div>
      </animated.div>
    </>
  )
}

export default HealthStep4