import React,{useState} from "react";
import FAQ from "../AllPageComponents/FAQ/FAQ";
import PartnerCompany from "../AllPageComponents/PartnerCompany/PartnerCompany";
import "../CarInsurnce/Carinsurence.css";
import CarInsuranceForm from "./CarInsuranceForm";
import FastImage from "../FastImage";
import { auto } from '@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core'
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { MdOutlineExpandMore } from 'react-icons/md';
import Redirect from "../AllPageComponents/Rederict";

const CompareQuotes = () => {

  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };


  const QnaArray = [
    {id:1,Q:'Worth of your car ',A:"The cost of your insurance policy should match with the value of your vehicle"},
    {id:2,Q:"The kind of a driver you are",A:"How frequent you drive and the kind of a driver you are with your driving habits matter a lot. What kind of driving you do affect the cost of your insurance when you apply for pay-as-you drive or pay-how-you drive options."},
    {id:3,Q:"Choose the Right Coverage",A:'Third-party insurance will do if you just want basic protection against traffic fines. But for full-on vehicle protection, go for a comprehensive policy.'},
    {id:4,Q:'Check the Claim Settlement Ratio (CSR)',A:"Before you commit, take a peek at the insurance company's claim settlement ratio. A higher CSR means a better chance of your claims getting sorted hassle-free."},
    {id:5,Q:"Decide on the IDV",A:'Aim for an Insured Declared Value (IDV) that matches your cars market value. While a lower IDV might lower your premium, it could leave you with higher expenses if you need to make a claim. You can use an online IDV calculator to help you figure out the right value.'},
    {id:6,Q:'Cashless Garages',A:"Look into the number of cashless network garages in your area. More options mean more convenience when it comes to cashless repairs."},
    {id:7,Q:"Compare Plans Online",A:"Don't settle for the first plan you see. Take the time to compare multiple car insurance plans online to find the one that fits your needs and budget."},
    {id:8,Q:'Smooth Claim Process',A:'Choose an insurer that promises a straightforward claim process, round-the-clock support, and speedy settlements.'},
    {id:9,Q:'Customer Support',A:'Make sure the insurance company offers 24x7 customer assistance. This ensures you can get help with your queries and claims whenever you need it.'},
    {id:10,Q:'Type of coverage you need ',A:'Choose for the following optionsNavigate from the options from comprehensive, liability, collision, and personal injury protection.'},
    {id:11,Q:'Area of your residence',A:'Online car insurance costs can differ depending on the area of your residence, such as crime rate in your area and traffic conditions in your area.'},
    {id:12,Q:'How much you are ready to pay from your own pocket',A:'This is completely as per your decided budget and how much risk you can afford. If any accident happens, and if you are not willing to or afford to spend money, from your own pocket for the repairing of your car, then opt for own damage cover instead of a Third-Party-Policy.'}
  ]


  const QnaArray2 = [
    {id:13,Q:'MMV of Your Car/Vehicle',A:'The vital factor which impacts the quotes for car insurance is your car’s MMV - make, model and variant.'},
    {id:14,Q:'Fuel Type',A:'Another factor that affects the car insurance premium quote of an insurance policy is the fuel type of the car/vehicle. Different premiums are charged for diesel cars and different premiums are quoted for petrol cars.'},
    {id:15,Q:'Policyholder’s Profession',A:'Insurance Providers value the correlation between the policyholder’s risk of meeting up an accident and the professions they are in, hence adjust the premium accordingly. People engaged in professions such as cab drivers, delivery agents are usually at a higher risk and thus a relatively higher premium charged.'},
    {id:16,Q:'Policyholder’s Credit Score',A:'Many insurance companies consider the credit score of the applicant to determine the Car Insurance Premium.'},
    {id:17,Q:'Car Size',A:'Generally larger cars are considered safer than smaller cars in terms of accidents. Therefore, often insurance companies offer relatively lower premiums to bigger cars than smaller ones.'},
    {id:18,Q:'Car’s Safety Rating Is Significant',A:"Buying a car with a high safety rating can help you get a car insurance plan at a relatively lower premium due to increased safety and lower chances of meeting an accident."},
    {id:19,Q:"Policyholder’s Driving History Need To Be Considered",A:"If the owner of the car/vehicle has been in accidents or has made previous car insurance claims, the insurance company will charge a comparatively higher premium to such individuals."}
  ]

  const QnaArray3 = [
    {id:20,Q:'Availing of Better Insurance Plans',A:'Most policies come with value add-on covers that increase the scope of the coverage it provides such as free towing and other inbuilt add-ons. With online platforms such as Beema1 comparing various policies, the car insurance companies and their network of cashless garages can be done with just a few clicks.'},
    {id:21,Q:'Find A Trustworthy Company',A:"Purchasing insurance from a reputed website like ours entrusts that your insurance is done in safe hands and through secured source and medium, with no chance of you being scammed. Comparing the insurance policies help you get the policy from a reliable insurance company through a trusted source or platform. It results into a safer purchasing experience for the customers."},
    {id:22,Q:'Better Insurance Plans',A:"Most of the car insurance policies have some value add-on covers that enhance the scope of the coverage it offers such as free towing and other inbuilt add-ons. With online platforms such as Beema1 comparing different policies with different features, the car insurance companies and their network of cashless garages can be done within just a few clicks."},
    {id:23,Q:"Convenient Insurance Experience",A:"Procuring insurance online is now absolutely simple, you can do it yourself with a little guidance from the experts. Moreover, in case you lose or misplace your document it is also easy to replace through an online platform."},
    {id:24,Q:"You can Save Premium Costs",A:"Different car insurance companies offer plans at different prices. Policy comparison will guide you find car insurance policies offered by some insurance companies at low prices or with discounts. Therefore, comparing car insurance policies will help you find the best car insurance deal."},
    {id:25,Q:'Hassle-free Claim Settlement',A:"Car insurance comparison aids in claim settlement. It creates possibilities and the space for the comparison. You can select the insurance company with a wide range of network garages and high claim settlement ratio, which finally confirm an easy and speedy claim settlement process. "}
  ]

  const [expanded, setExpanded] = useState(null);

  const handleChangese = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };




const CarInsureClashless = [
  {id:0, img:'/Website%20Img/Car%20crash-bro.png?updatedAt=1721821841553',text:'Discover over 5000 cashless network garages across India for your convenience, ensuring easy access to quality automotive services without the hassle of immediate payments, covering a vast geographical spread from metropolitan hubs to remote rural areas, offering peace of mind with a diverse range of repair and maintenance options tailored to suit every vehicle type and customer preference, facilitating seamless insurance claims processing and quick repairs through a robust network of trusted service providers committed to excellence and customer satisfaction, reflecting a commitment to enhancing your driving experience by providing reliable, efficient, and cost-effective solutions',Title:"5000+ CASHLESS NETWORK GARAGES FOR YOU TO CHOOSE FROM ACROSS INDIA"},
  {id:1, img:'/Website%20Img/Mobile%20login-cuate.png?updatedAt=1721821958694',text:'Just click and highlight the damages of your car, and your responsibility is transferred seamlessly, simplifying the process with user-friendly technology that ensures efficient and accurate documentation, empowering you to manage car repairs effortlessly by digitally marking damages and facilitating swift insurance claims processing, leveraging innovative tools to streamline the transfer of responsibility for car damages with ease and precision, offering a seamless experience where clicking and highlighting car damages transfers responsibility swiftly, utilizing intuitive digital tools to effortlessly highlight car damages and transfer responsibility with a simple click, ensuring convenience and efficiency in transferring car damage responsibility through intuitive digital solutions ',Title:'JUST CLICK AND HIGHLIGHT THE DAMAGES OF YOUR CAR AND YOUR RESPONSIBILITY IS TRANSFERRED'},
  {id:2, img:'/Website%20Img/Starman-pana.png?updatedAt=1721822122597',text:'Through Beema1, more than 90% of all claims for private four-wheelers have been successfully settled, underscoring its exceptional track record in providing reliable and efficient insurance services, thereby fostering trust and satisfaction among vehicle owners nationwide. This high settlement rate highlights Beema1s commitment to prompt resolution and customer-centricity, ensuring peace of mind and financial security for policyholders during unforeseen events.',Title:"THROUGH BEEMA1, MORE THAN 90% OF ALL CLAIMS OF PRIVATE FOUR-WHEELERS GOT SETTLED"},
  {id:2, img:'/Website%20Img/Calling-cuate.png?updatedAt=1721822312205',text:'24*7 call facility available even during national holidays, ensuring continuous accessibility to expert assistance and support around the clock, reinforcing our dedication to serving you with unwavering reliability and convenience, no matter the occasion or time of day, thereby ensuring peace of mind and prompt resolution of your queries or concerns whenever they arise.',Title:'24*7 CALL FACILITY EVEN DURING NATIONAL HOLIDAYS'},
]


const insuranceData = [
  { company: 'HDFC ERGO Car Insurance', garages: '6,800', claimRatio: '89.48%', startingFrom: 'Rs. 6 / day' },
  { company: 'ICICI Lombard Car Insurance', garages: '5,600', claimRatio: '87.84%', startingFrom: 'Rs. 6 / day' },
  { company: 'United India Car Insurance', garages: '3,100', claimRatio: '95.00%', startingFrom: 'Rs. 6 / day' },
  { company: 'New India Car Insurance', garages: '3,000', claimRatio: '92.23%', startingFrom: 'Rs. 6 / day' },
  { company: 'Royal Sundaram Car Insurance', garages: '3,300', claimRatio: '98.60%', startingFrom: 'Rs. 6 / day' },
  { company: 'Reliance Car Insurance', garages: '8,200', claimRatio: '98.00%', startingFrom: 'Rs. 6 / day' },
  { company: 'SBI Car Insurance', garages: '16,000', claimRatio: '100.00%', startingFrom: 'Rs. 6 / day' },
  { company: 'Future Generali Car Insurance', garages: '3,500', claimRatio: '96.30%', startingFrom: 'Rs. 6 / day' },
  { company: 'Liberty Car Insurance', garages: '4,500', claimRatio: '98.00%', startingFrom: 'Rs. 6 / day' },
  { company: 'IFFCO Tokio Car Insurance', garages: '4,300', claimRatio: '95.80%', startingFrom: 'Rs. 6 / day' },
  { company: 'Digit Car Insurance', garages: 'Repair Anywhere', claimRatio: '96.00%', startingFrom: 'Rs. 6 / day' },
  { company: 'Bajaj Allianz Car Insurance', garages: '4,000', claimRatio: '98.50%', startingFrom: 'Rs. 6 / day' },
];



















const styles = {
  th: {
    padding: '12px',
    textAlign: 'left',
    border: '1px solid white',
    fontWeight: 'bold',
    backgroundColor:'#0166B3',
    color:'white',
    textAlign:'center'
    
  },
  td: {
    padding: '12px',
    textAlign: 'left',
    borderBottom: '1px solid #ddd',
  },
};


  return (
    <>
    <Redirect/>
      <div
        className="compare-quotes-body-20"
        style={{ position: "relative", minHeight: "100%"}}>
        
      
<section className="car-insurance insurance-all insurance-background">
  <div className="container">
    <div className="row insurance-row-one">
      <div className="col-sm-12 col-md-4 col-lg-4 mian-one">
        <div className="car-insurance-first-inner">
          <h4 style={{fontFamily:'Poppins',fontWeight:500,fontSize:"18pt",color:'#0166B3'}}>Find The Best Car Insurance Rates & Save Today</h4>
        
        {!showMore ? (
        <div>
          <p>
            To compare things, it's best to think of similarities. And for contrast, think of differences. Here, Beema1 provides you with data information in categories formatted into easily explained methods, using exact facts and figures about affordable full coverage car insurance plans. 
            
          </p>
          <span onClick={toggleShowMore} style={{color:"#0166B3",cursor:'pointer'}}>See More</span>
        </div>
      ) : (
        <div>
          <p>
          To compare things, its best to think of similarities. And for contrast, think of differences.
Here, Beema1 provides you with data information in categories formatted into easily explained method, using exact facts and figures about affordable full coverage car insurance plans.
Browsing through the car insurance comparison sites and scrutinizing various car insurance plans is much essential in determining the right motor insurance policy for your four-wheeler.
Selecting the right insurance plan not only helps you to save your money, but also recommends you the best coverage to protect your car from unanticipated events like any kind of road accident.
Automobile insurance safeguards our vehicles from damages, reimburse medical expenses, and protects against personal liabilities.
In case of any type of car accidents and mis happenings, a car insurance plan can be a robust support and advantage.


Click, compare four-wheeler insurance quotes and buy your insurance from the leading insurers in India through Beema1. Don't settle for the first plan you see. Take the time to compare multiple car insurance quote online to find the one that fits your needs and budget.

Get cover for accidental damages and high claim settlement ratio with our 24/7 customer care support.
          </p>
          <span onClick={toggleShowMore} style={{color:"#0166B3",cursor:'pointer'}}>See Less</span>
        </div>
      )}


























        </div>
      </div>
      <div className="col-sm-12 col-md-4 col-lg-4 side-image">
      <FastImage
            url={"Website%20Img/carImagMascord.png?updatedAt=1718773961090"}
            width={auto}
            height={auto}
            alt={"ca-img"}
            />
      </div>
      <style
        dangerouslySetInnerHTML={{
          __html: "\n    .hide{\n        display: none;\n    }\n"
        }}
      />
    
      <CarInsuranceForm/>
    
   
   
    </div>
  </div>
</section>




      </div>
   

      {/* /******************** new section start *************************************       */}
<div className="container text-center">
<h4 style={{color:"#0166B3"}}>How to Compare Car Insurance Online?</h4>
</div>

      <div className="container ">
     
      
      
        <div className="row">
        <div className="col-md-6 d-flex justify-content-center align-items-center">
            <div>

         
                  <p style={{textAlign:"justify"}}>
                  It is very important to compare car insurance policies in order to choose the best policy for your car. Take a few minutes to compare and renew your car insurance 
                  </p>
                  <ul>
                    <li>Visit the Car Insurance Page: Head to the car insurance section on Beema1’s website.</li>
                    <li>Enter Your Car Details: Provide information about your car, including its make, model, and other relevant details.</li>
                    <li> Compare Quotes: Review quotes from the top insurance providers.</li>
                    <li>Select the Best Plan: Choose a policy that fits both your budget and coverage needs.</li>
                    <li>Pay Online: Complete your purchase by paying the premium online, and receive your insurance documents directly in your registered email.</li>
                  </ul>
                  </div>
          </div>

          <div className="col-md-6">
          <FastImage width={auto} height={auto} url="/Website%20Img/Pick%20up%20truck-bro.png?updatedAt=1721819503074" className="fmailimagegifs"/>
          </div>


        </div>
      </div>

      {/* ********************** third section starts here ************************************* */}

  <div className="container">
  <h4 style={{color:"#0166B3"}}>Drive Worry Free With BEEMA1</h4>
  <div className="yellowline"></div>
    <div className="row">
      <div className="col-md-6">
     
 <FastImage url="/Website%20Img/Race%20car-rafiki.png?updatedAt=1721819952458" width={auto} height={auto} className="fmailimagegifs"/>
      </div>
      <div className="col-md-6 d-flex justify-content-center align-items-center">
     <p style={{textAlign:'justify'}}>
     A car insurance policy, also known as vehicle insurance, helps you to save your hard-earned money when your car is damaged in an accident or affected because of any natural calamity. Your car insurance policy also covers your vehicle against theft and burglary. You may end up hurting others or damaging a property in an accident. A vehicle insurance policy also covers such third-party liabilities as well. If you own a car in India, having third party car insurance is a must for you. Hence, it is vital to buy and renew your policy on time to stay covered against car damages. Compare your ideal car insurance quotes with the best price, and at a low and justified premium amount, our car insurance policy protects you against all kind of risks so that you can be on roads enjoying drive worry-free. Apart from comprehensive car insurance, third-party only and own-damage only car insurance policies are also available with us. 

     </p>
     </div>
    </div>
  </div>




















  <div className="container">
  <h4 style={{color:"#0166B3"}}>How To Identify The Right Car Insurance Policy Online?</h4>
        
        <p>When it comes to choosing the perfect car insurance policy, there are a bunch of things to consider. Factors like coverage options, claim settlement track record, availability of cashless repairs, and more all play a role in finding the right fit for you. Check out some of the key factors listed below:</p>
    <div className="row">


<div className="col-md-6">

{QnaArray.slice(0, 6).map((data) => (
            <Accordion
              key={`panel${data.id}`}
              expanded={expanded === `panel${data.id}`}
              onChange={handleChangese(`panel${data.id}`)}
            >
              <AccordionSummary
                expandIcon={<MdOutlineExpandMore />}
                aria-controls={`panel1-content`}
                id={`panel1-header`}
              >
                <li className="listcol">{data.Q}</li>
              </AccordionSummary>
              <AccordionDetails>{data.A}</AccordionDetails>
            </Accordion>
          ))}

</div>
<div className="col-md-6">
{QnaArray.slice(6, 12).map((data) => (
            <Accordion
              key={`panel${data.id}`}
              expanded={expanded === `panel${data.id}`}
              onChange={handleChangese(`panel${data.id}`)}
            >
              <AccordionSummary
                expandIcon={<MdOutlineExpandMore />}
                aria-controls={`panel1-content`}
                id={`panel1-header`}
              >
                <li className="listcol">{data.Q}</li>
              </AccordionSummary>
              <AccordionDetails>{data.A}</AccordionDetails>
            </Accordion>
          ))}

</div>

    </div>
  </div>




<div className="container">
  <div className="row">
  {
  CarInsureClashless.map((data, index) => {
    if (index % 2 === 0) { // even index
      return (
        <>
          <div className="col-md-6 d-flex justify-content-center align-items-center">
          <p style={{textAlign:"justify"}}> 
          <h5 style={{color:"#0166B3",marginBottom:'15px'}}>{data.Title}</h5>
   
          {data.text}</p> 
          </div>
          <div className="col-md-6">
            <FastImage width="auto" height="auto" url={data.img} className="fmailimagegifs" />
          </div>
        </>
      );
    } else { // odd index
      return (
        <>
          <div className="col-md-6">
            <FastImage width="auto" height="auto" url={data.img} className="fmailimagegifs" />
          </div>
          <div className="col-md-6 d-flex justify-content-center align-items-center">
          
          <p style={{textAlign:"justify"}}> 
          <h5 style={{color:"#0166B3",marginBottom:'15px'}}>{data.Title}</h5>
      
          {data.text}</p> 
          </div>
        </>
      );
    }
  })
}


  </div>
</div>

<div className="container">
  <div className="row">
    <div className="col-md">
      <h4 style={{color:"#0166B3"}}>Significant Stats about Car Insurance in India</h4>
      <div className="yellowline"></div>
      
      <p style={{textAlign:'justify'}}>The average expense of car insurance in India is determined by assorted factors including the type of vehicle, location, age of the car, driving record of the car, type of insurance bought, etc.
So, here are some very essential statistics for car insurance in India:</p>

<p style={{textAlign:'justify'}}>#. The annual average cost of third-party liability policy for a mid-range Sedan may range between ₹5000 - ₹7000. On the other hand, the comprehensive cover may cost around ₹10000 - ₹20000 or may be even higher in monetary value.

#.  The data also displays that female drivers consistently pay lesser premium than male drivers, with ₹4000 - ₹8000 average premium for females and ₹8000 - ₹12000 for males, for third-party liability coverage.  
</p>


<h4 style={{color:"#0166B3"}}>OUR PARTNER CAR INSURANCE COMPANIES</h4>
<div className="yellowline"></div>
<p>there are 25 general insurance companies which provide car insurance plans. Here are some of the most reputable and popular among the mass car insurance providers.</p>


<h4  style={{color:"#0166B3"}}>What is Car Insurance?</h4>
<div className="yellowline"></div>

<p style={{textAlign:'justify'}}>A car insurance is a contract between you and the insurance company that protects and safeguards you against financial loss in the event of an accident or theft. In exchange for a small fee, insurance companies provide financial protection to four-wheelers against unexpected events such as accidents, theft, fires, natural disasters, and third-party liabilities. Plus, having valid car insurance can help you avoid hefty traffic fines. Car insurance covers the loss or damage to a car that is caused by an accident. Car insurance covers damages and defilements to the car's body, engine and other parts, but it does not cover the loss of personal belongings inside the car.</p>


<p style={{textAlign:'justify'}}> Now, there are three main types of car insurance policies to choose from: third-party, own damage, and comprehensive. Plus, as a car owner, you can opt for a Personal Accident Cover of up to INR 15 Lakh to make sure you and your driver are covered in case of any accidents.

In the expeditiously evolving landscape of automatized technology and insurance, the year 2024 holds the assurance for important metamorphosis and evolution in the field of car insurance. With the development in the upgradation of telematics, the expansion of electric vehicles (EVs), and as the Insures has sprung up, the future of car insurance is for innovation and adaptation and modification to ever-demanding consumer needs and inclinations and predilections.

When you buy or lease a car, it's very much required and essential to protect that investment. Getting your auto insured can offer you reassurance, assistance and confidence in case you're involved in an accident or your vehicle is stolen, vandalized or damaged by any natural disaster. Instead of paying out-of-pocket for auto accidents, people pay annual premiums to an auto insurance company; the company then pays off all or most of the expenses related to an auto accident or other vehicle damage or vehicle impairment.</p>

    </div>
  </div>
</div>

<div className="container">
  <div className="row">
    <div className="col-md">
    <h4 style={{color:"#0166B3"}}>Control your speed on roads, and never limit your car insurance. But never overspend on your car insurance policy</h4>
  <div className="yellowline"></div>
  
  <p>What You Need To Compare Car Insurance Quotes Online?
The following points will help you compare different car insurance policies and guide you to find car insurance quotes that best suited to your needs:</p>

<ul>
  <li>Understand what you truly require: The way to ensure that you can make an informed decision is to know and learn the basics of car insurance and its terminology. At Beema1 we have tried various of explaining the terminologies to help you in enhancing your knowledge in this regard. Knowing the basics of car insurance will help you realize what are your requirements for the purpose of insurance</li>
  
  <li>Choose the right insurance company: There are a number of factors you can depend on to determine the reputation of the insurance provider. This includes the insurance settlement ratio, existing customer care reviews, the ease of claim processing etc. Usually claims are processed during distressed state of mind at the time of emergencies and a non-efficient claim process can also add to your frustrations at such a time.</li>
  
  <li>Verify the terms and conditions of your insurance plan: The terms and conditions of an insurance policy document are in the fine prints. Hence, make sure to read in between the lines of the policy you purchase do choose carefully, there are often a number of points that are not covered or specifically mentioned in highlighted points or bullet points and that can make the policy a bad bargain.</li>
  
  <li>The cheapest policy is not always the best: When comparing car insurance policies, it is easy to just shift towards the cheapest premium amount. However, keep do note that low premiums also mean low coverage or high deductibles, this can prove more expensive in the long run. The best policies are reasonably affordable and effective at the same time.</li>
  

</ul>

<h4 style={{color:"#0166B3"}}>How to Compare Car Insurance Quotation Online on Beema1?</h4>
<div className="yellowline"></div>

<p>The following steps will help you compare the different car insurance policies online:
Step 1: Visit our website www.beema1.com 
Step 2: In the ‘Get Your Quote’ section fill in your car registration number and click the button. If you do not have your registration number for some reason you can also provide other information such as the car’s make, model number and year of registration.
Step 3: Provide your personal details, i.e., your name, mobile number and click to proceed.</p>



<p>
Step 4: All the quotes of your category will be displayed on the screen. You can compare and select the one that you are looking for and best suits your needs. If you purchase a comprehensive or own-damage cover you can also purchase add-on covers along with it.
Step 5: Once you have selected a policy based on your needs, you can further proceed for payment for the policy and the document will be sent to your registered email ID.
</p>

<h4 style={{color:"#0166B3"}}>Car Insurance Quotes Online</h4>
<div className="yellowline"></div>

<p>The premium for a third-party plan is fixed by the Insurance Regulatory and Development Authority (IRDAI), which keeps varying from plan to plan. Any which ways, the premium for stand alone own damage and comprehensive car insurance plans are based on a range of factors such as the extent of coverage, make, model and variant of the car, type of fuel of the car, RTO location, and many more.</p>







    </div>
  </div>
</div>

<div className="container">
  <div className="row">
    <div className="col-md">
    <div style={{ fontFamily: 'Arial, sans-serif', margin: 'auto' }}>
      <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px' }}>
        <thead>
          <tr style={{ backgroundColor: '#f2f2f2' }}>
            <th style={styles.th}>Car Insurance Company</th>
            <th style={styles.th}>Network garages</th>
            <th style={styles.th}>Claim settlement ratio</th>
            <th style={styles.th}>Plans starting from</th>
          </tr>
        </thead>
        <tbody>
          {insuranceData.map((insurance, index) => (
            <tr key={index} style={index % 2 === 0 ? { backgroundColor: '#ffffff' } : { backgroundColor: '#f9f9f9' }}>
              <td style={styles.td}>{insurance.company}</td>
              <td style={styles.td}>{insurance.garages}</td>
              <td style={styles.td}>{insurance.claimRatio}</td>
              <td style={styles.td}>{insurance.startingFrom}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    </div>
  </div>
</div>


<div className="container">
  <div className="row">
    <div className="col-md">
      <h4 style={{color:"#0166B3"}}>Factors Affecting Vehicle/Car Insurance Quotes?</h4>
      <div className="yellowline"></div>
      
      <p>There are a number of factors that affect the quote for car insurance. Some of them are as follows:</p>
      
      <p>
        <b>
    Type of the Plan
        </b>
The premium of the plan depends on the coverage that you have selected. Wider the coverage, the greater the premium. There are three basic types of car insurance based on the coverage they provide:
      </p>
      <ul>
        <li>Third Party Insurance: This type of insurance is mandatory and covers the cost of third-party liabilities including bodily injuries, death and property damage.</li>
        
        <li>Standalone Own-Damage Insurance: This covers the expense of the policyholder’s vehicle damage in case of a mis happening but is not mandatory.</li>
        
        <li>Comprehensive Car Insurance: This type of cover provides compensation for both third-party liabilities and own-damage expenses under a single premium making it the most simple and uncomplicated option of the three.</li>
      </ul>

      

    </div>
  </div>
</div>

<div className="container">
<div className="row">
<div className="col-md-6">

{QnaArray2.slice(0, 4).map((data) => (
            <Accordion
              key={`panel${data.id}`}
              expanded={expanded === `panel${data.id}`}
              onChange={handleChangese(`panel${data.id}`)}
            >
              <AccordionSummary
                expandIcon={<MdOutlineExpandMore />}
                aria-controls={`panel1-content`}
                id={`panel1-header`}
              >
                <li className="listcol">{data.Q}</li>
              </AccordionSummary>
              <AccordionDetails>{data.A}</AccordionDetails>
            </Accordion>
          ))}

</div>
<div className="col-md-6">
{QnaArray2.slice(4, 8).map((data) => (
            <Accordion
              key={`panel${data.id}`}
              expanded={expanded === `panel${data.id}`}
              onChange={handleChangese(`panel${data.id}`)}
            >
              <AccordionSummary
                expandIcon={<MdOutlineExpandMore />}
                aria-controls={`panel1-content`}
                id={`panel1-header`}
              >
                <li className="listcol">{data.Q}</li>
              </AccordionSummary>
              <AccordionDetails>{data.A}</AccordionDetails>
            </Accordion>
          ))}

</div>
</div>
</div>


<div className="container">
<h4 style={{color:"#0166B3"}}>Aware Of The Benefits of Comparing Car Insurance Online?</h4>
      <div className="yellowline"></div>
      <span>Buying car insurance online is easier and effective nowadays as it is fast, simple and suitable. The benefits of comparing car insurance online are:</span>
      
<div className="row">


<div className="col-md-6">

{QnaArray3.slice(0, 3).map((data) => (
            <Accordion
              key={`panel${data.id}`}
              expanded={expanded === `panel${data.id}`}
              onChange={handleChangese(`panel${data.id}`)}
            >
              <AccordionSummary
                expandIcon={<MdOutlineExpandMore />}
                aria-controls={`panel1-content`}
                id={`panel1-header`}
              >
                <li className="listcol">{data.Q}</li>
              </AccordionSummary>
              <AccordionDetails>{data.A}</AccordionDetails>
            </Accordion>
          ))}

</div>
<div className="col-md-6">
{QnaArray3.slice(3, 6).map((data) => (
            <Accordion
              key={`panel${data.id}`}
              expanded={expanded === `panel${data.id}`}
              onChange={handleChangese(`panel${data.id}`)}
            >
              <AccordionSummary
                expandIcon={<MdOutlineExpandMore />}
                aria-controls={`panel1-content`}
                id={`panel1-header`}
              >
                <li className="listcol">{data.Q}</li>
              </AccordionSummary>
              <AccordionDetails>{data.A}</AccordionDetails>
            </Accordion>
          ))}

</div>



</div>
</div>



<div className="container">
<h5 style={{color:"#0166B3"}}>Also know the Pros and Cons of Purchasing Insurance Online?</h5>
<div className="yellowline"></div>
      <br/>
  <div className="row">

    <div className="col-md-6">
     
      <b>Pros</b>
      
      <li>You have the opportunity to choose from a wide variety of insurance plans and can opt for the most suited one based on your requirements and budget.</li>
      <li>You have the options for making comparisons of prices, features, coverage, benefits offered by different plans under one single platform i.e., Beema1.</li>
      <li>The entire online process for buying insurance is much cost-effective, simple to understand and convenient as it involves lesser paperwork.</li>
      <li>Online policy purchasing process allows the policyholder to make decisions for themselves like policy renewal, paying premiums, keeping a track of investments, etc.</li>
    </div>

    <div className="col-md-6">
    <b>Cons</b>
      
      <li>Few advantages of purchasing car insurance plan online, and one of them is lack of customization. One can also not bargain over the different plan rates online.</li>
      <li>One needs to keep an eye on the online scams as there is information regarding your personal and financial data on the website of the insurance company from where you have purchased the policy.</li>
      <li>Lack of information and knowledge regarding insurance and usage of computer in order to make a well-informed decision.</li>
      <li>Lack of familiar assistance in the situation of filing a claim.</li>
    </div>
  </div>
</div>

      {/* ***********************fourth section starts here ****************************  */}
   <PartnerCompany/>

      {/* **********************************fourth section ******************************* */}

     <FAQ/>
    </>
  );
};

export default CompareQuotes;
