import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import '../SIP/SIP.css'
import Slider from '@mui/material/Slider';
import { Link, useNavigate } from 'react-router-dom';
import Redirect from '../../../AllPageComponents/Rederict';
import Calculatorlist from '../Calculator-Charat/Calculatorlist';


const SSY = () => {

  const nav = useNavigate()

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();



// Age For Girl

const [Age, setAge] = useState(1)
const girlage = (event) =>{
    setAge(event.target.value)
}

// Investment amount

const [Investment, setInvestment] = useState(500)
const investmentamount = (event) =>{
  setInvestment(event.target.value)
}

// Time Period 
const [Timeperiod, setTimeperiod] = useState(2019)
const timeperiodinvest = (event) =>{
    setTimeperiod(event.target.value)
}




const calculateMaturityAmount = () => {
   const setYear = 15
   const TotalInvested = Investment*setYear
return TotalInvested
  };

  const MaturityYear = () => {
    return 21 - Age;
 }
 


const maturity = MaturityYear() + Timeperiod +1







  return (
    <div>
    <div className="headingcalculator">
    <p>Home &nbsp;&gt;&nbsp;&nbsp;&nbsp;<Link to={'/calculator'}>calculator</Link>&nbsp;&nbsp;&nbsp;&nbsp;&gt;&nbsp;&nbsp;<span style={{color:'green'}}>Sukanya Samriddhi Yojana Calculator Calculator</span></p>

  <span className="titlecalculator">Sukanya Samriddhi Yojana <span className='Mainheading'>Calculator</span></span>

  <br />
  <sapn className="CalculatorDiscription">Use the Sukanya Samriddhi Yojana Calculator to plan your long-term investments wisely. It 
  helps estimate yearly investments, interest growth, and the maturity amount, aiding in financial 
  planning</sapn>
</div>
  <div className="calculatorhead">
  

  <div className="underdiv">
  <div className="calculatorse">
  
  {/* Calculator start div start hear */}
  <div className="calculaterse">
  <div className="calculator">






{/* Amount calculator */}
<div className="onecalculator">
<div className="amountdives">
  <p>Yearly Investment </p>
  <input type="text" value={Investment} onChange={(event)=>{investmentamount(event)}}/>
</div>
<div className="amountselectordiv">
  <Slider
    value={Investment}
    onChange={(event)=>{investmentamount(event)}}
    min={250}
    max={150000}
    step={500} // optional, set the step value
    aria-label="Default"
    valueLabelDisplay="auto"
  />
</div>
</div>

{/* Amount calculator */}
<div className="onecalculator">
<div className="amountdives">
<p>Girl's Age </p><input type="text" value={Age} onChange={(event)=>{
  girlage(event)
}}/>
</div>
<div className="amountselectordiv">
<Slider defaultValue={1} 
aria-label="Default" 
valueLabelDisplay="auto" 
onChange={(event)=>{
    girlage(event)
}}
min={1}
max={10}
step={1} // Step remains at 0.5
value={Age}
/>

</div>
</div>

{/* Amount calculator */}
<div className="onecalculator">
<div className="amountdives">
<p>Start Period Period (Year) </p><input type="text" value={Timeperiod} onChange={(event)=> {timeperiodinvest(event)}}/>
</div>
<div className="amountselectordiv">
<Slider defaultValue={2019} 
aria-label="Default" 
valueLabelDisplay="auto" 
onChange={(event)=> {timeperiodinvest(event)}}
min={2019}
max={2030}
value={Timeperiod}
/>
</div>
</div>


<div className="calculateamountdiv">
<div>
<p>Investment Value: <span style={{color:'#0166B3'}}>{calculateMaturityAmount()}</span></p>
<p>MAturity Age: <span style={{color:'#0166B3'}}>{MaturityYear()}</span></p>
<p>Maturity Year: <span style={{color:'#0166B3'}}>{maturity}</span></p>
</div>
</div>


  </div>
  <div className="graphdiv">

 {/*  <Chart
        type='donut'
        width={400}
        height={445}
        series={[investmentAmount(), ReturnAmount(),]}
        options={{
          labels:['Invested Amount', 'Return Value']
        }}
      />
*/} 
 





  
  </div>
  </div>
  </div>
  {/* Result part start hear */}
  <div className="resultse">
 <Calculatorlist/>
  </div>
  
  
  </div>
  </div>
  </div>
  )
}

export default SSY