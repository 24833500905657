import React from 'react'
import '../Calculator-Charat/Calculatorlist.css'
import { useNavigate } from 'react-router-dom'
const Calculatorlist = () => {
    const nav = useNavigate()

   const calculatorsData = [
    {
        id:0,
        name:"SIP-Calculator",
        link:"/calculator/sip-calculator"
    },
    {
        id:1,
        name:"Lumpsum Calculator",
        link:"/calculator/lumpsum-calculator"
    },
    {
        id:2,
        name:"BMI-Calculator",
        link:"/calculator/bmi-calculator"
    },
    {
        id:3,
        name:"EMI calculator",
        link:"/calculator/emi-calculator"
    },
    {
        id:4,
        name:"Car Loan calculator",
        link:"/calculator/car-loan-calculator"
    },
    {
        id:5,
        name:"Home-Loan Calculator",
        link:"/calculator/home-loan-calculator"
    },
    // {
    //     id:6,
    //     name:"SSy-calculator",
    //     link:"/calculator/ssy-calculator"
    // },
    {
        id:6,
        name:"FD-calculator",
        link:"/calculator/fd-calculator"
    },
    {
        id:7,
        name:"RD-calculator",
        link:"/calculator/rd-calculator"
    },
    {
        id:8,
        name:"Simple Interest - Calculator",
        link:"/calculator/simple-interest-calculator"
    },
    // {
    //     id:9,
    //     name:"Swp Calculator",
    //     link:"/calculator/swp-calculator"
    // },
    {
        id:10,
        name:"NPS calculator",
        link:"/calculator/nps-calculator"
    },
    {
        id:11,
        name:"Marrige Calculator",
        link:"/calculator/child-marrige-calculator"
    },
    {
        id:12,
        name:"Pension Calculator",
        link:"/calculator/pension-calculator"
    },
    {
        id:13,
        name:"HLV Calculator",
        link:"/calculator/human-life-vlue-calculator"
    },
    {
        id:12,
        name:"Child Education Calculator",
        link:"/calculator/child-education-calculator"
    },
    {
        id:13,
        name:"Retairment Calculator",
        link:"/calculator/retairment-calculator"
    },
    {
        id:14,
        name:"ULIP Calculator",
        link:"/calculator/ulip-calculator"
    },
    {
        id:15,
        name:"Bond Calculator",
        link:'/calculator/bond-calculator'
    },
    {
        id:16,
        name:"Long Term Capital Calculator",
        link:'/calculator/longtermcapitalgain'
    },
    {
        id:17,
        name:"Short Term Capital Calculator",
        link:'/calculator/shorttermcapitalgain'
    }
   ]
   





  return (
    <div>

    {
        calculatorsData.map((data,index)=>{
            return(
                <div className="calculatorsresult" onClick={()=>{nav(data.link)}} key={index}>{data.name}</div>
            )
        })
    }
   
    
  

    </div>
  )
}

export default Calculatorlist