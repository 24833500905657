import React, { useState, useEffect } from 'react';
import '../SIP/SIP.css'
import Slider from '@mui/material/Slider';
import '../BMI/BMI.css';
import { Link } from 'react-router-dom';
import Calculatorlist from '../Calculator-Charat/Calculatorlist';
import { Helmet } from 'react-helmet-async';

const BMI = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
const [bmi, setBMI] = useState(null);

// Set Gender
const [SelectGender, setSelectGender] = useState('Male')
   const setGender = (data) =>{
    setSelectGender(data)
   }
// Set height
   const [height, setHeight] = useState(4);
   const handelheight = (event) =>{
    setHeight(event.target.value)
   }
// set inchi
const [Inchi, setInchi] = useState(8)
const handelinchi = (event) =>{
    setInchi(event.target.value)
}
// Waight
const [weight, setWeight] = useState(51);
const handelwaight = (event) =>{
    setWeight(event.target.value)
}
// Age
const [Age, setAge] = useState(24)
const handelAge = (event) =>{
   setAge(event.target.value)
}

const HandelCalculate = () =>{
  // Input validation
  if (weight <= 0 || (height <= 0 && Inchi <= 0)) {
    alert('Please enter valid values for weight and height.');
    return;
  }

  let totalHeightInCM;
  if (height && !Inchi) {
    totalHeightInCM = height * 100;
  } else if (height && Inchi) {
    totalHeightInCM = (height * 30.48) + (Inchi * 2.54);
  } else {
    alert('Please enter a valid height value (centimeters or feet and inches).');
    return;
  }

  const heightInMeters = totalHeightInCM / 100;
  const bmi = weight / (heightInMeters * heightInMeters);

  setBMI(bmi.toFixed(2));
}




const [datase, setData] = useState(0);

const BMIDATAse = Math.ceil(bmi) 

console.log(BMIDATAse)

useEffect(() => {
  const BMIDATAse = Math.ceil(bmi);
  console.log(BMIDATAse); // This should log the correct BMI value to the console
  
  if (BMIDATAse < 14) {
    setData(2.8);
  } else if (BMIDATAse >= 14 && BMIDATAse <= 16) {
    setData(10.8);
  } else if (BMIDATAse >= 17 && BMIDATAse <= 18) {
    setData(30);
  } else if (BMIDATAse >= 18 && BMIDATAse <= 19) {
    setData(35);
  } else if (BMIDATAse >= 20 && BMIDATAse <= 22) {
    setData(40);
  } else if (BMIDATAse >= 23 && BMIDATAse <= 24) {
    setData(60);
  } else if (BMIDATAse >= 25 && BMIDATAse <= 26) {
    setData(70);
  } else if (BMIDATAse >= 27 && BMIDATAse <= 28) {
    setData(80);
  } else if (BMIDATAse >= 28 && BMIDATAse <= 30) {
    setData(100);
  } else if (BMIDATAse >= 30 && BMIDATAse <= 31) {
    setData(110);
  } else if (BMIDATAse >= 32 && BMIDATAse <= 33) {
    setData(120);
  } else if (BMIDATAse >= 33 && BMIDATAse <= 34) {
    setData(130);
  } else if (BMIDATAse >= 35 && BMIDATAse <= 36) {
    setData(140);
  } else if (BMIDATAse >= 37 && BMIDATAse <= 38) {
    setData(150);
  } else if (BMIDATAse >= 39 && BMIDATAse <= 40) {
    setData(160);
  } else if (BMIDATAse >= 41 && BMIDATAse <= 42) {
    setData(170);
  } else if (BMIDATAse >= 43 && BMIDATAse <= 45) {
    setData(180);
  } else {
    setData(180); // Default to 180 if no matching range is found
  }
}, [bmi]); // Include bmi as a dependency to trigger the effect on bmi change


// Convert datase to string
const dataseAsString = datase.toString();



const [BMICondition, setBMICondition] = useState('')

useEffect(()=>{
  
  if (bmi < 18) {
    setBMICondition("Underweight");
  } else if (bmi >= 19 && bmi < 30) {
    setBMICondition("Normal");
  } else if (bmi >= 31 && bmi < 39) {
    setBMICondition("Overweight");
  } else if(bmi > 40) {
  setBMICondition("Obese");
}
},[])


useEffect(()=>{

},[dataseAsString])

const UnderWaight = {
  color: '#cd0000',
  backgroundColor: '#fbc5c5',
  padding: '15px 0px',
  width: '99%',
  fontFamily: 'Poppins',
  borderRadius: '10px',
  margin: '20px auto',
  fontSize: "10pt"
};
const BMI1718 = {
  color: "rgb(159 144 20)",
  backgroundColor: "#ffe4001a",
  padding: "15px 0px",
  width: "99%",
  fontFamily: 'Poppins',
  borderRadius: "10px",
  margin: "20px auto", // Corrected from "20px auto"; to "20px auto",
  fontSize: "10pt"
}
const BMI1925 = {
  color: "#008137",
  backgroundColor: "rgb(0 129 55 / 12%)",
  padding: "15px 0px",
  width: "99%",
  fontFamily: 'Poppins',
  borderRadius: "10px",
  margin: "20px auto", // Corrected from "20px auto"; to "20px auto",
  fontSize: "10pt"
}
const BMI2635 = {
  color: "#D38888",
  backgroundColor: "rgb(211 136 136 / 54%)",
  padding: "15px 0px",
  width: "99%",
  fontFamily: 'Poppins',
  borderRadius: "10px",
  margin: "20px auto", // Corrected from "20px auto"; to "20px auto",
  fontSize: "10pt"
}
const BMI3240 = {
  color: "#BC2020",
  backgroundColor: "rgb(188 32 32 / 42%)",
  padding: "15px 0px",
  width: "99%",
  fontFamily: 'Poppins',
  borderRadius: "10px",
  margin: "20px auto", // Corrected from "20px auto"; to "20px auto",
  fontSize: "10pt"
}
const BMI40 = {
  color: "#8A0101",
  backgroundColor: "rgb(138 1 1 / 48%)",
  padding: "15px 0px",
  width: "99%",
  fontFamily: 'Poppins',
  borderRadius: "10px",
  margin: "20px auto", // Corrected from "20px auto"; to "20px auto",
  fontSize: "10pt"
}




const Normal = {
  color: "#00460d",
  backgroundColor: "#00813738",
  padding: "15px 0px",
  width: "99%",
  fontFamily: 'Poppins',
  borderRadius: "10px",
  margin: "20px auto", // Corrected from "20px auto"; to "20px auto",
  fontSize: "10pt"
};

const OverWaight = {
  color: "#837a0b",
  backgroundColor: "#a2a94038",
  padding: "15px 0px",
  width: "99%",
  fontFamily: 'Poppins',
  borderRadius: "10px",
  margin: "20px auto", // Corrected from "20px auto"; to "20px auto",
  fontSize: "10pt"
}

const Obesity = {
  color: "#830c0c",
  backgroundColor: "d9666638",
  padding: "15px 0px",
  width: "99%",
  fontFamily: 'Poppins',
  borderRadius: "10px",
  margin: "20px auto", // Corrected from "20px auto"; to "20px auto",
  fontSize: "10pt"
}

const dataAsString = `${dataseAsString}deg`;
    return (
      <>
 <Helmet>
  <title>Online BMI Calculator</title>
  <meta name="description" content="Calculate your BMI easily with our online BMI calculator." />
  <meta name="keywords" content="BMI calculator online, online BMI calculator, body mass index calculator, BMI calculator tool" />
</Helmet>


              <section className="margincalculator"></section>
      <div className="headingcalculator">
      <p>Home &nbsp;&gt;&nbsp;&nbsp;&nbsp; <Link to={"/calculator"}>calculator</Link>&nbsp;&nbsp;&nbsp;&nbsp;&gt;&nbsp;&nbsp;<span style={{color:'green'}}>BMI calculator</span></p>

    <span className="titlecalculator">BMI <span className='Mainheading'>Calculator</span></span>
    <br />
<sapn className="CalculatorDiscription">A BMI calculator assesses body fat using height and weight, indicating if someone is 
underweight, normal weight, overweight, or obese, aiding in understanding associated health 
risks</sapn>
</div>
   
<div className="calculatorhead">
  

  <div className="underdiv">
  <div className="calculatorse">

  {/* Calculator start div start hear */}
  <div className="calculaterse">





  <div className="calculator">
  <div className='bmigenderheader'>
  <span className={SelectGender === "Male" ? 'genderspan' : 'deactivategender'} onClick={()=> setGender('Male')}>Male</span>
  <span className={SelectGender === "Female" ? 'genderspan' : 'deactivategender'} onClick={()=> setGender('Female')}>Female</span>
    </div>


{/* Amount calculator */}

<div className="amountdives">
  <p>Height(ft)</p>
  <input type="text"   value={height}
  onChange={(event)=>{handelheight(event)}}/>
</div>
<div className="amountselectordiv">
  <Slider
    value={height}
    onChange={(event)=>{handelheight(event)}}
    min={1}
    max={10}
    step={1} // optional, set the step value
    aria-label="Default"
    valueLabelDisplay="auto"
  />
</div>


{/* Amount calculator */}
<div className="amountdives">
<p>Height (inchi)</p><input type="text" onChange={(event)=>{handelinchi(event)}} value={Inchi}/>
</div>
<div className="amountselectordiv">
<Slider defaultValue={50} 
onChange={(event)=>{handelinchi(event)}}
aria-label="Default" 
valueLabelDisplay="auto" 
value={Inchi}
min={1}
max={12}
step={1} // Step remains at 0.5

/>

</div>


{/* Amount calculator */}
<div className="amountdives">
<p>Weight</p><input type="text" onChange={(event)=>{handelwaight(event)}}
value={weight}/>
</div>
<div className="amountselectordiv">
<Slider defaultValue={50} 
onChange={(event)=>{handelwaight(event)}}
value={weight}
aria-label="Default" 
valueLabelDisplay="auto" 
min={1}
max={150}

/>
</div>



<div className="amountdives">
<p>Age</p><input type="text" onChange={(event)=>handelAge(event)}
value={Age}/>
</div>
<div className="amountselectordiv">
<Slider defaultValue={50} 
onChange={(event)=>handelAge(event)}
value={Age}
aria-label="Default" 
valueLabelDisplay="auto" 
min={1}
max={100}

/>
</div>

<div className="bmidata">
<button onClick={HandelCalculate} disabled={Age === ''} >Calculate</button>
</div>







  </div>



  <div className="BMIGraph">
<svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="300px"
      height="163px"
      viewBox="0 0 300 163"
    >


<defs>
    <marker id="arrowhead" markerWidth="10" markerHeight="7"
            refX="0" refY="3.5" orient="auto">
      <polygon points="0 0, 10 3.5, 0 7" />
    </marker>
  </defs>




      <g transform="translate(18,18)" style={{ fontFamily: "Arial, Helvetica, sans-serif", fontSize: 12 }}>
        <defs>
          <marker id="arrowhead" markerWidth={10} markerHeight={7} refX={0} refY="3.5" orient="auto">
            <polygon points="0 0, 10 3.5, 0 7" />
          </marker>
          <path id="curvetxt1" d="M-4 140 A140 140, 0, 0, 1, 284 140" style={{ fill: "none" }} />
          <path id="curvetxt2" d="M33 43.6 A140 140, 0, 0, 1, 280 140" style={{ fill: "none" }} />
          <path id="curvetxt3" d="M95 3 A140 140, 0, 0, 1, 284 140" style={{ fill: "none" }} />
          <path id="curvetxt4" d="M235.4 33 A140 140, 0, 0, 1, 284 140" style={{ fill: "none" }} />
        </defs>
        <path d="M0 140 A140 140, 0, 0, 1, 6.9 96.7 L140 140 Z" fill="#bc2020" />
        <path d="M6.9 96.7 A140 140, 0, 0, 1, 12.1 83.1 L140 140 Z" fill="#d38888" />
        <path d="M12.1 83.1 A140 140, 0, 0, 1, 22.6 63.8 L140 140 Z" fill="#ffe400" />
        <path d="M22.6 63.8 A140 140, 0, 0, 1, 96.7 6.9 L140 140 Z" fill="#008137" />
        <path d="M96.7 6.9 A140 140, 0, 0, 1, 169.1 3.1 L140 140 Z" fill="#ffe400" />
        <path d="M169.1 3.1 A140 140, 0, 0, 1, 233.7 36 L140 140 Z" fill="#d38888" />
        <path d="M233.7 36 A140 140, 0, 0, 1, 273.1 96.7 L140 140 Z" fill="#bc2020" />
        <path d="M273.1 96.7 A140 140, 0, 0, 1, 280 140 L140 140 Z" fill="#8a0101" />
        <path d="M45 140 A90 90, 0, 0, 1, 230 140 Z" fill="#fff" />
        <circle cx={140} cy={140} r={5} fill="#666" />
        <g style={{ paintOrder: "stroke", stroke: "#fff", strokeWidth: 2 }}>
          <text x={25} y={111} transform="rotate(-72, 25, 111)">
            16
          </text>
          <text x={30} y={96} transform="rotate(-66, 30, 96)">
            17
          </text>
          <text x={35} y={83} transform="rotate(-57, 35, 83)">
            18.5
          </text>
          <text x={97} y={29} transform="rotate(-18, 97, 29)">
            25
          </text>
          <text x={157} y={20} transform="rotate(12, 157, 20)">
            30
          </text>
          <text x={214} y={45} transform="rotate(42, 214, 45)">
            35
          </text>
          <text x={252} y={95} transform="rotate(72, 252, 95)">
            40
          </text>
        </g>
        <g style={{ fontSize: 14 }}>
          <text>
            <textPath xlinkHref="#curvetxt1">Underweight</textPath>
          </text>
          <text>
            <textPath xlinkHref="#curvetxt2">Normal</textPath>
          </text>
          <text>
            <textPath xlinkHref="#curvetxt3">Overweight</textPath>
          </text>
          <text>
            <textPath xlinkHref="#curvetxt4">Obesity</textPath>
          </text>
        </g>
        <line
  className='rotatingLine'
  x1={140}
  y1={140}
  x2={65}
  y2={140}
  stroke="#666"
  strokeWidth={3}
  markerEnd="url(#arrowhead)"
  style={{
    transformOrigin: '140px 140px',  // Set the origin of rotation at (140, 140)
    animation: 'rotateLine 2s linear forwards'  // Animation name, duration, timing function, and fill mode
  }}
>
    <animateTransform
      attributeName="transform"
      attributeType="XML"
      type="rotate"
      from="30 140 140"  // Rotate 30 degrees around point (140, 140)
      to={`${dataseAsString} 140 140`} 
      dur="5s"            // Duration of the animation
      fill="freeze"       // Freeze at the final state
      repeatCount="1"     // Repeat animation only once
      keyTimes="0;1"      // Key times for the animation
      keySplines="0.42, 0, 0.58, 1"  // Cubic Bezier timing function for smooth animation
    />
  </line>


        <text x={67} y={120} style={{ fontSize: 20, fontWeight: "bold", color: "#000" }}>
          BMI = {bmi || 0}
        </text>
      </g>
    </svg>
<div hidden={bmi < 5}>


  {bmi < 16 && (
        <h6 style={UnderWaight}>
          Your BMI is {bmi} which is {BMICondition}
        </h6>
      )}
    {(bmi >= 16 && bmi < 17) && (
        <h6 style={BMI1718}>
          Your BMI is {bmi} which is {BMICondition}
        </h6>
      )}
          {(bmi >= 19 && bmi < 25) && (
        <h6 style={BMI1925}>
          Your BMI is {bmi} which is {BMICondition}
        </h6>
      )}
          {(bmi >= 26 && bmi < 30) && (
        <h6 style={BMI1718}>
          Your BMI is {bmi} which is {BMICondition}
        </h6>
      )}
         {(bmi >= 31 && bmi < 35) && (
        <h6 style={BMI2635}>
          Your BMI is {bmi} which is {BMICondition}
        </h6>
      )}
         {(bmi >= 32 && bmi < 40) && (
        <h6 style={BMI3240}>
          Your BMI is {bmi} which is {BMICondition}
        </h6>
      )}
       {bmi > 40 && (
        <h6 style={BMI40}>
          Your BMI is {bmi} which is {BMICondition}
        </h6>
      )}

</div>




    </div>






  </div>
  </div>
  {/* Result part start hear */}
  <div className="resultse">
  <Calculatorlist/>
  </div>
  
  
  </div>
  </div>
  <style>{`
      @keyframes rotateLine {
        from {
          transform: rotate(0deg); 
        }
        to {
          transform: rotate(${dataAsString});
        }
      }

      .rotatingLine {
        transform-origin: 140px 140px;
        animation: rotateLine 2s linear forwards;
      }
    `}</style>


       </>
       );
      };
      
      export default BMI;