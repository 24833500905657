import React from 'react';
import '../HealthInsureComponents/DocumentForHealthInsurence.css';
import { GrDocumentUser } from "react-icons/gr";
const DocumentForHealthInsurence = () => {
  return (
<div className="fixwidthDivtext">

      <div className="container">
      <h4 className="healthHeading">Documents Required for Health Insurance</h4>
        <div className="yellowline"></div>
        <p className="normaltext">
          Below are some of the documents needed to buy health insurance policy:
        </p>
     <div className="flexdisplaydocrequird">
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div className="cardone">
              <ul>
                <li><GrDocumentUser /> &nbsp;Aadhaar card</li>
                <li><GrDocumentUser /> &nbsp;Driving license</li>
                <li><GrDocumentUser /> &nbsp;Passport</li>
                <li><GrDocumentUser /> &nbsp;PAN card</li>
                <li><GrDocumentUser /> &nbsp;A duly filled application form</li>
              </ul>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div className="cardtwo">
              <ul>
                <li><GrDocumentUser /> &nbsp;Voter ID card</li>
                <li><GrDocumentUser /> &nbsp;Pre-existing medical condition report</li>
                <li><GrDocumentUser /> &nbsp;Copy of previous medical insurance policy</li>
                <li><GrDocumentUser /> &nbsp;Copy of salary slips</li>
              </ul>
            </div>
          </div>
          </div>
      </div>
    </div>
  )
}

export default DocumentForHealthInsurence