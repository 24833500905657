import React from 'react';
import FAQ from '../AllPageComponents/FAQ/FAQ';
import PartnerCompany from '../AllPageComponents/PartnerCompany/PartnerCompany';
import BikeInsuranceForm from './BikeInsuranceForm';
import FastImage from "../FastImage";
import { auto } from '@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core'

const BikeClaimSettlement = () => {

  return (
    <>

<section className="car-insurance insurance-all insurance-background">
        <div className="container">
          <div
            className="row insurance-row-one"
            style={{ justifyContent: "space-around" }}
          >
            <div className="col-sm-12 col-md-4 col-lg-4 mian-one">
              <div className="car-insurance-first-inner">
                <h4>Bike Insurance Claim Settlement</h4>
                <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer too
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4 side-image">
  <FastImage
            url={"Website%20Img/BikeInsurence.png?updatedAt=1718779269495"}
            width={auto}
            height={auto}
            alt={"ca-img"}
            />
            </div>
          
            <style
              dangerouslySetInnerHTML={{
                __html: "\n    .hide{\n        display: none;\n    }\n",
              }}
            />
            <BikeInsuranceForm/>
           

          </div>
        </div>
      </section>
<section className="half-space-block">
  <div className="container">
    <div className="row insurance-row-two-less-farage">
      <div className="col-sm-12 col-md-12 col-lg-12">
        <div className="insurance-less-farage">
          <div className="simple-text-block">
            <h5>What is Bike Insurance Claim Settlement?</h5>
            <p>
              <span style={{ fontFamily: '"Segoe UI"' }}>
                Bike Insurance Claim is the process under which a bike insurance
                policyholders requests the insurer to compensate for the
                expenses/damage incurred by him/her due to an unfortunate event
                involving his/her bike. The amount and extent of the claim
                amount depend on the Insured Declared Value (IDV) of your bike
                and the type of bike insurance policy bought by you - third
                party, own damage or comprehensive.
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section className="car-claim-settlement">
  <div className="container">
    <div className="row car-claim-settlement-row-two justify-content-center align-items-center">
      <div className="col-sm-12 col-md-12 col-lg-12">
        <div className="insurance-less-farage">
          <div className="simple-text-block text-center">
            <h5>Types of Bike Claim Settlement</h5>
            <p>
              A bike insurance claim can be settled in either of the two ways:
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className="row car-claim-settlement-row-two">
      <div className="col-sm-12 col-md-6 col-lg-6">
        <div className="back-ground-gray">
          <div className="head-box">
            <h4>01</h4>
            <h6>Cashless Claim</h6>
          </div>
          <div className="car-claim-settlement-box-body">
            <p>
              If you get your bike repaired at a garage authorised by the
              insurer then you enter a cashless claim settlement process. As the
              name suggests, under this type of claim the entire process is
              cashless which means that the policyholder is not required to pay
              any cash/money to the insurer authorised garage for the repair of
              his/her bike. The insurer makes the payment to the garage directly
              subject to the claim amount. The policyholder is only liable to
              pay for the compulsory deductible and voluntary deductible if
              opted for at the time of policy purchase.
            </p>
          </div>
        </div>
      </div>
      <div className="col-sm-12 col-md-6 col-lg-6">
        <div className="back-ground-gray">
          <div className="head-box">
            <h4>02</h4>
            <h6>Reimbursement Claim</h6>
          </div>
          <div className="car-claim-settlement-box-body">
            <p>
              When you get your bike repaired at a garage/workshop outside the
              authorised network of the insurer, you enter a reimbursement claim
              settlement process. Under this process, the policyholder makes the
              payment for the repair cost at the garage of his/her choice and
              gets the repair cost reimbursed from the insurer later subject to
              the claim amount. The insurer reimburses the entire claim amount
              to the policyholder after factoring in the applicable deductibles
              - compulsory and voluntary (if opted for).
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section className="full-space-block">
  <div className="container">
    <div className="row full-space-block-row">
      <div className="col-sm-12 col-md-12 col-lg-12">
        <div className="insurance-less-farage">
          <div className="simple-text-block">
            <h5>Documents Required for Bike Insurance Claim Settlement</h5>
            <p>
              EYou need the following documents at the time of claim settlement
              of your bike insurance:
            </p>
            <ul className="calculator-list">
              <li>
                <p>Duly signed and filled claim form</p>
              </li>
              <li>
                <p>Insurance Policy Document</p>
              </li>
              <li>
                <p>Car’s Registration Certificate (RC)</p>
              </li>
              <li>
                <p>Driver’s licence (DL)</p>
              </li>
              <li>
                <p>Pollution Under Control (PUC) Certificate</p>
              </li>
              <li>
                <p>
                  Original receipt/bills of repairs (in case of reimbursement
                  claim)
                </p>
              </li>
              <li>
                <p>
                  FIR (in case of fatal injuries/accidents or third party
                  liabilities)
                </p>
              </li>
              <li>
                <p>
                  A copy of PAN Card (if the claim amount is more than Rs. 1
                  lakh).
                </p>
              </li>
              <li>
                <p>
                  Duly signed Satisfaction Voucher (SV) or Discharge Voucher
                  (DV)
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<PartnerCompany/>
<FAQ/>

    </>
  )
}

export default BikeClaimSettlement