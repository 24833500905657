import React from 'react';
import FAQ from '../AllPageComponents/FAQ/FAQ';
import { useDispatch } from 'react-redux'
import { VehicalApi } from '../Health/redux/Slice';
import { useState } from 'react';
import PartnerCompany from '../AllPageComponents/PartnerCompany/PartnerCompany';
import BikeInsuranceForm from './BikeInsuranceForm';
import FastImage from "../FastImage";
import { auto } from '@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core'



const BikeStandAlone = () => {
  const disptch = useDispatch()


  const [formData, setFormData] = useState({
    car_number: '',
    phone_no: '',
    pin: '',
    car_type: 'Twowheeler',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Log the entire form data
    disptch(VehicalApi(formData))
    //console.log('Form Data:', formData);
    // Add your form submission logic here
  };
  return (
    <>
    <section className="car-insurance insurance-all insurance-background">
  <div className="container">
    <div className="row insurance-row-one" style={{justifyContent:"space-around"}}>
      <div className="col-sm-12 col-md-4 col-lg-4 mian-one">
        <div className="car-insurance-first-inner">
          <h4>Bike Insurance Standalone Own Damage</h4>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer to
          </p>
        </div>
      </div>
      <div className="col-sm-12 col-md-4 col-lg-4 side-image">
      <FastImage
            url={"Website%20Img/BikeInsurence.png?updatedAt=1718779269495"}
            width={auto}
            height={auto}
            alt={"ca-img"}
            />
      </div>
      <style
        dangerouslySetInnerHTML={{
          __html: "\n    .hide{\n        display: none;\n    }\n"
        }}
      />
      <BikeInsuranceForm/>
   
      <section className="insurance-popup popup-all">
        <div className="insurance-popup-inner container">
          <div className="insurance-popup-sub">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="modal-header">
                  <button
                    href="javascript:void(0)"
                    className="trigger-back nextprevious"
                    data-activediv="make"
                  >
    
                  </button>
                  <button className="item-4 modal-close modal-toggle" />
                </div>
           
              </div>
            </div>
          </div>
        </div>
        <input type="hidden" id="rollverval" />
      </section>
    </div>
  </div>
</section>
<section>
  <div className="container">
    <div className="row">
      <div className="col-12">
        <div className="ins-text-description">
          <p />
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer to
          </p>
          <p />
        </div>
      </div>
    </div>
  </div>
</section>
<PartnerCompany/>
<FAQ/>

    </>
  )
}

export default BikeStandAlone