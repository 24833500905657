import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
// import "./style1.css";
import App from "./App";
import { Provider } from "react-redux";
import { Store } from "./Health/redux/Store";

import { HelmetProvider } from "react-helmet-async";
import ErrorBoundary from "./CommonPages/ErrorBoundary/ErrorBoundary";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
<>

<ErrorBoundary>
  <HelmetProvider>
  <Provider store={Store}>
  <App />
  </Provider>
  </HelmetProvider>
</ErrorBoundary>
  </>

);


