import { IKContext, IKImage } from "imagekitio-react";

const FastImage = ({
  url = "/default-img.jpg",
  alt="",
  width = 200,
  height = 200,
  isLazy = true,
  showBlurry = true,
  className=""
}) => {
  return (
    <IKContext 
      urlEndpoint="https://ik.imagekit.io/teofx8bxs"
      publicKey="public_cByGWF64WLo1UVjA1cd/XCatNkw=" //move to .env file later
    >
      <IKImage
      className={className}
        alt={alt}
        path={url}
        transformation={[
          {
            f: "webp", //can be auto,png,jpg,webp,..
            height: `${height}`,
            width: `${width}`,
          },
        ]}
        loading={isLazy ? "lazy" : ""}
        
        lqip={{ active: showBlurry, quality: 20, blur: 10 }}
        
      />
    </IKContext>
  );
};

export default FastImage;
