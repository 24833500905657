import React,{useState} from 'react'
import './AdviceFromExpert.css'
import Swal from "sweetalert2";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { FaChevronDown } from "react-icons/fa";
import { FaArrowRotateRight } from "react-icons/fa6";
import axios from 'axios';
import FastImage from '../../FastImage';
import { auto } from '@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core';

const AdviceFromExpert = () => {

    const [AddedSucessfull, setAddedSucessfull] = useState('')
    const [expanded, setExpanded] = useState(false);
    const [userInput, setUserInput] = useState("");
    const [captcha, setCaptcha] = useState(generateCaptcha());


    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };
  
       const [formData, setFormData] = useState({
      name: '',
      product_name: 'health-insurence',
      mobile_number: '',
      time_slot: '',
      message: '',
      code: '1234'
    });

    const handleChanges = (event) => {
      const { name, value } = event.target;
      setFormData({
        ...formData,
        [name]: value
      });
    };



    const validateForm = () => {
      const newErrors = {};
      if (!formData.name) newErrors.name = "Name is required";
      if (!formData.mobile_number) newErrors.mobile_number = "Mobile number is required";
      if (!formData.time_slot) newErrors.time_slot = "Time slot is required";
      if (!formData.message) newErrors.message = "Message is required";
      return Object.keys(newErrors).length === 0;
    };



    const handleSubmit = (event) => {
      event.preventDefault();
      if (!validateForm()) {
        return(
        Swal.fire({
          title: "Wrror",
          text: "Filds are Requird",
          icon: "error",
          confirmButtonText: "Done",
        })
      )
      };
   
      if (userInput === captcha) {
        axios.post( `${process.env.REACT_APP_BASE_URL}cms/user_callback_request`,formData).then((res)=>{
     
          if (res.data.status === true) {
            Swal.fire({
              title: "Success!",
              text: "Your Request Submitted Successfully",
              icon: "success",
              confirmButtonText: "Done",
            });
            setFormData({
              name: '',
              product_name: 'health-insurence',
              mobile_number: '',
              time_slot: '',
              message: '',
              code: ''
            });
            setUserInput('')
            handleRegenerate()
          }

           
        }).catch((err)=>{})

        // Here, you can use fetch or axios to post data to your server
      } else {
        Swal.fire({
          title: "Wrror",
          text: "Captcha Wrong",
          icon: "error",
          confirmButtonText: "Done",
        });
      }
    };


    function generateCaptcha() {
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let result = "";
      for (let i = 0; i < 6; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * characters.length)
        );
      }
      return result;
    }


    function handleRegenerate() {
      setCaptcha(generateCaptcha());
    }
  







const contextData = [
    {
        id:0,
        img: "Website%20Img/Calling-cuate.png?updatedAt=1718791866637",
        Title:"Span Free Guideline",
        text:"Spam may look like an integral part of our online experience, and something that we  have come to accept as normal but it doesn’t have to be. When you contact Beema1, we assure you that there will be no unsolicited and unwanted junk messages sent in bulk or unwanted calls to be made"
    },
 

   
  
]

const DropdownData = [
  {
    id:0,
    img: "Website%20Img/Webinar-pana.png?updatedAt=1718791867001" ,
    Title:"We provide free consultation and we will never force you to buy from Beema1",
    text:"What if something people don’t buy but are forced to buy something that they don’t need? It usually happens when you can’t stop yourself from binge buying or if you are forced to buy something under pushy salesmanship. At Beema1, you completely experience the opposite. Here we will only cater to your needs and requirements. We, as a team, do our due diligence before advising our best insurance products to our clients"
},
{
  id:1,
  img: "Website%20Img/Calling-cuate.png?updatedAt=1718791866637",
  Title:"Brief consultation without long phone calls",
  text:"We know that long phone calls are generally boring and monotonous if it's not from your other half. Therefore, we care about your precious time. We prefer keeping our consultation calls brief and crisp. If you and your maybe-client are clear on your business questions and concerns you have, you should be easily fleshed out within a decent timeframe. Beema1 promises not to kill your valuable time on long phone calls, ensuring you can focus on more important tasks, like securing the best insurance policy for your needs"
},
    
        {
            id:2,
            img: "Website%20Img/Good%20team-pana.png?updatedAt=1718791867136",
            Title:"Get engaged with experts and advisors",
            text:"Beema1 holds a team of actively engaged employees who are highly motivated and committed to their job roles and the company. We call them a team of experts. They are extremely focused, productive, and efficient, as their work directly contributes to the overall business results and company culture. Hence, our experts are quite capable of taking you through the chain of the best insurance plans in the market"
        },
    
        {
          id:3,
          img: "Website%20Img/Good%20team-pana.png?updatedAt=1718791867136",
          Title:"No aggressive or pushy salesmanship",
          text:"  Think of the ‘Wolf of Wall Street’, Market Stall Holders or Politicians! If they have an idea or a product they won’t stop until you get it. But at Beema1 we care about your sentiments. We propose need-based solutions to you at your convenience and as per your requirements. Whether you’re exploring the best insurance plans or other services, we focus on our prospects and make you feel comfortable while we professionally deal with you"
      },
      {
        id:4,
        Title:'No aggressive or pushy salesmanship',
        text:'  Think of the ‘Wolf of Wall Street’, Market Stall Holders or Politicians! If they have an idea or a product they won’t stop until you get it. But at Beema1 we care about your sentiments. We propose need-based solutions to you at your convenience and as per your requirements. Whether you’re exploring the best insurance plans or other services, we focus on our prospects and make you feel comfortable while we professionally deal with you'
      }
    
]





return (

<div className="fixwidthDivtext">
<div className="container">
<div className="headertextsee tile-text-with-text-shadow">
        <h2>Advice from the experts !</h2>

  
      </div>

      <div className="underflexdivs">
        <div className='underfaqs'>


<div className="commonone">


        <h4 className='theheadingcolor'>What You Get From US ?</h4>
        <div className="yellowline"></div>
          {contextData.map((data, index) => (
            <div className="contextsguide" key={index}>
              <div className="undertextcontext">
                {/* <li style={{listStyle:"none"}}><b>{data.Title}:-</b> &nbsp;{data.text}</li> */}
                <p className='textformat' >
                  <span className='specialprice'>{data.Title}:-</span>
                  {data.text}
                </p>
              </div>
            </div>
          ))}
          <div>
          {DropdownData.map((data, index) => (
        <Accordion
          expanded={expanded === `panel${index}`}
          onChange={handleChange(`panel${index}`)}
          key={index}
          defaultExpanded
        >
          <AccordionSummary
            expandIcon={<FaChevronDown />}
            aria-controls={`panel${index}-content`}
            id={`panel${data.index-1}-header`}
            className="accordingback"
          >
            {/* <IoMdAddCircle style={{color:'#2959B8',marginLeft:'5px',marginRight:'5px'}} fontSize={'15pt'}/> */}
            <Typography>
            <span className='listcol'>{data.Title}</span>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            <p className='textformat' >
                {data.text}
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    
   {/* <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<FaChevronDown />}
          aria-controls="panel2-content"
          id="panel2-header"
          className="accordingback"
        >
           <span className='listcol'>No aggressive or pushy salesmanship</span>
          
        </AccordionSummary>
        <AccordionDetails>
        <p className='textformat'>
        Think of the ‘Wolf of Wall Street’, Market Stall Holders or Politicians! If they have an idea or a product they won’t stop until you get it. But at Beema1 we care about your sentiments. We propose need-based solutions to you at your convenience and as per your requirements. Whether you’re exploring the best insurance plans or other services, we focus on our prospects and make you feel comfortable while we professionally deal with you
        </p>
        </AccordionDetails>
      </Accordion> */}
          </div>
          </div>


        </div>





<div className="parentformdivs">



        <div className="undertextimages">
        <form onSubmit={handleSubmit}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="headingfild">
              <h3>Request a Call Back</h3>
            </div>
          </div>
          <div className="col-md-6">
            <div className="inputfild">
              <p>Name <span className="text-danger">*</span></p>
              <input type="text" className='inputTag' placeholder='Your Full Name' name="name" value={formData.name} onChange={handleChanges} />
            </div>
          </div>
          <div className="col-md-6">
            <div className="inputfild">
              <p>Product <span className="text-danger">*</span></p>
              <select id="product_name" name="product_name" value={formData.product_name} onChange={handleChanges}>
                <option value="health-insurence">Health Insurance</option>
                <option value="bike-insurence">Bike Insurance</option>
                <option value="motor-insurence">Motor Insurance</option>
                <option value="car-insurence">Car Insurance</option>
              </select>
            </div>
          </div>
          <div className="col-md-6">
            <div className="inputfild">
              <p>Mobile Number <span className="text-danger">*</span></p>
              <input type="tel" className='inputTag' placeholder='Enter Mobile Number' id="mobile_number" name="mobile_number" value={formData.mobile_number} onChange={handleChanges} />
            </div>
          </div>
          <div className="col-md-6">
            <div className="inputfild">
              <p>Time Slot <span className="text-danger">*</span></p>
              <input type="time" className='inputTag' id="time_slot" name="time_slot" value={formData.time_slot} onChange={handleChanges} />
            </div>
          </div>
          <div className="col-md-12">
            <div className="inputfild">
              <p>Message <span className="text-danger">*</span></p>
              <textarea type="text" className='inputTagMassage' placeholder='Drop Your Massage Hear'  id="message" name="message" value={formData.message} onChange={handleChanges} />
            </div>
          </div>
          <div className="col-md-12">
            <div className="d-flex align-items-center form-input">
              <div className="advicefromexpertformbottom">
                <div className="captcha-divadive">
                  <p className="captcha-para">{captcha}</p>
                  <FaArrowRotateRight
                    className='buttonadvicecapta'
                    onClick={handleRegenerate}
                  />
                </div>

                <input
                  name="code"
                  className="captchacode"
                  placeholder="Enter captcha code"
                  type="text"
                  value={userInput}
                  onChange={(event) => setUserInput(event.target.value)}
                />
    

              </div>
              <label id="captchaMsg" className="error" />
            </div>
          </div>
          <div className="col-md-12">
            <div className="inputfild">
              <button type='submit'>Submit</button>
            </div>
            <p>{AddedSucessfull}</p>
          </div>
        </div>
      </div>
    </form>



        </div>
   

        <FastImage
                      
                      url={"/Website%20Img/pop-up_a.webp?updatedAt=1718775653542"}
                      width={auto}
                      height={auto}
                      alt={"term"}
                      src={"/Website%20Img/pop-up_a.webp?updatedAt=1718775653542"}
                      />

</div>


     
      </div>
    </div>
    </div>
);
};

export default AdviceFromExpert;