import React,{useEffect,useState} from 'react'
import './ContractUs.css'
import Redirect from '../../AllPageComponents/Rederict';
import { FaArrowRotateLeft } from "react-icons/fa6";
import axios from 'axios';
import FastImage from "../../FastImage";
import { auto } from '@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core'
import Swal from "sweetalert2";
import { Helmet } from 'react-helmet-async';

const ContractUs = () => {
    useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  const [formData, setFormData] = useState({
    name: '',
          product_name: '',
          mobile_number: '',
          time_slot: '',
          message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (userInput === generatedCaptcha) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}cms/user_callback_request`,
          formData
        );
        console.log(response.data);
        // Handle success response (e.g., display success message, clear form)
        Swal.fire({
          title: "Success",
          text: "Form Submitted successfully",
          icon: "success",
          confirmButtonText: "Done",
        })
        setFormData({
          name: '',
          product_name: '',
          mobile_number: '',
          time_slot: '',
          message: '',
        });
        setUserInput('');
        generateCaptcha(); // Generate a new CAPTCHA after successful submission
      } catch (error) {
        console.error(error);
        Swal.fire({
          title: "Error",
          text: "Failed to submit the form. Please try again later",
          icon: "error",
          confirmButtonText: "Done",
        })
      }

    } else {
      Swal.fire({
        title: "Error",
        text: "Captcha Wrong",
        icon: "error",
        confirmButtonText: "Done",
      })
    }
  };

  const [generatedCaptcha, setGeneratedCaptcha] = useState('');
  const [userInput, setUserInput] = useState('');
  const [resultMessage, setResultMessage] = useState('');

  const generateRandomString = (length) => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
  };

  const generateCaptcha = () => {
    const captchaText = generateRandomString(6);
    setGeneratedCaptcha(captchaText);
    setResultMessage('');
    setUserInput(''); // Clear the user input when generating a new CAPTCHA
  };

  useEffect(() => {
    generateCaptcha();
  }, []);

  return (
    <div>
<Helmet>
  <title>Contact Us - Beema1</title>
  <meta name="description" content="Get in touch with Beema1 for inquiries, support, and assistance. We're here to help!" />
  <meta name="keywords" content="contact Beema1, contact us, customer support, Beema1 contact, insurance inquiries" />
</Helmet>

    <section class="contec-us-banner-section">


   

<div class="container">
  <Redirect/>
<div className="row">
<div className="col-md-6 d-flex justify-content-center align-items-center"> 
<div>


<h1 className='contract'>Contact <span style={{color:'#2759B8'}}>Us</span> </h1>
<p className='textformat'>If you have any questions, opportunities for collaboration, or require additional assistance, please don't hesitate to contact us. Feel free to reach out to us at your convenience, and let's embark on a journey of exploring the endless possibilities together.</p>

</div>
</div>
<div className="col-md-6 d-flex justify-content-center align-items-center">
  <FastImage width={auto} height={auto} url={'/Website%20Img/contractus.png?updatedAt=1728281824078'} className='contractusimage'/>
</div>
</div>
</div>








    </section>
    <section class="contect-form-section">
        <div class="container">
            <div class="row contect-form-firts-row">
                <div class="col-md-6">
                    <div class="contect-form">



                    <form method="POST" id="contactusForm" role="form" onSubmit={handleSubmit} noValidate>
      <div className="row">
        <div className="col-sm-6 col-12">
          <div className="form-group">
            <label className="label-name">Name</label><span className="text-danger">*</span>
            <input
              type="text"
              id="name"
              name="name"
              className="form-control txtOnly"
              placeholder="Enter your name"
              value={formData.name}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-sm-6 col-12">
          <div className="form-group">
            <label className="label-name">Email</label><span className="text-danger">*</span>
            <input
              type="email"
              id="product_name"
              name="product_name"
              className="form-control"
              placeholder="Enter your mail"
              value={formData.product_name}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6 col-12">
          <div className="form-group">
            <label className="label-name">Mobile Number</label><span className="text-danger">*</span>
            <input
              type="tel"
              id="mobile_number"
              name="mobile_number"
              maxLength="10"
              placeholder="Enter your mobile number"
              className="form-control"
              value={formData.mobile_number}
              onChange={handleChange}
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, '');
              }}
            />
          </div>
        </div>
        <div className="col-sm-6 col-12">
          <div className="form-group">
            <label className="label-name">Subject</label><span className="text-danger">*</span>
            <input
              type="text"
              id="time_slot"
              name="time_slot"
              className="form-control"
              placeholder="Enter your subject"
              value={formData.time_slot}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <label className="label-name">Message</label><span className="text-danger">*</span>
            <textarea
              name="message"
              id="message"
              cols="30"
              rows="4"
              placeholder="Type your message here..."
              value={formData.message}
              onChange={handleChange}
            ></textarea>
          </div>
        </div>
      </div>

          <div className="captchaSection">
        <div id="captcha" className="genarateCaptcha">{generatedCaptcha}</div>
        <button onClick={generateCaptcha} style={{border:"none"}}><FaArrowRotateLeft /></button>
      </div>

        <input
          type="text"
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
          placeholder="Enter CAPTCHA"
          className='captchainput'
        />
       <p>{resultMessage}</p>
 
      <div className="blue-btn form-button contactusFormSubmit">
        <button type="submit" className="btn form-submit-button">Submit</button>
      </div>
    </form>
                    </div>
                </div>
                <div class="col-md-6 still-content">
                    <h2>Still have questions?</h2>
                    <h4><span class="SiteBlue">Beema</span><span class="SiteRed">1</span> is just a call away.</h4>
                    <p className='textformat'>
                We are here to assist you with any questions or concerns you may have. Whether you need more information about our services, require assistance with a specific query, or just want to provide feedback, our team is ready to help.
                </p>
                </div>
            </div>
        </div>
    </section>
    <section class="link-addres-section">
        <div class="container">
            <div class="row linking-row">
                                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="link-box calling">
                        <div class="socil-icon"><FastImage
            url={"Website%20Img/22.png?updatedAt=1718793911021"}
            width={auto}
            height={auto}
            alt={"ca-img"}
            />
            </div>
                        <a href="tel:+91 3323593438">+91 3323593438</a>
                    </div>
                </div>  
                                
                                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="link-box gmail">
                        <div class="socil-icon">
                        <FastImage
            url={"Website%20Img/33.png?updatedAt=1718793910975"}
            width={auto}
            height={auto}
            alt={"ca-img"}
            />
                          </div>
                        <a href="mailto:communication@beema1.com">communication@beema1.com</a>
                    </div>
                </div>  
                                
                                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="link-box address">
                        <div class="socil-icon">
                          
                        <FastImage
            url={"Website%20Img/44.png?updatedAt=1718793910906"}
            width={auto}
            height={auto}
            alt={"ca-img"}
            />
                          
                          </div>
                        <p> <a href="http://maps.google.com/?q=Andromeda Building 2nd Cross Rd, DD-30 Block, Sector 1, Bidhannagar, Kolkata, West Bengal 700064" target="_blank">Andromeda Building 2nd Cross Rd, DD-30 Block, Sector 1, Bidhannagar, Kolkata, West Bengal 700064</a></p>
                        
                    </div>
                </div>   
                                
            </div>
        </div>
    </section>
    <section class="contect-us-faq">
      

    </section>
    
    </div>
  )
}

export default ContractUs