import React, { useEffect, useState } from 'react';
import '../CompairPolicy/Compairpolicy.css';
import { useSelector } from 'react-redux';
import { useParams,useNavigate } from 'react-router-dom';
import axios from 'axios';
import { RxCross2 } from "react-icons/rx";
import { useDispatch } from 'react-redux';
import { selectUserData, updatedLoginPopup } from '../../redux/Slice';
import { formatToINR } from '../../../HomePage/MoneyHealper/Moneyhelper';


const CompairPolicyPage = () => {


  // const { policyData } = useParams();

  const currentUrl = window.location.href;
  
  // Create a URL object to easily access query parameters
  const url = new URL(currentUrl);
  let policyData = url.searchParams.get('plane_id');

  console.log('Received plane_id:', policyData);

  const [ComepolicyData, setComepolicyData] = useState([]);

 const dispatch = useDispatch()

 useEffect(() => {
  axios
    .post(`${process.env.REACT_APP_BASE_URL}health/compare`, {
      planCodes: policyData
    })
    .then((res) => {
      const receivedData = res.data.data;
      const planCodesArray = policyData.split(','); // Split the planCodes into an array
      
      // Create a map from plan_code to data for quick lookup
      const dataMap = receivedData.reduce((map, item) => {
        map[item.plan_code] = item;
        return map;
      }, {});

      // Map over the planCodesArray to create an ordered list of data or cross
      const reorderedData = planCodesArray.map(code => 
        dataMap[code] || { plan_code: code, isMissing: true } // Mark as missing if data is undefined
      );

      setComepolicyData(reorderedData);
    })
    .catch((err) => {
      console.warn(err);
    });

  window.scrollTo(0, 0);
}, [policyData]);




  const { RecevedpolicyCode ,  unic_id} = useSelector((state) => {
  return state.FetchPlan;
});



  console.log("Compaire Data",ComepolicyData)

const navigate = useNavigate();

const loginData = useSelector(selectUserData);


const handlePlanClick = (plan) => {
  if (loginData.data == false) {
    dispatch(updatedLoginPopup(true));
    return;
  }

  const planCode = plan.planCode;
  const insurerName = plan.insurerName;
  const url = `/afterplanfatch?req_id=${unic_id}&c_id=${insurerName}&plan_id=${planCode}`;
  navigate(url);
};






  return (
    <>
<div>
{/* <Redirect/> */}
{/* <section className="mainnargin-insurance-policypage"></section> */}
<div className="mainpolicy">

    <div className="poilicyheader">
    <div className="lebealhead">
      <div>


    <h4>Comparison summary</h4>
 
    </div>
    </div>





    {RecevedpolicyCode.map((data, index) => {
      const Primuim = data.premiumBreakup;
      return (
        <div className="policycart" key={index}>
          <div className="policyimage">
            <img src={data.insurerLogo} alt="" />
          </div>
          <div className="policyname">
            <span>{data.planName}</span>
            <br />
            <p className='plandes'>{data.planDescription}</p>
          </div>
          <div className="policyamount">
            <div className="blockamount">
              <span className="smallpolicyamount">Cover</span>
              <br />
              <span className="boldpolicyamount">
  {parseFloat(data.sumInsured) >= 10000000
    ? Math.floor(parseFloat(data.sumInsured) / 10000000) + ' Cr'
    : parseFloat(data.sumInsured) >= 100000
    ? Math.floor(parseFloat(data.sumInsured) / 100000) + ' L'
    : parseFloat(data.sumInsured).toLocaleString("en-IN", {
        style: "currency",
        currency: "INR",
      })}
</span>
            </div>
            <div className="blockamount">
              <span className="smallpolicyamount">Premium</span>
              <br />
              <span className="boldpolicyamount">{formatToINR(Primuim.totalPremium)}/-</span>
            </div>
          </div>
          <div className="buttondiv">
  
           <button onClick={()=>{handlePlanClick(data)}}>Buy now</button>
          </div>
        </div>
      );
    })}

  </div>
  </div>





  <div className="comapirChart">

  <p className='mobiltag'>Claims paid within 3 months</p>
<div className="chartstart">
<div className="LebalChart">
<p>Claims paid within 3 months</p>
</div>
{
  ComepolicyData.map((data,inbdex)=>{
    return(
       <div className="compair" key={inbdex}>
      <p>{data.claim_settlement_ratio && data.claim_settlement_ratio !== 'null' ? data.claim_settlement_ratio :  <RxCross2 color='red'/>}</p> 
      </div>
      )
    })
  }
<div className="bottomchart" hidden={policyData.length > 2}>

</div>
</div>
<p className='mobiltag'>Room rent limit</p>
{/* Two Start Hear */}
<div className="chartstart">
<div className="LebalChart">
<p >Room rent limit</p>
</div>
{
  ComepolicyData.map((data, index) => (
    <div className="compair" key={index}>
      <p>
        {data.room_rent_limit && data.room_rent_limit !== 'null' ? data.room_rent_limit : <RxCross2 color='red' />}
      </p> 
    </div>
  ))
}

<div className="bottomchart" hidden={policyData.length > 2}>

</div>
</div>
{/* Three Start Hear */}
<p className='mobiltag'>Restoration of cover</p>
<div className="chartstart">
<div className="LebalChart">
<p>Restoration of cover</p>
</div>
{
  ComepolicyData.map((data,inbdex)=>{
    return(
       <div className="compair" key={inbdex}>
      <p>{data.restoration_of_cover && data.restoration_of_cover !== 'null' ? data.restoration_of_cover : <RxCross2 color='red'/>}</p> 
      </div>
      )
    })
  }
<div className="bottomchart" hidden={policyData.length > 2}>

</div>
</div>
{/* Four Start Hear */}
<p className='mobiltag'>Cashless hospitals</p>
<div className="chartstart">
<div className="LebalChart">
<p>Cashless hospitals</p>
</div>
{
  ComepolicyData.map((data,inbdex)=>{
    return(
       <div className="compair" key={inbdex}>
      <p>{data.
        cashless_hospitals && data.cashless_hospitals !== 'null' ? data.
        cashless_hospitals : <RxCross2 color='red'/>}</p> 
      </div>
      )
    })
  }
<div className="bottomchart" hidden={policyData.length > 2}>

</div>
</div>
{/* Five Start Hear */}
<p className='mobiltag'>Co-Pay</p>
<div className="chartstart">
<div className="LebalChart">
<p>Co-Pay</p>
</div>
{
  ComepolicyData.map((data,inbdex)=>{
    return(
       <div className="compair" key={inbdex}>
      <p>{data.
        co_pay && data.co_pay !== 'null' ? data.
        co_pay : <RxCross2 color='red'/>}</p> 
      </div>
      )
    })
  }
<div className="bottomchart" hidden={policyData.length > 2}>

</div>
</div>
{/* Six Start Hear */}
<p className='mobiltag'>Pre-hospitalization coverage</p>
<div className="chartstart">
<div className="LebalChart">
<p>Pre-hospitalization coverage</p>
</div>
{
  ComepolicyData.map((data,inbdex)=>{
    return(
       <div className="compair" key={inbdex}>
      <p>{data.pre_hospitalization_coverage && data.pre_hospitalization_coverage !== 'null' ? data.pre_hospitalization_coverage : <RxCross2 color='red'/>}</p> 
      </div>
      )
    })
  }
<div className="bottomchart" hidden={policyData.length > 2}>

</div>
</div>
{/* Seven Start Hear */}
<p className='mobiltag'>Post-hospitalization coverage</p>
<div className="chartstart">
<div className="LebalChart">
<p>Post-hospitalization coverage</p>
</div>
{
  ComepolicyData.map((data,inbdex)=>{
    return(
       <div className="compair" key={inbdex}>
      <p>{data.post_hospitalization_coverage && data.post_hospitalization_coverage !== 'null' ? data.post_hospitalization_coverage :
         <RxCross2 color='red'/>}</p> 
      </div>
      )
    })
  }
<div className="bottomchart" hidden={policyData.length > 2}>

</div>
</div>
{/* Eight Start Hear */}
<p className='mobiltag'>Day care treatment</p>
<div className="chartstart">
<div className="LebalChart">
<p>Day care treatment</p>
</div>
{
  ComepolicyData.map((data,inbdex)=>{
    return(
       <div className="compair" key={inbdex}>
      <p>{data.day_care_treatment && data.day_care_treatment !== 'null'
        ? data.day_care_treatment : <RxCross2 color='red'/>}</p> 
      </div>
      )
    })
  }
<div className="bottomchart" hidden={policyData.length > 2}>

</div>
</div>
{/* Nine Start Hear */}
<p className='mobiltag'>Hospitalization at home</p>
<div className="chartstart">
<div className="LebalChart">
<p>Hospitalization at home</p>
</div>
{
  ComepolicyData.map((data,inbdex)=>{
    return(
       <div className="compair" key={inbdex}>
      <p>{data.
        hospitalization_at_home && data.hospitalization_at_home !== 'null'
        ? data.
        hospitalization_at_home : <RxCross2 color='red'/>}</p> 
      </div>
      )
    })
  }
<div className="bottomchart" hidden={policyData.length > 2}>

</div>
</div>
{/* ten Start Hear */}
<p className='mobiltag'>Ambulance charges</p>
<div className="chartstart">
<div className="LebalChart">
<p>Ambulance charges</p>
</div>
{
  ComepolicyData.map((data,inbdex)=>{
    return(
       <div className="compair" key={inbdex}>
      <p>{data.ambulance_charges && data.ambulance_charges !== 'null'
        ? data.ambulance_charges : <RxCross2 color='red'/>}</p> 
      </div>
      )
    })
  }
<div className="bottomchart" hidden={policyData.length > 2}>

</div>
</div>
{/*Eleven start Hear */}
<p className='mobiltag'>Existing Illness cover</p>
<div className="chartstart">
<div className="LebalChart">
<p>Existing Illness cover</p>
</div>
{
  ComepolicyData.map((data,inbdex)=>{
    return(
       <div className="compair" key={inbdex}>
      <p>{data.
existing_illness_cover && data.existing_illness_cover !== 'null'

        ? data.
        existing_illness_cover
         : <RxCross2 color='red'/>}</p> 
      </div>
      )
    })
  }
<div className="bottomchart" hidden={policyData.length > 2}>

</div>
</div>
{/* twelve */}
<p className='mobiltag'>Maternity cover</p>
<div className="chartstart">
<div className="LebalChart">
<p>Maternity cover</p>
</div>
{
  ComepolicyData.map((data,inbdex)=>{
    return(
       <div className="compair" key={inbdex}>
      <p>{data.maternity_cover && data.maternity_cover !== 'null' ? data.maternity_cover
:

         <RxCross2 color='red'/>}</p> 
      </div>
      )
    })
  }
<div className="bottomchart" hidden={policyData.length > 2}>

</div>
</div>
{/* Therteen */}
<p className='mobiltag'>Initial Waiting Period</p>
<div className="chartstart">
<div className="LebalChart">
<p>Initial Waiting Period</p>
</div>
{
  ComepolicyData.map((data,inbdex)=>{
    return(
       <div className="compair" key={inbdex}>
      <p>{data.initial_waiting_period && data.initial_waiting_period !== 'null'


        ? data.initial_waiting_period : <RxCross2 color='red'/>}</p> 
      </div>
      )
    })
  }
<div className="bottomchart" hidden={policyData.length > 2}>

</div>
</div>
{/* Fourteen */}
<p className='mobiltag'>Specific Illness cover</p>
<div className="chartstart">
<div className="LebalChart">
<p>Specific Illness cover</p>
</div>
{
  ComepolicyData.map((data,inbdex)=>{
    return(
       <div className="compair" key={inbdex}>
      <p>{data.specific_illness_cover && data.specific_illness_cover !== 'null'


        ? data.specific_illness_cover : <RxCross2 color='red'/>}</p> 
      </div>
      )
    })
  }
<div className="bottomchart" hidden={policyData.length > 2}>

</div>
</div>
{/* Fifteen */}
<p className='mobiltag'>Alternate medicine (AYUSH)</p>
<div className="chartstart">
<div className="LebalChart">
<p>Alternate medicine (AYUSH)</p>
</div>
{
  ComepolicyData.map((data,inbdex)=>{
    return(
       <div className="compair" key={inbdex}>
      <p>{data.
        alternate_medicine && data.alternate_medicine !== 'null'
        


        ? data.
        alternate_medicine : <RxCross2 color='red'/>}</p> 
      </div>
      )
    })
  }
<div className="bottomchart" hidden={policyData.length > 2}>

</div>
</div>
{/* Sixteen */}
<p className='mobiltag'>Worldwide coverage</p>
<div className="chartstart">
<div className="LebalChart">
<p>Worldwide coverage</p>
</div>
{
  ComepolicyData.map((data,inbdex)=>{
    return(
       <div className="compair" key={inbdex}>
      <p>{data.
        worldwide_coverage && data.worldwide_coverage !== 'null' ? data.
        worldwide_coverage :
        


       <RxCross2 color='red'/>}</p> 
      </div>
      )
    })
  }
<div className="bottomchart" hidden={policyData.length > 2}>

</div>
</div>
{/* seveenteen */}
<p className='mobiltag'>Domestic evacuation</p>
<div className="chartstart">
<div className="LebalChart">
<p>Domestic evacuation</p>
</div>
{
  ComepolicyData.map((data,inbdex)=>{
    return(
       <div className="compair" key={inbdex}>
      <p>{data.
        domestic_evacuation && data.domestic_evacuation !== 'null' ? data.
        domestic_evacuation :
        


      <RxCross2 color='red'/>}</p> 
      </div>
      )
    })
  }
<div className="bottomchart" hidden={policyData.length > 2}>

</div>
</div>
{/* eighteen  */}
<p className='mobiltag'>Cover for organ donor</p>
<div className="chartstart">
<div className="LebalChart">
<p>Cover for organ donor</p>
</div>
{
  ComepolicyData.map((data,inbdex)=>{
    return(
       <div className="compair" key={inbdex}>
      <p>{data.
        cover_for_organ_donor && data.cover_for_organ_donor !== 'null' ? data.
        cover_for_organ_donor :

        


         <RxCross2 color='red'/>}</p> 
      </div>
      )
    })
  }
<div className="bottomchart" hidden={policyData.length > 2}>

</div>
</div>
{/* nineteen  */}
<p className='mobiltag'>Animal bite vaccination</p>
<div className="chartstart">
<div className="LebalChart">
<p>Animal bite vaccination</p>
</div>
{
  ComepolicyData.map((data, index) => {
    return (
      <div className="compair" key={index}>
        <p>
          {data.animal_bite_vaccination && data.animal_bite_vaccination !== "null" 
            ? data.animal_bite_vaccination 
            : <RxCross2 color='red'/>}
        </p>
      </div>
    );
  })
}

<div className="bottomchart" hidden={policyData.length > 2}>

</div>
</div>

{/* Twenty  */}
<p className='mobiltag'>Mid year member addition</p>
<div className="chartstart">
<div className="LebalChart">
<p>Mid year member addition</p>
</div>
{
  ComepolicyData.map((data,inbdex)=>{
    return(
       <div className="compair" key={inbdex}>
      <p>{data.animal_bite_vaccination && data.animal_bite_vaccination !== 'null' ? data.animal_bite_vaccination : <RxCross2 color='red'/>}</p> 
      </div>
      )
    })
  }
<div className="bottomchart" hidden={policyData.length > 2}>

</div>
</div>

{/* twentyone  */}
<p className='mobiltag'>E-consultation</p>
<div className="chartstart">
<div className="LebalChart">
<p>E-consultation</p>
</div>
{
  ComepolicyData.map((data,inbdex)=>{
    return(
       <div className="compair" key={inbdex}>
      <p>{data.e_consultation && data.e_consultation !== 'null' ? data.e_consultation : <RxCross2 color='red'/>}</p> 
      </div>
      )
    })
  }
<div className="bottomchart" hidden={policyData.length > 2}>

</div>
</div>

{/* twentytwo  */}
<p className='mobiltag'>Discount on Renewal</p>
<div className="chartstart">
<div className="LebalChart">
<p>Discount on Renewal</p>
</div>
{
  ComepolicyData.map((data,inbdex)=>{
    return(
       <div className="compair" key={inbdex}>
      <p>{data.discount_on_renewal && data.discount_on_renewal !== 'null' ? data.discount_on_renewal : <RxCross2 color='red'/>}</p> 
      </div>
      )
    })
  }
<div className="bottomchart" hidden={policyData.length > 2}>

</div>
</div>

{/* twentythree  */}
<p className='mobiltag'>Daily cash allowance</p>
<div className="chartstart">
<div className="LebalChart">
<p>Daily cash allowance</p>
</div>
{
  ComepolicyData.map((data,inbdex)=>{
    return(
       <div className="compair" key={inbdex}>
      <p>{data.daily_cash_allowance && data.daily_cash_allowance !== 'null'
        ? data.daily_cash_allowance : <RxCross2 color='red'/>}</p> 
      </div>
      )
    })
  }
<div className="bottomchart" hidden={policyData.length > 2}>

</div>
</div>
{/* Twenty Four */}

  </div>
  </div>
    </>
  );
};

export default CompairPolicyPage;


