import React, { useState } from 'react';
import { TextField, Box, Typography, Grid } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import './AnswerSetSeven.css'; // Importing the CSS file
import { useDispatch } from "react-redux";
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

import { updateRelianceQAlist , selectReliancecheckedQuestion } from "../../redux/Slice";

const AnswerSetSeven = ({ pindex, qdata, parentqData }) => {
  const [sufferingSince, setSufferingSince] = useState(new Date());
  const [consultationDate, setConsultationDate] = useState(new Date());

  const [textData , setTextDta] = useState();


  const dispatch = useDispatch();

  const handleClick = (event) => {
    event.stopPropagation();
  };



  const [dataObj, setDataObj] = useState();

  const ReliancecheckedQuestion = useSelector(selectReliancecheckedQuestion);

  console.log("the answer set one data",ReliancecheckedQuestion)

  console.log("the pindex", pindex);

  console.log("the q data in set one", qdata)




  useEffect(() => {
    if (ReliancecheckedQuestion) {

      console.log("hdfc checked question",ReliancecheckedQuestion )
      const keys = Object.keys(ReliancecheckedQuestion);
      const numbersToCheck = [8];
      const anyNumberPresent = numbersToCheck.some((number) =>
        keys.includes(number.toString())
      );

      const resultObjects = numbersToCheck
        .filter((number) => keys.includes(number.toString()))
        .map((number) => ReliancecheckedQuestion[number.toString()]);


      console.log("the resultObjects", resultObjects) 
      let val;
      
 
      for (let key in resultObjects) {
        if (resultObjects[key].hasOwnProperty(pindex))
        {
          let data_one = resultObjects[key][pindex];
          // console.log("data_one",qdata.question_id)
          let val = data_one.find(item => item.Options[0].OptionId == qdata.QuestionSetCode );
          if (val != undefined)
          {
             console.log("val in for",val)
             let newobject = val;
             console.log("the newObject", newobject);
     
             if (newobject) {
     
               newobject.Options.map((item , index)=>{
                 if(item.OptionId == qdata.QuestionSetCode){
                  
                   
                     setDataObj(item);
                     return
                   }
               })
             }
          }
        }
     }

   
      
    }
  }, [ReliancecheckedQuestion]);

  console.log("the final data in set seven", dataObj);

  const convertMMDate = (dateString) =>{
    const date = new Date(dateString);
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getUTCFullYear();
  
    return `${month}/${year}`;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box className='set-seven-body'>
        {/* <Typography className='set-seven-body-title'>
          Q. Hypertension/ High blood pressure
        </Typography>
         */}
        <Grid container spacing={2} className='lower-set-seven-answers' style={{paddingLeft:"0px"}}>
        <Grid item xs={12}>
            <TextField
              label="Description"
              type="text"
              fullWidth

              value={textData ? textData :dataObj?.HasFiledClaimWithCurrPreviousInsured || ""}
              variant="standard"
              className='underline-textField'
              onClick={(e) => {
                handleClick(e);
              }}
              onChange={(e) => {
                setTextDta(e.target.value);
            dispatch(
                  updateRelianceQAlist({
                    name: "HasFiledClaimWithCurrPreviousInsured",
                    value: e.target.value,
                    pindex: pindex,
                    qdata: qdata,
                    parentqData: parentqData,
                  })
                )}}
            />
          </Grid>
   
        </Grid>
      </Box>
    </LocalizationProvider>
  );
};

export default AnswerSetSeven;
