import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { useState, useEffect } from "react";
import Loader from "../AllPageComponents/Loader";
import { FaArrowRotateLeft } from "react-icons/fa6";

const BikeInsuranceForm = () => {

  const FullName = localStorage.getItem('ProposalName')
  const PhoneNumber = localStorage.getItem('Proposalphonenumber')
  const PinCode = localStorage.getItem('PinCode')


const [ValidNumber, setValidNumber] = useState('')

useEffect(()=>{


if(PhoneNumber){
  setValidNumber(PhoneNumber)
}
},[PhoneNumber])


  const [formData, setFormData] = useState({
    bike_number: "",
    phone_no: "" || ValidNumber,
    pin: "" || PinCode,
    car_type: "Twowheeler",
    name: "" || FullName,
  });

  const [bikeNumError , setBikeNumError] = useState('');
  const [phoneNumError , setPhoneNumError] = useState('');
  const [pinError , setPinError] = useState('');
  const [nameError, setNameError] = useState("");

  const [isLoading , setIsLoading] = useState(false);

// all form validations start here ----------------




const [generatedCaptcha, setGeneratedCaptcha] = useState('');
  const [userInput, setUserInput] = useState('');
  const [resultMessage, setResultMessage] = useState('');

  // Function to generate a random alphanumeric string
  const generateRandomString = (length) => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
  };

  // Function to generate the CAPTCHA
  const generateCaptcha = () => {
    const captchaText = generateRandomString(6); // You can adjust the length as needed
    setGeneratedCaptcha(captchaText);
    setResultMessage('');
  };


  useEffect(()=>{
    generateCaptcha();
  },[])








  const isValidIndianPhoneNumber = (phoneNumber) => {
    const indianPhoneNumberRegex = /^[6-9]\d{9}$/;
    return indianPhoneNumberRegex.test(phoneNumber);
  };


const validateFullName =(event)=>{
  let isValid = true;

  if (event.target.value === "") {
    setNameError("Full name is required");
    isValid = false;
  } else {
    setNameError("");
  }
}

const validateBikeNumber = (event)=>{
  let isValid = true;

  if (event.target.value === "") {
    setBikeNumError("Bike number is required");
    isValid = false;
  } else {
    setBikeNumError("");
  }
}

const validatePhoneNumbger = (event)=>{
  let isValid = true;
  if (event.target.value === "") {
    setPhoneNumError("Phone number is required");
    isValid = false;
  } else if (!isValidIndianPhoneNumber(event.target.value)) {
    setPhoneNumError("Please enter a valid Indian phone number");
    isValid = false;
  } else if (event.target.value.length !== 10) {
    setPhoneNumError("Phone number must be 10 digits");
    isValid = false;
  }
   else {
    setPhoneNumError("");
  }
}

const validatePinCode = (event) =>{
  let isValid = true;

  if (event.target.value === "") {
    setPinError("Pin code is required");
    isValid = false;
  } else {
    setPinError("");
  }
}

// form validations ends here ---------------------




  const handleSubmit = async (event) => {
    event.preventDefault();

    let isValid = true;

    if (userInput !== generatedCaptcha) {
      setUserInput('');
      Swal.fire({
       title: 'Error!',
       text: 'Wrong Captcha !', // Changed punctuation for better readability
       icon: 'error',
       confirmButtonText: 'Back'
     });
      setTimeout(() => {
       generateCaptcha();
      }, 1500);

      return
    } 
      
  
    

    if (formData.name === "") {
      setNameError("Full name is required");
      isValid = false;
    } else {
      setNameError("");
    }

    if (formData.bike_number === "") {
      setBikeNumError("Bike number is required");
      isValid = false;
    } else {
      setBikeNumError("");
    }

    if (formData.phone_no === "") {
      setPhoneNumError("Phone number is required");
      isValid = false;
    } else if (!isValidIndianPhoneNumber(formData.phone_no)) {
      setPhoneNumError("Please enter a valid Indian phone number");
      isValid = false;
    } else if (formData.phone_no.length !== 10) {
      setPhoneNumError("Phone number must be 10 digits");
      isValid = false;
    }
     else {
      setPhoneNumError("");
    }

    if (formData.pin === "") {
      setPinError("Pin code is required");
      isValid = false;
    } else {
      setPinError("");
    }
    if (
      !(formData.phone_no === "" ||
        formData.pin === "" ||
        formData.bike_number === "" ||
        formData.name === "")
    ) {
      try {
        setIsLoading(true);
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}cms/car_enquiry`,
          formData
        );

        //console.log("Vehicle Response", response);
        if (response.status === 200) {
          setIsLoading(false);
          Swal.fire({
            title: "Sucess",
            text: "Your Form is Sucessfully Submitted",
            icon: "success",
            confirmButtonText: "Back",
          });
          generateCaptcha();

          setFormData({
            bike_number: "",
            phone_no: "",
            pin: "",
            car_type: "Twowheeler",
            name: "",
          });
          setUserInput('');
        } else {
          Swal.fire({
            title: "Error!",
            text: "Somthing Whent Wrong",
            icon: "error",
            confirmButtonText: "Back",
          });
        }

      } catch (error) {
        console.error("Error:", error);
        Swal.fire({
          title: "Error!",
          text: "Somthing Whent Wrong",
          icon: "error",
          confirmButtonText: "Back",
        });
        return;
      }
    }
  
};

const handleChange = (event) => {
  const { name, value } = event.target;
  setFormData({
    ...formData,
    [name]: value,
  });

};









return (
  <>
  {isLoading && <Loader/>}
    <div className="col-sm-12 col-md-12 col-lg-4">
      <div className="form-main">
        <form id="insurance_form_get_plan">
          <div className="d-flex align-items-center form-input">
            <h6>
              Secure Your Bike &amp; Save upto 75%* on Plans offered by
              Insurers
            </h6>

            <form onSubmit={handleSubmit} className="carformsubmited">
              <div className="form-inner-input form-group">
               
                <input
                  type="text"
                  name="bike_number"
                  value={formData.bike_number}
                  onChange={(event)=>{handleChange(event);validateBikeNumber(event)}}
                  className="form-control"
                  placeholder="Ex. GJ 01 AB 3263"
                  required
                />

               <span className="error" hidden={!bikeNumError}>{bikeNumError}</span>

                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={(event)=>{handleChange(event);validateFullName(event)}}
                  className="form-control"
                  placeholder="FULL NAME"
              
                  required
                />
                 <span className="error" hidden={!nameError}>{nameError}</span>
        
                <input
                  type="text"
                  name="phone_no"
                  value={formData.phone_no}
                  onChange={(event)=>{handleChange(event);validatePhoneNumbger(event)}}
                  className="form-control"
                  placeholder="Mobile Number"
               
                  required
                />
              <span className="error" hidden={!phoneNumError}>{phoneNumError}</span>
      
                <input
                  type="text"
                  name="pin"
                  value={formData.pin}
                  onChange={(event)=>{handleChange(event);validatePinCode(event)}}
                  className="form-control"
                  placeholder="Zip/Pin Code"
                  required
                />
           <span className="error" hidden={!pinError}>{pinError}</span>

              </div>
              
<br />

      <div className="containerdivcaptcha">
      <div className="captchaSection">
        <div id="captcha" className="genarateCaptcha">{generatedCaptcha}</div>
        <button onClick={generateCaptcha}><FaArrowRotateLeft /></button>
      </div>



      <div id="inputSection_captcha">
        <input
          type="text"
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
          placeholder="Enter CAPTCHA"
          className="Captachcode"
        />

        <p id="result">{resultMessage}</p>
      </div>
    </div>





















              <div className="blue-btn">
                <button onClick={handleSubmit} className="form-btn">
                  Get Quotation
                </button>
              </div>
            </form>
            {/* <p style={{fontFamily:'Poppins',fontSize:'10pt'}}>
              Don't know your bike number?{" "}
              <a style={{color:'#0166b3'}}href="javascript:void(0)">Click Here</a>
            </p> */}
            <p className="insurance-text-light">
              By clicking, I agree to{" "}
              <Link to={"/termsandcondition"}>*terms &amp; conditions</Link>{" "}
              and <Link to={"/privacy-policy"}>privacy policy</Link>.
            </p>
          </div>
        </form>
      </div>
    </div>
  </>
);
};

export default BikeInsuranceForm;
