import React,{useEffect} from 'react';
import './Termsandcondition.css';
import { Helmet } from 'react-helmet-async';

const Termsandcondition = () => {


  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div>
         <Helmet>
  <title>Terms & Conditions</title>
  <meta name="description" content="Read the terms and conditions for using our services and website." />
  <meta name="keywords" content="terms and conditions, website terms, service terms, legal agreement, user terms" />
</Helmet>

    {/*Stection start */}
    <div className="margintermsnadpolicy"></div>
      <section className="heades">
      <div className='underheader'>
      <h1>Terms <span className='middleone'>&</span> <span className='lastone'>Condition*</span></h1>
      <p>Guide2 Protect insurence Web Aggregator PVT.LTD</p>
      </div>
      </section>
    {/*Stection End */}

<div className="termsandcondition">
<div className="insuredetail">
<p className="normaltext"><span style={{color:'#0166B3'}}>Beema</span><span style={{color:"red"}}>1</span>.com (Website) is owned and operated by <span style={{color:"#0166B3"}}>Guide2Protect Insurance Web Aggregator Private Limited </span>(hereinafter referred to as Company), a company incorporated under the provisions of the Companies Act, 2013, and bearing IRDAI Web Aggregator License Code No. IRDAI/INT/WBA/79/2022, License No. 025, valid till 27/11/2025, and having its registered office at No – DD 30, 2nd Cross Road Andromeda Building 5th Floor, Saltlake, Sector 1 Kolkata 700064. This agreement is an electronic record in terms of the Information Technology Act, of 2000, and does not require any physical or digital signatures. Before you may use the Website, you must read all of the terms and conditions (Terms) herein and the Privacy Policy provided on the Website. By using Beema1.com Health Insurance, Motor Insurance, Term Insurance, and other Life and Non- life Insurance products as Permitted by the Insurance Regulatory Development Authority of India, software, services, and website, you understand and agree that the Website will treat your use of the Services as acceptance of these terms from such point of usage. You may not use the Services if you do not accept these Terms. If you do not agree to be bound by these Terms and the Privacy Policy. The Company or Website reserves the right at any time, at its sole discretion, to change or otherwise modify the Terms without prior notice, and your continued access or use of this Website signifies your acceptance of the updated or modified Terms.</p>
</div>


<div className="insuredetail">
<h3 className='insureh3'>Services Description and Warranty</h3>
<p className="normaltext">The Website contains information regarding the terms of insurance policies, including details of the benefits available and items not covered by such policies. Some of these statements are generalized in an attempt to give you a summary of the insurance policies available through Beema1.com. However, not all insurance policies are the same and you should confirm the exact terms of any insurance policy that you intend to purchase before purchasing that insurance policy.

The Company is an aggregator of insurance products/services owned by reputed insurance companies established in India and the Company is merely facilitating you through comparing tools to help you compare and evaluate your requirements with different products offered by the insurer company. In no event, Company is responsible for the choice of insurance you decide to procure, and such decision shall be purely based on your own prudent decision without any influence of the Company. You shall be responsible for your own decision and therefore, you need to take a prudent call while comparing your requirements on the Website. You shall in any doubt about the products offering free to consult the insurer through their given communication facility and take your independent call. Therefore, Company does not represent, warrants or guarantees anything regardless of the insurance products you may choose and does not induces you to enter into any contractual relationship with any insurer or with the Company itself.</p>

<p className="normaltext">The Company or the Website does not represent nor warrants any content provided in the Website which is owned by any third party. You acknowledge that the content on the Website is informative in nature and does not stand as any commercial offer for any products or services to you.</p>



<p className="normaltext">The beema1.Com by guide2protect insurance web aggregator private limited website, including all content, software, functions, materials, and information made available on or accessed through the website, is provided "as is." to the fullest extent permissible by law, the website by the company and its subsidiaries and affiliates make no representation or warranties of any kind whatsoever for the content on the website or the materials, information, and functions made accessible by the software used on or accessed through the web site, for any products or services or hypertext links to third parties for any breach of security associated with the transmission of sensitive information through website or any linked site. </p>

<p className="normaltext">Further, the company and website and its subsidiaries and affiliates disclaim any express or implied warranties, including, without limitation, non-infringement, merchantability, or fitness for a particular purpose. </p>


<p className="normaltext">Does not warrant that the functions contained in the website or any materials or content contained therein will be uninterrupted or error-free, that defects will be corrected, or that the website or the server that makes it available is free of viruses or other harmful components.  The company and its subsidiaries and affiliates shall not be liable for the use of the website, including, without limitation, the content and any errors contained therein.</p>

<p className="normaltext">Company and the Website reserves the right, at its sole discretion, to change, modify, add, or remove any portion of this Agreement, in whole or in part, at any time. Notification of changes in the Agreement will be posted on the Beema1.com Website and your continued use of the Website after such notice of changes signifies your acceptance of the changes.</p>
</div>
<div className="insuredetail">
<h3 className='insureh3'>Inscription: - </h3>

<p className="normaltext">The content of the Website is only for your personal, non-commercial use. All materials contained by the Website are protected by copyright and other intellectual property laws and are owned or controlled by the Company or the party credited as the provider of the Content. You will abide by any additional copyright notices, information, or restrictions contained in any content on the Website. You may download and make copies of the content and other downloadable items displayed on this Website for personal, non-commercial use only, provided that you maintain all copyright and other notices contained in such Content. Copying or storing of any Content for other than personal, non-commercial use is expressly prohibited without prior written permission from the Company or the copyright holder identified in the individual Content’s copyright notice.</p>
</div>

<div className="insuredetail">
<h3 className='insureh3'>Permission and Site Access: -</h3>

<p className="normaltext">Visitors shall not upload, post, transmit distribute, or otherwise publish Website any materials which restrict or inhibit any other user from using and enjoying the Website, which is unlawful, threatening, abusive, libelous, defamatory, obscene, vulgar, offensive, pornographic, profane, sexually explicit or indecent, constitute or encourage conduct that would constitute a criminal offense, give rise to civil liability or otherwise violate the law, violate, plagiarize or infringe the rights of third parties including, without limitation, copyright, trademark, patent, rights of privacy or publicity or any other proprietary right, contain a virus or other harmful component, contain any information, software or other material of a commercial nature, contain advertising of any kind, or constitute or contain false or misleading indications of origin or statements of fact.

</p>

</div>

<div className="insuredetail">
<h3 className='insureh3'>Indemnity: -</h3>
 

<p className="normaltext">You hereby agree to indemnify, defend and hold Company and its director, owners, agents, employees, information providers, affiliates, licensors, and licensees (collectively, the "Indemnified Parties") harmless from and against any liability and costs incurred by the Indemnified Parties in connection with any claim arising out of any breach of the Agreement or the foregoing representations, warranties and covenants, without limitation, attorneys' fees and costs. You shall cooperate as fully as reasonably required in the defence of any claim. Beema1.com by Guide2Protect Insurance Web Aggregator Private Limited reserves the right, at its own expense, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you and you shall not, in any event, settle any matter without the written consent of Beema1.com by Guide2Protect Insurance Web Aggregator Private Limited</p>
</div>

<div className="insuredetail">
<h3 className='insureh3'>Jurisdiction:</h3>

<p className="normaltext">Information submitted via our Website will be held confidential and will be used for insurance purposes only. Completion and/or submission of the application or request for additional information does not constitute the purchase of insurance, nor does it represent any agreement to provide coverage under any insurance policy. No coverage will be bound, added, altered, or changed as a result of submitting a request for information or quotation of insurance. Any dispute is only limited to Kolkata Only.</p>
</div>

<div className="insuredetail">
<h3 className='insureh3'>refund Policy:-</h3>

<span className='bulletpoint'>1. Cancellation and Refund</span>

<p className="normaltext">If you wish to cancel any insurance policy, you shall contact the customer service of the respective insurance company through mail/phone, whose product you had purchased through Beema1.com. Each insurer has a cancellation charge which is dependent on the cancellation terms and conditions of the specific insurer. The cancellation and refund process will be directly taken up by the insurer and Beema1.com shall only assist you in initiating the cancellation if required. We shall not be responsible in any manner whatsoever for return, refund, and cancellation or the payment or the charges incurred by the User in availing any insurance policy through the Website. The User is aware that the Website is neither acting as trustee nor acting in a fiduciary capacity concerning the transaction or the payment. The User further agrees and acknowledges that The Website is only a facilitator of information and has no interest in the payment and transaction to be carried between the User and the insurance provider. All disputes regarding payments shall be routed to the insurer.
<br />
<br />
<span className='bulletpoint'>2. Chargebacks</span>

<p className="normaltext">You as the cardholder may dispute a transaction carried out through our Website and file a chargeback with your issuing bank for the reversal of that transaction. Chargeback allows transactions to be reversed by you under specified circumstances such as, on account of 

alleged forgery of the card number/bank account or other details 

any charge/debit made on a card that has been listed as a hot listed card or otherwise listed on the card association (Visa, MasterCard, etc.) warning bulletins 

duplicate processing</p>
</p>
</div>

<div className="insuredetail">
<h3 className='insureh3'>Fees and Charges: -</h3>
<p className="normaltext">Beema1.com offers a FREE service with no charges from its Website visitors </p>
</div>

<div className="insuredetail">
<h3 className='insureh3'>LIMITATION OF LIABILITY: -</h3>
 
<p className="normaltext"><span style={{fontWeight:'bold'}}>Disclaimer regarding Insurers.</span> The Company shall neither be liable nor responsible for any of the following:</p>

<p className="normaltext">actions or inactions of any Insurer or any other third party including but not limited to delay in issuance or refusal to issue any insurance policy, delay in providing or refusal to provide any insurance claim amount, change in amount quoted for any insurance policy, change in any requirement for any insurance policy, or requirement to provide any additional information or proof to the Insurer; 

any breach of conditions, representations, or warranties, by any Insurer; 

any action or inaction of the government, the IRDAI, a court or tribunal; 

any false, incomplete, inaccurate, or outdated information provided by You; and/or 

Your failure to make any payment on time. Company hereby expressly disclaims any responsibility and liability about the above. Company shall not be responsible for mediating or resolving any dispute or disagreement between You and any Insurer. All disputes and policy claims must be resolved directly between You and the relevant Insurer.</p>
</div>

<div className="insuredetail">
<h3 className='insureh3'>Trademarks & Copyright: -</h3>
<p className="normaltext">All trademarks, service marks, trade names, logos, icons, and domain names located on this Website are the property of Guide2Protect Insurance Web Aggregator Private Limited. Any logos, trademarks, service marks domain names, and all related intellectual property rights, of the companies whose sites we search in providing the service, belong to those companies, and you may not reproduce them without their prior written consent. Except for them, all intellectual property rights including, without limitation, all logos, trademarks, service marks, domain names, database rights, rights in designs, rights in know-how, patents and rights in inventions (in all cases whether registered or unregistered and including all rights to apply for registration) and all other intellectual or industrial property rights in any jurisdiction in any information, content, materials, data or processes contained in or underlying this Website and/or the service we provide belong to Beema1.com. All rights of Beema1.com in such intellectual property rights are hereby reserved.</p>
</div>

<div className="insuredetail">
<h3 className='insureh3'>General: -</h3>

<p className="normaltext">Links from the Website to other third-party sites, maintained by third parties, do not constitute an endorsement by Beema1.com or any of its subsidiaries or affiliates of any third-party resources, or their contents. Website disclaims any responsibility for the content contained in any third-party materials provided through links from the Website.</p>

<p className="normaltext">The website will use reasonable efforts to maintain the accuracy or reliability of any statement or other information displayed or distributed through the Website. The information supplied on this Website about our products is informational only. You acknowledge that any reliance upon any such statement or information shall be at your sole risk. The Company reserves the right, in its sole discretion, to correct any errors or omissions in any portion of the Website. Coverage is controlled by the policy purchased and the particular facts of each situation. To obtain the coverage you must apply to Beema1.com. All applications are subject to underwriting approval.</p>
</div>










</div>



    </div>
  );
};

export default Termsandcondition;
