import React, { useState, useEffect } from "react";
import "../RetairmentCalculator/Retairment.css";
import Slider from "@mui/material/Slider";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Link } from "react-router-dom";
import Calculatorlist from "../Calculator-Charat/Calculatorlist";
import Chart from "react-apexcharts";
import { Helmet } from "react-helmet-async";

const Retairment = () => {
  const [tab, setTab] = useState(1);
  const [CurrentAge, setCurrentAge] = useState(18);

const CurrentAgeuser = (e) =>{
  let value = e.target.value;

  if (value === '') {
    setCurrentAge(''); // Set to an empty string if the input is cleared
  } else {
    value = Number(value);
    if (value > 59) {
      value = 59; // Set the value to 60 if it's greater than 60
    }
    setCurrentAge(value);
  }
}








  const [RetairmentAge, setRetairmentAge] = useState(60);

  const Retairmrentage = (e) =>{
    let value = e.target.value;
  
    if (value === '') {
      setRetairmentAge(''); // Set to an empty string if the input is cleared
    } else {
      value = Number(value);
      if (value > 60) {
        value = 60; // Set the value to 60 if it's greater than 60
      }
      setRetairmentAge(value);
    }
  }






  const [AnnualIncome, setAnnualIncome] = useState(200000);
  const [salaryIncrementYear, setsalaryIncrementYear] = useState(7);
  const [CurrentSaingForRetairment, setCurrentSaingForRetairment] =
    useState(10000);
  const [selectedValue, setSelectedValue] = React.useState("Fixed_Deposit");
  const [MonthlyExpanse, setMonthlyExpanse] = useState(10000);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  //   Calculation start heaer

  const totalAge = RetairmentAge - CurrentAge;
  const Total_Month = totalAge * 12;

  var convertRupeesIntoWords = require("convert-rupees-into-words");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function calculateFutureValue(PV, r, n) {
    return PV * Math.pow(1 + r, n);
  }
  function convertToAnnual(monthly) {
    return monthly * 12;
  }

  const PV = AnnualIncome;
  const r = 0.06;
  const n = totalAge;
  const FV = calculateFutureValue(PV, r, n);
  const annualIncome = convertToAnnual(FV);
  console.log(
    "The annual income that you need post-retirement is Rs",
    annualIncome.toFixed(2),
  );

  function calculateRetirementCorpus(PMT, rate, periods) {
    return PMT * ((1 - Math.pow(1 + rate, -periods)) / rate);
  }

  const PMT = Math.round(annualIncome) / 12;
  const rateOfReturn = salaryIncrementYear;
  const inflationRate = 0.06;
  const inflationAdjustedRate = (1 + rateOfReturn) / (1 + inflationRate) - 1;
  const periods = Total_Month;
  
  // Calculate retirement corpus
  const retirementCorpus = calculateRetirementCorpus(PMT, inflationAdjustedRate, periods);
  //console.log("You need Rs", retirementCorpus.toFixed(2), "to generate a yearly income of Rs 18,02,586.",PMT*Total_Month);
  
const [TheFetureReturn, setTheFetureReturn] = useState([])


  console.log(
    "You need Rs",
    retirementCorpus.toFixed(2),
    "to generate a yearly income of Rs 18,02,586.",
    PMT * Total_Month,
  );



  useEffect(() => {
    SelectValue();
  }, [selectedValue, CurrentSaingForRetairment]);

  const SelectValue = () => {
    if (selectedValue === "Fixed_Deposit") {
      const returns = 0.04;
      const years = totalAge;
      const data = CurrentSaingForRetairment * Math.pow(1 + returns, years);
      setTheFetureReturn(data);
    }
    if (selectedValue === "Stock_Markets") {
      const returns = 0.11;
      const years = totalAge;
      const data = CurrentSaingForRetairment * Math.pow(1 + returns, years);
      setTheFetureReturn(data);
    }
    if (selectedValue === "Mutual_Funds/ULIPs") {
      const returns = 0.12;
      const years = totalAge;
      const data = CurrentSaingForRetairment * Math.pow(1 + returns, years);
      setTheFetureReturn(data);
    }
  };

  //console.log("The Market", TheFetureReturn);
  
  
  
  

  function calculateFutureValue(PV, r, n) {
    return PV * Math.pow(1 + r, n);
  }

  const YearlyExpense = MonthlyExpanse * 12 * totalAge;
  const initialExpense = YearlyExpense;
  const inflationRates = 0.06;
  const years = totalAge;

  // Calculate future value
  const futureValue = calculateFutureValue(
    initialExpense,
    inflationRates,
    years,
  );

  console.log(
    "Future value of the expense after 38 years:",
    futureValue.toFixed(2),
  );

  const totalamoutis =
    Math.round(TheFetureReturn) + Math.round(PMT * Total_Month);

  const needmoney = PMT * Total_Month;
  // alert(totalamoutis)


 const additnalfirst = PMT * Total_Month
 const additnalsecond = TheFetureReturn
 const totaladditnalAmount = additnalfirst - additnalsecond


  return (
    <>
<Helmet>
  <title>Retirement Calculator</title>
  <meta name="description" content="Plan your retirement and estimate your future savings with our easy-to-use retirement calculator." />
  <meta name="keywords" content="retirement calculator, retirement savings planner, future retirement income calculator, retirement planning tool" />
</Helmet>

              <section className="margincalculator"></section>
      <div className="childeducation">
      <p className='breadcum'>Home &nbsp;&gt;&nbsp;&nbsp;&nbsp;<Link to={'/calculator'}>calculator</Link>&nbsp;&nbsp;&nbsp;&nbsp;&gt;&nbsp;&nbsp;<span style={{color:'green'}}>HLV Calculator</span></p>
        <h1>
          Retirement <span className="Mainheading">Calculator</span>
        </h1>
        <span className="subtaital">
          Retirement calculator is an easy to use online financial tool that
          helps you calculate the corpus you would need during your retirement.
          It takes into consideration factors such as income, investments and
          expenses to calculate your retirement corpus.
        </span>
      </div>

      <div className="underdiv">
        <div className="latemaincalculator">
          <div className="retairment">
            {tab === 1 && (
              <div className="calculatorRetaiment">
                <label htmlFor="">Current age</label>
                <div className="calculatetorsdiv">
                  <div className="sliderCalculator">
                    <Slider
                      value={CurrentAge}
                      onChange={CurrentAgeuser}
                      min={18}
                      max={59}
                      step={1}
                      aria-label="Monthly Investment"
                      valueLabelDisplay="auto"
                    />
                  </div>
                  <div className="textcalculator">
                    <input
                      type="text"
                      value={CurrentAge}
                      onChange={CurrentAgeuser}
                    />
                  </div>
                </div>
                <label htmlFor="">Retairment age</label>
                <div className="calculatetorsdiv">
                  <div className="sliderCalculator">
                    <Slider
                      value={RetairmentAge}
                      onChange={Retairmrentage}
                      min={19}
                      max={60}
                      step={1}
                      aria-label="Monthly Investment"
                      valueLabelDisplay="auto"
                    />
                  </div>
                  <div className="textcalculator">
                    <input
                      type="text"
                      value={RetairmentAge}
                      onChange={Retairmrentage}
                    />
                  </div>
                </div>
                <label htmlFor="">Monthly Income</label>
                <div className="calculatetorsdiv">
                  <div className="sliderCalculator">
                    <Slider
                      value={AnnualIncome}
                      onChange={(event) => {
                        setAnnualIncome(event.target.value);
                      }}
                      min={36000}
                      max={50000000}
                      step={1000}
                      aria-label="Monthly Investment"
                      valueLabelDisplay="auto"
                    />
                  </div>
                  <div className="textcalculator">
                    <input
                      type="text"
                      value={AnnualIncome}
                      onChange={(event) => {
                        setAnnualIncome(event.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="buttondivcalculator">
                  <button onClick={() => setTab(2)}>Next</button>
                </div>
              </div>
            )}
          </div>
          {tab === 2 && (
            <div className="retairment">
              <div className="calculatorRetaiment">
                <label htmlFor="">Income GrothRate</label>
                <div className="calculatetorsdiv">
                  <div className="sliderCalculator">
                    <Slider
                      value={salaryIncrementYear}
                      onChange={(event) => {
                        setsalaryIncrementYear(event.target.value);
                      }}
                      min={1}
                      max={14}
                      step={1}
                      aria-label="Monthly Investment"
                      valueLabelDisplay="auto"
                    />
                  </div>
                  <div className="textcalculator">
                    <input
                      type="text"
                      value={salaryIncrementYear}
                      onChange={(event) => {
                        setsalaryIncrementYear(event.target.value);
                      }}
                    />
                  </div>
                </div>
                <label htmlFor=""> Current Saving for Retairment</label>
                <div className="calculatetorsdiv">
                  <div className="sliderCalculator">
                    <Slider
                      value={CurrentSaingForRetairment}
                      onChange={(event) => {
                        setCurrentSaingForRetairment(event.target.value);
                      }}
                      min={100000}
                      max={10000000}
                      step={10000}
                      aria-label="Monthly Investment"
                      valueLabelDisplay="auto"
                    />
                  </div>
                  <div className="textcalculator">
                    <input
                      type="text"
                      value={CurrentSaingForRetairment}
                      onChange={(event) => {
                        setCurrentSaingForRetairment(event.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="selctinvestmenttype">
                  <FormControl component="fieldset">
                    <FormLabel component="legend" className="Investmentlabel">
                      Investment type
                    </FormLabel>
                    <RadioGroup
                      aria-label="gender"
                      name="Investment-type"
                      value={selectedValue} // Set the value of the radio group to the selected value
                      onChange={handleChange} // Call handleChange function when selection changes
                    >
                      <FormControlLabel
                        value="Fixed_Deposit"
                        control={<Radio />}
                        label="Fixed Deposit"
                        style={{ height: "auto", marginBottom: "-26px;" }}
                      />
                      <FormControlLabel
                        value="Mutual_Funds/ULIPs"
                        control={<Radio />}
                        label="Mutual Funds/ULIPs"
                        style={{ height: "auto", marginBottom: "-26px;" }}
                      />
                      <FormControlLabel
                        value="Stock_Markets"
                        control={<Radio />}
                        label="Stock Markets"
                        style={{ height: "auto", marginBottom: "-26px;" }}
                      />
                    </RadioGroup>
                  </FormControl>
                </div>

                <div className="buttondivcalculator">
                  <button onClick={() => setTab(1)} className="privious">
                    Privious
                  </button>
                  <button onClick={() => setTab(3)}>Next</button>
                </div>
              </div>
            </div>
          )}

          {tab === 3 && (
            <div className="retairment">
              <div className="calculatorRetaiment">
                <label htmlFor=""> Enter monthly Expese</label>
                <div className="calculatetorsdiv">
                  <div className="sliderCalculator">
                    <Slider
                      value={MonthlyExpanse}
                      onChange={(event) => {
                        setMonthlyExpanse(event.target.value);
                      }}
                      min={1000}
                      max={100000}
                      step={1000}
                      aria-label="Monthly Investment"
                      valueLabelDisplay="auto"
                    />
                  </div>
                  <div className="textcalculator">
                    <input
                      type="text"
                      value={MonthlyExpanse}
                      onChange={(event) => {
                        setMonthlyExpanse(event.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="failnalcalculculatorbutton">
                  <button onClick={() => setTab(2)} className="privious">
                    Privious
                  </button>
                  <button onClick={() => setTab(4)} className="next">
                    Calculate
                  </button>
                </div>
              </div>
            </div>
          )}
          {tab === 4 && (
            <div className="retairment">
              <div className="calculatorRetaiment">
                <div className="retsultcalculator">
                  <div className="underflexcalculator">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="Resultdivcalculator">
                          <span className="headincalculators">
                            To fund your retirement you will need
                          </span>
                          <h2>
                            ₹
                            {(() => {
                              let amount = Math.round(PMT * Total_Month);
                              return amount < 10000000
                                ? new Intl.NumberFormat("en-IN", {
                                    style: "currency",
                                    currency: "INR",
                                  }).format(amount)
                                : `${(amount / 10000000).toFixed(2)} crore`;
                            })()}
                          </h2>
                          <span className="moneyamountcalculator">
                            {convertRupeesIntoWords(needmoney)}
                          </span>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="Resultdivcalculator">
                          <span className="headincalculators">
                            Your current savings will grow to
                          </span>
                          <h2>
                            {(() => {
                              let investmentAmount =
                                Math.round(TheFetureReturn);
                              let InvestmenttotalFormatted =
                                investmentAmount < 10000000
                                  ? new Intl.NumberFormat("en-IN", {
                                      style: "currency",
                                      currency: "INR",
                                    }).format(Math.round(TheFetureReturn))
                                  : `${(investmentAmount / 10000000).toFixed(2)} crore`;
                              return InvestmenttotalFormatted;
                            })()}
                          </h2>
                          <span className="moneyamountcalculator">
                            {convertRupeesIntoWords(TheFetureReturn)}
                          </span>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="Resultdivcalculator">
                          <span className="headincalculators">
                            You will need an additional
                          </span>
                          <h2>
                            ₹
                            {(() => {
                              let totalAmount =
                              totaladditnalAmount
                              let totalFormatted =
                                totalAmount < 10000000
                                  ? new Intl.NumberFormat("en-IN", {
                                      style: "currency",
                                      currency: "INR",
                                    }).format(totalAmount)
                                  : `${(totalAmount / 10000000).toFixed(2)} crore`;
                              return totalFormatted;
                            })()}
                          </h2>
                          <span className="moneyamountcalculator">
                            {convertRupeesIntoWords(totalamoutis)}
                          </span>
                        </div>
                      </div>
<div className="col-md-6">
<Chart
        type='donut'
        width={400}
        height={445}
        series={[totaladditnalAmount, TheFetureReturn]}
        options={{
          labels:['You will need an additional', 'Your Savings Amount']
        }}
      />

</div>







                    </div>
                  </div>
                </div>
                <div className="buttondivcalculator">
                  <button onClick={() => setTab(3)}>Back</button>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="resultse">
          <Calculatorlist />
        </div>
      </div>
    </>
  );
};

export default Retairment;
