import React from 'react'
import './Howbeemaone.css'
import FastImage from "../../FastImage";
import { auto } from "@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core";

const Howbeemaone = () => {

    const WorkArray = [
        {
            id:0,
            name:'Fill in Your Details',
            description:"Fill in your details and get insurance policy premium quotes from top-rated insurers instantly.",
            img: "Website%20Img/0000.png?updatedAt=1718802109205",
            step: 1.
        },{
            id:1,
            name:'Compare Quotes and Choose a Insurance',
            description:"From numerous available quotes, choose the one that best suits your requirements and budget.",
            img: "Website%20Img/0001.png?updatedAt=1718802131817",
            step: 2.
        },{ 
            id:2,
            name:'Make Payment and Sit Back',
            description:"Pay online and get your policy right away in your inbox.",
            img: "Website%20Img/0002.png?updatedAt=1718802149906",
            step: 3.
        }
    ]

  return (
    <>
<div className="container globalspacing">
    <div className="tile-text-with-text-shadow textalinecenter">
  
<h1>How Beema1 Works ?</h1>
</div>


<div className="commonone">


<div className="beemaworkprosess">
{
    WorkArray.map((data, index) => {
        return (
            <React.Fragment key={index}>
                <div className="mainstatus">
            
                <FastImage
                        url={data.img}
                        width={auto}
                        height={auto}
                      />
  
                    <h3>     <span className='stepspan'>{data.step}</span> &nbsp;{data.name}</h3>
                    <span className='discription'>{data.description}</span>
                </div>
                {index < WorkArray.length - 1 &&   <FastImage
                        url={"Website%20Img/arroww.svg?updatedAt=1718802382542"}
                        width={auto}
                        height={auto}
                        className='arrowimgs'
                      />   }
            </React.Fragment>
        );
    })
}
</div>
</div>

</div>
    </>
  )
}

export default Howbeemaone