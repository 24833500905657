import React from 'react'
import './Preferredpolicy.css'



import FastImage from '../FastImage'
import { auto } from '@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core'


const  Preferredpolicy = () => {
  return (
    <div className="fixwidthDivtext">
    <div className='topheaderpreffer'> 



<div className="ratingdivcolor">


    <div className="ratings container">
    <div className='policynames '>

      <p class="largestTxt"> <span style={{color:"#2959B8"}}>Beema</span><span style={{color:'red'}}>1</span> is Fastest Growing digital web aggregator </p>
 
      </div>
    

    <div className='starcontainer'>
   
    <p>⭐</p><p>⭐</p><p>⭐</p><p>⭐</p> <span className='headerparagraph'>Review By Facebook</span>
    </div>
   
     <div className="clames"> 

       <div className="holders">
       <strong className='headerstrong'>3 Lakh+</strong>
       <p className='headerparagraph'>Policy holders</p> 
       </div>

      <div>
      <strong className='headerstrong'>75% </strong>
      <p className='headerparagraph'>Clames past 3 moths</p>
      </div>


      <div>
      <strong className='headerstrong'>7 Lakh+</strong>
      <p className='headerparagraph'>Policy Sold overall india</p> 
      </div>
   
   
     
     </div>
    </div>
    </div>
    {/* Comments Card start Hear */}
    <div className="comments">

<div className="flexcontainerpreff container">


    <div className="whyweare">
    <span className='whyus'>Why We Are Diffrent Then Others <strong>7L+ Indian Choose Beema<span className="onespan">1</span> as Fist Insurerence Guide</strong></span>
    </div>
<div className="commentpolicy">
         
    <div className="gridcards ">
    <div className="gridcardcontainer">
    <strong className='cardheads'>Track policy</strong>
    <FastImage

                  url={"Website%20Img/track.jpg?updatedAt=1718786083491"}
                  width={auto}
                  height={auto}
                  alt={"gridcoin"}
                  src={"Website%20Img/track.jpg?updatedAt=1718786083491"}
                  className='cardimage'
                />

    </div>

    <p className='cardcontent'>Regular reviews help ensure you have the right amount of coverage and avoid any gaps that 
    could cause problems with claims.</p>
      </div>
      <div className="gridcards">
    <div className="gridcardcontainer">
    <strong className='cardheads'>Compare Policy</strong>
    <FastImage

                  url={"Website%20Img/track.jpg?updatedAt=1718786083491"}
                  width={auto}
                  height={auto}
                  alt={"gridcoin"}
                  src={"Website%20Img/track.jpg?updatedAt=1718786083491"}
                  className='cardimage'
                />

    </div>

    <p className='cardcontent'>Compare Quotes from Top Insurers. Easy Online Process & Get Beema1 Expert Advice.s.</p>
      </div>
      <div className="gridcards left">
    <div className="gridcardcontainer">
    <strong className='cardheads'>Renew Policy</strong>
     <FastImage

                  url={"Website%20Img/shild.jpg?updatedAt=1718786178877"}
                  width={auto}
                  height={auto}
                  alt={"shild"}
                  src={"Website%20Img/shild.jpg?updatedAt=1718786178877"}
                />


    </div>
    <p className='cardcontent'>Unlock the full benefits of your policy by staying up-to-date with your annual premium payments.
    Experience seamless policy renewal options with Beema1</p>
      </div>

      <div className="gridcards left">
    <div className="gridcardcontainer">
    <strong className='cardheads'>Download policy</strong>
    <FastImage

                  url={"Website%20Img/downloadse.jpg?updatedAt=1718786083421"}
                  width={auto}
                  height={auto}
                  alt={"downloadpoli"}
                  src={"Website%20Img/downloadse.jpg?updatedAt=1718786083421"}
                />

    </div>
    <p className='cardcontent'>t's a good idea to keep a copy of your policy with you. You'll be able to review the specifics 
    whenever needed quickly</p>
      </div>
    </div>
    </div>



    </div>
    </div>
    </div>
  )
}

export default  Preferredpolicy
