import React from 'react'
import { Link } from 'react-router-dom'
import { AiOutlineFileProtect } from "react-icons/ai";
import { FaLink } from "react-icons/fa";
import { MdPolicy } from "react-icons/md";
import { FaFacebookSquare } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { BsInstagram } from "react-icons/bs";
import { FaLinkedin } from "react-icons/fa6";
import { IoLogoYoutube } from "react-icons/io";
import { IoCall } from "react-icons/io5";
import { IoMail } from "react-icons/io5";
import { BiSolidPhoneCall } from "react-icons/bi";
import { FcLike } from "react-icons/fc";


import FastImage from '../FastImage';
import { auto } from '@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core';
import { Helmet } from 'react-helmet-async';


const Footer = () => {
	const currentDate = new Date();
const currentYear = currentDate.getFullYear();
  return (
    <>
	  
   
  <footer className="footer-main">
			<div className="container">
				<div className="row footer-row-upper">
					<div className="col-sm-12 com-md-3 col-lg-3">
						<div className="footer-logo">
							
							<FastImage
                  url={"Website%20Img/beemaone.jpg?updatedAt=1718789827025"}
                  width={auto}
                  height={auto}
                  alt={"logo"}
                  src={"Website%20Img/beemaone.jpg?updatedAt=1718789827025"}
                />
						</div>
						<p className="footer-about-text">Our friendly customer support team is your extended family. Speak your heart out. They listen with undivided attention to resolve your concerns. Give us a call, request a callback or drop us an email, we’re here to help.</p>
						<div className="contact-info">
							<Link to ="mailto:communication@beema1.com"><IoMail className='socialmedia'/> communication@beema1.com</Link>
							<Link to ="tel:+91 3323593438"> <IoCall className='socialmedia'/>+91 3323593438</Link>
						</div>
						<div className="social-contact">
							<Link to ="https://www.facebook.com/beemaone/?_rdr" className='sociallink'  target="_blank" rel="noopener noreferrer"><FaFacebookSquare className='socialmedia'/></Link>
							<Link to ="https://in.linkedin.com/company/beema1" className='sociallink'  target="_blank" rel="noopener noreferrer"><FaLinkedin className='socialmedia'/></Link>
							{/* <Link to ="javascript:void(0)" className='sociallink'><FaSquareXTwitter style={{fontSize:'18pt'}}/></Link> */}
							<Link to ="https://www.instagram.com/beemaone/" className='sociallink'   target="_blank" rel="noopener noreferrer"><BsInstagram className='socialmedia'/></Link>
							{/* <Link to ="javascript:void(0)" className='sociallink'><IoLogoYoutube style={{fontSize:'18pt'}}/></Link> */}
						</div>
					</div>
					<div className="col-sm-12 com-md-3 col-lg-3">
						<div className="footer-link">
							<h6><AiOutlineFileProtect className='socialmedia'/>Insurance</h6>
							<ul>
								<li><Link to ="/car-insurance">Car Insurance</Link></li>
								<li><Link to ="/bike-insurance">Bike Insurance</Link></li>
								<li><Link to ='/health-insurance'>Health Insurance</Link></li>
								<li><Link to ="/term-insurance">Term Insurance</Link></li>
								{/* <li><Link to ="/travel-insurence">Travel Insurance</Link></li> */}
							</ul>
						</div>
					</div>
					<div className="col-sm-12 com-md-3 col-lg-3">
						<div className="footer-link">
							<h6><FaLink className='socialmedia'/>Quick Links</h6>
							<ul>
								<li><Link to ={'/'}>Home</Link></li>
								<li><Link to ={'/about-us'}>About us</Link></li>
								<li><Link to ={'/contractus'}>Contact us</Link></li>
								<li><Link to ={'/blogs'}>Blogs</Link></li>
								<li><Link to ={'/careers'}>Careers</Link></li>
								<li><Link to ={'/calculator'}>Calculators</Link></li>
							</ul>
						</div>
					</div>
					<div className="col-sm-12 com-md-3 col-lg-3">
						<div className="footer-link">
							<h6><MdPolicy className='socialmedia'/>Other Links</h6>
							<ul>
								<li><Link to ={'/termsandcondition'}>Terms and Conditions</Link></li>
								<li><Link to ={'/privacy-policy'}>Privacy Policy</Link></li>
							</ul>
						</div>
						<Link className="toll-free-number" to ="tel:+91 3323593438">
							<div className="left-icon">
								<BiSolidPhoneCall className='socialmedia'/>
							</div>
							<div className="tollfree-text">
								<p>Tall Free No.</p>
								<p>+91 3323593438</p>
							</div>
						</Link>
					</div>
				</div>




               





			</div>

			<div className="bottomlicence">
				<div className="row footer-row-below container">
					<div className="col-sm-12 col-md-8 col-lg-8">
						<p className="down-footer-text bold">Standard T&C Apply. All savings/proposals are provided by insurance companies as approved by the IRDAI for the product under relevant and file & use guidelines. Savings in motor insurance are as per the Indian Motor Tariff quotations. For furthermore details on risk factors, terms and conditions, please minutely go through the sales brochure of the respective insurers before concluding a sale. Tax benefits are subject to changes as per the applicable tax laws. Guide2Protect Insurance Web Aggregator Private Limited Company, (Andromeda Building, 2nd Cross Rd, DD 30, Block, Sector 1, Bidhannagar, Kolkata, West Bengal 700064) valid till 27th November 2025. Email : communication@beema1.com, Helpline number: 03323593438. You authorize Guide2Protect (Beema1) to contact you through call, SMS, email, WhatsApp or any other mode in future.</p>
					</div>
					<div className="col-sm-12 col-md-4 col-lg-4">
						<div className="bima-class-one">
							
							<FastImage
                  url={"Website%20Img/irdia.png?updatedAt=1718786083442"}
                  width={auto}
                  height={auto}
                  alt={"irda"}
                  src={"Website%20Img/irdia.png?updatedAt=1718786083442"}
                />
						
						</div>
					</div>
				</div>
				</div>

 
			
		</footer>
		<div className="container-fluid">
				<div className="row last-footer-new">
					<div className="col-sm-12 col-md-12 col-lg-12 flexxx">
						<span className="bottomtext">&#169; Made With <FcLike className='socialmedia'/> By <span className='beema'>beema</span> <span className='beema1'>1</span> Copyright {currentYear} All Rights Reserved.</span>
					</div>
				</div>
			</div>
    </>
  )
}

export default Footer