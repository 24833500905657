import React from 'react';
import FAQ from "../AllPageComponents/FAQ/FAQ";
import PartnerCompany from "../AllPageComponents/PartnerCompany/PartnerCompany";
import "../CarInsurnce/Carinsurence.css";
import FastImage from "../FastImage";
import { auto } from '@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core'
import CarInsuranceForm from './CarInsuranceForm';
import Redirect from '../AllPageComponents/Rederict';

const PremiumCalculator = () => {
  return (
    <>
    <Redirect/>
<section className="car-insurance insurance-all insurance-background">
  <div className="container">
    <div className="row insurance-row-one">
      <div className="col-sm-12 col-md-4 col-lg-4 mian-one">
        <div className="car-insurance-first-inner">
    
          <h4 style={{color:"#0166B3",fontFamily:"Poppins",fontWeight:500,fontSize:"18pt"}}>Premium Calculator</h4>
      <br />
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer too
          </p>
      
        </div>
      </div>
      <div className="col-sm-12 col-md-4 col-lg-4 side-image">
      <FastImage
            url={"Website%20Img/carImagMascord.png?updatedAt=1718773961090"}
            width={auto}
            height={auto}
            alt={"ca-img"}
            />
      </div>
      <style
        dangerouslySetInnerHTML={{
          __html: "\n    .hide{\n        display: none;\n    }\n"
        }}
      />
      <CarInsuranceForm/>
   
   
   

    </div>
  </div>
</section>
<section className="full-space-block">
  <div className="container">
    <div className="row full-space-block-row">
      <div className="col-sm-12 col-md-12 col-lg-12">
        <div className="insurance-less-farage">
          <div className="simple-text-block">
            <h5 style={{color:"#0166B3",fontFamily:"Poppins"}}>
              How to Use Car Insurance Premium Calculator Online?
            </h5>
            <div className="yellowline"></div>
            <br />
            <p>
              Every insurance provider has its own method of calculating the car
              insurance premium amount.
            </p>
            <p>
              However, there are two major divisions namely, the premium
              calculation for used cars and the premium calculation for new
              cars. You will be required to enter certain essential details in
              order to calculate the premium for your car insurance.
            </p>
            <p>
              Here are the steps to get the insurance premium through the
              vehicle/car insurance premium calculator:
            </p>
            <p>
            <FastImage
            url={"Website%20Img/Hand.png?updatedAt=1718777726806"}
            width={auto}
            height={auto}
            alt={"ca-img"}
            />
              &nbsp; &nbsp;Select the brand, model, variant and fuel type of
              your car
            </p>
            <p>
            <FastImage
            url={"Website%20Img/Hand.png?updatedAt=1718777726806"}
            width={auto}
            height={auto}
            alt={"ca-img"}
            />
              &nbsp; &nbsp;Select the registered location of your car correctly
            </p>
            <p>
            <FastImage
            url={"Website%20Img/Hand.png?updatedAt=1718777726806"}
            width={auto}
            height={auto}
            alt={"ca-img"}
            />
              &nbsp; &nbsp;Select the year in which you purchased your car
            </p>
            <p>
            <FastImage
            url={"Website%20Img/Hand.png?updatedAt=1718777726806"}
            width={auto}
            height={auto}
            alt={"ca-img"}
            />
              &nbsp; &nbsp;Select ‘Yes’ or ‘No’ to confirm if you made any claim
              in the previous year
            </p>
            <p>
            <FastImage
            url={"Website%20Img/Hand.png?updatedAt=1718777726806"}
            width={auto}
            height={auto}
            alt={"ca-img"}
            />
              &nbsp; &nbsp;After this, you will have to enter your name and
              mobile number. You will get an OTP on the mobile number, entering
              which will get you the policy premium -quotes&nbsp; &nbsp; &nbsp;
              &nbsp; &nbsp; for&nbsp;the car&nbsp; insurance.&nbsp;
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section className="insurance-popup-calculator popup-all-calculator">
  <div className="insurance-popup-inner container">
  
  </div>
</section>
<PartnerCompany/>
<FAQ/>

    </>
  )
}

export default PremiumCalculator