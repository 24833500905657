import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Termstepper from "./Termstepper";
import arrow from '../TermInsurence/TermImages/arrow-animation.gif'
import Redirect from "../AllPageComponents/Rederict";
import { Helmet } from "react-helmet-async";

const Termstepper1 = () => {
  const FullName = localStorage.getItem("ProposalName");
  const PhoneNumber = localStorage.getItem("Proposalphonenumber");
  const dateofBirth = localStorage.getItem("dateofbirth");

  const [phone_no, setPhoneNumber] = useState(PhoneNumber || "");
  const [name, setFullName] = useState(FullName || "");
  const [dob, setDob] = useState(dateofBirth || "");
  const [phoneNumError, setPhoneNumError] = useState("");
  const [nameError, setNameError] = useState("");
  const [dobError, setDobError] = useState("");
  const [calculatedAge, setCalculatedAge] = useState(null);
  const [genders, setGender] = useState("Male");

  const nav = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    calculateAgeFromDateOfBirth(dob);
  }, [dob]);

  const calculateAgeFromDateOfBirth = (dateOfBirth) => {
    if (!dateOfBirth) {
      setCalculatedAge(null);
      return;
    }

    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    const diffInMs = today - birthDate;
    const ageInYears = diffInMs / (1000 * 60 * 60 * 24 * 365.25);
    const roundedAge = Math.floor(ageInYears);
    setCalculatedAge(roundedAge);
  };

  const isValidIndianPhoneNumber = (phoneNumber) => {
    const indianPhoneNumberRegex = /^[6-9]\d{9}$/;
    return indianPhoneNumberRegex.test(phoneNumber);
  };

  const phoneNumValid = (data) => {
    let isValid = true;
    if (data === "") {
      setPhoneNumError("Phone number is required");
      isValid = false;
    } else if (!isValidIndianPhoneNumber(data)) {
      setPhoneNumError("Please enter a valid Indian phone number");
      isValid = false;
    } else if (data.length !== 10) {
      setPhoneNumError("Phone number must be 10 digits");
      isValid = false;
    } else {
      setPhoneNumError("");
    }
    return isValid;
  };

  const validateFullName = (data) => {
    let isValid = true;
  
   console.log('The Data',data)

    if (data === "") {
      setNameError("Full name is required");
      isValid = false;
    } else {
      setNameError("");
    }
    return isValid;
  };

  const validateDob = (data) => {
    let isValid = true;

    if (data === "") {
      setDobError("Date of birth is required");
      isValid = false;
    } else {
      setDobError("");
    }
    return isValid;
  };



  const handleNameChange = (event) => {
    setFullName(event.target.value);
  };

  const handlePhoneNumber = (event) => {
    let value = event.target.value;
    value = value.replace(/[^0-9]/g, "");
    setPhoneNumber(value);
  };



const [AgeArray, setAgeArray] = useState('')



function reverseDateFormat(dateStr) {
const [year, month, day] = dateStr.split('-');
  return `${day}-${month}-${year}`;
}


  const handleDobChange = (event) => {
    const { value } = event.target;
    setDob(value);
    calculateAgeFromDateOfBirth(value);
    validateDob(value);
  };

useEffect(()=>{
 const DOBchnage = reverseDateFormat(dob)
 setAgeArray(DOBchnage)
},[dob])






  const handleClick = (selectedGender) => {
    setGender(selectedGender);
  };

const HandleSubmitData = () => {
    // Validate each field
    const isPhoneNumValid = phoneNumValid(phone_no);
    const isNameValid = validateFullName(name);
    const isDobValid = validateDob(dob);

    // Proceed only if all fields are valid
    if (isPhoneNumValid && isNameValid && isDobValid) {
        // Save data to localStorage
        if (genders === "Male") {
            localStorage.setItem("Gender", "M");
        } else if (genders === "Female") {
            localStorage.setItem("Gender", "F");
        }

        localStorage.setItem("Proposalphonenumber", phone_no);
        localStorage.setItem("ProposalName", name);

        if (dob) {
            localStorage.setItem("dateofbirth", dob);
        }

        if (calculatedAge) {
            localStorage.setItem("ageadded", calculatedAge.toString());
        }

        // Navigate to the next page
        nav('/term-insurance/termstep2');
    } else {
        // Optionally handle the case when validation fails
        console.log("Validation failed. Please correct the errors and try again.");
    }
};


  return (
    <>
                 <Helmet>
        <title>Termstep1</title>
        {/* <meta name="description" content="A brief description of your page for SEO." /> */}
        <meta name="keywords" content="Insurance Plan, Insurance Quotes, buy insurance" />
      </Helmet>
    <Redirect/>
      <h1
        style={{
          textAlign: "center",
          padding: 20,
          color: "rgb(0, 85, 165)",
          marginTop: 20,
          fontFamily:'Poppins'
        }}
      >
        Secure Family With {" "}
        <span style={{ color: "rgb(229, 3, 24)" }}> 1 Crore</span> &nbsp;
        <span style={{ color: "rgb(253, 146, 2)" }}>Cover discount</span>
        <sup>**</sup>
      </h1>

      <Termstepper activeStep={1} />
      <div className="underterminsurence">
        <div className="undertermform">



          <div className="selectgenderdivs py-2">
            <div
              className={genders === "Male" ? "activegender" : "deactivegender"}
              onClick={() => handleClick("Male")}
            >
              Male
              <input type="checkbox" name="" id="" checked={genders === "Male"} className="smokeInput"/>
            </div>
            <div
              className={genders === "Female" ? "activegender" : "deactivegender"}
              onClick={() => handleClick("Female")}
            >
              Female
              <input type="checkbox" name="" id="" checked={genders === "Female"} className="smokeInput"/>
            </div>
          </div>

{           /* Input Filds  */}

          <div className="form-inner-input form-group ">
            <label htmlFor="form_name" className="Labelform">
              Name:
            </label>

            <input
              id="name"
              type="text"
              name="fullname"
              className="form-control txtOnly"
              placeholder="Enter your name"
              value={name}
              onChange={(e) => {
                handleNameChange(e);
                validateFullName(e.target.value);
              }}
            />
            {nameError && (
              <span className="termformerror">{nameError}</span>
            )}
          </div>
          

          <div className="form-inner-input form-group ">
            <label htmlFor="phone" className="Labelform">
              Mobile Number:
            </label>
          
            <input
              type="tel"
              id="mobile"
              name="mobile"
              placeholder="Enter your mobile number"
              className="form-control txtOnly"
              maxLength={10}
              value={phone_no}
              onChange={(e) => {
                handlePhoneNumber(e);
                phoneNumValid(e.target.value);
              }}
            />
            {phoneNumError && (
              <span className="termformerror">{phoneNumError}</span>
            )}
          </div>
          <div className="agedivterm py-2">
            <label htmlFor="dob" className="Labelform">
              Date Of Birth:
            </label>
          
            <input
              type="date"
              id="dob"
              name="dob"
              className="form-control txtOnly"
              max="2006-03-03"
              value={dob}
              onChange={(e) => {
                handleDobChange(e);
              }}
            />
            {dobError && (
              <span className="termformerror">{dobError}</span>
            )}
            {calculatedAge !== null && (
              <p className="Myage">{calculatedAge} years</p>
            )}
          </div>

          <div className="underbutton ">
            <button onClick={HandleSubmitData}>continue</button>
           <img src={arrow} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Termstepper1;
