import React from 'react';
import PartnerCompany from '../AllPageComponents/PartnerCompany/PartnerCompany';
import FAQ from '../AllPageComponents/FAQ/FAQ';
import './TermInsurence.css';
import { useState } from 'react';
import TermInsuranceForm from './TermInsuranceForm';
import FastImage from "../FastImage";
import { auto } from '@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core'
import { LuAlarmClock } from "react-icons/lu";
import Redirect from '../AllPageComponents/Rederict';
import TermFormtwo from './TermFormtwo';

const TermNriPlans = () => {

  return (
    <>
    <Redirect/>
      <section className="car-insurance insurance-all">
        <div className="container">
          <div className="row insurance-row-one">
            <div className="col-sm-12 col-md-4 col-lg-4 mian-one">
              <div className="healthdiscription">
                <h1>Term Insurance For NRI</h1>
                <span>
                  "Term insurance" refers to a type of life insurance that
                  provides coverage for a specific period, or "term," typically
                  ranging from 5 to 30 years. It offers a death benefit to the
                  beneficiaries if the insured individual passes away during the
                  term of the policy. Term insurance is known for its simplicity
                  and affordability compared to other types of life insurance,
                  such as whole life or universal life insurance.{" "}
                </span>
              </div>
              <div className="insurance-btn-set">
                <button
                  type="button"
                  className="reminderbutton"
                  data-toggle="modal"
                  data-target="#reminderpopup"
                >
                  {" "}
                  <LuAlarmClock
                    fontSize={"15pt"}
                    style={{ margin: "0 5px", color: "#FBA71B" }}
                  />{" "}
                  Set Reminder
                </button>
              </div>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4 side-image">

            <FastImage
            url={"Website%20Img/BikeInsurence.png?updatedAt=1718779269495"}
            width={auto}
            height={auto}
            alt={"ca-img"}
            className='fmailimagegifs'
            />




    
            </div>
            {/* <TermInsuranceForm/> */}
            <TermFormtwo/>
         
          </div>
        </div>
      </section>
      <div className="container">
    <h5 style={{color:"#2959B8"}}>What is Term Insurece For NRI ?</h5>
    <div className="yellowline"></div>
    <p>
      Currently there are 24 insurance companies that provide term insurance in
      India. Term insurance is a type of life insurance policy which provides a
      sum assured known as death benefit in case of an unfortunate demise of the
      life assured during the policy tenure. Term insurance can help an
      individual ensure financial security of their family. Hence it is very
      important to choose an insurance company whom you fully rely on. While
      choosing a term insurance company you should always consider the claim
      settlement ratio of the company.
    </p>
    <p>
      The Indian insurance market is full of term insurance companies that offer
      term insurance plans with high coverage at affordable premium amounts, to
      the insured. Currently, there are 24 insurance companies that offer term
      insurance plans in India.
    </p>
  </div>
  <br />
  <br />
<section className="full-space-block">
  <div className="container">
    <div className="row full-space-block-row">
      <div className="col-sm-12 col-md-12 col-lg-12">
        <div className="insurance-less-farage">
          <div className="simple-text-block">
            <h5>Features Of Term Plans For NRI</h5>
            <p>Following are some features of term plans for NRI:</p>
            <h6>1. Choice of Policy Term:</h6>
            <p>
              Non-Resident Indians can get term insurance from a range of
              companies (NRIs). These plans can be for as little as six months
              or as much as 25 years. To be eligible for insurance, NRIs must be
              at least 18 years old. Individuals above the age of 60 or 65 are
              usually unable to get a term plan.
            </p>
            <h6>2. Customisable Amounts:</h6>
            <p>
              When it comes to term insurance plans, the sum insured is the most
              important thing to consider. NRIs can invest as little as INR 2
              lakhs and as much as INR 5 crores. Some insurance companies do not
              have a maximum sum insured cap. of You can choose the amount of
              coverage based on the financial need of your family.
            </p>
            <h6>3. Easy Payments:</h6>
            <p>
              Insurance providers accept payments via internet banking to help
              NRIs to keep their policies active even while they are outside of
              India. They can also make regular premium payments using
              international remittance or their NRO, NRE, or FCNR accounts. In
              addition, NRIs have the option of choosing how often they want to
              pay their premium. Annual cycles are usually the most effective.
            </p>
            <h6>4. Quick Documentation:</h6>
            <p>
              Individuals applying for an NRI term plan must provide a few
              documents along with the application form. You must submit a copy
              of your passport, as well as documents confirming your age and
              income. NRIs must also provide papers related to their past
              medical history while purchasing term insurance plans. These facts
              will be used by the insurance company to determine the premium
              amount they may give.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<PartnerCompany/>
<FAQ/>
    </>
  )
}

export default TermNriPlans