import {feetAndInchesToCm , formatDate} from "./common";

import { updateHdfcPremium } from "../redux/Slice";
import { UseDispatch, useDispatch, useSelector } from "react-redux";
import { UseSelector } from "react-redux";




const payload = (data,PlanData,userdata,addonsArray,HDFCcheckedQuestion,quoteNoOne,quoteIdOne,HDCRiderPremium, hdfcPremium , requestdata) =>
{

	// //console.log('userdata',userdata)
	// //console.log("PlanData ",PlanData)
	const heightdata = feetAndInchesToCm(data.height_feet , data.height_inch);

	//console.log("the bheightdata", heightdata);

	let tenure =  1;

	
  
	if(PlanData.hasOwnProperty('premiumBreakup'))
	{
		tenure =  PlanData.premiumBreakup.term
	}
	// alert("the total discount" + PlanData.premiumBreakup.discountAmount)
   let output = {
		    "ProposalName": data.ProposalName,
		    "Proposalphonenumber": data.Proposalphonenumber,
		    "street": data.street,
		    "PinCode": Number(data.PinCode),
		    "District": data.District,
		    "City": data.District,
		    "State": data.Sate,
		    "PED": userdata.PED.join(),
		    "Email": data.Email,
		    "dateOfbirth": formatDate(data.KycDateOfbirth),
		    "height": data.height || heightdata || data.InsuredDetail[0].height,
		    "weight":  data.weight || data.InsuredDetail[0].weight,
		    "Occupation": data.Occupation,
		    "GrossIncome": data.AnnualIncome,
		    "Gender": userdata.Gender == 'M' ? 'Male' : 'Female',
		    "Premium": PlanData.premiumBreakup.totalPremium,
		    "BasePremium" : PlanData.premiumBreakup.basePremium,
			"TotalDiscount" : PlanData.premiumBreakup.discountAmount,
		    "SumInsured": PlanData.sumInsured,
		    "PlanCode": PlanData.planCode,
		    "PlanName": PlanData.planName,
		    "Tenure": tenure+" Year",
		    "MaritalStatus": data.MaritalStatus == 'Unmarried' ? 'Unmarried' : data.MaritalStatus,
		    "InsuredDetail": InsuredDetail(data.InsuredDetail,userdata,HDFCcheckedQuestion),
			"hdfc_premium" :hdfcPremium,
			
	
				"bank_account_umber": data.accountNumber,
				"name_as_per_bank_account": data.Name,
				"bank_name": data.bankName,
				"ifsc_code": data.ifscCode,
		
			"physical_dispatch" : ((data.gogreen ==  true ))?"no" : "yes",
		    "nominee": {
		        "firstName": extractNames(data.nomineeName).fname,
		        "middleName": null,
		        "lastName": extractNames(data.nomineeName).lname,
		        "dateOfbirth": formatDate(data.nomineeDateOfbirth),
		        "profession": null,
		        "gender": data.nomineerelationship == 'Self' ? userdata.Gender : getGender(data.nomineerelationship),
		        "relationship": data.nomineerelationship,
		        "PinCode":Number(data.PinCode),
		        "State": data.Sate,
		        "City": data.District,
		        "street": data.nomineeAddress
				    },
				"ApointeeDetails": {
            "FullName": data.ProposalName,
            "RelationWithNominee": data.RelationWithNominee,
            "street": data.street,
            "PinCode": Number(data.PinCode),
            "StateName": data.Sate,
            "CityName": data.District
        },    
		    "kycId": data.kycId,
		    "cardNum": data.cardNum,
		    "addons": addonsArray ? addonsArray.join() :"",
        "userToken":localStorage.getItem('userToken'),
		"deductible": addonsArray ? addonsArray.includes("deductible")?(requestdata.deductible ? requestdata.deductible : 0):0 : 0,
		'HDCRiderSI' : addonsArray ? addonsArray.includes("HDCRiderSI")?(requestdata.HDCRiderSI ? requestdata.HDCRiderSI : 0):0 : 0,
		'QuoteNo': quoteNoOne != "" ? quoteNoOne : PlanData.QuoteNo,
		'quoteId': quoteIdOne != "" ? quoteIdOne : PlanData.quoteId,
		'HDCRiderPremium' : HDCRiderPremium != "" ? HDCRiderPremium : PlanData.HDCRiderPremium
		}
	

	return output;
}

const InsuredDetail = (data,userdata,HDFCcheckedQuestion) => 
{
  let result = [];

  for (let i = 0; i < data.length; i++) 
  {
  	let obj = {
  		         InsuredName:data[i].InsuredName,
  		         InsuredRelation:data[i].InsuredRelation,
  		         dateOfbirth:formatDate(data[i].dateOfbirth),
  		         Gender: data[i].InsuredRelation == 'Self' ? (userdata.Gender == 'M' ? 'Male' : 'FeMale') : getGender(data[i].InsuredRelation),
  		         InsuredAge:data[i].InsuredAge,
  		         height:data[i].height,
  		         weight:data[i].weight,
  		         IPED: true,
  		         MaritalStatus:data[i].MaritalStatus,
  		         MedicalQuestionDetails:MedicalQuestionDetails(i,HDFCcheckedQuestion)
  		      };
  	result.push(obj);
  }
  return result;
}

const extractNames = (fullName) =>
{
  // Split the full name into an array of words
  const nameArray = fullName.split(' ');

  // Extract the first name (fname)
  const fname = nameArray[0];

  // Extract the last name (lname) if available
  const lname = nameArray.length > 1 ? nameArray.slice(1).join(' ') : '';

  return { fname, lname };
}
 
const getGender = (relation) =>
{
  const output = {
  	"Mother":'FeMale',
  	"Father":'Male',
  	"Grand Father":'Male',
  	"Grand Mother":'FeMale',
  	"Brother":'Male',
  	"Sister":'FeMale',
  	"Husband":'Male',
  	"Wife":'FeMale',
  	"Father In Law":'Male',
  	"Mother In Law":'FeMale',
  	"Uncle":'Male',
  	"Aunt":'FeMale',
  	"Grand Daughter":'FeMale',
  	"Grand Son":'Male',
  	"Son":'Male',
  	"Daughter":'FeMale'

  }
  return output[relation];
} 

const MedicalQuestionDetails = (index,HDFCcheckedQuestion) => 
{
	let array = [];
	
  for (let key in HDFCcheckedQuestion) {
   	if(HDFCcheckedQuestion[key].hasOwnProperty(index))
   	{

		array.push(HDFCcheckedQuestion[key][index])
	
   	}
   } 
   if(array.length != 0){
	let data = array.map((item)=>{
		console.log("the item", item)

		item = item[0];
		return item
	})
	console.log("the data", data)
	// array.map(item => item[0])
	return data
   }
   else{
	return array;

   }

}

export {payload}