// quickquote
// fetchquickquote
// updateall
// updatecompanywise

// parameter payload

import axios from "axios";
import { encryption } from "../helper/common";


export const quickQuote = async (payload) => {
  try {
    // setLoading(true);
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}health/quickquote`,
      payload,
      { headers: { Token: encryption() } }
    );
  
    return response;
  } catch (error) {
    console.log("error", error);
  } finally {
    // setLoading(false);
  }
};

export const fetchQuickQuote = async (unique_id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}health/fetch_quickquote/${unique_id}`,
      { headers: { Token: encryption() } }
    );
   
    return response;
  } catch (error) {
    //console.log(error);
    throw error;
  }
};

export const updateQuickQuote = async (payload , unique_id) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}health/updatequickquote/${unique_id}`,
      payload,
      { headers: { Token: encryption() } }
    );
   
    return response;
  } catch (error) {
  
    if (error && error.response) {
    
    } else {
  
    }
  }
};



export const updateQuickQuoteCompanywise = async (payload , unique_id ) => {

// comapny name and plancode is mandatory here to pass in payload 

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}health/updatequickquote_single_com/${unique_id}`,
      payload,
      { headers: { Token: encryption() } }
    );
    console.log("the response in updateQuickQuoteCompanywise", response);
 
    return response;
  } catch (error) {
  
    if (error && error.response) {
    
    } else {
  
    }
  }
};


export const updateAllCompany = async (payload, unique_id) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}health/updatequickquote_by_com/${unique_id}`,
      payload,
      { headers: { Token: encryption() } }
    );
  

    return response;
  } catch (error) {
   
    if (error && error.response) {
      //console.log(error.response);
    } else {
      //console.log(error);
    }
  }
};


export const createQuote = async(payload , c_name , unique_id)=>{

    // let config = {
    //     method: "post",
    //     maxBodyLength: Infinity,
    //     url: url,
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     data: payload,
    //   };
    try {
        const response = await axios.post(
            process.env.REACT_APP_BASE_URL + "health/createqoute/"+ c_name +"/" + unique_id,
            payload,
          { headers: { Token: encryption() } }
        );

      
    
        return response;
      } catch (error) {
      }

}


export const afterPlanFetchApi = async( unique_id ,c_name , plan_id) => {

try {
    const response = await axios.get(

        process.env.REACT_APP_BASE_URL + "health/after_plan_fetch/" +unique_id + "/" +c_name + "/" + plan_id,
        { headers: { Token: encryption() } }
    )
  
    return response ;
    
} catch (error) {

    console.log("the erropr ", error);

    
}

}

export const AddonApi = async (c_id , plan_id) =>{

  try {
    
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}health/fetch_question/${c_id}/${plan_id}`,
      { headers: { Token: encryption() } }
    );


    const responseData = response.data;

 
    return responseData;

  } catch (error) {
    console.error("AddOnApi Error:", error);
    throw error;
  }

}

export const updateAddonApi = async (unique_id , updatedUserdata) =>{
  try {

    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}health/updatequickquote_single_com/${unique_id}}`,
      updatedUserdata,
      { headers: { Token: encryption() } }
    );
    const responseData = response.data;

   


    return responseData;
  } catch (error) {
    console.error("AddOnApi Error:", error);
    throw error;
  }
}


export const updateDatabase = async ( unique_id , payloadData) =>{

  try {

    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}health/updatequickquote_single_com_without_api/${unique_id}}`,
      payloadData,
      { headers: { Token: encryption() } }
    )

    let data = response.data;

    return data
    
  } catch (error) {
    console.error("database api error:",error);
    throw error;
  }
}