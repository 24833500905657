import React, { useEffect, useState } from "react";
import "./Beemablog.css";
import axios from "axios";
import { Link } from "react-router-dom";
import Redirect from "../../AllPageComponents/Rederict";
import FastImage from '../../FastImage'
import { auto } from '@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core'
import { Helmet } from "react-helmet-async";




const Beemablog = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [selectedCategory, setSelectedCategory] = useState("General");
  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  // All blog details

  const [allblog, setAllblog] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}blog/all_blog`,
        );
        setAllblog(response.data.data);
      } catch (error) {
        throw new Error("Error fetching blog data:", error);
      }
    };

    fetchData();
  }, []);

  const truncateText = (text, wordLimit) => {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  };

  // Handel Search blog

  const [Handelserch, setHandelserch] = useState([]);

  const handelsearch = (result) => {
    const searchResult = allblog.filter((searchRes) =>
      searchRes.title.toLowerCase().includes(result.toLowerCase()),
    );
    setHandelserch(searchResult);
  };

  const handleInputChange = (event) => {
    const searchTerm = event.target.value.trim();
    if (searchTerm) {
      handelsearch(searchTerm);
    } else {
      // If no search query, show all data
      setHandelserch(allblog);
    }
  };

  useEffect(() => {
    // Initialize Handelserch with all blog entries
    setHandelserch(allblog);
  }, [allblog]);

  return (
    <section className="mainnargin-insurance">
<Helmet>
  <title>Queries & Solutions - Blog</title>
  <meta name="description" content="Explore answers to common questions and solutions to various queries in our blog." />
  <meta name="keywords" content="queries and solutions, blog, FAQ, common questions, problem-solving blog" />
</Helmet>



    <div className="container">
      <Redirect />
      <section className="topheader">
        <div className="textmodel">
          <h6>Stay Updated With Beema Blog's</h6>
          <p className='textformat'>
          Welcome to our blog section, where you'll find informative articles, helpful tips, and expert 
insights to enhance your knowledge
          </p>
        </div>
        <div className="blogimages">
        <FastImage
            url={"/Website%20Img/blogimage.png?updatedAt=1728282930857"}
            width={auto}
            height={auto}
            alt={"ca-img"}
            />
        </div>
      </section>
      
      <section className="tabsclick">
        <div className="tappdtabsdiv">
          <div className="undertabs">
            <div
              className={
                selectedCategory === "General" ? "tabse" : "deactivtabs"
              }
              onClick={() => handleCategoryClick("General")}
            >
              Genaral
            </div>
            <div
              className={
                selectedCategory === "Health" ? "tabse" : "deactivtabs"
              }
              onClick={() => handleCategoryClick("Health")}
            >
              Health
            </div>
            <div
              className={selectedCategory === "Car" ? "tabse" : "deactivtabs"}
              onClick={() => handleCategoryClick("Car")}
            >
              Car
            </div>
            <div
              className={selectedCategory === "Bike" ? "tabse" : "deactivtabs"}
              onClick={() => handleCategoryClick("Bike")}
            >
              Bike
            </div>
            {/* <div
              className={
                selectedCategory === "Travel" ? "tabse" : "deactivtabs"
              }
              onClick={() => handleCategoryClick("Travel")}
            >
              Travel
            </div> */}
            <div
              className={selectedCategory === "Term" ? "tabse" : "deactivtabs"}
              onClick={() => handleCategoryClick("Term")}
            >
              Terms
            </div>
            <div
              className={selectedCategory === "Life" ? "tabse" : "deactivtabs"}
              onClick={() => handleCategoryClick("Life")}
            >
              Life
            </div>
          </div>
        </div>
        <div className="searchbar">
          <input
            type="text"
            placeholder="Search Blogs, Articles, Insights..."
            style={{ fontFamily: "Poppins, sans-serif", color: "#407BFF" }}
            onChange={(e) => handelsearch(e.target.value)}
          />
        </div>
      </section>

      <div id="blog_container">
        <section class="our-blog-section">
          <div class="container">
            <div class="our-blog-row">
              {Handelserch.map((blog, index) => {
                const modifiedTitle = blog.title.replace(/\s+/g, "-");
                return (
                  <div
                    class=" blog-box"
                    hidden={
                      selectedCategory
                        ? blog.category !== selectedCategory
                        : false
                    }
                  >
                    <Link to={`/blogs/${modifiedTitle}/${blog.id}`}>
                      <div class="blog-box-img">
                        <img
                          src={`${process.env.REACT_APP_MAIN_URL}/uploads/blog_images/${blog.image}`}
                          alt="blog first"
                        />
                      </div>
                      <div class="blog-box-content">
                        <div class="blog-sm investment ">{blog.category}</div>
                        <div class="blog-box-title">
                          <h2>{blog.title}</h2>
                        </div>
                        <div class="blog-box-pera">
                          <p
                            dangerouslySetInnerHTML={{
                              __html: truncateText(blog.blog, 50),
                            }}
                          />
                        </div>
                        <div class="blog-box-read-more">
                          <span>read more</span>
                          <FastImage
            url={"Website%20Img/Arrow34.png?updatedAt=1718859621632"}
            width={auto}
            height={auto}
            alt={"ca-img"}
            />
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </section>

        <section class="pagination pag-mb">
          <div class="container">
            <nav aria-label="Page navigation example">
              <ul class="pagination"></ul>
            </nav>
          </div>
        </section>
      </div>
    </div>
    </section>
  );
};

export default Beemablog;
