import axios from "axios";
import { encryption } from "../../Health/helper/common";

export const quickQuoteApi = async (payload) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}term/quickquote`,
      payload,
      { headers: { Token: encryption() } },
    );

    return response;
  } catch (error) {
    console.log("error", error);
  } finally {
  }
};

export const CreateQuoteApiTerm = async (unic_id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}term/fetch_quickquote/${unic_id}`,
      { headers: { Token: encryption() } },
    );
    return response;
  } catch (err) {
    console.error("Error during API call:", err); // Log the error
    throw err;
  }
};

export const updateQuickQuots = async (unic_id, payload) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}term/updatequickquote/${unic_id}`,
      payload,
      { headers: { Token: encryption() } },
    );
    return response;
  } catch (err) {
    console.error("Error during API call:", err); // Log the error
    throw err;
  }
};

export const CreateQuotetionPayment = async (company, unic_id, payload) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}term/createqoute/${company}/${unic_id}`,
      payload,
      { headers: { Token: encryption() } }, // Correctly format headers
    );
    return response;
  } catch (err) {
    // console.error("Error during API call:", err); // Correct error logging
    throw err; // Re-throw the error to allow handling by the caller
  }
};
