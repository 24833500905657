export function formatToINR(amount) {

    if (typeof amount === 'string') {
      amount = amount.replace(/[^0-9.]/g, ''); 
    }
  

    const cleanedAmount = parseFloat(amount);
    if (isNaN(cleanedAmount)) {
      throw new Error("Invalid amount");
    }
  

    const parsedAmount = cleanedAmount.toFixed(2);
  
    // Split into whole and decimal parts
    const [whole, decimal] = parsedAmount.split('.');
  
    // Format the whole part with commas
    const formattedWhole = whole.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  
    // Return the formatted amount with ₹ as a string
    return `₹ ${formattedWhole}.${decimal}`;
  }
  