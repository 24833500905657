import React, { useState,useEffect } from 'react'
import '../Dashbord/Dashbord.css'
import { MdOutlineAutoGraph } from "react-icons/md";
import { AiOutlineFileProtect } from "react-icons/ai";
import { LuAlarmClock } from "react-icons/lu";
import { IoIosLogOut } from "react-icons/io";
import { Link, useNavigate } from 'react-router-dom';
import { RxCross2 } from "react-icons/rx";
import Swal from "sweetalert2";
import axios from 'axios';
import Pagination from '@mui/material/Pagination';
import FastImage from "../../FastImage";
import { auto } from '@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core'
import { MdMenuOpen } from "react-icons/md";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';

import { MdHealthAndSafety } from "react-icons/md";
import { IoUmbrellaSharp } from "react-icons/io5";
import { Helmet } from 'react-helmet-async';

const Dashbord = () => {
const [isactiveDashbord, setisactiveDashbord] = useState('Dashbord')
const [dashbordData, setdashbordData] = useState([]);
const [UserDatais, setUserDatais] = useState([]);
const [activeTabForm, setActiveTabForm] = useState('health');

const [currentPage, setCurrentPage] = useState(1);
const [Iteamperpage] = useState(4)

const indexOfLastItem = currentPage * Iteamperpage;
const indexOfFirstItem = indexOfLastItem - Iteamperpage;
const filteredItems = UserDatais.filter(data => data.request_for === activeTabForm);

useEffect(() => {
  setCurrentPage(1);
}, [activeTabForm]);


const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

//console.log("The Debug Value",currentItems)

const HandelDashbordActive = (data) =>{
    setisactiveDashbord(data)
}

const LogoutData = () =>{
  localStorage.clear();
  window.location.href = '/';
}

// Api calling section
const handlePageChange = (event, page) => {
  setCurrentPage(page);
};

//console.log('UserData is Resume',UserDatais)

const userToken = localStorage.getItem('userToken');

const total_claim = dashbordData.claimCount
const searchData = dashbordData.searchData
const Remember = dashbordData.reminderCount
const policyCount = dashbordData.policyCount
//console.log(searchData)

const fetchDashboardData = async () => {
  try {
    if (!userToken) {
      console.warn('User token not found');
      return;
    }
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}user/dashboard/dashboard_data`, {
      params: { token: userToken },
    });
    if(response.data.status === false){
      Swal.fire({
        title: "Session Expair",
        text: "Your is Session Expair",
        icon: "error",
        confirmButtonText: "Done",
      });
      localStorage.clear();
      window.location.href = '/';
    }
    setdashbordData(response.data.data);
    setUserDatais(response.data.data.searchData)
    
  } catch (error) {
    console.error('Error fetching dashboard data:', error);
  }
};

useEffect(() => {
  fetchDashboardData();
}, []); // Runs only on component mount





const navigate = useNavigate()


useEffect(() => {
  window.scrollTo(0, 0)
}, [])



const [ShowSideBar, setShowSideBar] = useState(true)

const ShowMenuBar = () =>{
  setShowSideBar(!ShowSideBar)
}






const handleTabClick = (tab) => {
  setActiveTabForm(tab);
};




const [open, setOpen] = React.useState(false);

const toggleDrawer = (open) => (event) => {
  if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
    return;
  }

  setOpen(open);
};

const list = () => (
  <Box
  sx={{ width: 250 }}
  role="presentation"
  onClick={toggleDrawer(false)}
  onKeyDown={toggleDrawer(false)}
>
<div className='crossdiv'>
<button><RxCross2/></button>
</div>
  <ul className="underullist">

    <li className="activeDashbord">
      <Link to="/dashbord">
        <MdOutlineAutoGraph /> &nbsp; Dashbord
      </Link>
    </li>
    <li className="deactiveDashbord">
      <Link to="/dashbord-policy">
        <AiOutlineFileProtect /> &nbsp; Policy
      </Link>
    </li>
    <li className="deactiveDashbord">
      <Link to="/setup-reminder">
        <LuAlarmClock /> &nbsp; Setup Reminders
      </Link>
    </li>
    <li className="deactiveDashbord">
      <Link to="/dashbord-Claim">
        <MdHealthAndSafety /> &nbsp; Clame Mangement
      </Link>
    </li>
    <li className="deactiveDashbord" onClick={LogoutData}>
      <IoIosLogOut /> &nbsp; Logout
    </li>
  </ul>
</Box>
);






  return (
    <div>
            <Helmet>
        <title>Dashbord</title>
        {/* <meta name="description" content="A brief description of your page for SEO." /> */}
        {/* <meta name="keywords" content="best term insurance in india , buy term life insurance, compare and purchase term insurance" /> */}
      </Helmet>
<div className="dashbordmargin"></div>

    <div className="maincontainer">
    {/* Sidebar Container Start Hear */}
    <div className={ShowSideBar ? "sidebarse" : ''}>
    <div className="subsidebar">
    <ul>
    <Link to={'/dashbord'}> <li className='activeDashbord' > <MdOutlineAutoGraph/> Dashbord</li></Link>
    <Link to={'/dashbord-policy'}> <li className="deactiveDashbord">  <AiOutlineFileProtect/> Policy</li></Link>
    <Link to={'/setup-reminder'}><li className="deactiveDashbord"><LuAlarmClock/> Setup Reminders</li></Link> 
    <Link to={'/dashbord-Claim'}> <li className="deactiveDashbord"> <MdHealthAndSafety/> Clame Mangement</li></Link>
    <li className="deactiveDashbord" onClick={LogoutData}><a><IoIosLogOut/>  Logout</a></li>
    </ul>
    </div>
    </div>
   {/* Sidebar Container End Hear */}



    {/* Dashbord Container Start Hear */}
    <div className="dashbordcontainer">

{
  isactiveDashbord === 'Dashbord' && (



<div className="underdashbordcontainer">

    <div className="underdashbord">
    <div className="buttonnavbar">
    
    <Button onClick={toggleDrawer(true)}><MdMenuOpen style={{fontSize:"25pt"}}/></Button>
      <Drawer
        anchor="left"
        open={open}
        onClose={toggleDrawer(false)}
      >
        {list()}
      </Drawer>
    
    </div>



    <div className="dashbordcard">
    <div className="dashbordicon">
      
      
    <FastImage
            url={"Website%20Img/77.svg?updatedAt=1718797266945"}
            width={auto}
            height={auto}
            alt={"ca-img"}
            />
      
      
      
      
      </div>
    <div className="numberdashbord">
    <div>
    <h1 style={{color:"#2957B6"}}></h1>
    <h1 style={{color:"#2959B8"}}>{searchData ? searchData.length : 0}</h1>
    <p>Search</p>
    </div>
    </div>
    </div>

    <div className="dashbordcard">
    <div className="dashbordicon">
      
      
    <FastImage
            url={"Website%20Img/88.svg?updatedAt=1718797266967"}
            width={auto}
            height={auto}
            alt={"ca-img"}
            />
      
      
      </div>
    <div className="numberdashbord">
    <div>
  
    <h1 style={{color:"#007FFF"}}>{policyCount || 0}</h1>
    <p>Policy</p>
    </div>
    </div>
    </div>

    <div className="dashbordcard">
    <div className="dashbordicon">
      
    <FastImage
            url={"Website%20Img/99.svg?updatedAt=1718797266799"}
            width={auto}
            height={auto}
            alt={"ca-img"}
            />
      
      
      </div>
    <div className="numberdashbord">
    <div>
  
    <h1 style={{color:"#FCA84C"}}>{Remember}</h1>
    <p>Remember</p>
    </div>
    </div>
    </div>

    <div className="dashbordcard">
    <div className="dashbordicon">
      
      
    <FastImage
            url={"Website%20Img/110.svg?updatedAt=1718797266845"}
            width={auto}
            height={auto}
            alt={"ca-img"}
            />
      
      
      </div>
    <div className="numberdashbord">
    <div>
  
    <h1 style={{color:"#ED3543"}}>{total_claim || 0}</h1>
    <p>Total Claim</p>
    </div>
    </div>
    </div>
    </div>


<div className="renecntsearch"><span>Recent Search</span></div>

<div className="tabs">
<div
        className={activeTabForm === 'health' ? 'tab' : 'deactivetab'}
        onClick={() => handleTabClick('health')}
      >
        <MdHealthAndSafety style={{ color: 'white', fontSize: '18pt' }} /> &nbsp; Health
      </div>
      <div
        className={activeTabForm === 'term' ? 'tab' : 'deactivetab'}
        onClick={() => handleTabClick('term')}
      >
        <IoUmbrellaSharp style={{ color: 'white', fontSize: '18pt' }} /> &nbsp; Term
      </div>
    
    </div>

<div className='supportdiv'>











    <table className="result-table">
    <thead>
        <tr>
        <th className="result-table-header">Insure</th>
            <th className="result-table-header">Name</th>
            <th className="result-table-header">Proposal Phone Number</th>
            <th className="result-table-header">Child</th>
            <th className="result-table-header">Adult</th>
            <th className="result-table-header">Max Age</th>
            <th className="result-table-header">Members</th>
            <th className="result-table-header">Action</th>
        </tr>
    </thead>
    <tbody>
        {currentItems.map((data, index) => {
            const handleResumeClick = () => {
                navigate(`/plans?req_id=${data.unique_id}`);
            };


            console.log("the Link",currentItems)
            const datase = JSON.parse(data.request);
            const TypeOf = data.request_for
            console.log(TypeOf)
            // Initialize variables
            let ProposalName = '';
            let Proposalphonenumber = '';
            let InsuredDetail = [];
            let numberOfPersonsUnder18 = '';
            let numberOfPersonsadove18 = '';
            let maxAge = '';
          
            // Check if the request is in the first format (with "name" and other individual details)
            if (datase.name) {
              ProposalName = datase.name;
              Proposalphonenumber = datase.phone_no || '';
            } else if (datase.ProposalName) { // Handle the second format (with "ProposalName" and "InsuredDetail")
              ProposalName = datase.ProposalName;
              Proposalphonenumber = datase.Proposalphonenumber || '';
          
              // Only process InsuredDetail if it exists and is a non-empty array
              if (datase.InsuredDetail && Array.isArray(datase.InsuredDetail) && datase.InsuredDetail.length > 0) {
                InsuredDetail = datase.InsuredDetail;
          
                // const personsUnder18 = InsuredDetail.filter(insured => insured.InsuredAge < 18);
                // const upper18 = InsuredDetail.filter(insured => insured.InsuredAge >= 18);



                const childArray = []; // Initialize an empty array for children
                const adultArray = []; // Initialize an empty array for adults
                
                InsuredDetail.forEach(detail => {
                    if (detail.InsuredRelation.toLowerCase() === "son" || detail.InsuredRelation.toLowerCase() === "daughter") {
                        childArray.push(detail); // Push children into childArray
                    } else {
                        adultArray.push(detail); // Push all other relations into adultArray
                    }
                });
                
                // Now childArray contains sons and daughters,
                // and adultArray contains all other details
                


     


                numberOfPersonsUnder18 = childArray.length;
                numberOfPersonsadove18 = adultArray.length;




             
      
          
                // Find max age
                maxAge = Math.max(...InsuredDetail.map(insured => insured.InsuredAge));
              }
            }
          

            const capitalizeFirstLetter = (str) => {
              if (typeof str !== 'string') return '';
              return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
            };
            


    


            return (
              <>
   

      
   <tr key={data.request_for} className="result-table-row" hidden={data.request_for !== activeTabForm}>
  <td className="result-table-data">
    {data.request_for === "health" ? (
      <MdHealthAndSafety style={{ color: '#2959B8', fontSize: '18pt' }} />
    ) : (
      <IoUmbrellaSharp style={{ color: '#2959B8', fontSize: '18pt' }} />
    )}
    &nbsp; {capitalizeFirstLetter(data.request_for)}
  </td>

  <td className="result-table-data">{ProposalName?.toUpperCase()}</td>
  <td className="result-table-data">{Proposalphonenumber}</td>
  <td className="result-table-data">{numberOfPersonsUnder18}</td>
  <td className="result-table-data">{numberOfPersonsadove18}</td>
  <td className="result-table-data">{maxAge !== -Infinity ? maxAge : ''}</td>
  <td className="result-table-data">
    {/* {InsuredDetail?.map((insured, idx) => ( */}
      <span  className="result-member">{InsuredDetail.length}</span>
    {/* ))} */}
  </td>
  <td className="result-table-data">
    <button className="result-resume-button" onClick={handleResumeClick}>Resume</button>
  </td>
</tr>



                </>
            );
        })}
    </tbody>
</table>












</div>            
</div>
  )
}



    </div>

 

    </div>
     {/* Dashbord Container End Hear */}

     <div className="padenationdiv" hidden={filteredItems.length <= Iteamperpage}>
        <Pagination
          count={Math.ceil(filteredItems.length / Iteamperpage)}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
        />
      </div>

   
 
    </div>
  )
}

export default Dashbord