import React from 'react';
import PartnerCompany from '../AllPageComponents/FAQ/FAQ';
import FAQ from '../AllPageComponents/FAQ/FAQ';
import './TermInsurence.css';
import TermInsuranceForm from './TermInsuranceForm';
import FastImage from "../FastImage";
import { auto } from '@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core'
import { LuAlarmClock } from "react-icons/lu";
import Redirect from '../AllPageComponents/Rederict';
import TermFormtwo from './TermFormtwo';
const TermCompareQuotes = () => {

  return (
   <>
   <Redirect/>
   {/* d-flex justify-content-center align-items-center */}
  <section className="car-insurance insurance-all">
        <div className="container">
          <div className="row insurance-row-one">
            <div className="col-sm-12 col-md-4 col-lg-4 ">

            <div>
              <div className="healthdiscription">
                <h1>Term Insurance Compare Your Quotes</h1>
                <span>
                  "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer too.{" "}
                </span>
              </div>
              <div className="insurance-btn-set">
                <button
                  type="button"
                  className="reminderbutton"
                  data-toggle="modal"
                  data-target="#reminderpopup"
                >
                  {" "}
                  <LuAlarmClock
                    fontSize={"15pt"}
                    style={{ margin: "0 5px", color: "#FBA71B" }}
                  />{" "}
                  Set Reminder
                </button>
              </div>
              </div>




            </div>
            <div className="col-sm-12 col-md-4 col-lg-4 side-image">

            <FastImage
            url={"Website%20Img/BikeInsurence.png?updatedAt=1718779269495"}
            width={auto}
            height={auto}
            alt={"ca-img"}
            className='fmailimagegifs'
            />




    
            </div>
            {/* <TermInsuranceForm/> */}
            <TermFormtwo/>
         
          </div>
        </div>
      </section>






{/* <section className="half-space-block">
  <div className="container">
    <div className="row insurance-row-two-less-farage">
      <div className="col-sm-12 col-md-12 col-lg-12">
        <div className="insurance-less-farage">
          <div className="simple-text-block Term-insurance">
            <h5>What is Compare Insurance Policy?</h5>
            <p>
              Currently there are 24 insurance companies that provide term
              insurance in India. Term insurance is a type of life insurance
              policy which provides a sum assured known as death benefit in case
              of an unfortunate demise of the life assured during the policy
              tenure. Term insurance can help an individual ensure financial
              security of their family. Hence it is very important to choose an
              insurance company whom you fully rely on. While choosing a term
              insurance company you should always consider the claim settlement
              ratio of the company.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> */}

<div className="container">
  <div className="row">
    <div className="col-md-12 ">
  
    <h5 style={{color:"#2959B8"}}>What is Compare Insurance Policy?</h5>
    <div className="yellowline"></div>
    <br />
    <p>
              Currently there are 24 insurance companies that provide term
              insurance in India. Term insurance is a type of life insurance
              policy which provides a sum assured known as death benefit in case
              of an unfortunate demise of the life assured during the policy
              tenure. Term insurance can help an individual ensure financial
              security of their family. Hence it is very important to choose an
              insurance company whom you fully rely on. While choosing a term
              insurance company you should always consider the claim settlement
              ratio of the company.
            </p>
    </div>
  </div>
</div>






<section className="full-space-block">
  <div className="container">
    <div className="row full-space-block-row">
      <div className="col-sm-12 col-md-12 col-lg-12">
        <div className="insurance-less-farage">
          <div className="simple-text-block">
            <p>
              The Indian insurance market is full of term insurance companies
              that offer term insurance plans with high coverage at affordable
              premium amounts, to the insured. Currently, there are 24 insurance
              companies that offer term insurance plans in India.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<PartnerCompany/>

   </>
  )
}

export default TermCompareQuotes