import React from 'react';
import FAQ from '../AllPageComponents/FAQ/FAQ';
import { useDispatch } from 'react-redux'
import { VehicalApi } from '../Health/redux/Slice';
import { useState } from 'react';
import PartnerCompany from '../AllPageComponents/PartnerCompany/PartnerCompany';
import BikeInsuranceForm from './BikeInsuranceForm';
import FastImage from "../FastImage";
import { auto } from '@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core'



const BikeRenewal = () => {
  const disptch = useDispatch()


  const [formData, setFormData] = useState({
    car_number: '',
    phone_no: '',
    pin: '',
    car_type: 'Twowheeler',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Log the entire form data
    disptch(VehicalApi(formData))
    //console.log('Form Data:', formData);
    // Add your form submission logic here
  };
  return (
    <>
    <section className="car-insurance insurance-all insurance-background">
  <div className="container">
    <div className="row insurance-row-one" style={{justifyContent:"space-around"}}>
      <div className="col-sm-12 col-md-4 col-lg-4 mian-one">
        <div className="car-insurance-first-inner">
          <h4> Bike Insurance Renewal</h4>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer too
          </p>
        </div>
      </div>
      <div className="col-sm-12 col-md-4 col-lg-4 side-image">
      <FastImage
            url={"Website%20Img/BikeInsurence.png?updatedAt=1718779269495"}
            width={auto}
            height={auto}
            alt={"ca-img"}
            />
      </div>
      <style
        dangerouslySetInnerHTML={{
          __html: "\n    .hide{\n        display: none;\n    }\n"
        }}
      />
      <BikeInsuranceForm/>
    
    
    </div>
  </div>
</section>
<section className="half-space-block">
  <div className="container">
    <div className="row insurance-row-two-less-farage">
      <div className="col-sm-12 col-md-12 col-lg-12">
        <div className="insurance-less-farage">
          <div className="simple-text-block">
            <h5>What is Bike Insurance Renewal?</h5>
            <p>
              Every bike insurance policy is valid only for a fixed tenure which
              maybe 1 year, 3 years or 5 years after which it needs to be
              renewed. A bike insurance renewal is essential to continue
              enjoying the benefits of the policy cover for your bike.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section className="full-space-block">
  <div className="container">
    <div className="row full-space-block-row">
      <div className="col-sm-12 col-md-12 col-lg-12">
        <div className="insurance-less-farage">
          <div className="simple-text-block">
            <p>
              You can get your bike insurance policy renewed by any bike
              insurance provider - be it the one from which you previously
              purchased a policy or a new insurer.
            </p>
            <h5>Benefits of Bike Insurance Renewal</h5>
            <p>
              The renewal of a bike insurance policy is not just a matter of
              procedure to continue to avail the policy, but it brings various
              benefits with it as well. Renewal of bike insurance policy offers
              the following benefits:
            </p>
            <h6>1. Help You Stay Insured</h6>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer too Lorem Ipsum is
              simply dummy text of the printing and typesetting industry. Lorem
              Ipsum has been the industry's standard dummy text ever since the
              1500s, when an unknown printer too
            </p>
            <h6>2. Helps You Keep No Claim Bonus (NCB) Intact:</h6>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer too Lorem Ipsum is
              simply dummy text of the printing and typesetting industry. Lorem
              Ipsum has been the industry's standard dummy text ever since the
              1500s, when an unknown printer too
            </p>
            <h6>3. Gives You The Option To Change Your Insurance Company:</h6>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer too Lorem Ipsum is
              simply dummy text of the printing and typesetting industry. Lorem
              Ipsum has been the industry's standard dummy text ever since the
              1500s, when an unknown printer too
            </p>
            <h6>4. To Opt Or Alter Add-On Covers:</h6>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer too Lorem Ipsum is
              simply dummy text of the printing and typesetting industry. Lorem
              Ipsum has been the industry's standard dummy text ever since the
              1500s, when an unknown printer too
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<PartnerCompany/>
<FAQ/>

    </>
  )
}

export default BikeRenewal