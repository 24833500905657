import React, { useEffect, useState } from 'react';
import '../Dashbord/Dashbord.css';
import { MdOutlineAutoGraph } from "react-icons/md";
import { AiOutlineFileProtect } from "react-icons/ai";
import { LuAlarmClock } from "react-icons/lu";
import { MdHealthAndSafety } from "react-icons/md";
import { IoIosLogOut } from "react-icons/io";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Pagination } from '@mui/material';
import Redirect from '../../AllPageComponents/Rederict';
import { RxCross2 } from "react-icons/rx";
import { MdMenuOpen } from "react-icons/md";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import { PiFilePdfDuotone } from "react-icons/pi";
import { Helmet } from 'react-helmet-async';

const Dashbordpolicy = () => {
  const Navigate = useNavigate();
  const [isactiveDashbord, setisactiveDashbord] = useState('Dashbord');
  const [policyData, setPolicyData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(7); // Change the number of items per page as needed
  const userToken = localStorage.getItem('userToken');

  useEffect(() => {
    const fetchPolicyData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}user/dashboard/policies`, {
          params: { token: userToken },
        });
        if(response.data.status === false){
          localStorage.clear();
          window.location.href = '/';
        }
        setPolicyData(response.data.data);
      } catch (error) {
        console.error('Error fetching policy data:', error);
        // Handle error gracefully (e.g., show error message to user)
      }
    };

    if (userToken) {
      fetchPolicyData();
    } else {
      console.warn('User token not found in local storage');
      // Handle case where user token is missing
    }
  }, [userToken]);

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = policyData.slice(indexOfFirstItem, indexOfLastItem);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const LogoutData = () =>{
  localStorage.clear();
  window.location.href = '/';
}
const [searchQuery, setSearchQuery] = useState("");
const [SearchData, setSearchData] = useState([])

const GlobalSearchFunction = (e) => {
  setSearchQuery(e.target.value.toLowerCase()); 
  const filteredData = policyData.filter((policy) => {
  const searchText = searchQuery.toLowerCase(); 
    const searchKeys = ["app_id","company", "created_at", "customerId","exp_date","id","policy_num","policy_type","premium_amt","proposal_no","propser_pan","unique_id","user_payment_id","users_id","users_veh_id"];
    return searchKeys.some((key) =>
      policy[key] && policy[key].toString().toLowerCase().includes(searchText)
    );
  });

    setSearchData(filteredData)

};



// ... rest of your code

const [ShowSideBar, setShowSideBar] = useState(true)

const ShowMenuBar = () =>{
  setShowSideBar(!ShowSideBar)
}


const [open, setOpen] = React.useState(false);

const toggleDrawer = (open) => (event) => {
  if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
    return;
  }

  setOpen(open);
};

const list = () => (
  <Box
  sx={{ width: 250 }}
  role="presentation"
  onClick={toggleDrawer(false)}
  onKeyDown={toggleDrawer(false)}
>
<div className='crossdiv'>
<button><RxCross2/></button>
</div>
  <ul className="underullist">

    <li className="activeDashbord">
      <Link to="/dashbord">
        <MdOutlineAutoGraph /> &nbsp; Dashbord
      </Link>
    </li>
    <li className="deactiveDashbord">
      <Link to="/dashbord-policy">
        <AiOutlineFileProtect /> &nbsp; Policy
      </Link>
    </li>
    <li className="deactiveDashbord">
      <Link to="/setup-reminder">
        <LuAlarmClock /> &nbsp; Setup Reminders
      </Link>
    </li>
    <li className="deactiveDashbord">
      <Link to="/dashbord-Claim">
        <MdHealthAndSafety /> &nbsp; Clame Mangement
      </Link>
    </li>
    <li className="deactiveDashbord" onClick={LogoutData}>
      <IoIosLogOut /> &nbsp; Logout
    </li>
  </ul>
</Box>
);


console.log('The Comming Url is',currentItems)

  return (
    <>
<div className="dashbordmargin"></div>
    <div className="maincontainer">
      {/* Sidebar Container Start Hear */}
      <div className={ShowSideBar ? "sidebarse" : ''}>
      <div className="subsidebar">
      <ul>
      <Link to={'/dashbord'}> <li className='deactiveDashbord' > <MdOutlineAutoGraph/> Dashbord</li></Link>
      <Link to={'/dashbord-policy'}> <li className="activeDashbord">  <AiOutlineFileProtect/> Policy</li></Link>
      <Link to={'/setup-reminder'}><li className="deactiveDashbord"><LuAlarmClock/> Setup Reminders</li></Link> 
      <Link to={'/dashbord-Claim'}> <li className="deactiveDashbord"> <MdHealthAndSafety/> Clame Mangement</li></Link>
      <li className="deactiveDashbord" onClick={LogoutData}><a><IoIosLogOut/>  Logout</a></li>
      </ul>
      </div>
      </div>
      {/* Sidebar Container End Hear */}

      {/* Dashbord Container Start Hear */}
      <div className="dashbordcontainer">
        <div className="underpolicy">
   
    
        
        <div className="textpolicy">
        <div className="buttonnavbar">
    
    <Button onClick={toggleDrawer(true)}><MdMenuOpen style={{fontSize:"25pt"}}/></Button>
      <Drawer
        anchor="left"
        open={open}
        onClose={toggleDrawer(false)}
      >
        {list()}
      </Drawer>
    
    </div>
            <span>Policy</span>

{/*<input type="text" placeholder='Search.....' onChange={GlobalSearchFunction}/> */}
          </div>
          <Helmet>
        <title>Policy</title>
        {/* <meta name="description" content="A brief description of your page for SEO." /> */}
        {/* <meta name="keywords" content="best term insurance in india , buy term life insurance, compare and purchase term insurance" /> */}
      </Helmet>
          <div className="table-container">
            <table className="policy-table">
              <thead>
                <tr>
                  <th>Policy ID</th>
                  <th>Purchase date</th>
                  <th>Policy Status</th>
                  <th>Expiry date</th>
                  <th>Company Details</th>
                  <th>Premium</th>
                  <th>Year</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {searchQuery === "" ? (
                  currentItems.map((data, index) => {
                    // Convert UTC time to local time
                    const utcDate = new Date(data.created_at);
                    const expdat = new Date(data.exp_date);

                    // Options for formatting date and time
                    const dateTimeOptions = {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: true
                    };

                    // Format date and time
                    const formattedDateTime = utcDate.toLocaleString(undefined, dateTimeOptions);
                    const erparydatetime = expdat.toLocaleString(undefined, dateTimeOptions);

                    function formatCurrencyInIndianRupees(premiumAmt) {
                      // Round the premium amount using Math.round
                      const roundedPremium = Math.round(premiumAmt);

                      // Format the rounded amount in Indian rupee format
                      const formattedPremium = roundedPremium.toLocaleString('en-IN', {
                        style: 'currency',
                        currency: 'INR',
                      });

                      return formattedPremium;
                    }

                    // Example usage:
                    // const premiumAmount = {data.premium_amt};
                    const formattedIndianRupees = formatCurrencyInIndianRupees(data.premium_amt);
                    // const capitalizedCompany = lowercaseCompany.charAt(0).toUpperCase() + lowercaseCompany.slice(1);

                    const lowercaseCompany = data.company.toLowerCase();
                    const MatchCompany = {
                        "icici": "icici",
                        "care": "CareHealthinsurance",
                        "godigit": "godigit",
                        "hdfc": "HDFC",
                        "reliance": "Reliance",
                        "zuno": "Zuno",
                        "bajaj": "Bajaj",
                        "star": "Star",
                        "national": "National",
                        "tataaig": "Tataaig"
                    };
                    
                    const matchedCompany = MatchCompany[lowercaseCompany] || lowercaseCompany; // Default to lowercaseCompany if no match
                    console.log('The match comapny',matchedCompany);
                    
                    let pdfUrl;

                    if (data.type === "online") {
                        pdfUrl = `${process.env.REACT_APP_MAIN_URL}/healthpdf/${matchedCompany}/${data.pdf_url}`;
                    } else if (data.type === "offline") {
                        pdfUrl = `${process.env.REACT_APP_CMS_URL}/healthpdf/${matchedCompany}/${data.pdf_url}`;
                    }
                    
                    console.log("The download URL:", pdfUrl);
                    
                    const onButtonClick = async () => {
                        try {
                            const response = await fetch(pdfUrl);
                            if (!response.ok) {
                                throw new Error(`HTTP error! Status: ${response.status}`);
                            }
                            const blob = await response.blob();
                            const link = document.createElement("a");
                            link.href = window.URL.createObjectURL(blob);
                            link.download = "document.pdf"; // Specify the filename for download
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                        } catch (error) {
                            console.error('Error fetching or downloading the PDF:', error);
                        }
                    };
                    
                    // Call onButtonClick when you want to initiate the download
                    
                  
                    
                 


                    return (
                      <tr key={index}>
                        <td>{data.policy_num}</td>
                        <td>{formattedDateTime}</td>
                        <td>{data.policy_status}</td>
                        <td>{erparydatetime}</td>
                        <td>{data.company}</td>
                        <td>{formattedIndianRupees}</td>
                        <td>Year</td>
                        {data.pdf_url ? <td className='renewbutton'>



                        {/* <button onClick={onButtonClick}>Download Policy</button>  */}

                        <a href={pdfUrl} target="_blank" download className='beemadownload'><PiFilePdfDuotone fontSize={'22pt'}/></a>

                        </td> : null}
                      </tr>
                    );
                  })
                ) : (
                  policyData.map((data, index) => {
                    const utcDate = new Date(data.created_at);
                    const expdat = new Date(data.exp_date);

                    // Options for formatting date and time
                    const dateTimeOptions = {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: true
                    };

                    // Format date and time
                    const formattedDateTime = utcDate.toLocaleString(undefined, dateTimeOptions);
                    const erparydatetime = expdat.toLocaleString(undefined, dateTimeOptions);

                    function formatCurrencyInIndianRupees(premiumAmt) {
                      // Round the premium amount using Math.round
                      const roundedPremium = Math.round(premiumAmt);

                      // Format the rounded amount in Indian rupee format
                      const formattedPremium = roundedPremium.toLocaleString('en-IN', {
                        style: 'currency',
                        currency: 'INR',
                      });

                      return formattedPremium;
                    }

                    // Example usage:
                    // const premiumAmount = {data.premium_amt};
                    const formattedIndianRupees = formatCurrencyInIndianRupees(data.premium_amt);
                    //console.log(formattedIndianRupees); // Output: ₹1,235
                    return (
                      <tr key={index}>
                        <td>{data.policy_num}</td>
                        <td>{formattedDateTime}</td>
                        <td>{data.policy_status}</td>
                        <td>{erparydatetime}</td>
                        <td>{data.company}</td>
                        <td>{formattedIndianRupees}</td>
                        <td>Year</td>
                        <td className='renewbutton'><button>Download</button></td>
                      </tr>
                    )
                  })
                )}
              </tbody>
            </table>

            {/* Pagination */}
          
          </div>

          <div className="padenationdiv" hidden={policyData.length < 7}>
              <Pagination 
                count={Math.ceil(policyData.length / itemsPerPage)}
                page={currentPage}
                onChange={(event, page) => setCurrentPage(page)} 
                color="primary" 
              />
            </div>
        </div>




      </div>





      
      {/* Dashbord Container End Hear */}
    </div>
    </>
  )
}

export default Dashbordpolicy;
