import React, { useEffect, useState , useRef} from "react";
import "../TermfetchPlanes/TermfetchPlanes.css";
import { AiOutlineDoubleRight } from "react-icons/ai";
import { FaAngleDown } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { GrFormDown } from "react-icons/gr";
import { AiOutlineCheck } from "react-icons/ai";
import { CiFilter } from "react-icons/ci";
import Redirect from "../../AllPageComponents/Rederict";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useNavigate } from "react-router-dom";
import Skeleton from '@mui/material/Skeleton';
import { CreateQuoteApiTerm, updateQuickQuots } from "../TermApi/TermApi";
import { AiOutlineInfoCircle } from "react-icons/ai";
import FastImage from "../../FastImage";
import { auto } from "@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core";
import { selectUserData, updatedLoginPopup } from "../../Health/redux/Slice";
import { Helmet } from "react-helmet-async";
import { formatToINR } from "../../HomePage/MoneyHealper/Moneyhelper";

const TermfetchPlanes = () => {
  const [personName, setPersonName] = useState("");
  const [userDistrict, setUserDistrict] = useState("");
  const [userAge, setUserAge] = useState("");
  const [userGender, setUserGender] = useState("");
  const [userSmoke, setUserSmoke] = useState("");
  const [employmentType, setEmploymentType] = useState("");


  const theme = useTheme();

  useEffect(() => {
    const storedName = localStorage.getItem("ProposalName");
    if (storedName) setPersonName(storedName);

    const storedDistrict = localStorage.getItem("District");
    if (storedDistrict) setUserDistrict(storedDistrict);

    const storedAge = localStorage.getItem("ageadded");
    if (storedAge) setUserAge(storedAge);

    const storedGender = localStorage.getItem("Gender");
    if (storedGender) setUserGender(storedGender);

    const storedSmoke = localStorage.getItem("Smoke");
    if (storedSmoke) setUserSmoke(storedSmoke);

    const storedEmploymentType = localStorage.getItem("Occupation");
    if (storedEmploymentType) setEmploymentType(storedEmploymentType);
  }, []);

  const [isOpen, setIsOpen] = useState(true);

  const handlePopupOpen = () => {
    setIsOpen(!isOpen);
  };

  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = 400; // Adjust this value as needed
      if (window.pageYOffset > offset) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const LifeCoverDetails = [
    { id: 0, Cover: 50 },
    { id: 1, Cover: 100 },
    { id: 2, Cover: 200 },
    { id: 3, Cover: 300 },
    { id: 4, Cover: 400 },
    { id: 5, Cover: 500 },
    // { id: 6, Cover: 600 },
    // { id: 7, Cover: 700 },
    // { id: 8, Cover: 800 },
    // { id: 9, Cover: 900 },
    // { id: 9, Cover: 1000 },
  ];

  const CoverUpto = [];

  for (let i = 27; i <= 84; i++) {
    CoverUpto.push({ key: i });
  }
  console.log(CoverUpto);

  const Payfrequncy = [
    {id: 0,data: "Monthly"},
    {id: 1,data: "Quterly"},
    {id: 2,data: "Half-Yearly"},
    {id: 3,data: "Yearly"},
  ];

  const Planetype = [
    { id: 0, name: "Base Plan" },
    { id: 1, name: "Return of Premium" },
    { id: 2, name: "Whole Life" },
    { id: 3, name: "Zero Cost Plan" },
  ];

  const shortby = [
    { id: 0, name: "Relevance" },
    { id: 1, name: "High to Low" },
    { id: 2, name: "Low to High" },
  ];

  const Premium = [
    { id: 0, name: "Limited Pay" },
    { id: 1, name: "Reguler Pay" },
  ];

  const PremiumTenure = [
    { id: 0, name: 5 },
    { id: 1, name: 6 },
    { id: 2, name: 7 },
    { id: 3, name: 8 },
    { id: 4, name: 9 },
    { id: 5, name: 10 },
    { id: 6, name: 11 },
    { id: 7, name: 12 },
  ];

  // const { userData } = useSelector((state) => state.terms);

  const [shortBy, setShortBy] = useState("Relevance"); // Default sorting option




  const [planesArray, setPlanesArray] = useState([
    {
      id: 1,
      name: "Saral Jeevan Bima",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4lrrMvwV6bWUR8L9O4FrK5rqhp-h2NkLSjA&s",
      price: 9999,
      company: "ICICI",
    },
    {
      id: 2,
      name: "Saral Jeevan Bima",
      image:
        "https://pay-eli.hdfclife.com/renew/resources/images/HDFC-Life-Web-Banners.jpg",
      price: 12999,
      company: "HDFC LIFE",
    },
    {
      id: 3,
      name: "Star Health Care",
      image:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/e/ed/Star_Health_and_Allied_Insurance.svg/2560px-Star_Health_and_Allied_Insurance.svg.png",
      price: 10999,
      company: "STAR",
    },
    {
      id: 4,
      name: "Care Term Prime",
      image:
        "https://upload.wikimedia.org/wikipedia/commons/9/90/Care_health_insurance_logo.png",
      price: 7999,
      company: "CARE",
    },
    {
      id: 5,
      name: "Digit Term Life",
      image: "https://www.chittorgarh.com/images/ipo/godigit_logo.png",
      price: 9599,
      company: "DIGIT",
    },
  ]);

  const handleSortByPrice = (option) => {
    const sortedPlanes = sortPlanesByPrice(planesArray, option);
    setPlanesArray(sortedPlanes);
    setShortBy(option);
  };

  // Utility function to sort planes array by price
  const sortPlanesByPrice = (planes, sortBy) => {
    const sortedPlanes = [...planes];
    if (sortBy === "High to Low") {
      sortedPlanes.sort((a, b) => b.price - a.price);
    } else if (sortBy === "Low to High") {
      sortedPlanes.sort((a, b) => a.price - b.price);
    }
    return sortedPlanes;
  };

  const [LifeCover, setLifeCover] = useState(5);
  const [coverUpto, setCoverUpto] = useState(27);
  const [payFrequently, setpayFrequently] = useState("Yearly");
  const [planeTyoe, setplaneTyoe] = useState("Base Plan");
  const [payprimiumas, setpayprimiumas] = useState("Limited Pay");
  const [primiumtanure, setprimiumtanure] = useState(5);
  const [selectedCompanies, setSelectedCompanies] = useState([]);

  const [isPopupOpen, setIsPopupOpen] = useState(false); // Initially set to false

  const toggleSelection = (company) => {
    if (selectedCompanies.includes(company)) {
      setSelectedCompanies(
        selectedCompanies.filter((item) => item !== company),
      );
    } else {
      setSelectedCompanies([...selectedCompanies, company]);
    }
  };
  const applySelections = () => {

    console.log("Selected Companies:", selectedCompanies);
    setIsPopupOpen(!isPopupOpen);
  };

  const togglePopup = (event) => {

  };
  const cancelSelections = () => {
    setIsPopupOpen(!isPopupOpen); // Close the popup without applying selections
  };

  const HandelRestFilters = () => {
    setLifeCover(5);
    setCoverUpto(27);
    setpayFrequently("Monthly");
    setplaneTyoe("Base Plan");
    setpayprimiumas("Limited Pay");
    setprimiumtanure(5);
  };


  const [handelAddonOpen, setHandelAddonOpen] = useState(false);
  const handleAddonToggle = () => {
    setHandelAddonOpen(!handelAddonOpen)
  }



const [getApiResponce, setgetApiResponce] = useState({})


console.log('Connecting responce',getApiResponce)


const [coverAmount, setcoverAmount] = useState(getApiResponce.total_cover_amount ? getApiResponce.total_cover_amount : 50)
const HndelCoverAmount = (event,data) =>{
event.stopPropagation();
setcoverAmount(data)
}

const [policyTerm, setpolicyTerm] = useState(27)
const HandelPolicyTerm = (event,data)=>{
event.stopPropagation();
setpolicyTerm(data)
}

const [PayFrequency, setPayFrequency] = useState('Yearly')
const HandelPayfrequency = (event,data) =>{
  event.stopPropagation();
  setPayFrequency(data)
}


const [PlaneType, setPlaneType] = useState('Base Plane')
const HandelPlane = (event,data) =>{
  event.stopPropagation();
  setPlaneType(data)
}

const [short, setshort] = useState('Hight to Low')
const HandelShort = (event,data) =>{
  event.stopPropagation();
  setshort(data)
}

const [paypremiumas, setpaypremiumas] = useState('Limited Pay')
const HandelPrimiumpay = (event,data) =>{
  event.stopPropagation();
  setpaypremiumas(data)
}

const [PrimiumPayingTerm, setPrimiumPayingTerm] = useState(5)
const HandlPrimiumPayingTerm = (event,data) =>{
  event.stopPropagation();
  setPrimiumPayingTerm(data)
}

  const [activePagenation, setactivePagenation] = useState(null)
  const handleMenuClick = (menuName) => {
    setactivePagenation((prevMenu) => (prevMenu === menuName ? null : menuName));
  };

 
  const popupRef = useRef(null);

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setactivePagenation(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);






  const Gender = localStorage.getItem('Gender');
  const Name = localStorage.getItem('ProposalName');
  const phone = localStorage.getItem('Proposalphonenumber');
  const dob = localStorage.getItem('dateofbirth');
  const smoke = localStorage.getItem('Smoke');
  const education = localStorage.getItem('Education');
  const occupation = localStorage.getItem('Occupation');
  const AnnualIncome = localStorage.getItem('AnnualIncome');
  const pinCode = localStorage.getItem('PinCode');
  const State = localStorage.getItem('State');
  const district = localStorage.getItem('District');
  const userage = localStorage.getItem('ageadded');


  const HandelGender = (data) =>{
      if(data === "M"){
        return "Male"
      }else{
        return 'Female'
      }
  }
const UserGender = HandelGender(Gender)

function reverseDateFormat(dateStr) {
  const [year, month, day] = dateStr.split('-');
    return `${day}-${month}-${year}`;
  }
 const UserRevarceDob = reverseDateFormat(dob)


 const [PlaneData, setPlaneData] = useState([]);


  const Payloaddata = {
   gender: UserGender,
    name: Name,
    phone_no: phone,
    dob: UserRevarceDob,
    smoke: smoke,
    education: education,
    occupation: occupation,
    annual_income: AnnualIncome,
    state: State,
    district: district,
    pin_code: pinCode,
    total_cover_amount: coverAmount,
    policy_term: policyTerm,
    payment_frequency: PayFrequency,
    plan_type: PlaneType,
    premium_paying_term: PrimiumPayingTerm,
    pay_premium_as: paypremiumas,
    plan_code: "",
    plan_name: "",
  }


  const [isLoading, setisLoading] = useState(false)



  const HandelUpdate = async () => {
    setisLoading(true)
    try {
      const response = await updateQuickQuots(unique_id,Payloaddata);
      setPlaneData(response.data.response)
    
      setisLoading(false)
    } catch (error) {
      console.error('Error during API call:', error);
    }

    console.log("The Load",Payloaddata)
  };

  
  const fetchPlanData = async () => {
    try {
      const response = await CreateQuoteApiTerm(unique_id);
      setPlaneData(response.data.response);
      setgetApiResponce(response.data.request)
    } catch (err) {
      console.log(err);
    }
  };
  
  useEffect(() => {
    fetchPlanData();
  }, []);






  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => () => {
    setState({ ...state, [anchor]: open });
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const list = (anchor) => (
   
    <Box
      sx={{
        width: { xs: 340, md: 220 }, 
        [theme.breakpoints.up('md')]: {
          width: 520,
        },
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
    >
      <List style={{margin:'auto'}}>

      <div className="Lifecover">
      <InputLabel id="occupation-label" sx={{ bgcolor: 'white' }} style={{color:'#0166B3',fontFamily:'Poppins',marginLeft:'5px',fontWeight:500}}>
            LifeCover
            </InputLabel>
      <FormControl sx={{ width: '100%', border: '1px solid #ccc', borderRadius: 2 , margin: 'auto'}}>
     
            <Select
              labelId="occupation-label"
              id="occupation"
              value={LifeCover}
              onChange={(e) => setLifeCover(e.target.value)}
              input={<OutlinedInput label="Occupation Type" />}
              sx={{ borderRadius: 'inherit' }}
            >
               {LifeCoverDetails.map((data, index) => (
                  <MenuItem key={index} value={data.Cover} onClick={(event)=>{HndelCoverAmount(event, data.Cover)}}>
                    {data.Cover} Lack
                  </MenuItem>
                ))}
            </Select>
      </FormControl>
      </div>

      <div className="Lifecover">
      <InputLabel id="occupation-label" sx={{ bgcolor: 'white' }} style={{color:'#0166B3',fontFamily:'Poppins',marginLeft:'5px',fontWeight:500}}>
      Policy Term
            </InputLabel>
      <FormControl sx={{ width: '100%', border: '1px solid #ccc', borderRadius: 2 , margin: 'auto'}}>
     
            <Select
              labelId="occupation-label"
              id="occupation"
              value={coverUpto}
              onChange={(e) => setCoverUpto(e.target.value)}
              input={<OutlinedInput label="Occupation Type" />}
              sx={{ borderRadius: 'inherit' }}
            >
               {CoverUpto.map((data, index) => (
                  <MenuItem key={index} value={data.key} onClick={(event) => {HandelPolicyTerm(event, data.key)}}>
                    {data.key} years
                  </MenuItem>
                ))}
            </Select>
      </FormControl>
      </div>

      <div className="Lifecover">
      <InputLabel id="occupation-label" sx={{ bgcolor: 'white' }} style={{color:'#0166B3',fontFamily:'Poppins',marginLeft:'5px',fontWeight:500}}>
      Payment Frequency
            </InputLabel>
      <FormControl sx={{ width: '100%', border: '1px solid #ccc', borderRadius: 2 , margin: 'auto'}}>
     
            <Select
              labelId="occupation-label"
              id="occupation"
              value={payFrequently}
              onChange={(e) => setpayFrequently(e.target.value)}
              input={<OutlinedInput label="Occupation Type" />}
              sx={{ borderRadius: 'inherit' }}
            >
               {Payfrequncy.map((data, index) => (
                  <MenuItem key={index} value={data.data} onClick={(event) => {HandelPayfrequency(event, data.data)}}>
                    {data.data} 
                  </MenuItem>
                ))}
            </Select>
      </FormControl>
      </div>

      <div className="Lifecover">
      <InputLabel id="occupation-label" sx={{ bgcolor: 'white' }} style={{color:'#0166B3',fontFamily:'Poppins',marginLeft:'5px',fontWeight:500}}>
     Planetype
            </InputLabel>
      <FormControl sx={{ width: '100%', border: '1px solid #ccc', borderRadius: 2 , margin: 'auto'}}>
     
            <Select
              labelId="occupation-label"
              id="occupation"
              value={planeTyoe}
              onChange={(e) => setplaneTyoe(e.target.value)}
              input={<OutlinedInput label="Occupation Type" />}
              sx={{ borderRadius: 'inherit' }}
            >
               {Planetype.map((data, index) => (
                  <MenuItem key={index} value={data.name} onClick={(event) => {HandelPlane(event, data.data)}}>
                    {data.name} 
                  </MenuItem>
                ))}
            </Select>
      </FormControl>
      </div>

      <div className="Lifecover">
      <InputLabel id="occupation-label" sx={{ bgcolor: 'white' }} style={{color:'#0166B3',fontFamily:'Poppins',marginLeft:'5px',fontWeight:500}}>
      Pay Premium as
            </InputLabel>
      <FormControl sx={{ width: '100%', border: '1px solid #ccc', borderRadius: 2 , margin: 'auto'}}>
     
            <Select
              labelId="occupation-label"
              id="occupation"
              value={payprimiumas}
              onChange={(e) => setpayprimiumas(e.target.value)}
              input={<OutlinedInput label="Occupation Type" />}
              sx={{ borderRadius: 'inherit' }}
            >
               {Premium.map((data, index) => (
                  <MenuItem key={index} value={data.name} onClick={(event) => {HandelPrimiumpay(event, data.name)}}>
                    {data.name} 
                  </MenuItem>
                ))}
            </Select>
      </FormControl>
      </div>








      <div className="Lifecover">
      <InputLabel id="occupation-label" sx={{ bgcolor: 'white' }} style={{color:'#0166B3',fontFamily:'Poppins',marginLeft:'5px',fontWeight:500}}>
      Primium Paying Term
            </InputLabel>
      <FormControl sx={{ width: '100%', border: '1px solid #ccc', borderRadius: 2 , margin: 'auto'}}>
     
            <Select
              labelId="occupation-label"
              id="occupation"
              value={primiumtanure}
              onChange={(e) => setprimiumtanure(e.target.value)}
              input={<OutlinedInput label="Occupation Type" />}
              sx={{ borderRadius: 'inherit' }}
            >
               {PremiumTenure.map((data, index) => (
                  <MenuItem key={index} value={data.name} onClick={(event) => {HandlPrimiumPayingTerm(event, data.name)}}>
                    {data.name} Years
                  </MenuItem>
                ))}
            </Select>
      </FormControl>
      </div>

      {/* <div className="Lifecover">
      <InputLabel id="occupation-label" sx={{ bgcolor: 'white' }} style={{color:'#0166B3',fontFamily:'Poppins',marginLeft:'5px',fontWeight:500}}>
     selected Company's
            </InputLabel>
      <FormControl sx={{ width: '100%', border: '1px solid #ccc', borderRadius: 2 , margin: 'auto'}}>
     
            <Select
              labelId="occupation-label"
              id="occupation"
              value={primiumtanure}
              onChange={(e) => setprimiumtanure(e.target.value)}
              input={<OutlinedInput label="Occupation Type" />}
              sx={{ borderRadius: 'inherit' }}
            >
               {PremiumTenure.map((data, index) => (
                  <MenuItem key={index} value={data.name}>
                    {data.name} Years
                  </MenuItem>
                ))}
            </Select>
      </FormControl>
      </div> */}
      </List>
      <div className="termbuttondiv"><button className="Applybtn" onClick={HandelUpdate}>Apply</button>
<button className="cancelld">Cancel</button>
</div>
    </Box>
 
  );

const nav = useNavigate()
const dispatch = useDispatch()
const loginData = useSelector(selectUserData);
const Handeleldatasend = (InsureName,PlaneID) =>{

    //console.log("Annualy Data", plan);
    if (loginData.data == false) {
      dispatch(updatedLoginPopup(true));
      return;
    }

  // dispatch(getSummerydata({data}))
  nav(`/term-insurance/termsummery?req_id=${unique_id}&planeId=${PlaneID}&company=${encodeURIComponent(InsureName)}`);



}





const combinedData = useSelector((state) => ({
  ...state.terms.userData,
  ...state.terms.updatePlane,
}));

console.log(combinedData);




const search = window.location.search;
const params = new URLSearchParams(search);
const unique_id = params.get("req_id");


console.log("The Planedata",PlaneData)

const formatCoverAmount = (amount) => {
  if (amount >= 1000) {
    return `${amount / 100} Cr`; // Convert 1000 to 10 CR
  } else if (amount >= 100) {
    return `${amount / 100} Cr`; // Convert 100 to 1 CR
  } else {
    return `${amount} L`; // Convert 50 to 50 Lacks
  }
};



const hasData = Object.keys(PlaneData).some(key => PlaneData[key].length > 0);

console.log('The Plane Come',hasData)

  return (
    <>
            <Helmet>
        <title>Terminsurence planes</title>
        {/* <meta name="description" content="A brief description of your page for SEO." /> */}
        <meta name="keywords" content="Insurance Plan, Insurance Quotes, buy insurance" />
      </Helmet>



      <Redirect />
      <div className="termtdit ">
        <div className="edit">





          <div className="d-flex justify-content-between showbarresult">
            <p className="editp">
              {" "}
              8 plans found for Sumon Chakraborty at HOOGHLY
            </p>
            <button className="editb" onClick={handlePopupOpen}>
              {" "}
              <i className="fa fa-pencil-square-o mx-2" aria-hidden="true" />
              Edit Details
            </button>
          </div>
 





        </div>
      </div>

      <div className={isSticky ? "sticky" : "userdetailsdivs"}>
        <div>
          <div className="underdivsterm">
            <p>{Name}</p>
          </div>
          
          <div className="termdetailsdivs">
            <p>{UserGender}</p>{" "}
        
            <span className="sidebarArrow">|</span>{" "}
            <p>{smoke === "true" ? "Smoker" : "Non-Smoker"}</p>{" "}
            <span className="sidebarArrow">|</span>{" "}
            <p>{userage}</p>{" "}
            <span className="sidebarArrow">|</span>{" "}
            <p>
              {occupation === "Salaried"
                ? "Employed"
                : "Self-Employed"}
            </p>
            <button className="filterterm" onClick={toggleDrawer('right', true)}><CiFilter style={{fontSize:"23px"}}/> Filter
</button>
          </div>

          <hr />

          <div className="filterterminsurence">
            






{/* Cover Amount */}
<div className="underfilterdiv">
<span>Cover Amount</span>
<div className="selectdives" onClick={() => handleMenuClick("cover")}>
{formatCoverAmount(coverAmount)}<GrFormDown />
{activePagenation === "cover" ? ( <div className="menubar" ref={popupRef}> 
<div className="overflowmenubar">
{LifeCoverDetails.map((data) => {return (
<li key={data.Cover} className={"listborder"} onClick={(event) => {HndelCoverAmount(event, data.Cover)}}>
<div className={ coverAmount == data.Cover ? "roundcireclactive" : "roundcirecldeactive"}></div>
{formatCoverAmount(data.Cover)}
</li> );
})}
 </div>
<div className="termbuttondiv"><button className="Applybtn" onClick={HandelUpdate}>Apply</button>
<button className="cancelld">Cancel</button>
</div>
</div>) : null}
</div>
</div>

{/* Policy Term */}

<div className="underfilterdiv">
<span>Policy Term</span>
<div className="selectdives" onClick={() => handleMenuClick("policy_term")}>
{policyTerm} Years<GrFormDown />
{activePagenation === "policy_term" ? ( <div className="menubar" ref={popupRef}> 
<div className="overflowmenubar">
{CoverUpto.map((data) => {return (
<li key={data.Cover} className={"listborder"} onClick={(event) => {HandelPolicyTerm(event, data.key)}}>
<div className={ policyTerm == data.key ? "roundcireclactive" : "roundcirecldeactive"}></div>
{data.key} Years
</li> );
})}
 </div>
<div className="termbuttondiv"><button className="Applybtn" onClick={HandelUpdate}>Apply</button>
<button className="cancelld">Cancel</button>
</div>
</div>) : null}
</div>
</div>


{/* Payment Frequency */}


<div className="underfilterdiv">
<span>Payment Frequency</span>
<div className="selectdives" onClick={() => handleMenuClick("payment_frequency")}>
{PayFrequency}<GrFormDown />
{activePagenation === "payment_frequency" ? ( <div className="menubar" ref={popupRef} > 
<div className="overflowmenubar">
{Payfrequncy.map((data) => {return (
<li key={data.data} className={"listborder"} onClick={(event) => {HandelPayfrequency(event, data.data)}}>
<div className={ PayFrequency == data.data ? "roundcireclactive" : "roundcirecldeactive"}></div>
{data.data}
</li> );
})}
 </div>
<div className="termbuttondiv"><button className="Applybtn" onClick={HandelUpdate}>Apply</button>
<button className="cancelld">Cancel</button>
</div>
</div>) : null}
</div>
</div>



{/* Plane Type */}


<div className="underfilterdiv">
<span>Plane Type</span>
<div className="selectdives" onClick={() => handleMenuClick("plane_type")}>
{PlaneType}<GrFormDown />
{activePagenation === "plane_type" ? ( <div className="menubar" ref={popupRef}> 
<div className="overflowmenubar">
{Payfrequncy.map((data) => {return (
<li key={data.data} className={"listborder"} onClick={(event) => {HandelPlane(event, data.data)}}>
<div className={ PlaneType == data.data ? "roundcireclactive" : "roundcirecldeactive"}></div>
{data.data}
</li> );
})}
 </div>
<div className="termbuttondiv"><button className="Applybtn" onClick={HandelUpdate}>Apply</button>
<button className="cancelld">Cancel</button>
</div>
</div>) : null}
</div>
</div>

{/* Short By */}





















<div className="underfilterdiv">
<span>Short By</span>
<div className="selectdives" onClick={() => handleMenuClick("short")}>
{short}<GrFormDown />
{activePagenation === "short" ? ( <div className="menubar" ref={popupRef}> 
<div className="overflowmenubar">
{shortby.map((data) => {return (
<li key={data.name} className={"listborder"} onClick={(event) => {HandelShort(event, data.name)}}>
<div className={ short == data.name ? "roundcireclactive" : "roundcirecldeactive"}></div>
{data.name}
</li> );
})}
 </div>
<div className="termbuttondiv"><button className="Applybtn" onClick={HandelUpdate}>Apply</button>
<button className="cancelld">Cancel</button>
</div>
</div>) : null}
</div>
</div>

{/*  Primium pay as */}


<div className="underfilterdiv">
<span>Pay Premium as</span>
<div className="selectdives" onClick={() => handleMenuClick("pay_premium_as")}>
{paypremiumas}<GrFormDown />
{activePagenation === "pay_premium_as" ? ( <div className="menubar" ref={popupRef}> 
<div className="overflowmenubar">
{Premium.map((data) => {return (
<li key={data.name} className={"listborder"} onClick={(event) => {HandelPrimiumpay(event, data.name)}}>
<div className={ paypremiumas == data.name ? "roundcireclactive" : "roundcirecldeactive"}></div>
{data.name}
</li> );
})}
 </div>
<div className="termbuttondiv"><button className="Applybtn" onClick={HandelUpdate}>Apply</button>
<button className="cancelld">Cancel</button>
</div>
</div>) : null}
</div>
</div>


{/* Primium paying Term */}


<div className="underfilterdiv">
<span>Primium Paying Term</span>
<div className="selectdives" onClick={() => handleMenuClick("Premium_paying_term")}>
{PrimiumPayingTerm} Years <GrFormDown />
{activePagenation === "Premium_paying_term" ? ( <div className="menubar" ref={popupRef}> 
<div className="overflowmenubar">
{PremiumTenure.map((data) => {return (
<li key={data.name} className={"listborder"} onClick={(event) => {HandlPrimiumPayingTerm(event, data.name)}}>
<div className={ PrimiumPayingTerm == data.name ? "roundcireclactive" : "roundcirecldeactive"}></div>
{data.name} Years
</li> );
})}
 </div>
<div className="termbuttondiv"><button className="Applybtn" onClick={HandelUpdate}>Apply</button>
<button className="cancelld">Cancel</button>
</div>
</div>) : null}
</div>
</div>


            
            {/* <button onClick={HandelRestFilters}>Rest all Filter</button> */}
          </div>
        </div>
        <div>



  <Drawer
    anchor="right"
    open={state.right}
    onClose={toggleDrawer('right', false)}
  
  >
    {list('right')}
  </Drawer>

    </div>
      </div>

      {/* Plans Page Start Hear */}

      <div className="planspage">
        <div className="underplanepageterm">
          
          <div className="underplanes">
            {  
           
           
            hasData === false ? (
              <>
                <div className="nodatafounddiv">
                  <FastImage sr width={auto} height={auto}/>
                  <h2>
                    Oops! No plans found for your selected filters. Please try
                    again with different filters
                  </h2>
                </div>
              </>
            )
            
            
            : (
              <>
 
{
  isLoading ? (  
    <>
 
    <Box sx={{ width: '90%' ,margin:'auto'}}>
    <Skeleton />
    <Skeleton animation="wave" />
    <Skeleton animation={false} />
  </Box>
  <br />
  <Box sx={{ width: '90%',margin:'auto' }}>
    <Skeleton />
    <Skeleton animation="wave" />
    <Skeleton animation={false} />
  </Box>
  <br />
  <Box sx={{ width: '90%' ,margin:'auto'}}>
    <Skeleton />
    <Skeleton animation="wave" />
    <Skeleton animation={false} />
  </Box>
  <br />
  <Box sx={{ width: '90%' ,margin:'auto'}}>
    <Skeleton />
    <Skeleton animation="wave" />
    <Skeleton animation={false} />
  </Box>
  <br />
  <Box sx={{ width: '90%' ,margin:'auto'}}>
    <Skeleton />
    <Skeleton animation="wave" />
    <Skeleton animation={false} />
  </Box>
<br />
<Box sx={{ width: '90%' ,margin:'auto'}}>
    <Skeleton />
    <Skeleton animation="wave" />
    <Skeleton animation={false} />
  </Box>

  
  </>
  
) : 
Object.keys(PlaneData).map((insurerKey) => (
  PlaneData[insurerKey].map((data) => {
    // Adjust access according to new structure
    const features = data.features || []; // Default to empty array if undefined
    const totalPremium = data.premiumBreakup ? data.premiumBreakup.totalPremium : 0;

    return (
      <div className="termplanes" key={data.plan_code}>
        <div className="imageterm">
          <img src={data.insurerLogo} alt="InsureLogo" />
          <div className="addtocompair">
            <input type="checkbox" />
            <p>Add to compare</p>
          </div>
        </div>

        <div className="underdetails">
          <div className="details">
            <div className="detailspolicy">
              <div className="uderpolicy">
                <p className="planename">{data.plan_name}</p>
                <span className="planespan">{data.planDescription}</span>
              </div>
              <a href="">
                View Benefits <AiOutlineDoubleRight />
              </a>
            </div>

            <div className="undercovermanagemnet">
              <div className="clamsterm">
                <span>Cover Amount</span>
                <h3>{formatToINR(data.total_cover_amount)}</h3>
              </div>
              <div className="clamsterm">
                <span>Policy Term</span>
                <h3>{data.policy_term} Years</h3>
              </div>
              <div className="clamsterm">
                <span>Claim Settled</span>
                <h3>99.66 % <AiOutlineInfoCircle /></h3>
              </div>
            </div>
          </div>
          <hr />

          <div className="addondivs">
            <div
              className={handelAddonOpen ? "addon" : "OpenAddon"}
              onClick={handleAddonToggle}
            >
              {handelAddonOpen ? null : (
                <span className="addonopen">
                  {features.length} Addons Included &nbsp;<FaAngleDown />
                </span>
              )}
              {handelAddonOpen && (
                <ul>
                  {features.map((feature, index) => (
                    <li key={index}>
                      <AiOutlineCheck
                        style={{
                          marginRight: "5px",
                          fontSize: "12pt",
                          color: "rgb(1 197 81)",
                        }}
                      />
                      {typeof feature === 'object' ? feature.name : feature}
                      <span className="cuseaddon">{feature}</span>
                      <span className="Typeterm">included</span>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>

        <div className="buttontermdiv">
          <button onClick={()=>Handeleldatasend(data.insurerName,data.plan_code)}>
            {formatToINR(totalPremium.toLocaleString("en-IN"))}
            {/* <span>Year</span> */}
          </button>
        </div>
      </div>
    );
  })
)



)
}
              


              </>
            )}
          </div>
          <div className="carasoulterm">
            <div className="righttop text-center ">
              <div className="col" style={{ padding: 0 }}>
                <div className="col">
                  <p>
                    11k+ customers in Mumbai got claims assistance worth ₹52cr
                  </p>
                </div>
                <hr className="termhr"/>
                <div className="col text-center">
                  <div className="row">
                    <div className="col-4 text-right p-0">
                      <i
                        className="fa fa-user-circle-o user"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="col-8 p-0 text-left">
                      <h1
                        className="h1 p-0"
                        style={{ lineHeight: "0.9", marginLeft: 10 }}
                      >
                        Lorem Ipsum{" "}
                        <i
                          className="fa fa-instagram"
                          aria-hidden="true"
                          style={{ color: "rgb(255, 0, 45)" }}
                        />{" "}
                        <br />{" "}
                        <span style={{ fontSize: 12 }}>There is no ones</span>
                      </h1>
                    </div>
                  </div>
                </div>
                <div className="row px-3 text-left">
                  <div className="col ">
                    <span style={{ fontSize: 13 }}>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took <br />
                      <span style={{ color: "red" }}>
                        All Story{" "}
                        <i
                          className="fa fa-chevron-right faaroc10px"
                          aria-hidden="true"
                        />
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="rightdown text-left ">
              <div className="row px-3 text-left">
                <div className="col ">
                  <span style={{ fontSize: 13 }}>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took{" "}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermfetchPlanes;
