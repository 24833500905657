import {feetAndInchesToCm} from "./common";


const payload = (data,PlanData,userdata,addonsArray,ICICIcheckedQuestion) =>
{
	// //console.log('userdata',userdata)
	// //console.log("PlanData ",PlanData)

	const heightdata = feetAndInchesToCm(data.height_feet , data.height_inch);

	//console.log("the bheightdata", heightdata);
  
  let tenure =  1;
  
  if(PlanData.hasOwnProperty('premiumBreakup'))
  {
    tenure =  PlanData.premiumBreakup.term
  }


  console.log("the ped",userdata )
  
   let output = {
    "ProposalName": data.ProposalName,
    "Proposalphonenumber": data.Proposalphonenumber,
    "street": data.street,
    "PinCode": data.PinCode,
    "District": data.District,
    "City": data.District,
    "State": data.Sate,
    "PED": userdata.PED.join(),
    "Email": data.Email,
    "dateOfbirth": data.KycDateOfbirth,
    "height": data.height || heightdata || data.InsuredDetail[0].height,
    "weight": data.weight || data.InsuredDetail[0].weight,
    "Occupation": data.Occupation,
    "GrossIncome": data.AnnualIncome,
    "Gender": userdata.Gender == 'M' ? 'Male' : 'Female',
    "Premium": PlanData.premiumBreakup.totalPremium,
    "SumInsured": PlanData.sumInsured,
    "PlanCode": PlanData.planCode,
    "Tenure": tenure+" Year",
    "InsuredDetail": InsuredDetail(data.InsuredDetail,userdata,ICICIcheckedQuestion),
    "nominee": {
        "firstName": extractNames(data.nomineeName).fname,
        "middleName": null,
        "lastName": extractNames(data.nomineeName).lname,
        "dateOfbirth": data.nomineeDateOfbirth,
        "profession": null,
        "gender": data.nomineerelationship == 'Self' ? userdata.Gender : getGender(data.nomineerelationship),
        "relationship": data.nomineerelationship == 'Husband' || data.nomineerelationship == 'Wife' ? 'Spouse' : data.nomineerelationship,
        "addons": addonsArray ? addonsArray.join() : "",
        "street" : data.nomineeAddress
    },
    "kycId": data.kycId,
    "cardNum": data.cardNum,
    "GUID": "97180dc3-8d32-41d5-b033-5ad8395cc349",
    "userToken":localStorage.getItem('userToken'),
    "addons": addonsArray ? addonsArray.join() : ""
}
	

	return output;
}

const InsuredDetail = (data,userdata,ICICIcheckedQuestion) => 
{
  let result = [];

  for (let i = 0; i < data.length; i++) 
  {
  	let obj = {
  		         InsuredName:data[i].InsuredName,
  		         InsuredRelation:data[i].InsuredRelation == 'Husband' || data[i].InsuredRelation == 'Wife' ? 'Spouse' : data[i].InsuredRelation,
  		         dateOfbirth:data[i].dateOfbirth,
  		         Gender: data[i].InsuredRelation == 'Self' ? (userdata.Gender == 'M' ? 'Male' : 'Female') : getGender(data[i].InsuredRelation),
  		         InsuredAge:data[i].InsuredAge,
  		         height:data[i].height,
  		         weight:data[i].weight,
  		         IPED: true,
  		         MaritalStatus:data[i].MaritalStatus,
  		         PEDIllness:MedicalQuestionDetails(i,ICICIcheckedQuestion)
  		      };
  	result.push(obj);
  }
  return result;
}

const extractNames = (fullName) =>
{
  // Split the full name into an array of words
  const nameArray = fullName.split(' ');

  // Extract the first name (fname)
  const fname = nameArray[0];

  // Extract the last name (lname) if available
  const lname = nameArray.length > 1 ? nameArray.slice(1).join(' ') : '';

  return { fname, lname };
}
 
const getGender = (relation) =>
{
  const output = {
  	"Mother":'Female',
  	"Father":'Male',
  	"Grand Father":'Male',
  	"Grand Mother":'Female',
  	"Brother":'Male',
  	"Sister":'Female',
  	"Husband":'Male',
  	"Wife":'Female',
  	"Father In Law":'Male',
  	"Mother In Law":'Female',
  	"Uncle":'Male',
  	"Aunt":'Female',
  	"Grand Daughter":'Female',
  	"Grand Son":'Male',
  	"Son":'Male',
  	"Daughter":'Female'

  }
  return output[relation];
} 

const MedicalQuestionDetails = (index,HDFCcheckedQuestion) => 
{
	let array = [];
  for (let key in HDFCcheckedQuestion) {
   	if(HDFCcheckedQuestion[key].hasOwnProperty(index))
   	{
   		array.push(key);
   		
   	}
   } 
	return array;

}


export {payload}