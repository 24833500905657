
import { useLocation, useNavigate} from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import { useState } from "react";



const Redirect = () => {
  const routeToComponentName = [
    { routename: "/", Compo: "Home" },
    { routename: "/health-insurance/*", Compo: "Health-Insurance" },
    {
      routename: "/health-insurance/health-compare-quotes",
      Compo: "Health-Compare-Quotes",
    },
    {
      routename: "/health-insurance/family-health-insurance",
      Compo: "Family-Health-Insurance",
    },
    {
      routename: "/health-insurance/critical-illness-insurance",
      Compo: "Critical-Illness-Insurance",
    },
    {
      routename: "/health-insurance/health-topup-plan",
      Compo: "Health-Topup-Plan",
    },
    {
      routename: "/health-insurance/senior-citizen-insurance",
      Compo: "Senior-Citizen-Insurance",
    },
    {
      routename: "/health-insurance/individual-health-insurance",
      Compo: "Individual-Health-Insurance",
    },
    { routename: "/understandpolicy", Compo: "Understand-Policy" },
    { routename: "/howbeemawork", Compo: "How-Beema-Works" },
    { routename: "/typeofpolicy", Compo: "Type-of-Policy" },
    { routename: "/term-insurance", Compo: "Term-Insurance" },
    { routename: "/healthStep1", Compo: "Step1" },
    { routename: "/healthStep2", Compo: "Step2" },
    { routename: "/healthStep3", Compo: "Step3" },
    { routename: "/plans", Compo: "Plans" },
    { routename: "/afterplanfatch", Compo: "AfterPlanFetch" },
    { routename: "/proposal", Compo: "Proposal" },
    { routename: "/medicaldetails", Compo: "Medical-Details" },
    { routename: "/about", Compo: "About" },
    { routename: "/career", Compo: "Career" },
    { routename: "/termsandcondition", Compo: "Terms-and-Condition" },
    { routename: "/bloges", Compo: "Blogs" },
    { routename: "/contractus", Compo: "Contact-Us" },
    { routename: "/user-profile", Compo: "User-Profile" },
    { routename: "/dashboard", Compo: "Dashboard" },
    { routename: "/dashboard-policy", Compo: "Dashboard-Policy" },
    { routename: "/dashboard-claim", Compo: "Dashboard-Claim" },
    { routename: "/setup-reminder", Compo: "Setup-Reminder" },
    { routename: "/bike-insurance", Compo: "Bike-Insurance" },
    { routename: "/car-insurance", Compo: "Car-Insurance" },
    { routename: "/travel-insurance", Compo: "Travel-Insurance" },
    { routename: "/calculator", Compo: "Calculator-List" },
    { routename: "/calculator/sip-calculator", Compo: "SIP-Calculator" },
    {
      routename: "/calculator/lumpsum-calculator",
      Compo: "Lumpsum-Calculator",
    },
    { routename: "/calculator/fd-calculator", Compo: "FD-Calculator" },
    { routename: "/calculator/emi-calculator", Compo: "EMI-Calculator" },
    {
      routename: "/calculator/car-loan-calculator",
      Compo: "Car-Loan-Calculator",
    },
    {
      routename: "/calculator/home-loan-calculator",
      Compo: "Home-Loan-Calculator",
    },
    { routename: "/calculator/rd-calculator", Compo: "RD-Calculator" },
    {
      routename: "/calculator/simple-interest-calculator",
      Compo: "Simple-Interest-Calculator",
    },
    { routename: "/calculator/swp-calculator", Compo: "SWP-Calculator" },
  ];

const location = useLocation()
let routeName = "" || "Privious Page";

if (location.pathname === "/plans") {
  routeName = "Step 4";
}
if (location.pathname === "/afterplanfatch") {
  routeName = "Planes Page";
}
if (location.pathname === "/proposal") {
  routeName = "Summery Page";
}
if (location.pathname === "/health-insurance") {
  routeName = "HomePage";
}


 const navigate = useNavigate()
  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="backbutton container">
      <button onClick={goBack}>
        <IoMdArrowBack /> &nbsp; Back to {routeName}
      </button>
    </div>
  );
};

export default Redirect;
