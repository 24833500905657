import React, { useState,useEffect } from 'react'
import PartnerCompany from '../../AllPageComponents/PartnerCompany/PartnerCompany'
import FAQ from '../../AllPageComponents/FAQ/FAQ'
import axios from 'axios'
import { LuAlarmClock } from "react-icons/lu";
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2';
import Loader from '../../AllPageComponents/Loader';
import { FaArrowRotateLeft } from "react-icons/fa6";
import Redirect from '../../AllPageComponents/Rederict';
import NoteWarthyAdvice from '../../AllPageComponents/NoteWarthyAdvice/NoteWarthyAdvice';
import FastImage from '../../FastImage';
import { auto } from '@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core';
import TravelForm from '../../TravelInsurence/TravelForm';


const Reminder = () => {

  const FullName = localStorage.getItem('ProposalName')
  const PhoneNumber = localStorage.getItem('Proposalphonenumber')
  const PinCode = localStorage.getItem('PinCode')




  const [generatedCaptcha, setGeneratedCaptcha] = useState('');
  const [userInput, setUserInput] = useState('');
  const [resultMessage, setResultMessage] = useState('');

  // Function to generate a random alphanumeric string
  const generateRandomString = (length) => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
  };

  const generateCaptcha = () => {
    const captchaText = generateRandomString(6); // You can adjust the length as needed
    setGeneratedCaptcha(captchaText);
    setResultMessage('');
  };

  useEffect(()=>{
    generateCaptcha();
  },[])


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [formData, setFormData] = useState({
    car_type: 'Travel-Insurence',
    phone_no: '' || PhoneNumber,
    car_number: '',
    pin: '' || PinCode,
    name:'' || FullName
  });

  const [isLoading, setisLoading] = useState(false)

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
       if (userInput === generatedCaptcha) {
      setResultMessage('Correct');
    } else {
      setResultMessage('Captcha is wrong');
      setUserInput('');
      return
    }
    if(formData.car_number === "" || formData.name === "" || formData.pin === "" || formData.phone_no === ""){
      Swal.fire({
      title: 'Error!',
      text: 'All Filds Are Requird', // Changed punctuation for better readability
      icon: 'error',
      confirmButtonText: 'Back'
    });
    } else {
      setisLoading(true)
      try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}cms/car_enquiry`, formData);
        //console.log('data Submitted', response);
        if(response.status === 200){
          setisLoading(false)
          Swal.fire({
      title: 'Sucess!',
      text: 'Your Form is Sucessfully Submitted', // Changed punctuation for better readability
      icon: "success",
      confirmButtonText: 'Back'
    });

          setTimeout(() => {
            setFormData({
              car_type: 'Travel-Insurence',
              phone_no: '',
              car_number: '',
              pin: '',
              name: ''
            });
          }, 2000);
          setUserInput('');
        } else {
          Swal.fire({
      title: 'Error!',
      text: 'Somthing whent Wrong', // Changed punctuation for better readability
      icon: 'error',
      confirmButtonText: 'Back'
    });
        }
      } catch (err) {
        console.error(err);
        Swal.fire({
      title: 'Error!',
      text: 'Somthing whent Wrong', // Changed punctuation for better readability
      icon: 'error',
      confirmButtonText: 'Back'
    });
      }
    }
  }



  return (
    <div>
    <Redirect/>
    {isLoading ? <Loader/> : null}
    <section className="car-insurance insurance-all">
    <div className="container">
      <div className="row insurance-row-one">
        <div className="col-sm-12 col-md-4 col-lg-4 mian-one">
        <div className="healthdiscription">
        <h1>Travel Insurence</h1>
        <span>Term insurance is a type of life insurance that provides coverage for a specific period, known as the "term." Unlike permanent life insurance policies, such as whole life or universal life insurance, term insurance does not accumulate cash value over time. Instead, it offers a straightforward death benefit to the beneficiaries if the insured individual passes away during the term of the policy.</span>
        </div>

     <div className="insurance-btn-set">
            <button type="button" className="reminderbutton" data-toggle="modal" data-target="#reminderpopup"> <LuAlarmClock fontSize={"15pt"} style={{margin:"0 5px" ,color:'#FBA71B'}}/>{" "}Set Reminder</button>
               </div>



        </div>
        <div className="col-sm-12 col-md-4 col-lg-4 side-image">
         
          <FastImage
           
           url={"Website%20Img/mascot_image1679061853.png?updatedAt=1718801439617"}
           width={auto}
           height={auto}
           alt={"agent"}
           src={"Website%20Img/mascot_image1679061853.png?updatedAt=1718801439617"}
         />
        </div>
      <TravelForm/>
      </div>
    </div>
  </section>
  <section className="insurance-first-background travel-insurance-new-back">
  <div className="container-fluid">
    <div className="row insurance-row-background">
      <div className="col-sm-12 col-md-12 col-lg-12">
        
        <FastImage
           
           url={"Website%20Img/term-insurance.svg?updatedAt=1718801571610"}
           width={auto}
           height={auto}
           alt={"background"}
           src={"Website%20Img/term-insurance.svg?updatedAt=1718801571610"}
         />
      </div>
    </div>
  </div>
</section>
<section class="what-c-insurance">
        <div class="container">
            <div class="row first-section-of-fifth justify-content-center wci-row">
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="tile-text-with-text-shadow">
                        <h6>What is Travel Insurance</h6>
                    </div>
                    <p class="common-text-line-clamp">&lt;span style="font-family: Poppins;"&gt;It is an agreement between the insurer and the policyholder whereby an insurance company agrees to reimburse for medical costs incurred by the policyholder during the tenure of the policy. According to the policy terms, the insured may incur medical expenses if they get ill or meet an unfortunate accident that leads to treatment at the hospital. To avail of the coverage benefits of the policy, the policyholder must pay a specific amount periodically, called a premium. The premium is determined by the insurance company and must be paid by the policyholders without any fail either monthly, quarterly, half-yearly or annually&lt;/span&gt;&lt;br&gt;</p>
                </div>
            </div>
        </div>
    </section>

    <section className="adv-dis-of-insurance">
  <div className="container">
    <div className="row adv-dis-row">
      <div className="col-sm-12 col-md-6 col-lg-6">
        <div className="adv-dis-title">
          <h4>Advantage</h4>
        </div>
        <div className="left-sid-adv adv-dis-box">
          <div className="adv-dis-boxs">
            <div className="adv-number">1</div>
            <div className="adv-dis-text-box">
              <h6>Convenience</h6>
              <p>
               Lorem Ipsum is simply
                dummy text of the printing and typesetting industry. Lorem Ipsum
                has been the industry's
              </p>
            </div>
          </div>
          <div className="adv-dis-boxs">
            <div className="adv-number">2</div>
            <div className="adv-dis-text-box">
              <h6>Minimal Paperwork</h6>
              <p>
               Lorem Ipsum is simply
                dummy text of the printing and typesetting industry. Lorem Ipsum
                has been the industry's
              </p>
            </div>
          </div>
          <div className="adv-dis-boxs">
            <div className="adv-number">3</div>
            <div className="adv-dis-text-box">
              <h6>Affordable Process</h6>
              <p>
                Lorem Ipsum is simply
                dummy text of the printing and typesetting industry. Lorem Ipsum
                has been the industry's
              </p>
            </div>
          </div>
          <div className="adv-dis-boxs">
            <div className="adv-number">4</div>
            <div className="adv-dis-text-box">
              <h6>Make the Process Faster</h6>
              <p>
             Lorem Ipsum is simply
                dummy text of the printing and typesetting industry. Lorem Ipsum
                has been the industry's.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-sm-12 col-md-6 col-lg-6">
        <div className="adv-dis-title">
          <h4>Disadvantage</h4>
        </div>
        <div className="right-sid-dis adv-dis-box">
          <div className="adv-dis-boxs">
            <div className="adv-number">1</div>
            <div className="adv-dis-text-box">
              <h6>Convenience</h6>
              <p>
            Lorem Ipsum is simply
                dummy text of the printing and typesetting industry. Lorem Ipsum
                has been the industry's.
              </p>
            </div>
          </div>
          <div className="adv-dis-boxs">
            <div className="adv-number">2</div>
            <div className="adv-dis-text-box">
              <h6>Minimal Paperwork</h6>
              <p>
               Lorem Ipsum is simply
                dummy text of the printing and typesetting industry. Lorem Ipsum
                has been the industry's.
              </p>
            </div>
          </div>
          <div className="adv-dis-boxs">
            <div className="adv-number">3</div>
            <div className="adv-dis-text-box">
              <h6>Affordable Process</h6>
              <p>
               Lorem Ipsum is simply
                dummy text of the printing and typesetting industry. Lorem Ipsum
                has been the industry's.
              </p>
            </div>
          </div>
          <div className="adv-dis-boxs">
            <div className="adv-number">4</div>
            <div className="adv-dis-text-box">
              <h6>Make the Process Faster</h6>
              <p>
          Lorem Ipsum is simply
                dummy text of the printing and typesetting industry. Lorem Ipsum
                has been the industry's
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<PartnerCompany/>
<FAQ/>


    </div>
  )
}

export default Reminder